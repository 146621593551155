<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start" style="margin-left:10px;">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title class="ion-text-center">
            <img class="title-image" src="{{logoUrl}}" width="96" height="55" *ngIf="logoUrl" />
        </ion-title>
    </ion-toolbar>
</ion-header>
<div class="ion-padding-horizontal" style="background-color:white; padding-top:15px !important; padding-bottom:10px;" *ngIf="showLoadingMetadata">
    <div>
        <div style="float:left"> <ion-icon name="arrow-back" style="font-size:24px; margin-bottom: 0px; padding-bottom:0px" (click)="loadRootServices()" *ngIf="!isAtRoot"></ion-icon><h2 class="screen-title"></h2></div>
        <div *ngIf="this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location">
            <a (click)="changeLocation()">{{this.getLocationText()}}</a>
        </div>
        <div *ngIf="!this.stateService.device$ || !this.stateService.device$.userPreferences || !this.stateService.device$.userPreferences.deliveryMethod || !this.stateService.device$.userPreferences.userLocation" class="change-location">
            <a (click)="changeLocation()">Change Preferences</a>
        </div>
    </div>
</div>
<ion-content class="ion-padding-horizontal" *ngIf="showLoadingMetadata && this.hideSkeleton && isDefaultServiceListTemplate()" [scrollEvents]="true" (ionScroll)="didScroll($event)">
    <!--<div *ngIf="this.stateService.device$ && this.stateService.device$.taeamServices && (this.stateService.device$.taeamServices.length > 1 || this.stateService.props$.manualServiceViewCall)">-->
    <div id="top"></div>
    <div *ngIf="this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0">
        <div class="{{getCardViewCss(false, 'product-card')}}" *ngFor="let service of this.stateService.device$.taeamServices; let index = index;" (click)="showService(service)">
            <div class="product-cover">
                <img src="{{service.imageUrl}}" alt="">
                <!--<div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                    <ion-icon name="md-share" (click)="showSocialSharingForTaeam($event)"></ion-icon>
                </div>-->
                <div class="free-simple-top" *ngIf="service.tagText"><span class="fa fa-tag"></span> {{service.tagText}}</div>
            </div>
            <div class="product-detail">
                <h2>{{service.serviceHeading}}</h2>
                <p style="text-align:left;">
                    {{service.serviceDescription}}
                </p>
            </div>
        </div>
    </div>
    <taeam-service-marketing-data></taeam-service-marketing-data>
    <app-powered-by-message></app-powered-by-message>
</ion-content>
<ion-content class="ion-padding-horizontal" *ngIf="showLoadingMetadata && this.hideSkeleton && isIconsServiceListTemplate()" [scrollEvents]="true" (ionScroll)="didScroll($event)">
    <div id="top"></div>
    <div class="ad-card-details" *ngIf="topSliderAds && topSliderAds.length > 1">
        <ion-slides [options]="getAdSliderSettings(topSliderAds, null, true)">
            <ion-slide *ngFor="let ad of topSliderAds; let index = index">
                <ad-view #adView style="width:100%; max-width:100%" [ad]="ad" checkForServiveAdLink="true"></ad-view>
            </ion-slide>
        </ion-slides>
    </div>
    <div class="ad-card-details" *ngIf="topSliderAds && topSliderAds.length == 1">
        <ad-view #adView [ad]="this.topSliderAds[0]" checkForServiveAdLink="true"></ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.topAd && this.topAd.mobileVideoUrl">
        <video-ad-view #videoAdView [ad]="this.topAd" checkForServiveAdLink="true"></video-ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.topAd && this.topAd.imageUrl">
        <ad-view #adView [ad]="this.topAd" checkForServiveAdLink="true"></ad-view>
    </div>
    <ion-grid *ngIf="hasServices()">
        <ion-row class="ion-justify-content-around">
            <ion-col size="{{getServiceColumnsToMerge(service, index)}}" (click)="showService(service)" *ngFor="let service of this.stateService.props$.taeamServices; let index = index;">
                <div *ngIf="service.ads && service.ads.length > 0">
                    <div class="ad-card-details" *ngIf="service.ads && service.ads.length > 1">
                        <ion-slides [options]="getAdSliderSettings(service.ads, service, true)">
                            <ion-slide *ngFor="let ad of service.ads; let index = index">
                                <ad-view #adView style="width:100%; max-width:100%" [ad]="ad" checkForServiveAdLink="true"></ad-view>
                            </ion-slide>
                        </ion-slides>
                    </div>
                    <div class="ad-card-details" *ngIf="service.ads && service.ads.length == 1">
                        <ad-view #adView [ad]="service.ads[0]" checkForServiveAdLink="true"></ad-view>
                    </div>
                </div>
                <ion-grid class="square service-shadow" *ngIf="!service.ads">
                    <ion-row>
                        <ion-col class="no-padding">
                            <div class="image">
                                <ion-img style="width:95%; height: 95%; max-height: 65px" [src]="getIconUrl(service)" *ngIf="getBaseUrl(service)"></ion-img>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row class="text">
                        <ion-col class="no-padding">
                            <div class="text">{{service.serviceShortTitle}}</div>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="ad-card-details" *ngIf="bottomSliderAds && bottomSliderAds.length > 1">
        <ion-slides [options]="getAdSliderSettings(bottomSliderAds, null, true)">
            <ion-slide *ngFor="let ad of bottomSliderAds; let index = index">
                <ad-view #adView style="width:100%; max-width:100%" [ad]="ad" checkForServiveAdLink="true"></ad-view>
            </ion-slide>
        </ion-slides>
    </div>
    <div class="ad-card-details" *ngIf="bottomSliderAds && bottomSliderAds.length == 1">
        <ad-view #adView [ad]="this.bottomSliderAds[0]" checkForServiveAdLink="true"></ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.bottomAd && this.bottomAd.mobileVideoUrl">
        <video-ad-view #videoAdView [ad]="this.bottomAd" checkForServiveAdLink="true"></video-ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.bottomAd && this.bottomAd.imageUrl">
        <ad-view #adView [ad]="this.bottomAd" checkForServiveAdLink="true"></ad-view>
    </div>
    <taeam-service-marketing-data></taeam-service-marketing-data>
    <app-powered-by-message></app-powered-by-message>
</ion-content>
<ion-content class="ion-padding-horizontal" *ngIf="showLoadingMetadata && this.hideSkeleton && isIconsServiceListWithCategoryTemplate()" [scrollEvents]="true" (ionScroll)="didScroll($event)">
    <div id="top"></div>
    <div class="ad-card-details" *ngIf="topSliderAds && topSliderAds.length > 1">
        <ion-slides [options]="getAdSliderSettings(topSliderAds, null, true)">
            <ion-slide *ngFor="let ad of topSliderAds; let index = index">
                <ad-view #adView style="width:100%; max-width:100%" [ad]="ad" checkForServiveAdLink="true"></ad-view>
            </ion-slide>
        </ion-slides>
    </div>
    <div class="ad-card-details" *ngIf="topSliderAds && topSliderAds.length == 1">
        <ad-view #adView [ad]="this.topSliderAds[0]" checkForServiveAdLink="true"></ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.topAd && this.topAd.mobileVideoUrl">
        <video-ad-view #videoAdView [ad]="this.topAd" checkForServiveAdLink="true"></video-ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.topAd && this.topAd.imageUrl">
        <ad-view #adView [ad]="this.topAd" checkForServiveAdLink="true"></ad-view>
    </div>
    <div *ngIf="hasServiceCategories()">
        <ion-grid *ngFor="let category of serviceCategories; let cindex = index;">
            <ion-row size="12" *ngIf="showServiceCategoryName(category)">
                <ion-col>
                    <div class="block-text-title">
                        <h1>{{category.serviceCategory}}</h1>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-around">
                <ion-col size="{{getServiceColumnsToMerge(service, index)}}" (click)="showService(service)" *ngFor="let service of category.taeamServices; let index = index;">
                    <div *ngIf="service.ads && service.ads.length > 0">
                        <div class="ad-card-details" *ngIf="service.ads && service.ads.length > 1">
                            <ion-slides [options]="getAdSliderSettings(service.ads, service, true)">
                                <ion-slide *ngFor="let ad of service.ads; let index = index">
                                    <ad-view #adView style="width:100%; max-width:100%" [ad]="ad" checkForServiveAdLink="true"></ad-view>
                                </ion-slide>
                            </ion-slides>
                        </div>
                        <div class="ad-card-details" *ngIf="service.ads && service.ads.length == 1">
                            <ad-view #adView [ad]="service.ads[0]" checkForServiveAdLink="true"></ad-view>
                        </div>
                    </div>
                    <ion-grid class="square service-shadow" *ngIf="!service.ads">
                        <ion-row>
                            <ion-col class="no-padding">
                                <div class="image">
                                    <ion-img style="width:95%; height: 95%; max-height: 65px" [src]="getIconUrl(service)" *ngIf="getBaseUrl(service)"></ion-img>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row class="text">
                            <ion-col class="no-padding">
                                <div class="text">{{service.serviceShortTitle}}</div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
    <div class="ad-card-details" *ngIf="bottomSliderAds && bottomSliderAds.length > 1">
        <ion-slides [options]="getAdSliderSettings(bottomSliderAds, null, true)">
            <ion-slide *ngFor="let ad of bottomSliderAds; let index = index">
                <ad-view #adView style="width:100%; max-width:100%" [ad]="ad" checkForServiveAdLink="true"></ad-view>
            </ion-slide>
        </ion-slides>
    </div>
    <div class="ad-card-details" *ngIf="bottomSliderAds && bottomSliderAds.length == 1">
        <ad-view #adView [ad]="this.bottomSliderAds[0]" checkForServiveAdLink="true"></ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.bottomAd && this.bottomAd.mobileVideoUrl">
        <video-ad-view #videoAdView [ad]="this.bottomAd" checkForServiveAdLink="true"></video-ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.bottomAd && this.bottomAd.imageUrl">
        <ad-view #adView [ad]="this.bottomAd" checkForServiveAdLink="true"></ad-view>
    </div>
    <taeam-service-marketing-data></taeam-service-marketing-data>
    <app-powered-by-message></app-powered-by-message>
</ion-content>
<ion-content *ngIf="showLoadingMetadata && !this.hideSkeleton">
    <!-- Skeleton screen -->
    <div class="product-card">
        <div class="product-cover ion-padding custom-skeleton">
            <ion-skeleton-text animated style="height: 100%"></ion-skeleton-text>
        </div>
        <div class="product-detail">
            <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
            <p>
                <ion-skeleton-text animated></ion-skeleton-text>
            </p>
        </div>
    </div>
    <div class="product-card">
        <div class="product-cover ion-padding custom-skeleton">
            <ion-skeleton-text animated style="height: 100%"></ion-skeleton-text>
        </div>
        <div class="product-detail">
            <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
            <p>
                <ion-skeleton-text animated></ion-skeleton-text>
            </p>
        </div>
    </div>
    <div class="product-card">
        <div class="product-cover ion-padding custom-skeleton">
            <ion-skeleton-text animated style="height: 100%"></ion-skeleton-text>
        </div>
        <div class="product-detail">
            <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
            <p>
                <ion-skeleton-text animated></ion-skeleton-text>
            </p>
        </div>
    </div>
    <div class="product-card">
        <div class="product-cover ion-padding custom-skeleton">
            <ion-skeleton-text animated style="height: 100%"></ion-skeleton-text>
        </div>
        <div class="product-detail">
            <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
            <p>
                <ion-skeleton-text animated></ion-skeleton-text>
            </p>
        </div>
    </div>
    <div class="product-card">
        <div class="product-cover ion-padding custom-skeleton">
            <ion-skeleton-text animated style="height: 100%"></ion-skeleton-text>
        </div>
        <div class="product-detail">
            <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
            <p>
                <ion-skeleton-text animated></ion-skeleton-text>
            </p>
        </div>
    </div>
</ion-content>
<ion-content class="no-data-found" *ngIf="!showLoadingMetadata">
    <ion-grid class="vertical-center">
        <ion-row>
            <ion-col>
                <taeam-loading-animation></taeam-loading-animation>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<taeam-cookie-consent></taeam-cookie-consent>