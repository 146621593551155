<div (click)="showServiceResourceAdWithLocationCheck($event, serviceResourceAd)" [ngClass]="applyPadding ? 'product-card' : 'product-card'" *ngIf="serviceResourceAd">
    <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasMultipleImagesOrVideos(serviceResourceAd.imagesAndVideos)">
        <!--<img src="{{resourcesBaseUrl}}{{serviceResourceAd.imageUrl}}" alt="">-->
        <ion-slides #slider [options]="slideOptions" (ionSlideTransitionEnd)="slideChange(slider)">
            <ion-slide #slide *ngFor="let asset of serviceResourceAd.imagesAndVideos; let aindex = index">
                <div class="clickable-div" (click)="showServiceResourceAdWithLocationCheck($event, serviceResourceAd)"></div>
                <img #img [src]="getServiceResourceResourceUrl(serviceResourceAd, asset.name)" (error)="onImageError(img)" alt="" *ngIf="!asset.isVideo">
                <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(serviceResourceAd.multipleDisplayVideoPosterUrls, aindex)" *ngIf="asset.isVideo">
                    <source [src]="getServiceResourceResourceUrl(serviceResourceAd, asset.name)" type="video/mp4" />
                </video>
                <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.paused">
                    <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                </div>
                <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.paused">
                    <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                </div>
                <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.muted">
                    <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                </div>
                <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.muted">
                    <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                </div>
                <div class="free-simple-top" *ngIf="!wlaActive && serviceResourceAd.tagText && serviceResourceAd.tagTextIsOffer">{{serviceResourceAd.tagText}}</div>
                <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                    <ion-icon name="md-share" (click)="showServiceResourceAdSocialSharing($event, serviceResourceAd)"></ion-icon>
                </div>
                <div class="offer-top" *ngIf="!wlaActive && serviceResourceAd.tagText && !serviceResourceAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{serviceResourceAd.tagText}}</div>
                <div class="restaurant-card-bottom-bar warning" *ngIf="serviceResourceAd.status && serviceResourceAd.status == 'comming-soon'">Coming soon</div>
                <div *ngIf="serviceResourceAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                        [style.background]="'url(' + resourcesBaseUrl + serviceResourceAd.logoUrl + ')'"
                        [style.background-size]="'100% 100%'">
                </div>
                <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive"></taeam-favourites-icon>
                <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
            </ion-slide>
        </ion-slides>
    </div>
    <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasImageOnly(serviceResourceAd.imagesAndVideos)">
        <img #img [src]="getServiceResourceAdResourceUrl(serviceResourceAd)" (error)="onImageError(img)" alt="">
        <div class="free-simple-top" *ngIf="!wlaActive && serviceResourceAd.tagText && serviceResourceAd.tagTextIsOffer">{{serviceResourceAd.tagText}}</div>
        <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
            <ion-icon name="md-share" (click)="showServiceResourceAdSocialSharing($event, serviceResourceAd)"></ion-icon>
        </div>
        <div class="offer-top" *ngIf="!wlaActive && serviceResourceAd.tagText && !serviceResourceAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{serviceResourceAd.tagText}}</div>
        <div class="restaurant-card-bottom-bar warning" *ngIf="serviceResourceAd.status && serviceResourceAd.status == 'comming-soon'">Coming soon</div>
        <div *ngIf="serviceResourceAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                [style.background]="'url(' + resourcesBaseUrl + serviceResourceAd.logoUrl + ')'"
                [style.background-size]="'100% 100%'">
        </div>
        <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive"></taeam-favourites-icon>
        <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
    </div>
    <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasVideoOnly(serviceResourceAd.imagesAndVideos)">
        <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(serviceResourceAd.multipleDisplayVideoPosterUrls, 0)">
            <source [src]="getServiceResourceAdResourceUrl(serviceResourceAd)" type="video/mp4" />
        </video>
        <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
            <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
            <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
            <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
            <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="free-simple-top" *ngIf="!wlaActive && serviceResourceAd.tagText && serviceResourceAd.tagTextIsOffer">{{serviceResourceAd.tagText}}</div>
        <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
            <ion-icon name="md-share" (click)="showServiceResourceAdSocialSharing($event, serviceResourceAd)"></ion-icon>
        </div>
        <div class="offer-top" *ngIf="!wlaActive && serviceResourceAd.tagText && !serviceResourceAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{serviceResourceAd.tagText}}</div>
        <div class="restaurant-card-bottom-bar warning" *ngIf="serviceResourceAd.status && serviceResourceAd.status == 'comming-soon'">Coming soon</div>
        <div *ngIf="serviceResourceAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                [style.background]="'url(' + resourcesBaseUrl + serviceResourceAd.logoUrl + ')'"
                [style.background-size]="'100% 100%'">
        </div>
        <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive"></taeam-favourites-icon>
        <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
    </div>
</div>
