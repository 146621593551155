import { Component, AfterViewInit, ChangeDetectorRef, Input, Output, ViewChildren, QueryList, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectBase } from 'src/app/shared/object.base';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/services/device.service';
import { SupportService } from '../../../services/support.service';
import { EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-news',
    templateUrl: 'app.news.html',
    styleUrls: ['app.news.scss']
})
export class NewsAlert extends ObjectBase implements OnInit, AfterViewInit {

    private deviceService: DeviceService;
    private deviceInfo: any;

    version: string;
    @Input() data: any;
    @Input() showAllActiveNews: boolean;
    @Input() hideClose: boolean;

    @Input() player: any;
    @Input() hideSliderView: boolean;
    @Input() applyPadding: boolean = false;
    @Input() doNotCallScroll: boolean = false;
    @Input() logClickEvent: boolean = false;
    @Input() source: string = "";

    @Output("didScroll") didScroll: EventEmitter<any> = new EventEmitter();

    currentSlideIndex: number = 0;

    @ViewChildren('adPlayer') adPlayers: QueryList<any>;
    @ViewChildren('slide') slides: QueryList<any>;

    @ViewChild("adPlayer") adPlayer: any;
    @ViewChild("slider") slider: any;


    //@ViewChild("adPlayer1", { static: false }) adPlayer1: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;

    public slideOptions = {
        slidesPerView: 1.05,
        initialSlide: 0,
        speed: 400,
        spaceBetween: 10
    };


    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected supportService: SupportService,
        private sanitizer: DomSanitizer) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.deviceService = DeviceService.Instance;
        this.deviceService.getDeviceInfo().then((deviceInfo) => {
            this.deviceInfo = deviceInfo;
            this.version = this.deviceInfo.appVersion;
        });
    }

    ngOnInit() {
        this.version = environment.version;
        this.personaliseService.logEvent("news-alert", JSON.stringify({}));
        if (!this.data || this.data.length == 0) {
            this.personaliseService.getTaeamNews().subscribe(result => {
                if (this.showAllActiveNews) {
                    this.data = result;
                    this.stateService.setProps({ newsData: this.data });
                } else {
                    this.checkIfNewsIdsArePresent(result);
                }
            });
        }
        //Because this scenario is already handeled in component
        //else {
        //    this.checkIfNewsIdsArePresent(this.data);
        //}
    }

    ngAfterViewInit() {
        if (this.data && this.data.length > 0 && !this.isNullOrEmpty(this.data[0].videoUrl)) {
            if (this.adPlayers && this.adPlayers.length > 0) {
                this.adPlayer = this.adPlayers.first.nativeElement;
                this.adPlayer.muted = true;
                this.adPlayer.play();
                this.Log("Ad player", this.adPlayer);
            }
        }
    }

    showNews($event, news: any) {
        if (news && !this.isNullOrEmpty(news.redirectLink)) {
            this.personaliseService.logEvent("news-alert-clicked", JSON.stringify({ id: news.id }));
            this.navigateWithState(news.redirectLink);
        }
    }

    dismiss() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    play($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.playVideo(player);
        $event.stopPropagation();
    }

    pause($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.pauseVideo(player);
        $event.stopPropagation();
    }

    mute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.muteAudio(player);
        $event.stopPropagation();
    }

    unmute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.unMuteAudio(player);
        $event.stopPropagation();
    }

    hasNewsData(): boolean {
        if (this.stateService && this.stateService.props$ && this.stateService.props$.newsData && this.stateService.props$.newsData.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    checkIfNewsIdsArePresent(data: any) {

        var newsList = this.clone(data);
        this.data = [];
        var currentDate = moment();
        this.Log("current date is ", currentDate);
        this.Log("news list", newsList);

        if (newsList && newsList.length > 0) {
            var newsLog = this.stateService.device$.newsLog;
            if (!newsLog) {
                newsLog = [];
            } else if (newsLog && newsLog.length > 0) {
                newsLog = newsLog.filter(x => currentDate.diff(moment(x.date), 'days') < x.expireInDays);
            }

            if (!this.data) {
                this.data = [];
            }

            for (var i = 0; i < newsList.length; i++) {
                var item = null;
                if (newsLog.length > 0) {
                    item = newsLog.filter(x => x.id == newsList[i].id);
                }

                if (!item || item.length == 0) {
                    newsLog.push({ date: currentDate, id: newsList[i].id, expireInDays: newsList[i].expireInDays });
                    this.data.push(this.clone(newsList[i]));
                } else {
                    if (newsList[i].forceShow) {
                        data.push(this.clone(newsList[i]));
                    }
                } 
            }

            this.Log("news log", newsLog);
            this.stateService.setProps({ newsData: this.data });
            this.stateService.setDevice({ newsLog: newsLog});
        }
    }
}
