import { Component, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { ObjectBase } from '../../object.base';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import { NavController } from '@ionic/angular';
import { ClassifiedAdsSearchService } from '../../../services/classified.ads.search.service';
import { ClassifiedAdsService } from '../../../services/classified.ads.service';


@Component({
    selector: 'taeam-filter-classified-ad-catagories',
    templateUrl: 'filter.classified.ad.catagories.html',
    styleUrls: ['filter.classified.ad.catagories.scss']
})
export class FilterClassifiedAdCatagories extends ObjectBase implements AfterViewInit {
    public classifiedAds: any[];
    @Input() categories: any[];
    @Input() topClass: string;
    @Input() includeMargin: boolean = true;
    @Input() templateId: number = 0;
    selectedCategory: any;
    allCategory: any;



    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected userService: UserService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private classifiedAdsService: ClassifiedAdsService,
        private classifiedAdsSearchService: ClassifiedAdsSearchService) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.classifiedAdsService = ClassifiedAdsService.Instance;
        this.classifiedAdsSearchService = ClassifiedAdsSearchService.Instance;
        this.allCategory = {};
        this.allCategory.name = 'All';
        this.allCategory.imageName = 'all.png';
        this.selectedCategory = this.allCategory;
        this.isCurrent(this.allCategory);
    }

    ngAfterViewInit(): void {
    }

    getImageNameUrl(category: any): string {
        if (category && category.imageName) {
            return this.getService().serviceAssetsUrl + category.imageName.toLowerCase().trim() + ".png";
        } else if (category && category.name) {
            return this.getService().serviceAssetsUrl + category.name.replace(' ', '').toLowerCase().trim() + ".png";// + foodType.imageName;
        }

        return null;
    }

    getNoImageUrl() {
        return this.getService().serviceAssetsUrl + "no-image.png";
    }

    //getNoImageUrl($event: any, item: any) {
    //    this.Log("No Food Type: ");
    //    $event.srcElement.src = this.getService().serviceAssetsUrl + "no-image.png";
    //}

    async search(category: any) {
        await this.showLoader();

        var categoryName: any = null;

        if (this.isCurrent(category)) {
            categoryName = '';
            this.stateService.setProps({ selectedfilterCategory: null });
            this.selectedCategory = null;
        } else {
            this.selectedCategory = category;
            categoryName = this.selectedCategory.name;
        }

        if (this.selectedCategory && this.selectedCategory.name == 'All') {
            categoryName = '';
            this.stateService.setProps({ selectedfilterCategory: null });
        } else {
            this.stateService.setProps({ selectedfilterCategory: category });
        }

        this.personaliseService.logEvent("classified-ads-searching-category", JSON.stringify({ category: category, searchText: this.stateService.props$.searchText }));
        if (!this.selectedCategory || category == '') {
            var longitude = 0;
            var latitude = 0;
            if (this.stateService.props$.userLocation && this.stateService.props$.userLocation && this.stateService.props$.userLocation.longitude != 0) {
                longitude = this.stateService.props$.userLocation.longitude;
                latitude = this.stateService.props$.userLocation.latitude;
            }

            if (this.stateService.props$.searchText && this.stateService.props$.searchText.length > 0) {
                this.classifiedAdsSearchService.searchClassifiedAdsByTag(this.stateService.props$.searchText)
                    .subscribe(
                        result => {
                            if (result) {
                                this.stateService.setProps({ forceSearchClassifiedAds: true });
                                this.personaliseService.clearRewarded();
                                this.Log(result);
                                this.classifiedAds = result;
                                this.classifiedAdsService.publish(result);
                                //this.restaurants.forEach(x => x.foodTypes.forEach(f => { if (!this.foodTypes.find(ft => ft.name === f)) { this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() }) } }));
                                this.state$.classifiedAds = this.classifiedAds;
                                //this.state$.foodTypes = this.foodTypes;
                                this.hideLoader();
                            }
                        },
                        error => {
                            //this.Log(error);
                            this.handleError(error);
                        },
                        () => {
                            this.Log('Finished sequence');
                            this.hideLoader();
                        }
                    );
            } else if (longitude != 0) {
                this.classifiedAdsSearchService.searchNearestClassifiedAdsByGeoLocation(longitude, latitude)
                    .subscribe(
                        result => {
                            if (result) {
                                this.personaliseService.clearRewarded();
                                this.Log(result);
                                this.stateService.setProps({ forceSearchClassifiedAds: true });
                                this.classifiedAds = result;
                                this.classifiedAdsService.publish(result);
                                //this.restaurants.forEach(x => x.foodTypes.forEach(f => { if (!this.foodTypes.find(ft => ft.name === f)) { this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() }) } }));
                                this.state$.classifiedAds = this.classifiedAds;
                                //this.state$.foodTypes = this.foodTypes;
                                this.hideLoader();
                            }
                        },
                        error => {
                            //this.Log(error);
                            this.handleError(error);
                        },
                        () => {
                            this.Log('Finished sequence');
                            this.hideLoader();
                        }
                    );
            } else {
                this.classifiedAdsSearchService.searchClassifiedAdsByTag('')
                    .subscribe(
                        result => {
                            if (result) {
                                this.personaliseService.clearRewarded();
                                this.Log(result);
                                this.stateService.setProps({ forceSearchClassifiedAds: true });
                                this.classifiedAds = result;
                                this.classifiedAdsService.publish(result);
                                //this.restaurants.forEach(x => x.foodTypes.forEach(f => { if (!this.foodTypes.find(ft => ft.name === f)) { this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() }) } }));
                                this.state$.classifiedAds = this.classifiedAds;
                                //this.state$.foodTypes = this.foodTypes;
                                this.hideLoader();
                            }
                        },
                        error => {
                            //this.Log(error);
                            this.handleError(error);
                        },
                        () => {
                            this.Log('Finished sequence');
                            this.hideLoader();
                        }
                    );
            }
        } else {
            this.classifiedAdsSearchService.searchClassifiedAdsByCategory(this.selectedCategory.name, this.stateService.props$.searchText)
                .subscribe(
                    result => {
                        if (result) {
                            this.personaliseService.clearRewarded();
                            this.Log(result);
                            this.stateService.setProps({ forceSearchClassifiedAds: true });
                            this.classifiedAds = result;
                            this.classifiedAdsService.publish(result);
                            //this.restaurants.forEach(x => x.foodTypes.forEach(f => { if (!this.foodTypes.find(ft => ft.name === f)) { this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() }) } }));
                            this.state$.classifiedAds = this.classifiedAds;
                            //this.state$.foodTypes = this.foodTypes;
                            this.hideLoader();
                        }
                    },
                    error => {
                        //this.Log(error);
                        this.handleError(error);
                    },
                    () => {
                        this.Log('Finished sequence');
                        this.hideLoader();
                    }
                );
        } 
    }

    isCurrent(category: any) {
        return category == this.selectedCategory;
    }

    isHome() {
        return this.selectedCategory == null;
    }

    getBaseUrl(category: any) {
        if (category && category.iconUrl) {
            return this.getService().serviceAssetsBaseUrl + this.getService().serviceName + '/images/' + category.iconUrl;
        } else if (category && category.imageUrl) {
            return this.getService().serviceAssetsBaseUrl + this.getService().serviceName + '/images/' + category.imageUrl;
        }

        return null;
    }
}
