import { Injector } from "@angular/core";

export class Common {

  static InjectorInstance: Injector;

  constructor() {
  }

  static init(injector: Injector) {
    Common.InjectorInstance = injector;
  }
}
