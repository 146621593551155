import { ObjectBase } from 'src/app/shared/object.base';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.services';
import { DeviceService } from 'src/app/services/device.service';
import { UiStateService } from 'src/app/services/ui.state.service';
import { LoginService } from 'src/app/services/login.service';
import { NavController, MenuController } from '@ionic/angular';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ClassifiedAdsService } from '../../../services/classified.ads.service';
import { DisplayType } from '../../models/constants.model';

@Component({
    selector: 'dynamic-options-input-control',
    templateUrl: 'dynamic.options.input.control.html',
    styleUrls: ['./dynamic.options.input.control.scss']
})
export class DynamicOptionsInputControl extends ObjectBase {

    @Input() title: string;
    @Input() obj: any;
    @Input() fieldName: string;
    @Input() options: string; //csv string
    @Input() allowMultiple: boolean;
    @Input() maxOptionsAllowed: number;

    public listData: Array<any>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected menuController: MenuController,
        private classifiedAdsService: ClassifiedAdsService,
        private deviceService: DeviceService,
        private loginService: LoginService) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.classifiedAdsService = ClassifiedAdsService.Instance;
    }

    public getColSize(item: any): number {
        if (item && !this.isNullOrEmpty(item.columnSize)) {
            return item.columnSize;
        } else {
            return 2;
        }
    }

    //public getItemsAsArrayFromDataSource(dataSource: string, options: string): boolean {
    //    if (!this.listData) {
    //        if (!this.isNullOrEmpty(dataSource)) {
    //            this.classifiedAdsService.getFieldLookupValues(dataSource).subscribe(result => {
    //                this.listData = result.csvValues;
    //                this.Log(result);
    //            });
    //        } else {
    //            this.listData = this.getItemsAsArray(options);
    //            //return Observable.of([]);
    //        }
    //    }

    //    return true;
    //}

    public getData(): any {
        if (this.listData && this.listData.length > 0) {
            return this.listData;
        }

        if (this.obj) {
            if (!this.isNullOrEmpty(this.obj.dataSource)) {
                this.listData = this.getItemsAsArray(this.getLocalDataSources(this.obj.dataSource));
            } else {
                this.listData = this.getItemsAsArray(this.options);
            }
        }

        return this.listData;
    }

    public isListButton() {
        return this.isSame(this.obj.displayType, DisplayType.ListButton);
    }

    public isListBox() {
        return this.isNullOrEmpty(this.obj.displayType) || this.isSame(this.obj.displayType, DisplayType.ListBox);
    }

    public isListDropDown() {
        return this.isSame(this.obj.displayType, DisplayType.ListDropDown);
    }

    public isListDropDownFlexible() {
        return this.isSame(this.obj.displayType, DisplayType.ListDropDownFlexible);
    }
}
