import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
    path: '',
    loadChildren: () => import('./views/tabs/tabs.module').then(m => m.TabsPageModule)
    },
    {
        path: 'services',
        loadChildren: () => import('./views/services/services.module').then(m => m.ServicesModule)
    },
    {
        path: 'favourites',
        loadChildren: () => import('./views/favourites/favourites.module').then(m => m.FavouritesModule)
    },
    {
        path: 'home',
        //loadChildren: () => import('./views/tabs/tabs.module').then(m => m.TabsPageModule)
        loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'restaurant',
        loadChildren: () => import('./views/restaurant-menu/restaurant.menu.module').then(m => m.RestaurantMenuModule)
    },
    {
        path: 'join',
        loadChildren: () => import('./views/join-marketplace-auto/join.marketplace.auto.module').then(m => m.JoinMarketplaceAutoModule)
    },
    {
        path: 'join-confirm/:id',
        loadChildren: () => import('./views/join-marketplace-confirm/join.marketplace.confirm.module').then(m => m.JoinMarketplaceConfirmModule)
    },
    {
        path: 'tfa',
        loadChildren: () => import('./views/signup/signup.module').then(m => m.SignUpModule)
    },
    {
        path: 'appStore/:id',
        loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'appStore/:app/:id',
        loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'appstore/:id',
        loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'appstore/:app/:id',
        loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'appOrder/:orderId',
        loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'apporder/:orderId',
        loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'appmenu/:restaurantId',
        loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'appMenu/:restaurantId',
        loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule)
    },
    {
        path: 'credit',
        loadChildren: () => import('./views/credit-requests/credit.requests.module').then(m => m.CreditRequestsModule)
    },
    {
        path: 'property-mgt',
        loadChildren: () => import('./views/property-mgt/property.mgt.module').then(m => m.PropertyMgtModule)
    },
    {
        path: 'classified-ads',
        loadChildren: () => import('./views/classified-ads/classified.ads.module').then(m => m.ClassifiedAdsModule)
    },
    {
        path: 'myads',
        loadChildren: () => import('./views/classified-ads/my-ads/myads.module').then(m => m.MyAdsModule)
    },
    {
        path: 'serviceresouce',
        loadChildren: () => import('./views/serviceresource/serviceresource.module').then(m => m.ServiceResourceModule)
    },
    {
        path: 'mysads',
        loadChildren: () => import('./views/serviceresource/my-ads/myads.module').then(m => m.MyAdsModule)
    },
    {
        path: 'booking-calendar',
        loadChildren: () => import('./views/serviceresource/booking-calendar/booking.calendar.module').then(m => m.BookingCalendarModule)
    },
    {
        path: 'service-dashboard',
        loadChildren: () => import('./views/serviceresource/dashboard/dashboard.module').then(m => m.DashboardModule)
    }
    //{
    //    path: '',
    //    component: TabsPage,
    //    children: [
    //        {
    //            path: 'home',
    //            loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule)
    //        },
    //        {
    //            path: 'search',
    //            loadChildren: () => import('./views/search/search.module').then(m => m.SearchPageModule)
    //        },
    //        {
    //            path: 'myorders',
    //            loadChildren: () => import('./views/myorders/myorders.module').then(m => m.MyOrdersPageModule)
    //        },
    //        {
    //            path: 'profile',
    //            loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfilePageModule)
    //        },
    //        {
    //            path: 'basket',
    //            loadChildren: () => import('./views/basket/basket.module').then(m => m.BasketPageModule)
    //        },
    //        {
    //            path: 'order',
    //            loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule)
    //        },
    //        {
    //            path: 'restaurant/menu',
    //            loadChildren: () => import('./views/restaurant-menu/restaurant.menu.module').then(m => m.RestaurantMenuModule)
    //        }
    //    ]
    //},
    //{
    //    path: '',
    //    loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule)
    //},
    //{
    //    path: 'tfa',
    //    loadChildren: () => import('./views/signup/signup.module').then(m => m.SignUpModule)
    //},
    //{
    //    path: 'home',
    //    loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule)
    //},
    //{
    //    path: 'search',
    //    loadChildren: () => import('./views/search/search.module').then(m => m.SearchPageModule)
    //},
    //{
    //    path: 'myorders',
    //    loadChildren: () => import('./views/myorders/myorders.module').then(m => m.MyOrdersPageModule)
    //},
    //{
    //    path: 'profile',
    //    loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfilePageModule)
    //},
    //{
    //    path: 'basket',
    //    loadChildren: () => import('./views/basket/basket.module').then(m => m.BasketPageModule)
    //},
    //{
    //    path: 'order',
    //    loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule)
    //},
    //{
    //    path: 'restaurant/menu',
    //    loadChildren: () => import('./views/restaurant-menu/restaurant.menu.module').then(m => m.RestaurantMenuModule)
    //}
];

//const routes: Routes = [
//    {
//        path: '',
//        component: HomePage,
//        children: [
//            {
//                path: '',
//                loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule)
//            },
//            {
//                path: 'home',
//                loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule)
//            },
//            {
//                path: 'search',
//                loadChildren: () => import('./views/search/search.module').then(m => m.SearchPageModule)
//            },
//            {
//                path: 'myorders',
//                loadChildren: () => import('./views/myorders/myorders.module').then(m => m.MyOrdersPageModule)
//            },
//            {
//                path: 'profile',
//                loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfilePageModule)
//            },
//            {
//                path: 'basket',
//                loadChildren: () => import('./views/basket/basket.module').then(m => m.BasketPageModule)
//            },
//            {
//                path: 'tfa',
//                loadChildren: () => import('./views/signup/signup.module').then(m => m.SignUpModule)
//            }
//        ]
//    }
//];

@NgModule({
  imports: [
      RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
    //RouterModule.forRoot(routes)

  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
