import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UiStateService {

  private static _instance: UiStateService;

  public isFullScreen: boolean = false;
  private uiStateSubject = new Subject();
  UiState = this.uiStateSubject.asObservable();

  constructor() {
  }

  public static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this._instance || (this._instance = new this());
  }

  setFullScreenStatus(status: boolean) {
    this.isFullScreen = status;
    this.publish();
  }

  getfullScreenStatus(): boolean {
    return this.isFullScreen;
  }

  publish() {
    this.uiStateSubject.next();
  }
}
