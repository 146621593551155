<div>
    <div class="text-block">
        <div class="inner-text-block">
            <h1>{{this.getService().serviceRecommendTitle}}</h1>
            <p>{{this.getService().serviceRecommendDescription}}</p>
        </div>
    </div>
</div>
<div>
    <ion-grid class="basket-no-padding">
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-textarea type="text" placeholder="{{this.getService().serviceRecommendQuestion}}"  [(ngModel)]="recommendRestaurant.restaurantName"></ion-textarea>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Your email address" [(ngModel)]="recommendRestaurant.email"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Your mobile #" [(ngModel)]="recommendRestaurant.mobileNo"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Your postcode" [(ngModel)]="recommendRestaurant.postcode"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item lines="none">
                    <ion-button size="large" expand="block" (click)="saveData()">Submit</ion-button>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="seperator-bar">&nbsp;</div>
</div>