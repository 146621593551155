<ion-grid fixed class="filter-categories">
    <ion-row>
        <ion-col size="12">
            <ion-segment class="template-0-segment" scrollable *ngIf="templateId==0">
                <ion-segment-button *ngFor="let item of foodTypes; let index = index" (click)="search(item)">
                    <ion-label>{{this.toCapitalise(item.name)}}</ion-label>
                </ion-segment-button>
            </ion-segment>
            <ion-segment class="template-1-segment" scrollable *ngIf="templateId==1">
                <ion-segment-button style="padding: 0px; margin-top:0px; margin-bottom:0px;" *ngFor="let item of foodTypes; let index = index" (click)="search(item)">
                    <img [src]="this.getImageNameUrl(item)" style="width:60px; height:60px" [attr.data-noimageurl]="getNoImageUrl()" onerror="this.onerror = null; this.src = this.dataset.noimageurl" />
                    <ion-label>{{this.toCapitalise(item.name)}}</ion-label>
                </ion-segment-button>
            </ion-segment>
            <ion-segment class="template-2-segment" scrollable style="margin:0px; padding:0px" *ngIf="templateId==2">
                <ion-segment-button *ngFor="let item of foodTypes; let index = index" (click)="search(item)">
                    <span>{{this.toCapitalise(item.name)}}</span>
                </ion-segment-button>
            </ion-segment>

        </ion-col>
    </ion-row>
</ion-grid>
