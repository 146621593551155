import { Component, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { ObjectBase } from '../../object.base';
import { Restaurant, FoodType } from '../../models/shared.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import { NavController } from '@ionic/angular';


@Component({
    selector: 'taeam-filter-catagories',
    templateUrl: 'filter.catagories.html',
    styleUrls: ['filter.catagories.scss']
})
export class FilterCatagories extends ObjectBase implements AfterViewInit {
    public restaurants: Restaurant[];
    @Input() foodTypes: FoodType[];
    @Input() topClass: string;
    @Input() includeMargin: boolean = true;
    @Input() templateId: number = 0;
    selectedFoodType: FoodType;
    allFoodType: FoodType;



    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected userService: UserService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private restaurantService: RestaurantService) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.restaurantService = RestaurantService.Instance;
        this.allFoodType = new FoodType();
        this.allFoodType.name = 'All';
        this.allFoodType.imageName = 'all.png';
        this.selectedFoodType = this.allFoodType;
        this.isCurrent(this.allFoodType);
    }

    ngAfterViewInit(): void {        
    }

    getImageNameUrl(foodType: FoodType): string {
        if (foodType && foodType.imageName) {
            return this.getService().serviceAssetsUrl + foodType.imageName.toLowerCase().trim() + ".png";
        } else if (foodType && foodType.name) {
            return this.getService().serviceAssetsUrl + foodType.name.replace(' ', '').toLowerCase().trim() + ".png";// + foodType.imageName;
        }

        return null;
    }

    getNoImageUrl() {
        return this.getService().serviceAssetsUrl + "no-image.png";
    }

    //getNoImageUrl($event: any, item: any) {
    //    this.Log("No Food Type: ");
    //    $event.srcElement.src = this.getService().serviceAssetsUrl + "no-image.png";
    //}

    search(foodType: FoodType) {
        this.selectedFoodType = foodType;
        var foodTypeName = this.selectedFoodType.name;
        if (this.selectedFoodType && this.selectedFoodType.name == 'All') {
            foodTypeName = '';
        }

        this.Log(this.selectedFoodType);
        this.personaliseService.logEvent("restaurant-searching-foodtype", JSON.stringify({ foodType: foodType, searchText: this.stateService.props$.searchText }));
        if (foodTypeName == '') {
            var longitude = 0;
            var latitude = 0;
            if (this.stateService.props$.userLocation && this.stateService.props$.userLocation && this.stateService.props$.userLocation.longitude != 0) {
                longitude = this.stateService.props$.userLocation.longitude;
                latitude = this.stateService.props$.userLocation.latitude;
            }

            if (this.stateService.props$.searchText && this.stateService.props$.searchText.length > 0) {
                this.restaurantService.searchRestaurantsByTag(this.stateService.props$.searchText)
                    .subscribe(
                        result => {
                            if (result) {
                                this.stateService.setProps({ forceSearchRestaurant: true });
                                this.personaliseService.clearRewarded();
                                this.Log(result);
                                this.restaurants = result;
                                this.restaurantService.publish(result);
                                //this.restaurants.forEach(x => x.foodTypes.forEach(f => { if (!this.foodTypes.find(ft => ft.name === f)) { this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() }) } }));
                                this.state$.restaurants = this.restaurants;
                                //this.state$.foodTypes = this.foodTypes;
                            }
                        },
                        error => {
                            //this.Log(error);
                            this.handleError(error);
                        },
                        () => { this.Log('Finished sequence'); }
                    );
            } else if (longitude != 0) {
                this.restaurantService.searchRestaurantsByGeolocation(longitude, latitude)
                    .subscribe(
                        result => {
                            if (result) {
                                this.stateService.setProps({ forceSearchRestaurant: true });
                                this.personaliseService.clearRewarded();
                                this.Log(result);
                                this.restaurants = result;
                                this.restaurantService.publish(result);
                                //this.restaurants.forEach(x => x.foodTypes.forEach(f => { if (!this.foodTypes.find(ft => ft.name === f)) { this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() }) } }));
                                this.state$.restaurants = this.restaurants;
                                //this.state$.foodTypes = this.foodTypes;
                            }
                        },
                        error => {
                            //this.Log(error);
                            this.handleError(error);
                        },
                        () => { this.Log('Finished sequence'); }
                    );
            } else {
                this.restaurantService.searchRestaurantsByTag('')
                    .subscribe(
                        result => {
                            if (result) {
                                this.stateService.setProps({ forceSearchRestaurant: true });
                                this.personaliseService.clearRewarded();
                                this.Log(result);
                                this.restaurants = result;
                                this.restaurantService.publish(result);
                                //this.restaurants.forEach(x => x.foodTypes.forEach(f => { if (!this.foodTypes.find(ft => ft.name === f)) { this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() }) } }));
                                this.state$.restaurants = this.restaurants;
                                //this.state$.foodTypes = this.foodTypes;
                            }
                        },
                        error => {
                            //this.Log(error);
                            this.handleError(error);
                        },
                        () => { this.Log('Finished sequence'); }
                    );
            }
        } else {
            this.restaurantService.searchRestaurantsByFoodType(this.selectedFoodType.name, this.stateService.props$.searchText)
                .subscribe(
                    result => {
                        if (result) {
                            this.stateService.setProps({ forceSearchRestaurant: true });
                            this.personaliseService.clearRewarded();
                            this.Log(result);
                            this.restaurants = result;
                            this.restaurantService.publish(result);
                            //this.restaurants.forEach(x => x.foodTypes.forEach(f => { if (!this.foodTypes.find(ft => ft.name === f)) { this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() }) } }));
                            this.state$.restaurants = this.restaurants;
                            //this.state$.foodTypes = this.foodTypes;
                        }
                    },
                    error => {
                        //this.Log(error);
                        this.handleError(error);
                    },
                    () => { this.Log('Finished sequence'); }
                );
        } 
    }

    isCurrent(foodType: FoodType) {
        return foodType == this.selectedFoodType;
    }

    isHome() {
        return this.selectedFoodType == null;
    }
}
