import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageKeys } from '../shared/storage.keys';
import { environment } from '../../environments/environment';
import { Common } from '../shared/common';
import { Guid } from "guid-typescript";

@Injectable({
    providedIn: 'root'
})
export class StateService {

    private static _instance: StateService;
    id: any = Guid.create().toString()
    device$: any;
    props$: any;
    state$: any;
    private stateSubject = new Subject<any>();
    OnState = this.stateSubject.asObservable();

    constructor() {
        this.clearState();
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<StateService>(StateService));
        obj.initialise();
        return obj;
    }

    clearState(forcedClear: boolean = false) {
        if (!this.state$ || forcedClear) {
            this.state$ = new Object();
        }

        this.publishState();
    }

    public setState(state: any) {
        //this.state$ = state;

        if (!this.state$) {
            this.state$ = new Object();
        }

        if (state) {
            for (let key of Object.keys(state)) {
                this.state$[key] = state[key];
            }
        }
    }

    public clearDevice() {
        localStorage.removeItem(this.getStorageKey(StorageKeys.Device));
    }

    public setDevice(props: any) {
        //this.Log("Inside setdevice");

        if (!this.device$) {
            this.device$ = new Object();
            this.device$["did"] = this.id;
        }

        if (props) {
            for (let key of Object.keys(props)) {
                this.device$[key] = props[key];
            }
        }

        localStorage.setItem(this.getStorageKey(StorageKeys.Device), JSON.stringify(this.device$));
        
        //this.Log(this.device$);
    }

    public loadLastPropsState() {
        if (!this.props$) {
            this.props$ = new Object();
        }

        var props = this.device$.lastPropsState;

        if (props) {
            for (let key of Object.keys(props)) {
                this.props$[key] = props[key];
            }
        }

        var stateObj = this.device$.lastStateObjState;

        if (stateObj) {
            for (let key of Object.keys(stateObj)) {
                this.state$[key] = stateObj[key];
            }
        }

        this.setDevice({ lastPropsState: null, lastStateObjState: null });
    }

    public savePropsState(navigateUrl: string = null) {
        if (navigateUrl) {
            this.setProps({ navigateUrl: navigateUrl });
        }

        var lastPropsState = this.props$;
        var lastStateObjState = this.state$;
        this.setDevice({ lastPropsState: lastPropsState, lastStateObjState: lastStateObjState });
    }

    public reloadDeviceData() {
        this.LogClear();
        this.Log(localStorage.getItem(this.getStorageKey(StorageKeys.Device)));
        this.device$ = JSON.parse(localStorage.getItem(this.getStorageKey(StorageKeys.Device)));
        if (!this.device$) {
            this.device$ = new Object();
        }

        this.device$["did"] = this.id;
    }

    public initialise() {
        //this.Log("Storage service - initialise");
        this.device$ = JSON.parse(localStorage.getItem(this.getStorageKey(StorageKeys.Device)));

        if (!this.device$) {
            this.device$ = new Object();
        }

        if (!this.props$) {
            this.props$ = new Object();
        }

        if (this.device$ && !this.props$.token) {
            this.props$.user = this.device$.user;
            this.props$.token = this.device$.token;
        }

        //this.Log(this.device$);
    }

    public setProps(props: any) {
        if (!this.props$) {
            this.props$ = new Object();
        }

        for (let key of Object.keys(props)) {
            this.props$[key] = props[key];
        }
    }

    public getState(): any {
        return this.state$;
    }

    public copyToClipboard(data: string) {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', data);
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    };

    private publishState() {
        this.stateSubject.next(this.state$);
    }

    protected Log(message: any) {
        if (environment.production == false) {
            console.log(message);
        }
    }

    protected LogClear() {
        if (environment.production == false) {
            console.clear();
        }
    }

    protected getStorageKey(key: string): string {
        if (environment.wlaActive) {
            return key + '_' + environment.appVariant;
        }

        return key;
    }

    public resetProps() {
        this.setProps({
            searchText: null,
            selectedCategory: null,
            selectedfilterCategory: null,
            menuCategories: null,
            classifiedAd: null,
            filterClassifiedAdCategories: null,
            displayClassifiedAds: null,
            selectedClassifiedAdCategory: null,
            parentAdCategory: null
        });
    }
}
