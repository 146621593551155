import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FilterCatagories } from './components/filter-catagories/filter.catagories';
import { Footer } from './components/footer/footer';
import { TabMenu } from './components/tab-menu/tab.menu';
import { HomePage } from '../views/home/home.page';
import { AppMenu } from './components/app-menu/app.menu';
import { AppHeader } from './components/app-header/app.header';
import { TaeamFeedback } from './components/taeam-feedback/taeam.feedback';
import { CookieConsent } from './components/cookie-consent/cookie.consent';
import { TaeamOrderFeedback } from './components/taeam-order-feedback/taeam.order.feedback';
import { MarketingData } from './components/marketing-data/marketing.data';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { RecommendRestaurant } from './components/recommend-restaurants/recommend.restaurant';
import { Services } from '../views/services/services';
import { ServiceMarketingData } from './components/service-marketing-data/service.marketing.data';
import { ProviderView } from './components/provider-view/provider.view';
import { AdView } from './components/ad-view/ad.view';
import { VideoAdView } from './components/video-ad-view/video.ad.view';
import { ProviderItemView } from './components/provider-item-view/provider.item.view';
import { ProviderCategoryView } from './components/provider-category-view/provider.category.view';
import { FavouriteIcon } from './components/favourites-button/favourities.button';
import { NewsAlert } from './components/app-news/app.news';
import { RequestCreditIcon } from './components/request-credit-button/request.credit.button';
import { NegativeAmountPipe } from '../pipes/negative.amount.pipe';
import { AddressLocator } from './components/address-locator/address.locator';
import { ImageVideoView } from './components/image-video-view/image.video.view';
import { CustomAddressLocator } from './components/custom-address-locator/custom.address.locator';
import { PoweredByMessage } from './components/powered-by-message/powered.by.message';
import { ClassifiedAdView } from './components/classified-ad-view/classified.ad.view';
import { FilterClassifiedAdCatagories } from './components/filter-classified-ad-catagories/filter.classified.ad.catagories';
import { ClassifiedAdImagesVideosSlider } from './components/classified-ad-images-videos-slider/classified.ad.images.videos.slider';
import { ClassifiedAdSummaryView } from './components/classified-ad-summary-view/classified.ad.summary.view';
import { ProviderSummaryView } from './components/provider-summary-view/provider.summary.view';
import { ProviderImagesVideosSlider } from './components/provider-images-videos-slider/provider.images.videos.slider';
import { DynamicOptionsInputControl } from './components/dynamic-options-input-control/dynamic.options.input.control';
import { ImageCropper } from './components/image-cropper/image.cropper';
import { AppImageUploader } from './components/app-image-uploader/app.image.uploader';
import { ImageFullScreenViewer } from './components/image-full-screen-viewer/image.full.screen.viewer';
import { FilterServiceResourceAdCatagories } from './components/filter-service-resouce-ad-catagories/filter.service.resource.ad.catagories';
import { ServiceResourceAdImagesVideosSlider } from './components/service-resource-ad-images-videos-slider/service.resource.ad.images.videos.slider';
import { ServiceResourceAdSummaryView } from './components/service-resource-ad-summary-view/service.resource.ad.summary.view';
import { ServiceResourceAdView } from './components/service-resource-ad-view/service.resource.ad.view';
import { ServiceResourceAdDefaultView } from './components/service-resource-ad-view/themes/default/service.resource.ad.default.view';
import { ServiceResourceAdCareWorkerView } from './components/service-resource-ad-view/themes/careworker/service.resource.ad.careworker.view';
import { LoadingAnimation } from './components/loading-animation/loading.animation';

@NgModule({
    declarations: [
        FilterCatagories,
        Footer,
        CookieConsent,
        TabMenu,
        HomePage,
        AppMenu,
        AppHeader,
        TaeamFeedback,
        TaeamOrderFeedback,
        MarketingData,
        RecommendRestaurant,
        ServiceMarketingData,
        Services,
        ProviderView,
        ProviderItemView,
        ProviderCategoryView,
        ProviderSummaryView,
        ProviderImagesVideosSlider,
        ClassifiedAdView,
        FilterClassifiedAdCatagories,
        ClassifiedAdImagesVideosSlider,
        ClassifiedAdSummaryView,
        DynamicOptionsInputControl,
        AdView,
        VideoAdView,
        FavouriteIcon,
        RequestCreditIcon,
        NewsAlert,
        NegativeAmountPipe,
        AddressLocator,
        ImageVideoView,
        CustomAddressLocator,
        PoweredByMessage,
        ImageCropper,
        AppImageUploader,
        ImageFullScreenViewer,
        FilterServiceResourceAdCatagories,
        ServiceResourceAdView,
        ServiceResourceAdDefaultView,
        ServiceResourceAdCareWorkerView,
        ServiceResourceAdImagesVideosSlider,
        ServiceResourceAdSummaryView,
        LoadingAnimation
    ],
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        HttpClientModule
    ],
    exports: [
        HttpClientModule,
        FilterCatagories,
        Footer,
        CookieConsent,
        TabMenu,
        HomePage,
        AppMenu,
        AppHeader,
        TaeamFeedback,
        TaeamOrderFeedback,
        MarketingData,
        ServiceMarketingData,
        RecommendRestaurant,
        ProviderView,
        ProviderItemView,
        ProviderCategoryView,
        ProviderSummaryView,
        ProviderImagesVideosSlider,
        ClassifiedAdView,
        FilterClassifiedAdCatagories,
        ClassifiedAdImagesVideosSlider,
        ClassifiedAdSummaryView,
        DynamicOptionsInputControl,
        AdView,
        VideoAdView,
        FavouriteIcon,
        RequestCreditIcon,
        NewsAlert,
        NegativeAmountPipe,
        AddressLocator,
        ImageVideoView,
        CustomAddressLocator,
        PoweredByMessage,
        ImageCropper,
        AppImageUploader,
        ImageFullScreenViewer,
        FilterServiceResourceAdCatagories,
        ServiceResourceAdView,
        ServiceResourceAdDefaultView,
        ServiceResourceAdCareWorkerView,
        ServiceResourceAdImagesVideosSlider,
        ServiceResourceAdSummaryView,
        LoadingAnimation
    ],
    providers: [],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule {
}
