<ion-tabs no-border>
    <ion-tab-bar slot="bottom">
        <ion-tab-button tab="search">
            <div class="btn-round">
                <ion-icon name="search"></ion-icon>
            </div>
            <ion-label>Search</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="myorders">
            <div class="btn-round">
                <ion-icon name="notifications-outline"></ion-icon>
            </div>
            <ion-label>My Orders</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="home">
            <div class="btn-round">
                <ion-icon name="home"></ion-icon>
            </div>
            <ion-label>Home</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="basket">
            <div class="btn-round">
                <ion-icon name="basket"></ion-icon>
            </div>
            <ion-label>Basket</ion-label>
            <ion-badge color="success">22</ion-badge>
        </ion-tab-button>
        <ion-tab-button tab="profile">
            <div class="btn-round">
                <ion-icon name="person"></ion-icon>
            </div>
            <ion-label>Profile</ion-label>
        </ion-tab-button>
    </ion-tab-bar>
</ion-tabs>
