<div  class="{{getCardViewCss(false, 'ad-card')}}" (click)="adClicked(ad, checkForServiveAdLink)" *ngIf="ad">
    <div class="restaurant-ad">
        <img style="border-radius:5px" src="{{marketingResourcesBaseUrl}}{{ad.imageUrl}}" alt="">
        <div class="free-simple-top" *ngIf="ad.tagText">{{ad.tagText}}</div>
    </div>
    <div class="ad-detail with-padding" *ngIf="ad.adText && ad.adText.length > 0">
        <ion-thumbnail style="width:1px; height:1px;">
            <ion-img style="width:0px; height:0px;" [src]="this.getImpressionBlankImageUrl(ad)" (ionImgDidLoad)="logAdImpression(ad)"></ion-img>
        </ion-thumbnail>
        <h2>{{ad.adText}}&nbsp;</h2>
        <p style="text-align:left;">
            {{ad.adSubText}}&nbsp;
        </p>
    </div>
</div>