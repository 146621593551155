import { TopicService } from './topic.service';
import { Plugins } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ServiceBase } from './service.base';
import { HttpClient } from '@angular/common/http';
import { FCMProtocol } from '@capacitor-community/fcm';
import {
    PushNotificationSchema,
    Token,
    ActionPerformed
} from '@capacitor/push-notifications';
import { HTTP } from '@ionic-native/http/ngx';
import { NavController } from '@ionic/angular';
import { PersonlisationService } from './personalisation.service';

import { registerPlugin } from '@capacitor/core';
import { environment } from '../../environments/environment';

const { PushNotifications } = Plugins;
// alternatively - without types
// const { FCMPlugin } = Plugins;
//const fcm = new FCM();

// const Fcm = registerPlugin<FCMProtocol>(
//   'FCMPlugin',
//   {},
// );
const fcm = registerPlugin<FCMProtocol>(
 'FCMPlugin',
 {},
);
@Injectable({
    providedIn: 'root'
})
export class RemoteNotificationService extends ServiceBase {

    private static _instance: RemoteNotificationService;
    private deviceInfo: any;
    public token: any;
    private notificationSubject = new Subject<any>();
    public onNotification = this.notificationSubject.asObservable();

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP,
        protected navCtrl: NavController,
        private personaliserService: PersonlisationService,
        private topicService: TopicService) {
        super(http, httpNative);
        this.personaliserService = PersonlisationService.Instance;
        this.deviceService.getDeviceInfo().then((deviceInfo) => {
            this.deviceInfo = deviceInfo;
        });
    }

    public static setInstance(obj: RemoteNotificationService) {
        this._instance = obj;
        this._instance.initialise();
    }

    public static get Instance() {
        return this._instance;
    }

    initialise() {
        this.deviceService.getDeviceInfo().then((deviceInfo) => {
            this.deviceInfo = deviceInfo;

            this.Log("Remote notification service initialising");
            this.Log(this.deviceInfo.platform);
            if (this.deviceInfo.platform != 'web') {

                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.requestPermissions().then(result => {
                    if (result.granted || result.receive == "granted") {

                        // On success, we should be able to receive notifications
                        PushNotifications.addListener('registration',
                            (token: Token) => {
                                //this.token = token.value;
                                //this.stateService.setDevice({ pnt: this.token });
                                //alert("registration");
                                //alert(JSON.stringify(token));
                                if (token && token.value && token.value.length > 65) {
                                    this.token = token.value;

                                    this.stateService.setDevice({ pnt: this.token });
                                    this.personaliserService.logEventIfFirstRunAfterAppInstalled();
                                    this.notificationSubject.next(this.token);
                                } else {
                                    this.personaliserService.logEventIfFirstRunAfterAppInstalled();
                                }
                            }
                        );

                        // Some issue with our setup and push will not work
                        PushNotifications.addListener('registrationError',
                            (error: any) => {
                                this.Log('Error on registration: ' + JSON.stringify(error));
                            }
                        );

                        // Show us the notification payload if the app is open on our device
                        PushNotifications.addListener('pushNotificationReceived',
                            (notification: PushNotificationSchema) => {
                                this.Log("pushNotificationReceived");
                                this.Log(notification);
                                this.Log('Push received: ' + JSON.stringify(notification));
                                if (notification.title == "Crazon activation code") {
                                    this.stateService.setProps({ code: notification.body });
                                }

                                this.notificationSubject.next(notification);
                                this.toastNotificationService.showToastMessage(notification.body);
                            }
                        );

                        // Method called when tapping on a notification
                        PushNotifications.addListener('pushNotificationActionPerformed',
                            (action: ActionPerformed) => {
                                this.Log(action);
                                this.notificationSubject.next(action.notification);
                                this.Log('Push action performed: ' + JSON.stringify(action));
                                if (action.notification.data && action.notification.data.type == 'crazon-message') {
                                    this.personaliserService.logEvent("crazon-message-viewed", action.notification);
                                }
                                if (action.notification.data && (
                                    action.notification.data.type == 'neworder' ||
                                    action.notification.data.type == 'orderaccepted' ||
                                    action.notification.data.type == 'orderdispatched' ||
                                    action.notification.data.type == 'orderready' ||
                                    action.notification.data.type == 'orderrefused' || 
                                    action.notification.data.type == 'orderupdated' )) {
                                    var order = JSON.parse(action.notification.data.payload);
                                    this.stateService.setState({ order: order });
                                    this.navCtrl.navigateForward("tabs/order/details");
                                }

                                if (action.notification.data && !this.isNullOrEmpty(action.notification.data.pEventId)) {
                                    this.personaliserService.reward(action.notification.data.pEventName, action.notification.data.pEventId, action.notification.data.restaurantId, 1.0);
                                }

                                if (action.notification.data && !this.isNullOrEmpty(action.notification.data.eventName)) {
                                    if (!action.notification.data.eventData) {
                                        action.notification.data.eventData = {};
                                        action.notification.data.eventData.eventName = action.notification.data.eventName;
                                        action.notification.data.eventData.actionName = action.notification.data.actionName;
                                        action.notification.data.eventData.id = action.notification.data.id;
                                    }

                                    action.notification.data.eventData.appId = environment.appId;
                                    action.notification.data.eventData.appVariant = environment.appVariant;
                                    this.personaliserService.logEvent(action.notification.data.eventName, action.notification.data.eventData);
                                }

                                if (action.notification.data && action.notification.data.actionName == "appstore" && action.notification.data.id) {
                                    this.stateService.setDevice({ appmId: action.notification.data.id });
                                    this.go("home");
                                } else if (action.notification.data && action.notification.data.actionName == "apporder" && action.notification.data.id) {
                                    this.stateService.setDevice({ appmOrderId: action.notification.data.id });
                                    this.go("home");
                                } else if (action.notification.data && action.notification.data.actionName == "appmenu" && action.notification.data.id) {
                                    this.stateService.setDevice({ appmRestaurantId: action.notification.data.id });
                                    this.go("home");
                                } else if (action.notification.data && action.notification.data.actionName == "servicemarketplaces" && action.notification.data.serviceName) {
                                    this.personaliserService.getTaeamService(action.notification.data.serviceName).subscribe(
                                        result => {
                                            this.stateService.setDevice({ selectedService: result });
                                            this.stateService.setProps({ forceSearchRestaurant: true, marketingAds: null });
                                            this.go("home");
                                        });
                                } else if (action.notification.data && action.notification.data.actionName == "showservices") {
                                    this.stateService.setDevice({ selectedService: null });
                                    this.go("services");
                                }
                            }
                        );

                        PushNotifications.register().then(registerResult => {
                            this.getToken().then(result => {
                                //if (!this.token) {
                                //    this.token = result.token;
                                //}

                                //alert("get token");
                                //alert(JSON.stringify(result));

                                if (result.token && result.token.length > 65) {
                                    this.token = result.token;
                                }
                                this.stateService.setDevice({ pnt: this.token });
                                this.personaliserService.logEventIfFirstRunAfterAppInstalled();

                                this.notificationSubject.next(this.token);
                                this.topicService.getDefaultTopics().subscribe(result => {
                                    this.subscribeTopics(result);
                                });
                            });
                        });

                    } else {
                        this.Trace("Push notification not authorised or an error occurred.");
                        this.Log("Push notification not authorised or an error occurred.");
                    }
                });
            }
        });
    }

    subscribeTopics(topics: Array<string>) {
        if (topics && topics.length > 0) {
            topics.forEach(topic => {
                fcm.subscribeTo({ topic: topic });
            });
        }
    }

    unsubscribeTopics(topics: Array<string>) {
        if (topics && topics.length > 0) {
            topics.forEach(topic => {
                fcm.unsubscribeFrom({ topic: topic });
            });
        }
    }

    getToken(): Promise<{ token: string; }> {
        return fcm.getToken();
    }

    public go(url: string, forward: boolean = true, returnUrl: string = null) {
        this.stateService.setProps({ returnUrl: returnUrl });
        this.navigateWithState(url, null, forward);
    }

    protected navigateWithState(url: string, state: any = null, forward: boolean = true) {
        if (state) {
            this.stateService.setState(state);
        }

        //this.router.navigate([url]);
        if (forward) {
            this.navCtrl.navigateForward(url);
        } else {
            this.navCtrl.navigateBack(url);
        }
    }
}
