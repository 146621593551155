export class StorageItemKey {
  public static AuthenticatedUser = '___cpusr';
  public static CspReturnUrl = '___cpusr_returl';
  public static UserName = '___cpusr_remem_name';
  public static Password = '___cpusr_remem_pwd';
}

export class SearchTypes {
    public static Standard: "standard" = "standard";
    public static Nearest: "nearest" = "nearest";
}

export class OrderSources {
    public static Web: "Web" = "Web";
    public static Mobile: "Mobile" = "Mobile";
    public static WebMobile: "Web-Mobile" = "Web-Mobile";
}

export class MenuTemplateIds {
    public static DefaultList = "default-list";
    public static FashionList = "fashion-list";
    public static FashionGridList = "fashion-grid-list";
    public static FashionGrid2CatPerRowList = "fashion-grid-2cat-per-row-list";
    public static FashionGrid4CatPerRowList = "fashion-grid-4cat-per-row-list";
    public static FashionGrid6CatPerRowList = "fashion-grid-6cat-per-row-list";
    public static MainListMenuDefaultList = "main:list-menu:defaultlist";
    public static MainListMenuGrid2Column = "main:list-menu:grid-2-column";
    public static MainListMenuGrid3Column = "main:list-menu:grid-3-column";

    public static MainGridMenuList = "main:grid-menu:list";
    public static MainGridMenuGrid2Column = "main:grid-menu:grid-2-column";
    public static MainGridMenuGrid3Column = "main:grid-menu:grid-3-column";
    public static MainGridMenuDefaultList = "main:grid-menu:defaultlist";
    public static MainGrid2CatPerRowMenuList = "main:grid-2cat-per-row-menu:list";
    public static MainGrid4CatPerRowMenuList = "main:grid-4cat-per-row-menu:list";
    public static MainGrid6CatPerRowMenuList = "main:grid-6cat-per-row-menu:list";
    public static MainGrid2CatPerRowMenuGrid2Column = "main:grid-2cat-per-row-menu:grid-2-column";
    public static MainGrid2CatPerRowMenuGrid3Column = "main:grid-2cat-per-row-menu:grid-3-column";
    public static MainGrid2CatPerRowMenuDefaultList = "main:grid-2cat-per-row-menu:defaultlist";
    public static MainGrid4CatPerRowMenuGrid2Column = "main:grid-4cat-per-row-menu:grid-2-column";
    public static MainGrid4CatPerRowMenuGrid3Column = "main:grid-4cat-per-row-menu:grid-3-column";
    public static MainGrid4CatPerRowMenuDefaultList = "main:grid-4cat-per-row-menu:defaultlist";
    public static MainGrid6CatPerRowMenuGrid2Column = "main:grid-6cat-per-row-menu:grid-2-column";
    public static MainGrid6CatPerRowMenuGrid3Column = "main:grid-6cat-per-row-menu:grid-3-column";
    public static MainGrid6CatPerRowMenuDefaultList = "main:grid-6cat-per-row-menu:defaultlist";


    public static FashionListWithoutCategoryInMenu = "fashion-list-without-category-in-menu";
    public static FashionGridListWithoutCategoryInMenu = "fashion-grid-list-without-category-in-menu";
    public static FashionGrid2CatPerRowListWithoutCategoryInMenu = "fashion-grid-2cat-per-row-list-without-category-in-menu";
    public static FashionGrid4CatPerRowListWithoutCategoryInMenu = "fashion-grid-4cat-per-row-list-without-category-in-menu";
    public static FashionGrid6CatPerRowListWithoutCategoryInMenu = "fashion-grid-6cat-per-row-list-without-category-in-menu";
    public static MainListMenuDefaultListWithoutCategoryInMenu = "main:list-menu:defaultlist-without-category-in-menu";
    public static MainListMenuGrid2ColumnWithoutCategoryInMenu = "main:list-menu:grid-without-category-in-menu-2-column";
    public static MainListMenuGrid3ColumnWithoutCategoryInMenu = "main:list-menu:grid-without-category-in-menu-3-column";
    public static MainGridMenuListWithoutCategoryInMenu = "main:grid-menu:list-without-category-in-menu";
    public static MainGridMenuGrid2ColumnWithoutCategoryInMenu = "main:grid-menu:grid-without-category-in-menu-2-column";
    public static MainGridMenuGrid3ColumnWithoutCategoryInMenu = "main:grid-menu:grid-without-category-in-menu-3-column";
    public static MainGridMenuDefaultListWithoutCategoryInMenu = "main:grid-menu:defaultlist";

    public static MainGrid2CatPerRowMenuListWithoutCategoryInMenu = "main:grid-2cat-per-menu:list-without-category-in-menu";
    public static MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenu = "main:grid-2cat-per-menu:grid-without-category-in-menu-2-column";
    public static MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenu = "main:grid-2cat-per-menu:grid-without-category-in-menu-3-column";
    public static MainGrid2CatPerRowMenuDefaultListWithoutCategoryInMenu = "main:grid-2cat-per-menu:defaultlist";
    public static MainGrid4CatPerRowMenuListWithoutCategoryInMenu = "main:grid-4cat-per-menu:list-without-category-in-menu";
    public static MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenu = "main:grid-4cat-per-menu:grid-without-category-in-menu-2-column";
    public static MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenu = "main:grid-4cat-per-menu:grid-without-category-in-menu-3-column";
    public static MainGrid4CatPerRowMenuDefaultListWithoutCategoryInMenu = "main:grid-4cat-per-menu:defaultlist";
    public static MainGrid6CatPerRowMenuListWithoutCategoryInMenu = "main:grid-6cat-per-menu:list-without-category-in-menu";
    public static MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenu = "main:grid-6cat-per-menu:grid-without-category-in-menu-2-column";
    public static MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenu = "main:grid-6cat-per-menu:grid-without-category-in-menu-3-column";
    public static MainGrid6CatPerRowMenuDefaultListWithoutCategoryInMenu = "main:grid-6cat-per-menu:defaultlist";

    public static DefaultListLeftImage ="default-list-left-image";
    public static FashionListLeftImage ="fashion-list-left-image";
    public static FashionGridListLeftImage ="fashion-grid-list-left-image";
    public static FashionGrid2CatPerRowListLeftImage ="fashion-grid-2cat-per-row-list-left-image";
    public static FashionGrid4CatPerRowListLeftImage ="fashion-grid-4cat-per-row-list-left-image";
    public static FashionGrid6CatPerRowListLeftImage ="fashion-grid-6cat-per-row-list-left-image";
    public static MainListMenuDefaultListLeftImage ="main:list-menu:defaultlist-left-image";
    public static MainListMenuGrid2ColumnLeftImage ="main:list-menu:grid-2-column-left-image";
    public static MainListMenuGrid3ColumnLeftImage ="main:list-menu:grid-3-column-left-image";

    public static MainGridMenuListLeftImage ="main:grid-menu:list-left-image";
    public static MainGridMenuGrid2ColumnLeftImage ="main:grid-menu:grid-2-column-left-image";
    public static MainGridMenuGrid3ColumnLeftImage ="main:grid-menu:grid-3-column-left-image";
    public static MainGridMenuDefaultListLeftImage ="main:grid-menu:defaultlist-left-image";
    public static MainGrid2CatPerRowMenuListLeftImage ="main:grid-2cat-per-row-menu:list-left-image";
    public static MainGrid4CatPerRowMenuListLeftImage ="main:grid-4cat-per-row-menu:list-left-image";
    public static MainGrid6CatPerRowMenuListLeftImage ="main:grid-6cat-per-row-menu:list-left-image";
    public static MainGrid2CatPerRowMenuGrid2ColumnLeftImage ="main:grid-2cat-per-row-menu:grid-2-column-left-image";
    public static MainGrid2CatPerRowMenuGrid3ColumnLeftImage ="main:grid-2cat-per-row-menu:grid-3-column-left-image";
    public static MainGrid2CatPerRowMenuDefaultListLeftImage ="main:grid-2cat-per-row-menu:defaultlist-left-image";
    public static MainGrid4CatPerRowMenuGrid2ColumnLeftImage ="main:grid-4cat-per-row-menu:grid-2-column-left-image";
    public static MainGrid4CatPerRowMenuGrid3ColumnLeftImage ="main:grid-4cat-per-row-menu:grid-3-column-left-image";
    public static MainGrid4CatPerRowMenuDefaultListLeftImage ="main:grid-4cat-per-row-menu:defaultlist-left-image";
    public static MainGrid6CatPerRowMenuGrid2ColumnLeftImage ="main:grid-6cat-per-row-menu:grid-2-column-left-image";
    public static MainGrid6CatPerRowMenuGrid3ColumnLeftImage ="main:grid-6cat-per-row-menu:grid-3-column-left-image";
    public static MainGrid6CatPerRowMenuDefaultListLeftImage ="main:grid-6cat-per-row-menu:defaultlist-left-image";


    public static FashionListWithoutCategoryInMenuLeftImage ="fashion-list-without-category-in-menu-left-image";
    public static FashionGridListWithoutCategoryInMenuLeftImage ="fashion-grid-list-without-category-in-menu-left-image";
    public static FashionGrid2CatPerRowListWithoutCategoryInMenuLeftImage ="fashion-grid-2cat-per-row-list-without-category-in-menu-left-image";
    public static FashionGrid4CatPerRowListWithoutCategoryInMenuLeftImage ="fashion-grid-4cat-per-row-list-without-category-in-menu-left-image";
    public static FashionGrid6CatPerRowListWithoutCategoryInMenuLeftImage ="fashion-grid-6cat-per-row-list-without-category-in-menu-left-image";
    public static MainListMenuDefaultListWithoutCategoryInMenuLeftImage ="main:list-menu:defaultlist-without-category-in-menu-left-image";
    public static MainListMenuGrid2ColumnWithoutCategoryInMenuLeftImage ="main:list-menu:grid-without-category-in-menu-2-column-left-image";
    public static MainListMenuGrid3ColumnWithoutCategoryInMenuLeftImage ="main:list-menu:grid-without-category-in-menu-3-column-left-image";
    public static MainGridMenuListWithoutCategoryInMenuLeftImage ="main:grid-menu:list-without-category-in-menu-left-image";
    public static MainGridMenuGrid2ColumnWithoutCategoryInMenuLeftImage ="main:grid-menu:grid-without-category-in-menu-2-column-left-image";
    public static MainGridMenuGrid3ColumnWithoutCategoryInMenuLeftImage ="main:grid-menu:grid-without-category-in-menu-3-column-left-image";
    public static MainGridMenuDefaultListWithoutCategoryInMenuLeftImage ="main:grid-menu:defaultlist-left-image";

    public static MainGrid2CatPerRowMenuListWithoutCategoryInMenuLeftImage ="main:grid-2cat-per-menu:list-without-category-in-menu-left-image";
    public static MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ="main:grid-2cat-per-menu:grid-without-category-in-menu-2-column-left-image";
    public static MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ="main:grid-2cat-per-menu:grid-without-category-in-menu-3-column-left-image";
    public static MainGrid2CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ="main:grid-2cat-per-menu:defaultlist-left-image";
    public static MainGrid4CatPerRowMenuListWithoutCategoryInMenuLeftImage ="main:grid-4cat-per-menu:list-without-category-in-menu-left-image";
    public static MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ="main:grid-4cat-per-menu:grid-without-category-in-menu-2-column-left-image";
    public static MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ="main:grid-4cat-per-menu:grid-without-category-in-menu-3-column-left-image";
    public static MainGrid4CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ="main:grid-4cat-per-menu:defaultlist-left-image";
    public static MainGrid6CatPerRowMenuListWithoutCategoryInMenuLeftImage ="main:grid-6cat-per-menu:list-without-category-in-menu-left-image";
    public static MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ="main:grid-6cat-per-menu:grid-without-category-in-menu-2-column-left-image";
    public static MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ="main:grid-6cat-per-menu:grid-without-category-in-menu-3-column-left-image";
    public static MainGrid6CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ="main:grid-6cat-per-menu:defaultlist-left-image";

    public static PropertyInvestment = "property-investment";
}

export class MarketplaceThemes {
    public static Default = "default";
    public static Feature = "feature";
}

export class AdCategoryViewTemplateIds {
    public static CardImageView = "card-image-view";
    public static CardTextView = "card-text-view";
    public static GridIcon = "grid-icon";
    public static GridText = "grid-text";
    public static ListIcon = "list-icons";
    public static ListText = "list-text";
    public static SquareGridIcon = "square-grid-icon";
}

export class ClassifiedAdsTemplateIds {
    public static DefaultView = "default-view";
}

export class ClassifiedAdThemes {
    public static Default = "default";
    public static Feature = "feature";
}

export class ClassifiedAdStatus {
    public static Draft: "Draft" = "Draft";
    public static Published: "Published" = "Published";
    public static Expired: "Expired" = "Expired";
    public static Sold: "Sold" = "Sold";
}

export class DisplayType {
    public static Normal = "normal";
    public static ListButton = "list-button";
    public static ListBox = "list-box";
    public static ListDropDown = "list-dropdown";
    public static ListDropDownFlexible = "list-dropdown-flexible";
}

export class ServiceResourceAdsTemplateIds {
    public static DefaultView = "default-view";
}

export class ServiceResourceAdThemes {
    public static Default = "default";
    public static Feature = "feature";
    public static CareWorker = "careworker";
}

export class ServiceResourceAdStatus {
    public static Draft: "Draft" = "Draft";
    public static Published: "Published" = "Published";
    public static Expired: "Expired" = "Expired";
    public static Booked: "Booked" = "Booked";
    public static Closed: "Closed" = "Closed";
}

export class BookingCalendarTypes {
    public static DayCalendar = "Day Calendar";
    public static DateCalendar = "Date Calendar";
}

export class CheckoutProcess {
    public static RegisterServiceResource = "RegisterServiceResource";
    public static BookingCalendar = "BookingCalendar";
}

export class TimeSlotStatus {
    public static Available = "Available";
    public static Booked = "Booked";
}

export class ServiceResourceRegisterStatus {
    public static Pending = "Pending";
    public static CheckedIn = "Checked-In";
    public static CheckedOut = "Checked-Out";
    public static CancelledByCustomer = "Cancelled By Customer";
    public static CancelledByResource = "Cancelled By Resource";
    public static ArrivingLate = "Arriving Late";
    public static ArrivedLate = "Arrived Late";
    public static Completed = "Completed";
    public static Paid = "Paid";
}

export class Feedback {
    public static Happy = "Happy";
    public static Neutral = "Neutral";
    public static Sad = "Sad";
}

export class Flags {
    public static HasServiceAds = "hasServiceAds";
}