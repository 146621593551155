import { Component, ViewChild, ChangeDetectorRef, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { ObjectBase } from '../../object.base';
import { UiStateService } from '../../../services/ui.state.service';
import { UserService } from '../../../services/user.services';

@Component({
  selector: 'taeam-loading-animation',
  templateUrl: 'loading.animation.html',
    styleUrls: ['./loading.animation.scss'],
})
export class LoadingAnimation extends ObjectBase implements OnInit {

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected uiStateService: UiStateService,
    protected cdr: ChangeDetectorRef,
    protected navCtrl: NavController,
    protected userService: UserService,
    public modalController: ModalController) {
    super(router, route, uiStateService, cdr, navCtrl, userService);
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
  }

  ionViewDidLeave() {
  }
}
