import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import * as moment from 'moment';
import 'moment-timezone';
import { Common } from '../shared/common';
import { ServiceResourceAdStatus } from '../shared/models/constants.model';

declare var google: any;

@Injectable()
export class ServiceResourceService extends ServiceBase {

    private static _instance: ServiceResourceService;

    private searchServiceResourceAdsSubject = new Subject<Array<any>>();
    SearchResults = this.searchServiceResourceAdsSubject.asObservable();

    private createServiceResourceAdApiUrl: string;
    private createServiceCustomerRequestApiUrl: string;
    private createServiceCustomerRequestLogApiUrl: string;
    private getAdCategoriesApiUrl: string;
    private getAdCategoryFieldsApiUrl: string;
    private getAdTypesApiUrl: string;
    private getServiceResourceAdCountriesApiUrl: string;
    private getMyServiceResourceAdsApiUrl: string;
    private saveServiceResourceAdImagesApiUrl: string;
    private getAdPackagesApiUrl: string;
    private getServiceResourceAdApiUrl: string;
    private getServiceResourceAdByNameKeyApiUrl: string;
    private getServiceResourceAdInsightsApiUrl: string;
    private logServiceResourceAdEventApiUrl: string;
    private updateServiceResourceAdStatusApiUrl: string;
    private getPaymentGatewayDetailsApiUrl: string;
    private paymentRequestApiUrl: string;
    private getContactNoApiUrl: string;
    private getPaymentTokenApiUrl: string;
    private getFieldLookupValuesApiUrl: string;
    private getMyServiceCustomerBookingsApiUrl: string;
    private getMyServiceResourceBookingsApiUrl: string;
    private getServiceResourceBookedSlotsApiUrl: string;
    private getMyServiceCustomerRequestsApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.createServiceResourceAdApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/create';
        this.createServiceCustomerRequestApiUrl = environment.serviceResourceAdsApiUrl + 'service-customer-request/create';
        this.createServiceCustomerRequestLogApiUrl = environment.serviceResourceAdsApiUrl + 'service-customer-request/log';
        this.getAdCategoriesApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/category/get';
        this.getAdCategoryFieldsApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/category-fields/get/';
        this.getAdTypesApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/type/get';
        this.getServiceResourceAdCountriesApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/countries/get';
        this.getMyServiceResourceAdsApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/my/';
        this.saveServiceResourceAdImagesApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/upload/image/';
        this.getAdPackagesApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/packages/get';        
        this.getServiceResourceAdApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/get/';
        this.getServiceResourceAdByNameKeyApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/get/nk/';
        this.getServiceResourceAdInsightsApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/cai/';
        this.logServiceResourceAdEventApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/cae/';
        this.updateServiceResourceAdStatusApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/status/';
        this.getPaymentGatewayDetailsApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/payment/gatewaydetails/';
        this.paymentRequestApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/payment/request/';
        this.getContactNoApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/contactno/get/';
        this.getPaymentTokenApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/payment/token/';
        this.getFieldLookupValuesApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-ads/ctf/get/';
        this.getMyServiceCustomerBookingsApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-bookings/my/';
        this.getMyServiceResourceBookingsApiUrl = environment.serviceResourceAdsApiUrl + 'service-customer-bookings/my/';
        this.getServiceResourceBookedSlotsApiUrl = environment.serviceResourceAdsApiUrl + 'service-resource-bookings/slots/';
        this.getMyServiceCustomerRequestsApiUrl = environment.serviceResourceAdsApiUrl + 'service-customer-requests/my/';

        if (!ServiceResourceService._instance) {
            ServiceResourceService._instance = this;
        }
    }

    public static get Instance() {
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<ServiceResourceService>(ServiceResourceService));
        return obj;
    }

    public createServiceResourceAd(ad: any): Observable<any> {
        return this.post(this.createServiceResourceAdApiUrl, ad);
        //return this.post("http://192.168.86.25:7094/api/service-resource-ads/create", ad);
    }

    public createServiceCustomerRequest(request: any): Observable<any> {
        return this.post(this.createServiceCustomerRequestApiUrl, request);
    }

    public createServiceCustomerRequestLog(request: any): Observable<any> {
        return this.post(this.createServiceCustomerRequestLogApiUrl, request);
    }

    public getAdCategories(parentCategory: string = "default"): Observable<any> {
        parentCategory = encodeURIComponent(parentCategory);
        return this.get(this.getAdCategoriesApiUrl + '/' + parentCategory + '/');
    }

    public getAdCategoryFields(parentCategoryKey: string, categoryKey: string): Observable<any> {
        return this.get(this.getAdCategoryFieldsApiUrl + encodeURIComponent(parentCategoryKey) + '/' + encodeURIComponent(categoryKey) + '/');
    }

    public getAdTypes(): Observable<any> {
        return this.get(this.getAdTypesApiUrl);
    }

    public getAdPackages(categoryName): Observable<any> {
        categoryName = encodeURIComponent(categoryName);
        var url = this.getAdPackagesApiUrl + '/' + categoryName + '/';
        return this.get(url);
    }

    public getServiceResourceAdCountries(): Observable<any> {
        return this.get(this.getServiceResourceAdCountriesApiUrl);
    }

    public getMyServiceResourceAds(): Observable<any> {
        return this.get(this.getMyServiceResourceAdsApiUrl);
    }

    public getMyServiceCustomerRequests(): Observable<any> {
        return this.get(this.getMyServiceCustomerRequestsApiUrl);
    }

    public getServiceResourceAd(id: string): Observable<any> {
        var headers = this.getPDHeader(true);
        return this.get(this.getServiceResourceAdApiUrl + id + '/', headers);
    }

    public getServiceResourceAdInsights(id: string): Observable<any> {
        return this.get(this.getServiceResourceAdInsightsApiUrl + id + '/');
    }

    public getServiceResourceAdByNameKey(adKey: string): Observable<any> {
        var headers = this.getPDHeader(true);
        return this.get(this.getServiceResourceAdByNameKeyApiUrl + adKey + '/', headers);
    }

    public getMyServiceCustomerBookings(startDate: any, endDate: any): Observable<any> {
        this.setQueryDates(startDate, endDate);
        var url = this.getMyServiceCustomerBookingsApiUrl + this.getId() + '/';
        return this.get(url);
    }

    public getServiceResourceBookedSlots(startDate: any, endDate: any, serviceResourceAdId: any): Observable<any> {
        this.setQueryDates(startDate, endDate);
        var url = this.getServiceResourceBookedSlotsApiUrl + serviceResourceAdId + '/' + this.getId() + '/';
        return this.get(url);
    }

    public getMyServiceResourceBookings(startDate: any, endDate: any): Observable<any> {
        this.setQueryDates(startDate, endDate);
        var url = this.getMyServiceResourceBookingsApiUrl + this.getId() + '/';
        return this.get(url);
    }

    public getServiceResourceRateTypes(serviceResourceAd: any): any {
        var data = [];
        if (serviceResourceAd) {
            if (serviceResourceAd.visitRate > 0) {
                data.push({ id: "Visit", name: "Visit" });
            }

            if (serviceResourceAd.hourlyRate > 0) {
                data.push({ id: "Hourly", name: "Hourly" });
            }

            if (serviceResourceAd.dailyRate > 0) {
                data.push({ id: "Daily", name: "Daily" });
            }

            if (serviceResourceAd.monthlyRate > 0) {
                data.push({ id: "Monthly", name: "Monthly" });
            }
        }

        return data;
    }

    public getUserTitles(): Observable<any> {
        var data = [];
        data.push({ id: "Mr", name: "Mr" });
        data.push({ id: "Mrs", name: "Mrs" });
        data.push({ id: "Ms", name: "Ms" });
        data.push({ id: "Miss", name: "Miss" });
        data.push({ id: "Dr", name: "Dr" });
        data.push({ id: "Prof", name: "Prof" });
        data.push({ id: "Sir", name: "Sir" });
        return Observable.of(data);
    }

    public getGenders(): Observable<any> {
        var data = [];
        data.push({ id: "Male", name: "Male" });
        data.push({ id: "Female", name: "Female" });
        data.push({ id: "Rather Not Say", name: "Rather Not Say" });
        return Observable.of(data);
    }

    public saveServiceResourceAdImages(request: any): Observable<any> {
        return this.post(this.saveServiceResourceAdImagesApiUrl, request);
    }

    public updateServiceResourceAdStatus(id: string, status: string): Observable<any> {
        return this.post(this.updateServiceResourceAdStatusApiUrl + id + '/' + encodeURIComponent(status) + '/', {});
    }

    public publishServiceResourceAd(id: string): Observable<any> {
        return this.updateServiceResourceAdStatus(id, ServiceResourceAdStatus.Published);
    }

    public unPublishServiceResourceAd(id: string): Observable<any> {
        return this.updateServiceResourceAdStatus(id, ServiceResourceAdStatus.Draft);
    }

    public closedServiceResourceAd(id: string): Observable<any> {
        return this.updateServiceResourceAdStatus(id, ServiceResourceAdStatus.Closed);
    }

    public bookedServiceResourceAd(id: string): Observable<any> {
        return this.updateServiceResourceAdStatus(id, ServiceResourceAdStatus.Booked);
    }

    public unBookServiceResourceAd(id: string): Observable<any> {
        return this.updateServiceResourceAdStatus(id, ServiceResourceAdStatus.Published);
    }

    public logServiceResourceAdEvent(id: string, insightEventName: string, insightEventData: any = null, eventName: string = null) {

        var headers = this.addHeader('x-insight-event-name', insightEventName);

        if (insightEventData) {
            headers = this.addHeader('x-insight-event-data', insightEventData, headers);
        }

        if (!this.isNullOrEmpty(eventName)) {
            headers = this.addHeader('x-event-name', eventName, headers);
        }

        this.get(this.logServiceResourceAdEventApiUrl + id + '/' + this.getId(), headers).subscribe(
            result => {
            });
    }

    public getPaymentGatewayDetails(requestType: string = "default"): Observable<any> {
        var headers = this.addHeader('x-pgrt', requestType);
        return this.get(this.getPaymentGatewayDetailsApiUrl, headers);
    }

    public paymentRequest(request: any, requestType: string = "default"): Observable<any> {
        var headers = this.addHeader('x-pgrt', requestType);
        request.userLocation = this.stateService.props$.userLocation;
        return this.post(this.paymentRequestApiUrl, request, headers);
    }

    public getPaymentToken(requestType: string = "default"): Observable<any> {
        var headers = this.addHeader('x-pgrt', requestType);
        return this.get(this.getPaymentTokenApiUrl, headers);
    }

    public getContactNo(): Observable<any> {
        return this.get(this.getContactNoApiUrl);
    }

    public getFieldLookupValues(dataSource: string): Observable<any> {
        dataSource = encodeURIComponent(dataSource);
        var url = this.getFieldLookupValuesApiUrl + dataSource + '/';
        return this.get(url);
    }

    public isOpen(serviceResourceAd: any): boolean {
        if (this.isNullOrEmpty(serviceResourceAd.lastOrderTime) || this.isNullOrEmpty(serviceResourceAd.lastOrderTime)) {
            return true;
        }

        var firstOrderTime = moment.tz(serviceResourceAd.firstOrderTime, 'h:mma', serviceResourceAd.timeZone);
        var lastOrderTime = moment.tz(serviceResourceAd.lastOrderTime, 'h:mma', serviceResourceAd.timeZone);

        var currentTime = moment().tz(serviceResourceAd.timeZone);

        if (serviceResourceAd.lastOrderTime.indexOf("am") > 0) {
            return (currentTime.isAfter(firstOrderTime) || currentTime.isBefore(lastOrderTime));
        } else {
            return (currentTime.isAfter(firstOrderTime) && currentTime.isBefore(lastOrderTime))
        }
    }

    public isClosed(serviceResourceAd: any): boolean {
        return !this.isOpen(serviceResourceAd);
    }

    public orderReadyFor(serviceResourceAd: any, applyTimeRounding: boolean = false): any {
        var currentTime: moment.Moment = moment.tz(serviceResourceAd.timeZone).add(serviceResourceAd.avgOrderReadyTimeInMinutes ? serviceResourceAd.avgOrderReadyTimeInMinutes : 30, 'minutes');

        if (applyTimeRounding) {
            var intervals = Math.floor(currentTime.minutes() / 15);
            if (currentTime.minutes() % 15 != 0)
                intervals++;
            if (intervals == 4) {
                currentTime.add('hours', 1);
                intervals = 0;
            }
            currentTime.minutes(intervals * 15);
            currentTime.seconds(0);
        }

        return currentTime;
    }

    publish(classfiedAd: any) {
        this.searchServiceResourceAdsSubject.next(classfiedAd);
    }
}
