import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Common } from '../shared/common';
import { v4 as uuidv4 } from 'uuid';
import { HTTP } from '@ionic-native/http/ngx';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PersonlisationService extends ServiceBase {

    private static _instance: PersonlisationService;

    public corellationId: string;

    private logTrackingApiUrl: string;
    private logTrackingQsApiUrl: string;
    private rewardApiUrl: string;
    private saveUserMarketingDataApiUrl: string;
    private getMarketingAdsApiUrl: string;
    private getTaeamNewsApiUrl: string;
    private getTaeamServicesApiUrl: string;
    private rewarded: Array<any>;
    private getServicesListApiUrl: string;
    private getTaeamServiceApiUrl: string;
    private getServicesMarketingAdsApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.logTrackingApiUrl = environment.trackingApiUrl + this.corellationId + "/";
        this.saveUserMarketingDataApiUrl = environment.trackingApiUrl + "user/keepmeinformed/";
        this.rewardApiUrl = environment.trackingApiUrl + "prr/" + this.corellationId + "/";
        this.getMarketingAdsApiUrl = environment.trackingApiUrl + "prr/marketing/ads/";
        this.getTaeamNewsApiUrl = environment.trackingApiUrl + "prr/news/";
        this.getTaeamServicesApiUrl = environment.trackingApiUrl + "prr/services/";
        this.getServicesListApiUrl = environment.trackingApiUrl + "prr/" + "services-list/" + this.corellationId + "/";
        this.getTaeamServiceApiUrl = environment.trackingApiUrl + "prr/services/get/";
        this.getServicesMarketingAdsApiUrl = environment.trackingApiUrl + "prr/marketing/services/ads/";
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        if (this._instance == null) {
            //this._instance = new this(Common.InjectorInstance.get<HttpClient>(HttpClient),Common.InjectorInstance.get<HTTP>(HTTP));
            var obj = this._instance || (this._instance = Common.InjectorInstance.get<PersonlisationService>(PersonlisationService));
            this._instance.corellationId = uuidv4();
        }

        return this._instance;
    }

    public logEventIfFirstRunAfterAppInstalled() {
        var runCounter: number = 0;
        if (!this.stateService.device$) { return; }

        if (!this.stateService.device$.taeam) {
            this.stateService.setDevice({ taeam: this.getUId() });
        }

        if (this.stateService.device$.runCounter) {
            runCounter = this.stateService.device$.runCounter;
        } 

        runCounter++;

        if (!this.deviceService.isWeb) {
            if (!this.stateService.device$.firstRunAfterAppInstalled) {
                this.logEvent('app-installed-first-run', JSON.stringify({}));
                this.stateService.setDevice({ firstRunAfterAppInstalled: true, installedOn: moment(), runCounter: 1 });
            }
        }

        var appRunEvent = { taeam: this.getUId(), pnt: this.stateService.device$.pnt, runCounter: runCounter };
        this.logEvent("app-run-event", JSON.stringify(appRunEvent));
    }

    public logEvent(event: string, data: any) {
        this.logInHeaders(null, event, data);
    }

    public log(campaign: string, event: string, data: any) {
        event = this.getEventName(event);

        //this.logTrackingApiUrl = environment.trackingApiUrl + this.corellationId + "/";
        this.logTrackingApiUrl = environment.trackingApiUrl;

        var uid = this.getUId();
        var pixelName = null;

        var request = this.validateDefault(campaign, data, pixelName);

        var url = this.logTrackingApiUrl;
        url = url + request.campaign + "/" + event + "/" + uid + "/" + request.pixelName;
        this.Log(url);

        return this.get(url).subscribe(
            result => {
            });
    }

    public logInHeaders(campaign: string, event: string, data: any) {
        event = this.getEventName(event);

        this.logTrackingApiUrl = environment.trackingApiUrl + this.corellationId + "/";

        var uid = this.getUId();
        var pixelName = null;

        var request = this.validateDefault(campaign, data, pixelName);

        var headers = this.addHeader('X-Event', this.getData(uid, request.campaign, event, request.data, request.token, request.pixelName));

        this.Log("Request for tracking");
        this.Log(this.logTrackingApiUrl);
        this.Log(headers);
        return this.get(this.logTrackingApiUrl, headers).subscribe(
            result => {
            });
    }

    public postLog(campaign: string, event: string, data: any) {
        event = this.getEventName(event);

        this.logTrackingApiUrl = environment.trackingApiUrl + this.corellationId + "/";

        var uid = this.getUId();
        var pixelName = null;
        var request = this.validateDefault(campaign, data, pixelName);

        this.post(this.logTrackingApiUrl, this.getData(uid, request.campaign, event, request.data, request.token, request.pixelName))
            .subscribe(
                result => {
                });
    }

    public getTagUrl(campaign, event, data, pixelName) {
        event = this.getEventName(event);

        this.logTrackingQsApiUrl = environment.trackingQsApiUrl + this.corellationId + "/";

        var uid = this.getUId();

        var request = this.validateDefault(campaign, data, pixelName);

        var url = this.logTrackingQsApiUrl;
        return url + request.campaign + "/" + event + "/" + uid + "/" + request.token + "/" + request.data + "/" + request.pixelName + + "?aid=" + environment.appId;
    }

    public getImpressionUrl(campaign, event, data, pixelName) {
        event = this.getEventName(event);

        this.logTrackingQsApiUrl = environment.trackingQsApiUrl + this.corellationId + "/";

        var uid = this.getUId();

        var request = this.validateDefault(campaign, data, pixelName);

        var url = this.logTrackingQsApiUrl;
        return url + request.campaign + "/" + event + "/" + uid + "/" + data + "/" + request.pixelName + "?aid=" + environment.appId;
    }

    public addTag(campaign, event, data, pixelName) {
        event = this.getEventName(event);

        var uid = this.getUId();

        this.validateDefault(campaign, data, pixelName);

        var url = this.logTrackingApiUrl;

        var element = document.createElement("img");
        //Assign different attributes to the element.  
        element.setAttribute("src", url + campaign + "/" + event + "/" + uid + "/" + pixelName);
        element.setAttribute("width", "0px");
        element.setAttribute("height", "0px");
        element.setAttribute("display", "none");
        document.body.appendChild(element);
        //return "<img src='" + url + campaign + "/" + event + "/" + uid + "/" + data + "/" + pixelName + "' style='width:0px; height:0px; display:none' />";
    }

    public reward(scenario: string, eventId: string, restaurantId, score: any) {
        var campaign = "personaliser";
        var event = this.getEventName("personaliser-reward");
        var data = { scenario: scenario, eventId: eventId, restaurantId: restaurantId, reward: score };

        this.Trace("Reward function is called");
        if (!this.rewarded) {
            this.clearRewarded();
        }

        if (this.rewarded && this.rewarded.length > 0 && this.rewarded.findIndex(x => x.eventId === eventId && x.scenario === scenario) > -1) {
            this.Trace("reward is already recorded");
            return;
        } else {
            this.Trace("Reward is being recorded");
            this.Trace(this.rewarded);
            this.rewarded.push(data);
            this.Trace(this.rewarded);
        }

        this.Trace("Reward is not calling server api");
        this.Trace(this.rewardApiUrl);
        var uid = this.getUId();

        var headers = this.addHeader('X-PD', this.getRewardData(uid, campaign, event, data, eventId, restaurantId, score, this.stateService.props$.token));

        this.rewardApiUrl = environment.trackingApiUrl + "prr/" + this.corellationId + "/";

        return this.get(this.rewardApiUrl, headers).subscribe(
            result => {
                this.Trace("Response from reward api");
                this.Trace(result);
                if (!this.rewarded) {
                    this.clearRewarded();
                }

                this.Trace("Reward recorded");
                this.Trace(this.rewarded);
            });
    }

    public clearRewarded(): void {
        this.rewarded = new Array<any>();
    }

    public saveMarketingData(marketingData: any): Observable<any> {
        return this.post(this.saveUserMarketingDataApiUrl, marketingData);
    }

    public getMarketingAds(): Observable<Array<any>> {
        return this.get(this.getMarketingAdsApiUrl);
    }

    public getServicesMarketingAds(): Observable<Array<any>> {
        return this.get(this.getServicesMarketingAdsApiUrl);
    }

    public getTaeamNews(): Observable<Array<any>> {
        return this.get(this.getTaeamNewsApiUrl);
    }

    public getTaeamServices(parentServiceName: string = null): Observable<Array<any>> {
        if (parentServiceName && parentServiceName.trim().length > 0) {
            var headers = this.addHeader("x-parent-service", parentServiceName);
            return this.get(this.getTaeamServicesApiUrl + this.corellationId + "/", headers);
        } else {
            return this.get(this.getTaeamServicesApiUrl + this.corellationId + "/");
        }
    }

    public getTaeamService(serviceName: string): Observable<any> {
        return this.get(this.getTaeamServiceApiUrl + serviceName + "/" + this.corellationId + "/");
    }

    public convertTaeamServiceToCategoriesAndServices(services: any): any {
        var result = [];        
        if (this.hasData(services)) {
            var data = services.sort(x => this.serviceCategoryOrderComparer);

            for (var i = 0; i < data.length; i++) {
                if (this.isNullOrEmpty(data[i].serviceCategory)) {
                    data[i].serviceCategory = "Others";
                }

                var category = result.find(x => x.serviceCategory == data[i].serviceCategory);
                if (!category) {
                    category = { serviceCategory: data[i].serviceCategory, taeamServices: [] };
                    category.taeamServices.push(data[i]);
                    result.push(category);
                } else {
                    category.taeamServices.push(data[i]);
                }
            }
        }

        this.Log("Converted services");
        this.Log(result);
        return result;
    }

    public getRestaurantService(): any {
        var service: any = {};
        service.serviceName = "restaurants";
        service.serviceHeading = "Restaurants & Takeaways";
        service.serviceDescription = "Food you love for less";
        service.serviceActionText = "Order Now";
        service.positionIndex = "0";
        service.imageUrl = "https://assets.crazon.io/services/uk/restaurants.jpg";
        service.serviceSingularName = "Restaurant";
        service.servicePluralName = "Restaurants";
        service.serviceSearchTextBoxMessage = "Search restaurant";
        service.serviceNoResultFoundMessage = "No restaurants found";
        service.serviceNoResultTitle = "We are coming soon in your area so keep watching this space!";
        service.serviceNoResultDescription = "We are working hard with your local halal restaurants and takeaway businesses to bring to you the delicious food. Please provide us your details and we will keep you informed and also share the offers that you love!";
        service.serviceNoResultQuestion = "Which restaurants would you like to see here?";
        service.serviceLookingForMoreTitle = "Looking for more Halal restaurants and takeaways?";
        service.serviceLookingForMoreDescription = "We are working hard with your local halal restaurants and takeaway businesses to bring to you delicious food. Following are some of the restaurants and takeaways which will be here very soon!";
        service.serviceNotTakingOrdersTitle = "Restaurants and takeaways not taking orders at present";
        service.serviceNotTakingOrdersDescription = "Sorry, we are closed but don't forget to visit us again soon!";
        service.serviceRecommendTitle = "Get 20% discount voucher";
        service.serviceRecommendDescription = "Recommend your local restaurants or takeaway and we will give you discount voucher for your first order with them";
        service.serviceRecommendQuestion = "Restaurant or takeaway names";
        service.serviceAllergyNoteTitle = "Have a food allergy?";
        service.serviceNearestTitle = "Your nearest restaurants and takeaways";
        service.serviceNoResultDescriptionAlreadySignup = "We are working hard with your local halal restaurants and takeaway businesses to bring to you the delicious food. We will keep you informed and also share the offers that you love!";
        service.serviceChangePreferencesText = "Change Preferences";
        service.serviceRecommendedForYouText = "Recommended for you";
        service.serviceSliderAdsSectionText = "Today's Offers";
        service.defaultCurrency = "GBP";
        service.supportedCurrencies = "";
        service.checkOutCurrencyQuestion = "Would you like to pay in other supported currencies?";
        service.nearestServiceDistanceLimitForMarketingData = 3;
        service.marketingDataCommingSoonShowAtTop = true;
        service.marketingDataCommingSoonShowAtBottom = false;
        service.excludeCategoriesInMainList = true;
        service.serviceLocalTimeZone = "Europe/London";
        service.showOrderReadyMessageBar = true;
        service.applyOrderReadyForTimeRounding = false;
        service.serviceTypeTemplateId = 0;
        service.serviceAssetsBaseUrl = "https://assets.crazon.io/services/";
        service.serviceAssetsUrl = "https://assets.crazon.io/services/uk/icons/service-types/";
        service.moreServicesImageName = "taeam-services-button.jpg";
        service.menuTypeName = "Menu"; //This is designed for different types of menu of each service such as restaurants menu but for fashion it would catalouge
        service.serviceTemplateId = "marketplace";
        service.viewServiceListTemplateId = 0;
        service.iconUrl = "https://assets.crazon.io/icons/services/restaurants-markets-v1-icon.png";
        service.serviceShortTitle = "Restaurants";
        service.adsPerView = 1.05;
        service.defaultServiceRadius = 10;
        service.checkoutButtonText = "Submit"
        service.checkoutThankYouMessageLine1 = "";
        service.checkoutThankYouMessageLine2 = "";
        service.checkoutThankYouMessageLine3 = "";
        service.confirmationMessageBeforePlacingAdOrOrder = "Are you sure you want to submit your order?";
        service.comingSoonImageUrl = "https://assets.crazon.io/mobile/taeam-restaurant-loading.jpg"
        service.customerMyServiceActionTitle = "";
        service.providerMyServiceActionTitle = "";
        service.serviceRelease = 1;
        service.serviceApiVersion = "v2";
        return service;
    }

    private getEventName(event: string): string {
        if (this.deviceService.isWeb) {
            event = 'mw-' + event;
        } else {
            event = 'm-' + event;
        }

        return event;
    }

    private getData(uid: string, campaign: string, event: string, data: any, token: string, pixelName: string): string {
        var request = {
            userIdentifier: uid,
            campaign: campaign,
            eventName: event,
            data: data,
            token: token,
            pixelName: pixelName,
            userLocation: null,
            geoLocation: null,
            weatherData: null,
            timeOfDay: null,
            serviceTimeOfDay: null,
            dayofWeek: null,
            deliveryMethod: null,
            taeam: null
        };

        if (this.stateService.props$.userLocation) {
            request.userLocation = this.stateService.props$.userLocation;
        } else if (this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.userLocation) {
            request.userLocation = this.stateService.device$.userPreferences.userLocation;
        } else if (this.stateService.props$.geo && this.stateService.props$.geo.coords) {
            request.userLocation = this.stateService.props$.geo.coords;
        } else if (this.stateService.device$.geo && this.stateService.device$.geo.coords) {
            request.userLocation = this.stateService.device$.geo.coords;
        } 

        request.geoLocation = this.stateService.props$.geolocation;
        request.weatherData = this.stateService.props$.weatherData;
        request.timeOfDay = this.getTimeOfDay();
        request.dayofWeek = this.getDayOfWeek();
        if (this.stateService.device$.selectedService && this.stateService.device$.selectedService.serviceLocalTimeZone) {
            request.serviceTimeOfDay = this.getTimeOfDay(this.stateService.device$.selectedService.serviceLocalTimeZone);
        }

        request.deliveryMethod = this.stateService.device$.deliveryMethod;
        request.taeam = this.stateService.device$.taeam;

        var result = this.encode(JSON.stringify(request));
        return result;
    }

    private getRewardData(uid: string, campaign: string, event: string, data: any, eventId: string, restaurantId: string, score: any, token: string): string {
        var request = {
            userIdentifier: uid,
            campaign: campaign,
            eventName: event,
            data: data,
            eventId: eventId,
            restaurantId: restaurantId,
            reward: score,
            token: token,
            userLocation: null,
            geoLocation: null,
            weatherData: null,
            timeOfDay: null,
            dayofWeek: null,
            serviceTimeOfDay: null,
            deliveryMethod: null,
            taeam: null
        };

        request.userLocation = this.stateService.props$.userLocation;
        request.geoLocation = this.stateService.props$.geolocation;
        request.weatherData = this.stateService.props$.weatherData;
        request.timeOfDay = this.getTimeOfDay();
        request.dayofWeek = this.getDayOfWeek();
        if (this.stateService.device$.selectedService && this.stateService.device$.selectedService.serviceLocalTimeZone) {
            request.serviceTimeOfDay = this.getTimeOfDay(this.stateService.device$.selectedService.serviceLocalTimeZone);
        }
        request.deliveryMethod = this.stateService.device$.deliveryMethod;
        request.taeam = this.stateService.device$.taeam;

        var result = this.encode(JSON.stringify(request));
        this.Trace(result);
        return result;
    }

    private validateDefault(campaign: any, data: any, pixelName: any): any {
        if (pixelName === null || pixelName === undefined || pixelName === "") {
            pixelName = "default.img";
        }

        if (campaign === null || campaign === undefined || campaign === "") {
            campaign = "default";
        }

        if (data === null || data === undefined || data === "") {
            data = "{}";
        } 

        var token = "";
        if (this.stateService.props$ && this.stateService.props$.token) {
            token = this.stateService.props$.token;
        }

        return { campaign: campaign, data: data, pixelName: pixelName, token: token };
    }

    public checkIfNewsIdsArePresent(data: any): any {

        var newsList = this.clone(data);
        data = [];
        var currentDate = moment();
        this.Log("current date is ", currentDate);
        this.Log("news list", newsList);

        if (newsList && newsList.length > 0) {
            var newsLog = this.stateService.device$.newsLog;
            if (!newsLog) {
                newsLog = [];
            } else if (newsLog && newsLog.length > 0) {
                newsLog = newsLog.filter(x => currentDate.diff(moment(x.date), 'days') < x.expireInDays);
            }

            for (var i = 0; i < newsList.length; i++) {
                var item = null;
                if (newsLog.length > 0) {
                    item = newsLog.filter(x => x.id == newsList[i].id);
                }

                if (!item || item.length == 0) {
                    newsLog.push({ date: currentDate, id: newsList[i].id, expireInDays: newsList[i].expireInDays });
                    data.push(this.clone(newsList[i]));
                } else {
                    if (newsList[i].forceShow) {
                        data.push(this.clone(newsList[i]));
                    }
                }
            }

            this.Log("news log", newsLog, data);
            this.stateService.setDevice({ newsLog: newsLog });
            return data;
        }
    }

    public getServicesList(lat: any, lng: any): Observable<any> {
        if (!this.corellationId) {
            this.corellationId = uuidv4();
        }

        this.getServicesListApiUrl = environment.trackingApiUrl + "prr/" + "services-list/" + this.corellationId + "/";

        return this.get(this.getServicesListApiUrl + lat + "/" + lng);
    }
}
