import { Component, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, Renderer2, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Restaurant, FoodType } from 'src/app/shared/models/shared.model';
import { UiStateService } from 'src/app/services/ui.state.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Observable, Subscription } from 'rxjs';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { SearchTypes } from 'src/app/shared/models/constants.model';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import * as moment from 'moment';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { environment } from '../../../environments/environment';
import { NewsAlert } from '../../shared/components/app-news/app.news';

@Component({
    selector: 'app-home',
    templateUrl: 'home.page.html',
    styleUrls: ['home.page.scss']
})
export class HomePage extends ObjectBase {

    public restaurants: Restaurant[];
    public displayRestaurants: Array<any> = [];
    public recommendedRestaurants: Restaurant[];
    public comingSoonRestaurants: Restaurant[];
    public closedRestaurants: Restaurant[];
    public datafound: boolean = false;
    public manualLocationErrorTrigger = true;
    public foodTypes: FoodType[];
    public showSearch: boolean = false;
    public searchQuery: string;
    private timer: Subscription;
    private lastSearchType: string;
    public dataLoadCounter: number = 0;
    private geo: any;
    public hideSkeleton: boolean = false;
    public marketingData: any = { fullName: null };
    public fullName: string;
    public dataFeetchProcessingCompleted: boolean = false;
    public marketingDataCommingSoonShowAtTop = false;
    public marketingDataCommingSoonShowAtBottom = false;
    public topCategoriesTitles: Array<any> = [];
    public middleCategoriesTitles: Array<any> = [];
    public bottomCategoriesTitles: Array<any> = [];

    @ViewChild('searchBox') searchBox: ElementRef;
    @ViewChildren('providerView') providerViews: QueryList<any>;
    @ViewChildren('adPlayer') adPlayers: QueryList<any>;
    @ViewChildren('providerPlayer') providerPlayers: QueryList<any>;
    @ViewChildren('adView') adViews: QueryList<any>;
    @ViewChildren('videoAdView') videoAdViews: QueryList<any>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private restaurantService: RestaurantService,
        protected userService: UserService,
        private geolocation: Geolocation,
        public geoService: LocationService,
        private basketService: BasketService,
        private deviceService: DeviceService,
        private appVersionService: AppVersionService,        
        //private loadingController: LoadingController,
        private videoPlayer: VideoPlayer,
        private ngZone: NgZone,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.restaurantService = RestaurantService.Instance;
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
        this.restaurantService.SearchResults.subscribe(result => this.displayData(result));
        //TODO: Implement logic to find the user current location.
        this.init();
    }

    init() {
        this.Log("Init");
        if (this.goToReturnUrlIfAny()) {
            return;
        } 

        if (this.getService().isBespokeService == true) {
            this.go('services', false);
        }

        this.marketingData = { fullName: null };
        this.foodTypes = new Array<FoodType>();
        this.restaurants = new Array<Restaurant>();
        this.displayRestaurants = new Array<any>();
        this.topCategoriesTitles = new Array<any>();
        this.middleCategoriesTitles = new Array<any>();
        this.bottomCategoriesTitles = new Array<any>();

        this.searchQuery = "";
        if (this.stateService.props$.showSearch) {
            this.showSearch = this.stateService.props$.showSearch;
        } else {
            this.showSearch = false;
        }

        this.stateService.props$.searchText = "";
        //this.Log("**************************");
        //this.Log(document.documentElement.style.getPropertyValue('--safe-area-inset-top'));
        //this.Log(document.documentElement.style.getPropertyValue('--safe-area-inset-bottom'));
        //this.Log("**************************");
        this.findPageAds();
        this.initDefaultSocialSharingData();
        this.checkForAppRate();
    }    

    showMenu() {
        this.Log("show menu clicked");
        this.menu.open("main");
    }

    displaySearchTextBox() {
        this.showSearch = !this.showSearch;
        if (this.showSearch) {
            this.cdr.detectChanges();
            this.searchBox.nativeElement.focus();
        }
    }

    async ionViewWillEnter() {
        this.resetLoginReturnUrl();
        if (this.goToReturnUrlIfAny()) {
            return;
        } 

        this.stateService.setProps({ manualServiceViewCall: null });
        this.personaliseLocationErrorMessage();
        if (!this.doWeKnowUserLocation()) {
            if (!this.checkIfLocationPreferenceToShow()) {
                await this.loadWlaActiveMarketplaceData();
            } else {
                this.stateService.setProps({ setUserLocation: true });
                //this.goWithReturn('preferences');
                this.go('preferences');
            }
        } else {
            if (!this.stateService.props$.marketingAds) {
                this.personaliseService.getMarketingAds().subscribe(
                    result => {
                        this.stateService.setProps({ marketingAds: result });
                        this.findPageAds();
                    },
                    error => {
                    }
                );
            } else {
                this.findPageAds();
            }

            if (!this.stateService.props$.menuBackRequest) {                
                this.scrollIntoView("top");
            }

            this.stateService.setProps({ menuCategories: null, menuBackRequest: null });
            this.hideSkeleton = false;
            this.locationErrorOccurred = false;
            if (!this.wlaActive) {
                await this.checkVersion();
            }
        }
    }

    public getRestaurantsCount(): number {
        var count:number = 0;
        if (this.recommendedRestaurants) {
            count += this.recommendedRestaurants.length;
        }

        if (this.restaurants) {
            count += this.restaurants.length;
        }

        return count;
    }

    async checkVersion() {
        if (this.localDeviceService.isWeb) {
            if (this.stateService.device$ && (!this.stateService.device$.lastVersionCheckTime || moment().diff(this.stateService.device$.lastVersionCheckTime, 'minutes') > 15)) {
                setTimeout(() => {
                    this.stateService.setDevice({ lastVersionCheckTime: moment() });
                    this.appVersionService.getAppVersion().subscribe(
                        async result => {
                            if (result && result.forceInstall) {
                                var deviceInfo = await this.deviceService.getDeviceInfo();
                                if (deviceInfo && deviceInfo.isiOSDevice) {
                                    result.appUrl = "https://apps.apple.com/us/app/id1550205085";
                                    this.alert(this.appName, null, result.forceInstallMessage + "\n" + "Url: <a href='" + result.appUrl + "' _target='_blank'>Click here to update with latest version</a>");
                                } else if (deviceInfo && deviceInfo.isAndroidDevice) {
                                    result.appUrl = "https://play.google.com/store/apps/details?id=com.crazon.app";
                                    this.alert(this.appName, null, result.forceInstallMessage);
                                }
                            } 
                        });
                }, 1000); 
            }
        }
    }

    ionViewDidEnter() {

        if (this.goToReturnUrlIfAny(true)) {
            return;
        } 

        this.personaliseService.logEvent("restaurant-searching", JSON.stringify({ searchType: this.lastSearchType, searchText: this.searchQuery, geoLocation: this.getGeolocation() }));
        this.basketService.restoreBasketFromServer();
        if (this.wlaActive && this.stateService.props$ && this.stateService.props$.restaurant) {
            this.loadWlaParams(this.stateService.props$.restaurant);
            this.showRestaurantMenu(this.stateService.props$.restaurant);
        } else if (this.wlaActive && this.stateService.props$ && this.stateService.props$.groupRestaurants && this.stateService.props$.groupRestaurants.length > 0) {
            this.displayData(this.stateService.props$.groupRestaurants);
        } else if (this.stateService.device$ && this.stateService.device$.appmRestaurantId) {
            this.loadWlaParams(this.stateService.props$.restaurant);

            this.restaurantService.getRestaurant(this.stateService.device$.appmRestaurantId).subscribe(
                result => {
                    this.stateService.setDevice({ appmRestaurantId: null });
                    this.showRestaurantMenu(result);
                },
                error => {
                    this.stateService.setDevice({ appmRestaurantId: null });
                    this.init();
                    this.loadNearestRestaurants(null);
                });
        } else if (this.hasSelectedServiceId()) { //This is designed to load direct menu of selected service with default restaurant / service id
            this.restaurantService.getRestaurant(this.getService().selectedServiceId).subscribe(
                result => {
                    this.showRestaurantMenu(result);
                },
                error => {
                    this.init();
                    this.loadNearestRestaurants(null);
                });
        } else if ((!this.restaurants && !this.displayRestaurants) || this.restaurants.length == 0 || this.stateService.props$.forceSearchRestaurant) {
            this.Log('Loading data');
            this.init();
            this.loadNearestRestaurants(null);
        }
    }

    ionViewDidLeave() {
        if (this.timer) {
            this.timer.unsubscribe();
        }
        this.stateService.setProps({ forceSearchRestaurant: true });
    }

    seeAll() {
        this.Log('see all clicked');
        this.navigateWithState('/search', { restaurants: this.restaurants });
    }

    async refreshData(event) {
        this.Log("refreshData");
        this.Log(event);
        if (this.lastSearchType == SearchTypes.Standard) {
            await this.searchRestaurants(event, false);
        } else if (this.lastSearchType == SearchTypes.Nearest) {
            await this.loadNearestRestaurants(event, false);
        }

        if (event) {
            event.target.complete();
        }
    }

    async loadNearestRestaurants(event, showLoader: boolean = true) {
        if (!this.doWeKnowUserLocation()) {
            this.stateService.setProps({ setUserLocation: true });
            this.go('preferences');
        } else {
            try {
                this.lastSearchType = SearchTypes.Nearest;
                await this.showLoader(showLoader);
                await this.searchByLocation(event);    
            } finally {
                this.hideLoader();
            }
        }
    }

    async loadNearestGroupRestaurants(event, showLoader: boolean = true) {
        if (!this.doWeKnowUserLocation()) {
            this.stateService.setProps({ setUserLocation: true });
            this.go('preferences');
        } else {
            try {
                this.lastSearchType = SearchTypes.Nearest;
                await this.showLoader(showLoader);
                await this.searchByLocation(event);
            } finally {
                this.hideLoader();
            }
        }
    }

    async searchRestaurants(event, showLoader: boolean = true) {
        await this.showLoader(showLoader);
        this.searchQuery = event.target.value;
        this.stateService.setProps({ searchText: this.searchQuery });

        if (!this.searchQuery || this.searchQuery.trim().length == 0) {
            return await this.loadNearestRestaurants(null);
        }

        this.Log(this.searchQuery);
        this.Log(event);
        this.lastSearchType = SearchTypes.Standard;

        await this.searchByText(event);
        //this.geolocation.getCurrentPosition()
        //    .then(async (result) => {
        //        this.geo = result;
        //        await this.searchByText(event);
        //    })
        //    .catch(async (error) => {
        //        await this.searchByText(event);
        //    });

    }

    //async searchByLocation(event) {
    //    var latitude = this.geo.coords.latitude;
    //    var longitude = this.geo.coords.longitude;

    //    this.personaliseService.logEvent("restaurant-searching", JSON.stringify({ searchType: this.lastSearchType, searchText: this.searchQuery, geoLocation: this.getGeolocation() }));
    //    if (this.stateService.props$.userLocation && this.stateService.props$.userLocation && this.stateService.props$.userLocation.longitude != 0) {
    //        longitude = this.stateService.props$.userLocation.longitude;
    //        latitude = this.stateService.props$.userLocation.latitude;
    //    }

    //    this.restaurantService.searchRestaurantsByGeolocation(longitude, latitude)
    //        .subscribe(
    //            result => {
    //                this.personaliseService.clearRewarded();
    //                this.personaliseService.logEvent("restaurant-searching-result", JSON.stringify({ resultCount: (result ? result.length : 0), searchType: this.lastSearchType, searchText: this.searchQuery, geoLocation: this.getGeolocation() }));
    //                this.displayData(result);
    //            },
    //            error => {
    //                //this.Log(error);
    //                if (error.error && error.error.status == "401") {
    //                    this.getToken().then(() => {
    //                        //this.go("/tabs/home");
    //                        this.loadNearestRestaurants(null);
    //                    });
    //                } else {
    //                    this.handleError(error);
    //                }

    //            },
    //            () => {
    //                //this.Log('Finished sequence');
    //                if (event) {
    //                    event.target.complete();
    //                }

    //                this.hideLoader();
    //            });
    //}

    async searchByLocation(event) {
        var userLocation = this.geoService.getUserLocation(this.geo);

        if (userLocation) {
            this.personaliseService.logEvent("restaurant-searching", JSON.stringify({ searchType: this.lastSearchType, searchText: this.searchQuery, geoLocation: this.getGeolocation() }));
            this.restaurantService.searchRestaurantsByGeolocation(userLocation.longitude, userLocation.latitude)
                .subscribe(
                    result => {
                        this.stateService.setProps({ forceSearchRestaurant: true });
                        this.personaliseService.clearRewarded();
                        this.personaliseService.logEvent("restaurant-searching-result", JSON.stringify({ resultCount: (result ? result.length : 0), searchType: this.lastSearchType, searchText: this.searchQuery, geoLocation: this.getGeolocation() }));
                        this.displayData(result);
                    },
                    error => {
                        if (error.error && error.error.status == "401") {
                            this.getToken().then(() => {
                                this.loadNearestRestaurants(null);
                            });
                        } else {
                            this.hideLoader();

                           // this.handleError(error);
                        }
                    },
                    () => {
                        //this.Log('Finished sequence');
                        if (event) {
                            event.target.complete();
                        }

                        this.hideLoader();
                    });
        }
    }


    async displayRecommendedRestaurants(data: Array<any>) {
        this.recommendedRestaurants = new Array<any>();

        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].eventId) {
                    this.recommendedRestaurants.push(data[i]);
                }
            }
        }
    }

    async displayCommingSoonRestaurants(data: Array<any>) {
        this.comingSoonRestaurants = new Array<any>();

        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].status == 'comming-soon') {
                    this.comingSoonRestaurants.push(data[i]);
                }
            }
        }
    }

    async displayClosedRestaurants(data: Array<any>) {
        this.closedRestaurants = new Array<any>();

        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].status != 'comming-soon' && (!this.restaurantService.isOpen(data[i]) || data[i].isClosed)) {
                    this.closedRestaurants.push(data[i]);
                }
            }
        }
    }

    async displayCategoryRestaurants(data: Array<any>) {
        this.topCategoriesTitles = new Array<any>();
        this.middleCategoriesTitles = new Array<any>();
        this.bottomCategoriesTitles = new Array<any>();

        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].listCategoryTitle && data[i].status != 'comming-soon' && this.restaurantService.isOpen(data[i]) && !data[i].isClosed) {
                    //Top section
                    if (data[i].listCategoryTitlePosition == -1) {
                        var topCategory = this.topCategoriesTitles.find(x => x.listCategoryTitle == data[i].listCategoryTitle);
                        if (!topCategory) {
                            topCategory = { listCategoryTitle: data[i].listCategoryTitle, listCategoryTitleIndex: data[i].listCategoryTitleIndex, restaurants: new Array<any>() };
                            topCategory.restaurants.push(data[i]);
                            this.topCategoriesTitles.push(topCategory);
                        } else {
                            topCategory.restaurants.push(data[i]);
                        }
                    }

                    //Bottom section
                    if (data[i].listCategoryTitlePosition == -2) {
                        var bottomCategory = this.bottomCategoriesTitles.find(x => x.listCategoryTitle == data[i].listCategoryTitle);
                        if (!bottomCategory) {
                            bottomCategory = { listCategoryTitle: data[i].listCategoryTitle, listCategoryTitleIndex: data[i].listCategoryTitleIndex, restaurants: new Array<any>() };
                            bottomCategory.restaurants.push(data[i]);
                            this.bottomCategoriesTitles.push(bottomCategory);
                        } else {
                            bottomCategory.restaurants.push(data[i]);
                        }
                    }

                    //Middle section
                    if (data[i].listCategoryTitlePosition == -1 && data[i].listCategoryTitlePosition == -2) {
                        var middleCategory = this.middleCategoriesTitles.find(x => x.listCategoryTitle == data[i].listCategoryTitle);
                        if (!middleCategory) {
                            middleCategory = { listCategoryTitle: data[i].listCategoryTitle, listCategoryTitleIndex: data[i].listCategoryTitleIndex, restaurants: new Array<any>() };
                            middleCategory.restaurants.push(data[i]);
                            this.middleCategoriesTitles.push(middleCategory);
                        } else {
                            middleCategory.restaurants.push(data[i]);
                        }
                    }
                }
            }

            if (this.topCategoriesTitles && this.topCategoriesTitles.length > 0) {
                this.topCategoriesTitles = this.topCategoriesTitles.sort((n1, n2) => {
                    if (n1.listCategoryTitleIndex > n2.listCategoryTitleIndex) {
                        return 1;
                    }

                    if (n1.listCategoryTitleIndex < n2.listCategoryTitleIndex) {
                        return -1;
                    }

                    return 0;
                });
            }

            if (this.middleCategoriesTitles && this.middleCategoriesTitles.length > 0) {
                this.middleCategoriesTitles = this.middleCategoriesTitles.sort((n1, n2) => {
                    if (n1.listCategoryTitleIndex > n2.listCategoryTitleIndex) {
                        return 1;
                    }

                    if (n1.listCategoryTitleIndex < n2.listCategoryTitleIndex) {
                        return -1;
                    }

                    return 0;
                });
            }

            if (this.bottomCategoriesTitles && this.bottomCategoriesTitles.length > 0) {
                this.bottomCategoriesTitles = this.bottomCategoriesTitles.sort((n1, n2) => {
                    if (n1.listCategoryTitleIndex > n2.listCategoryTitleIndex) {
                        return 1;
                    }

                    if (n1.listCategoryTitleIndex < n2.listCategoryTitleIndex) {
                        return -1;
                    }

                    return 0;
                });
            }

            this.Log("Result from category function");
            this.Log(this.topCategoriesTitles);
            this.Log(this.middleCategoriesTitles);
            this.Log(this.bottomCategoriesTitles);
        }
    } 

    async searchByText(event) {
        this.personaliseService.logEvent("restaurant-searching", JSON.stringify({ searchType: this.lastSearchType, searchText: this.searchQuery, geoLocation: this.getGeolocation() }));
        this.restaurantService.searchRestaurantsByTag(this.searchQuery)
            .subscribe(
                result => {
                    this.stateService.setProps({ forceSearchRestaurant: true });
                    this.personaliseService.clearRewarded();
                    this.personaliseService.logEvent("restaurant-searching-result", JSON.stringify({ resultCount: (result ? result.length : 0), searchType: this.lastSearchType, searchText: this.searchQuery, geoLocation: this.getGeolocation() }));
                    if (result && result.length > 0) {
                        this.displayData(result);
                    } else {
                        var resoponse: any = result;
                        this.alert(this.appName, "", resoponse.message);
                    }
                },
                error => {
                    if (error.error && error.error.status == "401") {
                        this.getToken().then(() => {
                            this.searchRestaurants(null);
                        });
                    } else {
                        this.hideLoader();
                        //this.handleError(error);
                    }
                },
                () => {
                    //this.Log('Finished sequence');
                    if (event && event.target && event.target.complete) {
                        event.target.complete();
                    }

                    this.hideLoader();
                });

    }

    displayData(data: Restaurant[]) {

        if (!this.stateService.props$.forceSearchRestaurant) {
            return;
        }

        this.Log("displayData");
        this.Log(data);
        this.dataLoadCounter = 0;
        this.marketingDataCommingSoonShowAtTop = false;
        this.marketingDataCommingSoonShowAtBottom = false;
        this.foodTypes = new Array<FoodType>();
        this.restaurants = new Array<Restaurant>();
        this.recommendedRestaurants = new Array<any>();
        this.comingSoonRestaurants = new Array<any>();
        this.closedRestaurants = new Array<any>();
        if (!this.stateService.props$.marketingAds) {
            this.personaliseService.getMarketingAds().subscribe(
                result => {
                    this.stateService.setProps({ marketingAds: result });
                    this.findPageAds();
                },
                error => {
                }
            );
        }
        this.stateService.setDevice({ appmId: null });
        this.displayRestaurants = new Array<any>();
        this.findPageAds();
        if (data && data.length > 0) {

            this.loadWlaParams(data[0]);

            //this.displayRecommendedRestaurants(this.restaurants);
            //this.displayCommingSoonRestaurants(this.restaurants);
            //this.displayClosedRestaurants(this.restaurants);
            if (!this.foodTypes.find(ft => ft.name == 'All')) {
                this.foodTypes.push({ name: 'All', imageName: 'all' });
            }

            data.forEach(x => {
                if (x.foodTypes) {
                    x.foodTypes.forEach(f => {
                        if (!this.foodTypes.find(ft => ft.name === f)) {
                            this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() });
                        }
                    });
                }

                x.isClosed = this.restaurantService.isClosed(x);
                //this.Log("Restaurant current ready time is ", this.restaurantService.orderReadyFor(x));
                x.orderReadyFor = this.restaurantService.orderReadyFor(x, this.getService().applyOrderReadyForTimeRounding);
                //this.Log(x.name, x.orderReadyFor, x);
                if (x.orderReadyFor == null || x.orderReadyFor == undefined) {
                    alert('Missing order ready for ' + x.name);
                }

                x.assetUrls = this.getMultipleAssetUrls(x.multipleDisplayAssetUrls);
                x.assetPosterUrls = this.getMultipleAssetUrls(x.multipleDisplayVideoPosterUrls);
                if (x.assetUrls && x.assetUrls.length > 0) {
                    x.hasMultipleAssets = x.assetUrls.length > 1;
                    for (var i = 0; i < x.assetUrls.length; i++) {
                        if (x.assetUrls[i].isVideo && !x.assetVideoUrl) {
                            x.assetVideoUrl = x.assetUrls[i].url;
                        }

                        if (!x.assetUrls[i].isVideo && !x.assetImageUrl) {
                            x.assetImageUrl = x.assetUrls[i].url;
                        }

                        if (x.assetImageUrl && x.assetVideoUrl) {
                            break;
                        }
                    }
                }
                //this.Log("Order ready for: ", x.orderReadyFor);
            });

            this.restaurants = data;

            this.displayRecommendedRestaurants(this.restaurants);
            this.displayCommingSoonRestaurants(this.restaurants);
            this.displayClosedRestaurants(this.restaurants);
            this.displayCategoryRestaurants(this.restaurants);

            this.datafound = this.restaurants && this.restaurants.length > 0;
            this.marketingDataCommingSoonShowAtTop = this.datafound && this.getService().nearestServiceDistanceLimitForMarketingData > 0 && this.restaurants[0].distance > this.getService().nearestServiceDistanceLimitForMarketingData && this.getService().marketingDataCommingSoonShowAtTop;
            this.marketingDataCommingSoonShowAtBottom = this.datafound && this.getService().nearestServiceDistanceLimitForMarketingData > 0 && this.restaurants[0].distance > this.getService().nearestServiceDistanceLimitForMarketingData && this.getService().marketingDataCommingSoonShowAtBottom;
            this.restaurants = this.restaurants.filter(x => !x.eventId && x.status != 'comming-soon' && !x.isClosed);

            if (this.getService().excludeCategoriesInMainList) {
                this.restaurants = this.restaurants.filter(x => !x.listCategoryTitle);
            }

            this.state$.restaurants = this.restaurants;
            this.state$.foodTypes = this.foodTypes;
            this.timer = Observable.interval(60000)
                .subscribe((val) => {
                    this.Log("timer triggered");
                    this.restaurants.forEach(x => x.foodTypes.forEach(f => {
                        x.isClosed = !this.restaurantService.isOpen(x);
                        x.orderReadyFor = this.restaurantService.orderReadyFor(x, this.getService().applyOrderReadyForTimeRounding);
                    }));
                });

            this.loadDisplayData(null);
        } else {
            this.marketingDataCommingSoonShowAtTop = this.getService().nearestServiceDistanceLimitForMarketingData > 0 && this.getService().marketingDataCommingSoonShowAtTop;
            this.marketingDataCommingSoonShowAtBottom = this.getService().nearestServiceDistanceLimitForMarketingData > 0 && this.getService().marketingDataCommingSoonShowAtBottom;

            if (!this.marketingDataCommingSoonShowAtTop && !this.marketingDataCommingSoonShowAtBottom) {
                this.marketingDataCommingSoonShowAtTop = true;
            }

            this.displayRestaurants = new Array<any>();
        }


        this.hideSkeleton = true;
        this.dataFeetchProcessingCompleted = true;
    }

    loadDisplayData(event) {
        this.Log("load display data");
        // App logic to determine if all data is loaded
        // and disable the infinite scroll
        if (this.restaurants && this.restaurants.length > this.dataLoadCounter) {
            for (var i = 0; i < 16; i++) {
                var ad = this.getAd(this.dataLoadCounter);
                if (ad) {
                    this.displayRestaurants.push({ ad: ad.ad });
                }
                var rest = this.restaurants[this.dataLoadCounter];
                if (rest) {
                    this.displayRestaurants.push(rest);
                }

                this.dataLoadCounter++;
            }

            if (this.restaurants.length <= this.dataLoadCounter) {
                var ad = this.getAd(this.displayRestaurants.length);
                if (ad) {
                    this.displayRestaurants.push({ ad: ad.ad });
                }
            }

            this.Log(this.displayRestaurants);

            this.logEvent();
            //this.Log(this.displayRestaurants);
        } else {
            //if (event) {
            //  event.target.disabled = true;
            //}
        }

        if (event) {
            event.target.complete();
        }
        //setTimeout(() => {

        //}, 500);
    }

    logEvent() {
        var eventData = JSON.stringify({ lastSearchType: this.lastSearchType, searchText: this.searchQuery, geoLocation: this.getGeolocation(), dataLoadCounter: this.dataLoadCounter });
        this.personaliseService.logEvent("search-restaurant-page-scroll", eventData);
    }

    showRestaurantMenu(restaurant: Restaurant) {
        //this.navigateWithState('/restaurant/menu', { restaurant: restaurant, reloadRestaurantMenu: true });
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            this.navigateWithState('/restaurant/' + restaurant.nameKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { restaurant: restaurant, reloadRestaurantMenu: true });
        } else {
            this.navigateWithState('/restaurant/' + restaurant.nameKey + '/delivery', { restaurant: restaurant, reloadRestaurantMenu: true });
        }
    }

    showRestaurantMenuWithLocationCheck(restaurant: any) {
        if (restaurant.ad) {
            this.adClicked(restaurant.ad, false).then(() => { });
            return;
        }

        if (this.doWeKnowUserLocation()) {
            this.showRestaurantMenu(restaurant);
        } else {
            var url = "";
            if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
                url = '/restaurant/' + restaurant.nameKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase();
            } else {
                url = '/restaurant/' + restaurant.nameKey + '/delivery';
            }
            this.stateService.setState({ restaurant: restaurant, reloadRestaurantMenu: true });
            this.stateService.setProps({ restaurantMenuUrl: url });
            this.changeLocation();
        }
    }

    getGeolocation() {
        //if (this.geo && this.geo.coords) {
        //    return { longitude: this.geo.coords.longitude, latitude: this.geo.coords.latitude }
        //}
        return this.geoService.getUserLocation(this.geo);
    }

    changeLocation() {
        this.stateService.setProps({ displayRestaurants: this.displayRestaurants });
        this.navigateWithState('/preferences');
    }

    showServices() {
        this.stateService.setProps({ manualServiceViewCall: true });
        this.go('services');
    }

    personaliseLocationErrorMessage() {
        var service = this.getService();
        this.locationErrorMessage = "Oops! No " + service.serviceSingularName.toLowerCase() + " found. You may need to permit the location permission to search your nearest " + service.servicePluralName.toLowerCase() + " or you can just type the town or city name or type what you're looking for in " + service.serviceSearchTextBoxMessage.toLowerCase() + " text box";
    }

    playVideoAd(adPlayer) {
        if (adPlayer) {
            adPlayer.muted = true;
            adPlayer.play();
        }
    }

    playingVideo(player) {
        if (this.currentPlaying) {
            return;
        }

        const nativeElement = player.nativeElement;
        const inView = true; //this.isElementInViewPort(nativeElement);

        if (this.stickyVideo && this.stickyVideo.src == nativeElement.src) {
            return;
        }

        if (inView) {
            this.currentPlaying = nativeElement;
            this.currentPlaying.muted = true;
            this.currentPlaying.play();
        }
    }

    childScrollCall() {
        this.didScroll(null);
    }

    didScroll($event) {
        //console.log("Did scroll event - List of players: ", this.adPlayers);
        //console.log("Did scroll event - List of provider views: ", this.providerViews);
        //console.log("Did scroll event - Current playing: ", this.currentPlaying);
        if ($event && $event.adPlayers && $event.adPlayers.length > 0) {
            //this.Log($event);
            this.currentPlaying.pause();
            this.currentPlaying = null;
            this.checkPlayers($event.adPlayers);
        }

        if (this.providerViews && this.providerViews.length > 0) {
            this.providerViews.forEach(provider => {
                //this.Log("Provider views", provider.adPlayers);
                this.checkPlayers(provider.adPlayers);
            });
        }

        if (this.videoAdViews && this.videoAdViews.length > 0) {
            this.videoAdViews.forEach(adView => {
                //this.Log("Video ad views", adView.adPlayers);
                this.checkPlayers(adView.adPlayers);
            });
        }

        this.checkPlayers(this.adPlayers);
        this.checkPlayers(this.providerPlayers);

        if (this.currentPlaying && this.isElementInViewPort(this.currentPlaying)) {
            return;
        } else if (this.currentPlaying && !this.isElementInViewPort(this.currentPlaying)) {
            this.currentPlaying.pause();
            this.currentPlaying = null;
        }
    }

    muteAudio(ad: any) {
        if (this.currentPlaying) {
            this.currentPlaying.muted = true;
        }
        this.videoAdClicked(ad, false);
    }

    unMuteAudio(ad: any) {
        if (this.currentPlaying) {
            this.currentPlaying.muted = false;
        }
        this.videoAdClicked(ad, false);
    }

    muteProviderAudio() {
        if (this.currentPlaying) {
            this.currentPlaying.muted = true;
        }
    }

    unMuteProviderAudio() {
        if (this.currentPlaying) {
            this.currentPlaying.muted = false;
        }
    }

    public getMoreServicesImageUrl() {
        if (environment.marketingResourcesBaseUrl && this.getService().moreServicesImageName) {
            return environment.marketingResourcesBaseUrl + this.getService().moreServicesImageName;
        } else {
            return "/assets/images/taeam-services-button.jpg";
        }
    }

    async loadWlaActiveMarketplaceData() {
        if (!this.wlaActive) {
            //NOTE: I am not sure what is the purpose of below code so commenting it until we fin dout use case but we should load page ads
            //this.personaliseService.getTaeamServices().subscribe(
            //    result => {
            //        this.Log("Taeam service called", result);
            //        if (result) {
            //            this.Log("Taeam service called and data found", result);
            //            this.stateService.setDevice({ taeamServices: result });

            //            if (this.stateService.device$ && this.stateService.device$.appmRestaurantId) {
            //                this.stateService.setDevice({ selectedService: result[0] });
            //                this.stateService.setProps({ manualServiceViewCall: false, forceSearchRestaurant: true });
            //                this.personaliseService.getMarketingAds().subscribe(result => { this.stateService.setProps({ marketingAds: result }); });
            //                this.hideLoader();
            //                this.goHome();
            //            } else {
            //                this.stateService.setProps({ manualServiceViewCall: true });
            //                this.hideLoader();
            //                this.checkForNews();
            //                this.goServices();
            //            }
            //        } else {
            //            this.Log("Taeam service called and not data found");
            //        }
            //    });

            if (!this.stateService.props$.marketingAds) {
                this.personaliseService.getMarketingAds().subscribe(
                    result => {
                        this.stateService.setProps({ marketingAds: result });
                        this.findPageAds();
                    },
                    error => {
                    }
                );
            } else {
                this.findPageAds();
            }

            if (!this.stateService.props$.menuBackRequest) {
                this.scrollIntoView("top");
            }

            this.stateService.setProps({ menuCategories: null, menuBackRequest: null });
            this.hideSkeleton = false;
            this.locationErrorOccurred = false;
            if (!this.wlaActive) {
                await this.checkVersion();
            }
        } else {
            if (!this.isNullOrEmpty(this.wlaServiceId)) {
                this.personaliseService.getTaeamService(this.wlaServiceId).subscribe(result => {
                    this.stateService.setDevice({ selectedService: result });
                    this.stateService.setProps({ selectedService: result });
                });
            }
            //this.basketService.forceClearBasket();
            this.restaurantService.getGroupRestaurants(environment.wlaRestaurantGroupId).subscribe(result => {
                if (result && result.length == 1 && !this.isNullOrEmpty(result[0].statusCode) && !this.isNullOrEmpty(result[0].statusDescription)) {
                    this.stateService.setProps({ wlaMultipleRestaurants: false, statusCode: result[0].statusCode, statusTitle: result[0].statusTitle, statusDescription: result[0].statusDescription, statusImageUrl: result[0].statusImageUrl });
                    this.go("status-response");
                } else if (result && result.length == 1) {
                    this.stateService.setState({ restaurant: result[0] });
                    this.stateService.setProps({ restaurant: result[0], wlaMultipleRestaurants: false });
                    this.stateService.setDevice({ restaurant: result[0] });
                    this.go("/restaurant/menu");
                } else if (result && result.length > 1) {
                    this.stateService.setState({ groupRestaurants: result });
                    this.stateService.setProps({ groupRestaurants: result, wlaMultipleRestaurants: true });
                    this.goHome();
                }
            });
        }
    }

    checkForNews() {
        this.ngZone.run(() => {
            this.personaliseService.getTaeamNews().subscribe(
                async data => {
                    data = this.personaliseService.checkIfNewsIdsArePresent(data);
                    if (data && data.length > 0) {
                        this.newsData = data;
                        this.showNewsModel = true;
                        await this.presentModal();
                    } else {
                        this.newsData = null;
                        this.showNewsModel = false;
                    }

                    this.stateService.setProps({ newsData: data });
                }
            );
        });

    }

    async presentModal() {
        const modal = await this.modalController.create({
            component: NewsAlert,
            componentProps: { data: this.newsData },
            cssClass: 'news-model'
        });

        //this.displayActionSheet("News", "");
        await modal.present();
    }

}
