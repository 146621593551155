<ion-footer class="app-copyright">
    <ion-grid>
        <ion-row class="ion-justify-content-center">
            <ion-col class="ion-align-self-center">
                <div class="ion-text-center">
                    <ion-label>Copyright &copy; crazon.io 2021</ion-label>
                </div>
                <div class="ion-text-center">
                    <ion-label>Terms &#38; Conditions &#8226; Privacy Policy &#8226; Press</ion-label>
                </div>
                <div class="ion-text-center">
                    <ion-label>A place for everything</ion-label>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>