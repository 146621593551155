import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import 'moment-timezone';
import { Common } from '../shared/common';

declare var google: any;

@Injectable()
export class ClassifiedAdsSearchService extends ServiceBase {

    private static _instance: ClassifiedAdsSearchService;

    private searchClassifiedAdsApiUrl: string;
    private searchNearestClassifiedAdsByGeoLocationApiUrl: string;
    private searchClassifiedAdsByTagApiUrl: string;
    private searchClassifiedAdsByCategoryApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.searchClassifiedAdsApiUrl = environment.restaurantApiUrl + 'classified-ads/';
        this.searchNearestClassifiedAdsByGeoLocationApiUrl = environment.classifiedAdsSearchApiUrl + 'classified-ads/nearest/';
        this.searchClassifiedAdsByTagApiUrl = environment.classifiedAdsSearchApiUrl + 'classified-ads/tags/';
        this.searchClassifiedAdsByCategoryApiUrl = environment.classifiedAdsSearchApiUrl + 'classified-ads/category/';

        if (!ClassifiedAdsSearchService._instance) {
            ClassifiedAdsSearchService._instance = this;
        }
    }

    public static get Instance() {
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<ClassifiedAdsSearchService>(ClassifiedAdsSearchService));
        return obj;
    }

    public searchNearestClassifiedAdsByGeoLocation(longitude: any, latitude: any): Observable<Array<any>> {
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchNearestClassifiedAdsByGeoLocationApiUrl + latitude + '/' + longitude);
        } else {
            return Observable.of([]);
        }
    }

    public searchClassifiedAdsByTag(value: string): Observable<Array<any>> {
        value = encodeURIComponent(value);
        this.stateService.props$.searchText = value;
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchClassifiedAdsByTagApiUrl + value, headers);
        } else {
            return Observable.of([]);
        }
    }

    public searchClassifiedAdsByCategory(value: string, searchText: string): Observable<Array<any>> {
        var result = Observable.of([]);
        value = encodeURIComponent(value);
        searchText = encodeURIComponent(searchText);

        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchClassifiedAdsByCategoryApiUrl + value + "/" + searchText, headers);
        } else {
            return Observable.of([]);
        }
    }

    public searchClassifiedAds(value: string): Observable<Array<any>> {
        value = encodeURIComponent(value);
        this.stateService.props$.searchText = value;
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchClassifiedAdsApiUrl + value, headers);
        } else {
            return Observable.of([]);
        }
    }
}
