<ion-grid>
    <ion-row>
        <ion-col size="12">
            {{this.getExperienceLabelText()}}
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col><img src="../../../../assets/images/happy.png" (click)="happy()" [ngClass]="{ 'feedback-selected': isHappy }" /></ion-col>
        <ion-col><img src="../../../../assets/images/neutral.png" (click)="neutral()" [ngClass]="{ 'feedback-selected': isNeutral }" /></ion-col>
        <ion-col><img src="../../../../assets/images/sad.png" (click)="sad()" [ngClass]="{ 'feedback-selected': isSad }" /></ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <ion-textarea rows="4" cols="14" placeholder="Tell us about your experience" [(ngModel)]="feedbackComments"></ion-textarea>
        </ion-col>
    </ion-row>
    <ion-row *ngIf="feedbackReceived">
        <ion-col size="12">
            Thank you for your feedback
        </ion-col>
    </ion-row>
</ion-grid>