import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { ServiceBase } from './service.base';
import { Geolocation, Geoposition } from '@ionic-native/geolocation/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { WeatherService } from './weather.service';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { Common } from "../shared/common";

@Injectable({
    providedIn: "root"
})
export class LocationService extends ServiceBase {

    private static _instance: LocationService;

    protected weatherService: WeatherService;

    private getIpApiUrl: string = environment.ipifyApiUrl;

    constructor(protected http: HttpClient,
        protected httpNative: HTTP,
        private networkInterface: NetworkInterface,
        private geolocation: Geolocation) {
        super(http, httpNative);
        this.weatherService = WeatherService.Instance;
        this.getIpApiUrl = environment.ipifyApiUrl;

        if (!LocationService._instance) {
            LocationService._instance = this;
        }

    }

    public static get Instance() {
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<LocationService>(LocationService));
        return obj;
    }

    getLocation(term: string): Observable<any> {
        var url = environment.googleApiUrl + term + "&sensor=false&key=" + environment.googleApiKey;
        return this.http.get(url);
    }

    getLatLongLocation(coords: any): Observable<any> {
        //var latitude = 24.860735;
        //var longitude = 67.001137;
        //var latitude = 40.730610;
        //var longitude = -73.935242;
        //var latitude = 31.000000;
        //var longitude = -100.000000;
        //var latitude = 39.833851;
        //var longitude = -74.871826;

        var url = environment.googleApiUrl + "latlng=" + coords.latitude + "," + coords.longitude + "&key=" + environment.googleApiKey;
        return this.http.get(url);
    }

    getLatLongLocationByAddress(address: any): Observable<any> {
        var url = environment.googleApiUrl + "address=" + address + "&key=" + environment.googleApiKey;
        return this.http.get(url);
    }

    clearLocation() {
        this.stateService.setProps({ geolocation: null, userLocation: null });
        this.Log("User location cleared");
    }

    getCurrentLocation(): Promise<Geoposition> {
        return this.geolocation.getCurrentPosition();
    }

    saveLocation() {
        this.stateService.setProps({ defaultCountry: "United Kingdom" });

        this.geolocation.getCurrentPosition()
            .then(
                geo => {
                    this.Log("Result from getcurrentposition");
                    this.Log(geo);
                    var userCurrentLocation: any = { latitude: geo.coords.latitude, longitude: geo.coords.longitude };
                    this.stateService.setProps({ userCurrentLocation: userCurrentLocation });
                    this.networkInterface.getCarrierIPAddress()
                        .then(address => {
                            userCurrentLocation.ip = address.ip;
                            this.stateService.setProps({ userCurrentLocation: userCurrentLocation });
                        });

                    this.stateService.setProps({ geo: { coords: { latitude: geo.coords.latitude, longitude: geo.coords.longitude } } });
                    this.stateService.setDevice({ geo: { coords: { latitude: geo.coords.latitude, longitude: geo.coords.longitude } } });
                    this.stateService.setDevice({ sysLocation: { latitude: geo.coords.latitude, longitude: geo.coords.longitude } });
                    this.Log(this.stateService.props$.geo);
                    this.getLatLongLocation(geo.coords).subscribe(
                        result => {
                            var userLocation = this.getLocationDetails(result, geo.coords);
                            this.stateService.setProps({ geolocation: result, userLocation: userLocation });
                            this.Log("User location");
                            this.Log(this.stateService.props$.userLocation);
                            this.weatherService.getWeather();
                            this.debugCore();
                        }
                    );
                })
            .catch(error => {
                //alert(error);
                //this.Log("getLocationByIp");
                //this.getLocationByIp();
                //this.weatherService.getWeather();
            });
    }

    async saveUserLocation(geo: any, getAdditionalInfo: boolean) {
        var userCurrentLocation: any = { latitude: geo.coords.latitude, longitude: geo.coords.longitude };
        this.stateService.setProps({ userCurrentLocation: userCurrentLocation });
        this.stateService.setProps({ geo: { coords: { latitude: geo.coords.latitude, longitude: geo.coords.longitude } } });
        this.stateService.setDevice({ geo: { coords: { latitude: geo.coords.latitude, longitude: geo.coords.longitude } } });
        this.stateService.setDevice({ sysLocation: { latitude: geo.coords.latitude, longitude: geo.coords.longitude } });
        this.Log(this.stateService.props$.geo);

        if (getAdditionalInfo) {
            this.networkInterface.getCarrierIPAddress()
                .then(address => {
                    userCurrentLocation.ip = address.ip;
                    this.stateService.setProps({ userCurrentLocation: userCurrentLocation });
                });

            this.getLatLongLocation(geo.coords).subscribe(
                result => {
                    var userLocation = this.getLocationDetails(result, geo.coords);
                    this.stateService.setProps({ geolocation: result, userLocation: userLocation });
                    this.Log("User location");
                    this.Log(this.stateService.props$.userLocation);
                    this.weatherService.getWeather();
                    this.debugCore();
                }
            );
        }
    }

    async saveLocationByLatLong(lat, long): Promise<any> {
        var geo = { coords: { latitude: lat, longitude: long } };
        this.stateService.setProps({ geo: geo });
        var result = await this.getLatLongLocation(geo.coords).toPromise();
        var userLocation = this.getLocationDetails(result, geo.coords);
        this.stateService.setProps({ geolocation: result, userLocation: userLocation });
        this.Log("User location");
        this.Log(result);
        this.Log(userLocation);
        this.Log(this.stateService.props$.userLocation);

        this.weatherService.getWeatherByLatLong(lat, long);
        return userLocation;
        //this.weatherService.getWeatherByLatLong(lat, long);
    }

    async saveCustomLocationByLatLong(lat, long): Promise<any> {
        var geo = { coords: { latitude: lat, longitude: long } };
        this.stateService.setProps({ customGeo: geo });
        var result = await this.getLatLongLocation(geo.coords).toPromise();
        var customLocation = this.getLocationDetails(result, geo.coords);
        this.stateService.setProps({ customGeoLocation: result, customUserLocation: customLocation });
        return customLocation;
    }

    //getTimeTime(lat, long) {
    //    var url = "https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + ", " + long + "&key=AIzaSyDTMOTc7M70LAzQH0d1viO_-VQJsCL9OQE";
    //    this.get(url).subscribe(result => {
    //        this.Log("Time zone data");
    //        this.stateService.setProps({ userTimeZoneData: result, userTimeZone: result. });
    //    });
    //}

    saveLocationByLatLong2(lat, long): Observable<any> {
        return this.getLatLongLocation({ latitude: lat, longitude: long });
    }

    async saveLocationByAddress(address: string): Promise<any> {

        var result = await this.getLatLongLocationByAddress(address).toPromise();
        var userLocation = this.getLocationDetails(result, null);
        if (userLocation) {
            var geo = { coords: { latitude: userLocation.latitude, longitude: userLocation.longitude } };
            this.stateService.setProps({ geo: geo });
            this.stateService.setProps({ geolocation: result, userLocation: userLocation });

            this.Log("User location");
            this.Log(result);
            this.Log(userLocation);
            this.Log(this.stateService.props$.userLocation);

            this.weatherService.getWeatherByLatLong(userLocation.latitude, userLocation.longitude);
        }

        return userLocation;
        //this.weatherService.getWeatherByLatLong(lat, long);
    }

    async saveCustomLocationByAddress(address: string): Promise<any> {

        var result = await this.getLatLongLocationByAddress(address).toPromise();
        var customLocation = this.getLocationDetails(result, null);
        if (customLocation) {
            var geo = { coords: { latitude: customLocation.latitude, longitude: customLocation.longitude } };
            this.stateService.setProps({ customGeo: geo });
            this.stateService.setProps({ customGeolocation: result, customLocation: customLocation });
        }

        return customLocation;
    }

    getIp(): Observable<any> { 
        return this.get(this.getIpApiUrl);
    }

    getLocationByIp(): any {
        var userLocation = { formatted_address: null, address: null, first_line: null, country: null, state: null, city: null, postcode: null, latitude: null, longitude: null };
        this.networkInterface.getCarrierIPAddress()
            .then(address => {
                this.Log('IP: ${address.ip}, Subnet: ${address.subnet}');
                this.Log("Ip Address");
                this.Log(address);
                var url = environment.ipstack.replace('###ip-address###', address.ip); //'86.176.195.231');
                this.get(url).toPromise().then(response => {
                    userLocation.country = response.country_name;
                    userLocation.latitude = response.latitude;
                    userLocation.longitude = response.longitude;
                    var geo = { coords: { latitude: response.latitude, longitude: response.longitude } };

                    this.stateService.setProps({ geo: geo, userLocation: userLocation });

                    this.debugCore();
                });
            })
            .catch(error => {
                console.error(`Unable to get IP: ${error}`)
            });
    }

    getIPAddress(): any {
        var userCurrentLocation = this.stateService.props$.userCurrentLocation;
        if (!userCurrentLocation) {
            userCurrentLocation = {};
        }

        this.networkInterface.getCarrierIPAddress()
            .then(address => {
                userCurrentLocation.ip = address.ip; 
                this.stateService.setProps({ userCurrentLocation: userCurrentLocation });
            });
    }

    getUserLocation(geo: any): any {
        var userLocation = { latitude: 0, longitude: 0 };

        if (this.stateService.props$ && this.stateService.props$.userLocation) {
            userLocation.latitude = this.stateService.props$.userLocation.latitude;
            userLocation.longitude = this.stateService.props$.userLocation.longitude;
        } else if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.userLocation) {
            userLocation.latitude = this.stateService.device$.userPreferences.userLocation.latitude;
            userLocation.longitude = this.stateService.device$.userPreferences.userLocation.longitude;
        } else if (geo && geo.coords) {
            userLocation.latitude = geo.coords.latitude;
            userLocation.longitude = geo.coords.longitude;
        } else if (this.stateService.props$ && this.stateService.props$.geo && this.stateService.props$.geo.coords) {
            userLocation.latitude = this.stateService.props$.geo.coords.latitude;
            userLocation.longitude = this.stateService.props$.geo.coords.longitude;
        } else if (this.stateService.device$ && this.stateService.device$.geo && this.stateService.device$.geo.coords) {
            userLocation.latitude = this.stateService.device$.geo.coords.latitude;
            userLocation.longitude = this.stateService.device$.geo.coords.longitude;
        } else {
            userLocation = null;
        }

        return userLocation;
    }

    getLocationPreferenceText() {
        if (this.stateService.device$.userPreferences &&
            this.stateService.device$.userPreferences.deliveryMethod &&
            this.stateService.device$.userPreferences.userLocation &&
            this.stateService.device$.userPreferences.userLocation.city) {
            return this.stateService.device$.userPreferences.deliveryMethod + ", " + this.stateService.device$.userPreferences.userLocation.city;
        } else {
            return "Change Preferences";
        }
    }

    getLocationDetails(googleResult: any, coords: any): any {
        if (googleResult && googleResult.status == "OK" && googleResult.results && googleResult.results.length > 0) {
            var result = { formatted_address: null, address: null, first_line: null, country: null, countryName: null, state: null, city: null, postcode: null, latitude: null, longitude: null };

            var addressElements = googleResult.results[0].address_components;
            this.Log("Geoencoding result");
            this.Log(googleResult);
            this.Log("Address elements");
            this.Log(addressElements);

            if (addressElements) {
                addressElements.forEach(item => {
                    if (item.types) {
                        item.types.forEach(it => {
                            if (it == 'route') {
                                result.first_line = item.long_name;
                            } else if (it == 'postal_code') {
                                result.postcode = item.short_name;
                            } else if (it == 'postal_town') {
                                result.city = item.short_name;
                            } else if (it == 'country') {
                                result.country = item.short_name;
                                result.countryName = item.long_name;
                            } else if (it == 'locality') {
                                result.city = item.short_name;
                            } else if (it == 'administrative_area_level_3') {
                                result.city = item.short_name;
                            } else if (it == 'administrative_area_level_1') {
                                result.state = item.short_name;
                            }
                        });
                    }
                });
            }

            result.formatted_address = googleResult.results[0].formatted_address;
            result.latitude = googleResult.results[0].geometry.location.lat;
            result.longitude = googleResult.results[0].geometry.location.lng;
            result.formatted_address = googleResult.results[0].formatted_address;

            var addressElements: any = [];

            if (!this.isNullOrEmpty(result.formatted_address)) {
                addressElements = result.formatted_address.split(",");
            }

            if (this.isNullOrEmpty(result.first_line)) {

                if (addressElements && addressElements.length > 0) {
                    result.first_line = addressElements[0];
                }
            }

            if (this.isNullOrEmpty(result.countryName)) {

                if (addressElements && addressElements.length > 0) {
                    result.countryName = addressElements[addressElements.length-1];
                }
            }

            if (coords) {
              result.latitude = coords.latitude;
              result.longitude = coords.longitude;
            }

            if (result.formatted_address) {
                result.address = result.formatted_address;
            } else {
                result.address = result.first_line + " " + result.postcode + " " + result.city + " " + result.state + " " + result.country;
            }

            result.countryName = this.getCountryName(result.countryName);
            this.setDefaultCountry(result);
            return result;
        }

        return null;
    }

    private setDefaultCountry(address: any) {
        if (address && address.formatted_address) {
            var addr = address.formatted_address.toLowerCase();
            var countryName = this.getCountryName(addr);
            this.stateService.setProps({ defaultCountry: countryName });
        } else {
            this.stateService.setProps({ defaultCountry: "United Kingdom" });
        }
    }

    public getCountryName(countryName: any) {
        if (countryName) {
            var addr = countryName.toLowerCase();
            if (addr.indexOf('uk') > -1 || addr.indexOf('gb') > -1 || addr.indexOf('united kingdom') > -1 || addr.indexOf('britain') > -1) {
                return "United Kingdom";
            } else if (addr.indexOf('pakistan') > -1 || addr.indexOf('pk') > -1) {
                return "Pakistan";
            } else if (addr.indexOf('united states') > -1 || addr.indexOf('usa') > -1) {
                return "United States";
            } else if (addr.indexOf('canada') > -1 || addr.indexOf('gb') > -1 || addr.indexOf('britain') > -1) {
                return "Canada";
            } else {
                return "United Kingdom";
            }
        } else {
            return "United Kingdom";
        }
    }
}