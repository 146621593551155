import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'NegativeAmountPipe' })
export class NegativeAmountPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            return value.toString().charAt(0) === '-' ? '(' + value.toString().substring(1, value.toString().length) + ')' : value;
        }

        return value;
    }
}