import { ChangeDetectorRef, ViewChild, ViewChildren, QueryList, Renderer2, Input, OnInit, Output, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController, IonSlides } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { ServiceResourceAdStatus, ServiceResourceAdThemes } from 'src/app/shared/models/constants.model';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { ServiceResourceService } from '../services/serviceresource.service';
import { ServiceResourceCoreBase } from './service.resource.core.base';

@Directive()
export class ServiceResourceAdBase extends ServiceResourceCoreBase implements OnInit {
    //@Input() serviceResourceAd: any;
    @Input() player: any;
    @Input() hideSliderView: boolean;
    @Input() applyPadding: boolean = false;
    @Input() doNotCallScroll: boolean = false;
    @Input() showOrderReadyFor: boolean = true;
    @Input() forceReloadData: boolean = false;
    @Input() logClickEvent: boolean = false;
    @Input() source: string = "";
    @Input() showRequestCredit: boolean = true;
    @Input() showInsightsSummary: boolean = false;
    @Input() hideCategoryName: boolean = false;
    @Input() leftImageViewStyle: boolean = false;
    @Input() showFullScreenImageSlider: boolean = false;

    modelLoaded: boolean = false;

    @Output("didScroll") didScroll: EventEmitter<any> = new EventEmitter();

    currentSlideIndex: number = 0;

    @ViewChildren('adPlayer') adPlayers: QueryList<any>;
    @ViewChildren('slide') slides: QueryList<any>;

    @ViewChild("adPlayer") adPlayer: any;
    @ViewChild("slider") slider: any;


    //@ViewChild("adPlayer1", { static: false }) adPlayer1: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;

    public slideOptions = {
        slidesPerView: 1.05,
        initialSlide: 0,
        speed: 400,
        spaceBetween: 10
    };


    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected geolocation: Geolocation,
        public geoService: LocationService,
        protected basketService: BasketService,
        protected deviceService: DeviceService,
        protected appVersionService: AppVersionService,
        protected serviceResourceService: ServiceResourceService,
        //private loadingController: LoadingController,
        protected videoPlayer: VideoPlayer,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
        this.serviceResourceService = ServiceResourceService.Instance;
    }

    ngOnInit() {
    }

    public getServiceResourceAdHeading(serviceResourceAd: any): string {
        var result = null;

        if (this.showName() && serviceResourceAd) {
            var categoryName = this.getFullCategoryName(serviceResourceAd);

            if (this.hideCategoryName) {
                categoryName = null;
            }

            if (!this.isNullOrEmpty(categoryName) && serviceResourceAd.town) {
                result = categoryName + " - " + serviceResourceAd.town;
            } else if (!this.isNullOrEmpty(categoryName) && !serviceResourceAd.town) {
                result = categoryName;
            } else if (this.isNullOrEmpty(categoryName) && serviceResourceAd.town) {
                result = serviceResourceAd.town;
            }
        }

        if (!this.isNullOrEmpty(result)) {
            return this.toCapitalise(result);
        }

        return "";
    }

    public getPrice(serviceResourceAd: any): number {
        return serviceResourceAd.price + serviceResourceAd.tax;
    }

    public showPrice(serviceResourceAd: any): boolean {
        if (serviceResourceAd && serviceResourceAd.showPriceInput == true) {
            return true;
        }

        return false;
    }

    public getFullCategoryName(serviceResourceAd): string {
        if (!serviceResourceAd) return null;

        if (!this.isNullOrEmpty(serviceResourceAd.adParentCategory) && !this.isNullOrEmpty(serviceResourceAd.adCategory)) {
            return serviceResourceAd.adParentCategory + " > " + serviceResourceAd.adCategory;
        } else if (!this.isNullOrEmpty(serviceResourceAd.adParentCategory) && this.isNullOrEmpty(serviceResourceAd.adCategory)) {
            return serviceResourceAd.adParentCategory;
        } else if (this.isNullOrEmpty(serviceResourceAd.adParentCategory) && !this.isNullOrEmpty(serviceResourceAd.adCategory)) {
            return serviceResourceAd.adCategory;
        } else {
            return null;
        }
    }

    showServiceResourceAd(serviceResourceAd: any) {
        //this.navigateWithState('/restaurant/menu', { restaurant: restaurant, reloadRestaurantMenu: true });
        var url = null;
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            //this.navigateWithState('/view-sad/' + serviceResourceAd.adKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { serviceResourceAd: serviceResourceAd, reloadServiceResourceAd: true });
            url = '/view-sad/' + serviceResourceAd.adKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase();
        } else {
            //this.navigateWithState('/view-sad/' + serviceResourceAd.adKey, { serviceResourceAd: serviceResourceAd, reloadServiceResourceAd: true });
            url = '/view-sad/' + serviceResourceAd.adKey;
        }

        this.setHomeUrl(url);
        this.navigateWithState(url, { serviceResourceAd: serviceResourceAd, reloadServiceResourceAd: true });
    }

    showMenuWithReloaServiceResourceAdData(serviceResourceAd: any) {
        //this.navigateWithState('/restaurant/menu', { restaurant: restaurant, reloadRestaurantMenu: true });
        var url = null;
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            //this.navigateWithState('/view-sad/' + serviceResourceAd.adKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { serviceResourceAd: null, reloadServiceResourceAd: true });
            url = '/view-sad/' + serviceResourceAd.adKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase();
        } else {
            //this.navigateWithState('/view-sad/' + serviceResourceAd.adKey, { serviceResourceAd: null, reloadServiceResourceAd: true });
            url = '/view-sad/' + serviceResourceAd.adKey;
        }

        this.setHomeUrl(url);
        this.navigateWithState(url, { serviceResourceAd: null, reloadServiceResourceAd: true });
    }

    showServiceResourceAdWithLocationCheck($event, serviceResourceAd: any) {

        if (this.showFullScreenImageSlider) {
            this.showFullScreenViewer();
        } else {
            if (serviceResourceAd.ad) {
                this.adClicked(serviceResourceAd.ad, false).then(() => { });
                return;
            }

            if (this.logClickEvent) {
                this.logClickedEvent(this.source, serviceResourceAd.id);
            }

            if (this.forceReloadData && this.doWeKnowUserLocation()) {
                this.showMenuWithReloaServiceResourceAdData(serviceResourceAd);
            } else if (this.doWeKnowUserLocation()) {
                this.showServiceResourceAd(serviceResourceAd);
            } else {
                this.Log(serviceResourceAd);
                var url = '/view-sad/' + serviceResourceAd.adKey;
                this.stateService.setState({ serviceResourceAd: serviceResourceAd, reloadServiceResourceAd: true });
                this.stateService.setProps({ serviceResourceAdUrl: url });
                this.goChangeLocation();
            }
        }

        $event.preventDefault();
    }

    play($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        } 

        this.playVideo(player);
        $event.stopPropagation();
    }

    pause($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.pauseVideo(player);
        $event.stopPropagation();
    }

    mute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.muteAudio(player);
        $event.stopPropagation();
    }

    unmute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.unMuteAudio(player);
        $event.stopPropagation();
    }

    //slideChanged($event) {
    //    this.Log("Slide changed event fired");
    //    this.Log($event);
    //    this.Log(this.slider);
    //    this.slider.getActiveIndex().then(index => {
    //        this.currentSlideIndex = index;
    //    });
    //}

    //slideTaped($event) {
    //    this.Log($event);
    //    this.Log("Slide taped");
    //    this.slider.getActiveIndex().then(index => {
    //        this.currentSlideIndex = index;
    //    });
    //}

    //changeSlideIndex(num: number) {
    //    this.Log("Slide index function  is called", num);
    //    if (num > 0) {
    //        this.currentSlideIndex += num;
    //    } else if (num < 0 && this.currentSlideIndex > 0) {
    //        this.currentSlideIndex -= num;
    //    }
    //}

    //changeSlideIndex($event) {
    //    this.Log("Slide index function  is called", $event);
    //}

    slideChange(slider: IonSlides) {       
        this.Log(slider);
        slider.getActiveIndex().then(index => {
            //this.Log("Get index completed");
            ////this.currentSlideIndex = index;
            //this.Log(this.currentSlideIndex);
            //this.Log(this.slides);
        });

        if (this.didScroll && !this.doNotCallScroll) {
            this.Log("Calling didscroll ");
            this.didScroll.emit({ adPlayers: this.adPlayers });
        } else if (this.doNotCallScroll) {
            this.checkPlayers(this.adPlayers);
        }
    }

    public getAssetPosterUrl(assets: any, index: any): string {
        if (assets && assets.length > index && index >= 0) {
            for (var i = index; i < assets.length; i++) {
                if (assets[i].isPoster) {
                    return environment.serviceResourceAdsResourcesBaseUrl + this.serviceResourceAd.id + "/" + assets[i].name;
                }
            }
        } 

        return "";
    }

    public getAssetPosterUrlV2(assets: any, index: any): string {
        if (assets) {
            var assetsList = assets.split(',');
            if (assetsList && assetsList.length > index && index >= 0) {
                return assetsList[index];
            }
        }

        return "";
    }

    public getServiceResourceResourceUrl(serviceResourceAd: any, assetName: string): string {
        return environment.serviceResourceAdsResourcesBaseUrl + this.serviceResourceAd.id + "/" + assetName;
    }


    public getBaseUrl(): string {
        return environment.serviceResourceAdsResourcesBaseUrl + this.serviceResourceAd.id + "/";
    }

    public getServiceResourceAdResourceUrl(serviceResourceAd: any, index: any = 0): string {
        return environment.serviceResourceAdsResourcesBaseUrl + this.serviceResourceAd.id + "/" + serviceResourceAd.imagesAndVideos[index].name;
    }

    public logServiceResourceAdImpression(serviceResourceAd: any) {
        if (!serviceResourceAd.impressionCounted) {
            var data = {};
            var service = this.getService();
            if (service) {
                data = { id: serviceResourceAd.id };
            } else {
                data = { id: serviceResourceAd.id };
            }

            this.personaliseService.logInHeaders("default", "service-resource-ad-impression", data);
            this.serviceResourceService.logServiceResourceAdEvent(this.serviceResourceAd.id, 'service-resource-ad-impression', null, 'service-resource-ad-impression');
            serviceResourceAd.impressionCounted = true;
            this.Log("service-resource-ad-impression", this.serviceResourceAd);
        } else {
            this.Log("impression already logged", this.serviceResourceAd);
        }
    }

    public async unPublishServiceResourceAd($event: any, serviceResourceAd: any) {
        $event.stopPropagation();

        this.confirm(
            this.getServiceResourceTitle(),
            null,
            "Are you sure you want to un-publish your ad?",
            async () => {
                await this.showLoader();
                this.serviceResourceService.unPublishServiceResourceAd(serviceResourceAd.id).subscribe(result => {
                    this.serviceResourceAd = result;
                    serviceResourceAd = result;
                },
                    error => {
                        this.handleError(error);
                    },
                    () => {
                        this.hideLoader();
                    });
            },
            () => {
                this.hideLoader();
            });
    }

    public async publishServiceResourceAd($event: any, serviceResourceAd: any) {
        $event.stopPropagation();

        this.confirm(
            this.getServiceResourceTitle(),
            null,
            "Are you sure you want to publish your ad?",
            async () => {
                await this.showLoader();
                this.serviceResourceService.publishServiceResourceAd(serviceResourceAd.id).subscribe(result => {
                    this.serviceResourceAd = result;
                    serviceResourceAd = result;
                },
                    error => {
                        this.handleError(error);
                    },
                    () => {
                        this.hideLoader();
                    });
            },
            () => {
                this.hideLoader();
            });        
    }

    public async bookedServiceResourceAd($event: any, serviceResourceAd: any) {
        $event.stopPropagation();

        this.confirm(
            this.getServiceResourceTitle(),
            null,
            "That is great news! We will remove your ad and you will not be able to receive any further queries. Are you sure to proceed?",
            async () => {
                await this.showLoader();
                this.serviceResourceService.bookedServiceResourceAd(serviceResourceAd.id).subscribe(result => {
                    this.serviceResourceAd = result;
                    serviceResourceAd = result;
                },
                    error => {
                        this.handleError(error);
                    },
                    () => {
                        this.hideLoader();
                    });
            },
            () => {
                this.hideLoader();
            }); 
    }

    public async unBookServiceResourceAd($event: any, serviceResourceAd: any) {
        $event.stopPropagation();

        this.confirm(
            this.getServiceResourceTitle(),
            null,
            "Are you sure you want to mark this ad as open for booking?",
            async () => {
                await this.showLoader();
                this.serviceResourceService.unBookServiceResourceAd(serviceResourceAd.id).subscribe(result => {
                    this.serviceResourceAd = result;
                    serviceResourceAd = result;
                },
                    error => {
                        this.handleError(error);
                    },
                    () => {
                        this.hideLoader();
                    });
            },
            () => {
                this.hideLoader();
            }); 
    }

    public isBooked(serviceResourceAd: any): boolean {
        return serviceResourceAd.status == ServiceResourceAdStatus.Booked;
    }

    public canBeUnBooked(serviceResourceAd: any): boolean {
        return !serviceResourceAd.isExpired && !serviceResourceAd.isDisabled && !serviceResourceAd.isClosed && !serviceResourceAd.isAdPublished && serviceResourceAd.status == ServiceResourceAdStatus.Booked;
    }

    public canBeEdited(serviceResourceAd: any): boolean {
        return !serviceResourceAd.isExpired && !serviceResourceAd.isDisabled && !serviceResourceAd.isClosed && !serviceResourceAd.isAdPublished && serviceResourceAd.status == ServiceResourceAdStatus.Booked;
    }

    public showServiceResourceAdActionButtons(serviceResourceAd: any) {
        return !serviceResourceAd.isExpired && !serviceResourceAd.isDisabled && !serviceResourceAd.isClosed;
    }    

    async showFullScreenViewer() {
        //if (this.modelLoaded) {
        //    return;
        //}

        //this.modelLoaded = true;

        //const modal = await this.modalController.create({
        //    component: ImageFullScreenViewer,
        //    componentProps: {
        //        ad: this.serviceResourceAd,
        //        baseUrl: this.getBaseUrl(),
        //        data: this.serviceResourceAd.imagesAndVideos
        //    },
        //    cssClass: "image-editor-model-full-black"
        //});

        //modal.onDidDismiss()
        //    .then((result: any) => {
        //        this.modelLoaded = false;
        //    });

        ////this.displayActionSheet("News", "");
        //await modal.present();

        this.setFullImageSlider(true);
    }

    public getServiceResourceTitle(prefix: string = null, postfix: string = null): string {
        var value = this.getEmptyIfNull(prefix, "") + " " + this.getService().serviceSingularName + this.getEmptyIfNull(postfix, "");
        if (value) {
            return value.trim();
        }

        return null;
    }

    public getServiceResourceAd(serviceResourceAd: any): any {
        if (!serviceResourceAd) {
            serviceResourceAd = this.stateService.props$.serviceResourceAd;
        }

        return serviceResourceAd;
    }

    public isSameTheme(serviceResourceAd: any, themeName: string): boolean {
        serviceResourceAd = this.getServiceResourceAd(serviceResourceAd);
        if (serviceResourceAd && serviceResourceAd.adThemeId == themeName) {
            return true;
        }

        return false;
    }

    public isDefaultTheme(serviceResourceAd: any): boolean {
        var result = this.isSameTheme(serviceResourceAd, ServiceResourceAdThemes.Default);
        if (result == false && this.wlaActive == false && this.isNullOrEmpty(serviceResourceAd.adThemeId)) {
            result = true; //override in case marketplace existing shops do not have the theme value
        }

        return result;
    }

    public isCareWorkerTheme(serviceResourceAd: any): boolean {
        var result = this.isSameTheme(serviceResourceAd, ServiceResourceAdThemes.CareWorker);
        return result;
    }

    public getRate(serviceResourceAd: any): any {
        if (serviceResourceAd) {
            if (serviceResourceAd.dailyRate > 0) {
                return serviceResourceAd.priceSymbol + serviceResourceAd.dailyRate + "/pd"
            } else if (serviceResourceAd.hourlyRate > 0) {
                return serviceResourceAd.priceSymbol + serviceResourceAd.hourlyRate + "/ph"
            }
        }

        return null;
    }

    public getExperience(serviceResourceAd: any): any {
        if (serviceResourceAd) {
            if (serviceResourceAd.experienceInMonths > 0) {
                if (serviceResourceAd.experienceInMonths < 12) {
                    return serviceResourceAd.experienceInMonths + " Mths"
                } else {
                    return this.getYears(serviceResourceAd.experienceInMonths) + " Yrs"
                }
            }
        }

        return null;
    }

    public getCustomersServed(serviceResourceAd: any): any {
        if (serviceResourceAd) {
            if (serviceResourceAd.totalCustomersServed > 0) {
                if (serviceResourceAd.totalCustomersServed < 1000) {
                    return serviceResourceAd.totalCustomersServed;
                } else {
                    return this.getAbsoluteNumberInThousands(serviceResourceAd.totalCustomersServed) + "k"
                }               
            }
        }

        return "1.5k"
        return null;
    }
}