import { Component, ChangeDetectorRef, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectBase } from 'src/app/shared/object.base';
import { NavController, ModalController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationService } from 'src/app/services/location.service';
import { UserService } from '../../../services/user.services';
import { SupportService } from '../../../services/support.service';

declare var google: any;
declare var $: any;

@Component({
    selector: 'app-address-locator',
    templateUrl: 'address.locator.html',
    styleUrls: ['address.locator.scss']
})
export class AddressLocator extends ObjectBase implements OnInit, OnDestroy {

    @ViewChild('Map', { static: false }) mapElement: ElementRef;
    map: any;
    mapOptions: any;
    location = { lat: null, lng: null };
    markerOptions: any = { position: null, map: null, title: null };
    marker: any;
    public address: string;
    private geo: any;
    public tempDeliveryMethod: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        private geolocation: Geolocation,
        private locationService: LocationService,
        private supportService: SupportService,
        public modalController: ModalController,
        private sanitizer: DomSanitizer) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.geo = this.stateService.props$.geo;
    }
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    async ionViewWillEnter() {
        await this.showLoader();
        this.load();
        await this.initMap();
    }

    ionViewDidEnter() {
    }

    ngOnDestroy() {
    }

    showlocation() {
        if ("geolocation" in navigator) {
            /* geolocation is available */
            // One-shot position request.
            navigator.geolocation.getCurrentPosition(this.callback, this.error);
        } else {
            /* geolocation IS NOT available */
            console.warn("geolocation IS NOT available");
        }
    }

    error(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
    }

    callback(position) {

        var lat = position.coords.latitude;
        var lon = position.coords.longitude;

        //this.address = lat + ", " + lon;

        var latLong = new google.maps.LatLng(lat, lon);
        this.map.setZoom(16);
        this.map.setCenter(latLong);
    }

    async initMap() {
        this.Log("initMap is called");

        try {
            this.geo = await this.geolocation.getCurrentPosition();
        } catch (ex) {
            this.Log("Exception found during get current position");
            this.hideLoader();
        }

        /*Map options*/
        if (this.location) {
            this.mapOptions = {
                center: this.location,
                zoom: 16,
                mapTypeControl: false,
                disableDefaultUI: true
            };
        } else {
            this.mapOptions = {
                zoom: 16,
                mapTypeControl: false,
                disableDefaultUI: true
            };
        }

        setTimeout(async () => {
            this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);

            google.maps.event.addListener(this.map, 'dragend', async () => {
                this.Log("dragend event is fired");
                var marketplaceLocation = { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() };
                this.stateService.setProps({ marketplaceLocation: marketplaceLocation });
                await this.locationService.saveLocationByLatLong(this.map.getCenter().lat(), this.map.getCenter().lng());
                if (this.stateService.props$.userLocation) {
                    this.address = this.stateService.props$.userLocation.address; // + " - " + this.userLocation.lat + ", " + this.userLocation.lng;
                    this.stateService.setProps({ joinOurNetwork: { userLocation: this.stateService.props$.userLocation } });
                    this.stateService.props$.step2 = true;
                    this.personaliseService.getServicesList(this.location.lat, this.location.lng).subscribe(result => {
                        this.stateService.setProps({ servicesList: result });
                    });
                    this.stateService.setDevice({ prevLocation: { lat: this.stateService.props$.userLocation.latitude, lng: this.stateService.props$.userLocation.longitude }, prevAddress: this.address });
                }
            });

            this.hideLoader();
        }, 500);
    }

    async search() {
        this.Log("search is called");
        this.debugCore();

        await this.locationService.saveLocationByAddress(this.address);

        this.callback(this.stateService.props$.geo);

        if (this.stateService.props$.userLocation) {
            this.location.lat = this.stateService.props$.userLocation.latitude;
            this.location.lng = this.stateService.props$.userLocation.longitude;
            this.address = this.stateService.props$.userLocation.address;
            this.stateService.setProps({ joinOurNetwork: { userLocation: this.stateService.props$.userLocation } });
            this.stateService.props$.step2 = true;
            this.personaliseService.getServicesList(this.location.lat, this.location.lng).subscribe(result => {
                this.stateService.setProps({ servicesList: result });
            });
            this.supportService.getSubscriptionList().subscribe(result => {
                this.stateService.setProps({ subscriptionList: result });
            });
        }

        this.Log("after search is called");
        this.debugCore();
    }

    async setMapDefaultLocation(location: any = null) {

        if (this.stateService.device$ && this.stateService.device$.geo && this.stateService.device$.geo.coords) {
            this.location.lat = this.stateService.device$.geo.coords.latitude;
            this.location.lng = this.stateService.device$.geo.coords.longitude;
        } else {
            this.location = { lat: 51.5287718, lng: -0.2416815 };
        }

        this.mapOptions = {
            center: this.location,
            zoom: 16,
            mapTypeControl: false,
            disableDefaultUI: true
        };

        setTimeout(() => {
            this.Log("Setting up map object");
            this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);

            google.maps.event.addListener(this.map, 'dragend', async () => {
                this.Log("dragend event is fired");
                var restaurantLocation = { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() };
                this.stateService.setProps({ restaurantLocation: restaurantLocation });
            });

            this.hideLoader();
        }, 500);
    }

    getGeolocation() {
        if (this.geo && this.geo.coords) {
            return { longitude: this.geo.coords.longitude, latitude: this.geo.coords.latitude }
        }
    }

    setDeliveryMethod(method: string) {
        this.tempDeliveryMethod = method;
    }

    isSelectedDeliveryMethod(method: string): boolean {
        return this.stateService.device$.userPreferences.deliveryMethod == method;
    }

    save() {
    }

    load() {
        this.Log("Load called");

        if (this.stateService.device$ && this.stateService.device$.geo && this.stateService.device$.geo.coords) {
            this.location.lat = this.stateService.device$.geo.coords.latitude;
            this.location.lng = this.stateService.device$.geo.coords.longitude;
        } else {
            this.location = { lat: 51.5287718, lng: -0.2416815 };
        }
    }

    goBack() {
        this.goBack();
    }

    dismiss() {
        this.hideLoader();
        this.modalController.dismiss({
            'dismissed': true
        });
    }
}