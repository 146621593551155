import { ChangeDetectorRef, ViewChild, ViewChildren, QueryList, Renderer2, Input, OnInit, Output, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController, IonSlides } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { ClassifiedAdStatus } from 'src/app/shared/models/constants.model';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { ClassifiedAdsService } from '../services/classified.ads.service';

@Directive()
export class ClassifiedAdBase extends ObjectBase implements OnInit {
    @Input() classifiedAd: any;
    @Input() player: any;
    @Input() hideSliderView: boolean;
    @Input() applyPadding: boolean = false;
    @Input() doNotCallScroll: boolean = false;
    @Input() showOrderReadyFor: boolean = true;
    @Input() forceReloadData: boolean = false;
    @Input() logClickEvent: boolean = false;
    @Input() source: string = "";
    @Input() showRequestCredit: boolean = true;
    @Input() showInsightsSummary: boolean = false;
    @Input() hideCategoryName: boolean = false;
    @Input() leftImageViewStyle: boolean = false;
    @Input() showFullScreenImageSlider: boolean = false;

    modelLoaded: boolean = false;

    @Output("didScroll") didScroll: EventEmitter<any> = new EventEmitter();

    currentSlideIndex: number = 0;

    @ViewChildren('adPlayer') adPlayers: QueryList<any>;
    @ViewChildren('slide') slides: QueryList<any>;

    @ViewChild("adPlayer") adPlayer: any;
    @ViewChild("slider") slider: any;


    //@ViewChild("adPlayer1", { static: false }) adPlayer1: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;

    public slideOptions = {
        slidesPerView: 1.05,
        initialSlide: 0,
        speed: 400,
        spaceBetween: 10
    };


    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected geolocation: Geolocation,
        public geoService: LocationService,
        protected basketService: BasketService,
        protected deviceService: DeviceService,
        protected appVersionService: AppVersionService,
        protected classifiedAdsService: ClassifiedAdsService,
        //private loadingController: LoadingController,
        protected videoPlayer: VideoPlayer,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
        this.classifiedAdsService = ClassifiedAdsService.Instance;
    }

    ngOnInit() {
    }

    public getClassifiedAdHeading(classifiedAd: any): string {
        var result = null;

        if (this.showName() && classifiedAd) {
            var categoryName = this.getFullCategoryName(classifiedAd);

            if (this.hideCategoryName) {
                categoryName = null;
            }

            if (!this.isNullOrEmpty(categoryName) && classifiedAd.town) {
                result = categoryName + " - " + classifiedAd.town;
            } else if (!this.isNullOrEmpty(categoryName) && !classifiedAd.town) {
                result = categoryName;
            } else if (this.isNullOrEmpty(categoryName) && classifiedAd.town) {
                result = classifiedAd.town;
            }
        }

        if (!this.isNullOrEmpty(result)) {
            return this.toCapitalise(result);
        }

        return "";
    }

    public getPrice(classifiedAd: any): number {
        return classifiedAd.price + classifiedAd.tax;
    }

    public showPrice(classifiedAd: any): boolean {
        if (classifiedAd && classifiedAd.showPriceInput == true) {
            return true;
        }

        return false;
    }

    public getFullCategoryName(classifiedAd): string {
        if (!classifiedAd) return null;

        if (!this.isNullOrEmpty(classifiedAd.adParentCategory) && !this.isNullOrEmpty(classifiedAd.adCategory)) {
            return classifiedAd.adParentCategory + " > " + classifiedAd.adCategory;
        } else if (!this.isNullOrEmpty(classifiedAd.adParentCategory) && this.isNullOrEmpty(classifiedAd.adCategory)) {
            return classifiedAd.adParentCategory;
        } else if (this.isNullOrEmpty(classifiedAd.adParentCategory) && !this.isNullOrEmpty(classifiedAd.adCategory)) {
            return classifiedAd.adCategory;
        } else {
            return null;
        }
    }

    showClassifiedAd(classifiedAd: any) {
        //this.navigateWithState('/restaurant/menu', { restaurant: restaurant, reloadRestaurantMenu: true });
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            this.navigateWithState('/view-ad/' + classifiedAd.adKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { classifiedAd: classifiedAd, reloadClassifiedAd: true });
        } else {
            this.navigateWithState('/view-ad/' + classifiedAd.adKey, { classifiedAd: classifiedAd, reloadClassifiedAd: true });
        }
    }

    showMenuWithReloaClassifiedAdData(classifiedAd: any) {
        //this.navigateWithState('/restaurant/menu', { restaurant: restaurant, reloadRestaurantMenu: true });
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            this.navigateWithState('/view-ad/' + classifiedAd.adKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { classifiedAd: null, reloadClassifiedAd: true });
        } else {
            this.navigateWithState('/view-ad/' + classifiedAd.adKey, { classifiedAd: null, reloadClassifiedAd: true });
        }
    }

    showClassifiedAdWithLocationCheck($event, classifiedAd: any) {

        if (this.showFullScreenImageSlider) {
            this.showFullScreenViewer();
        } else {
            if (classifiedAd.ad) {
                this.adClicked(classifiedAd.ad, false).then(() => { });
                return;
            }

            if (this.logClickEvent) {
                this.logClickedEvent(this.source, classifiedAd.id);
            }

            if (this.forceReloadData && this.doWeKnowUserLocation()) {
                this.showMenuWithReloaClassifiedAdData(classifiedAd);
            } else if (this.doWeKnowUserLocation()) {
                this.showClassifiedAd(classifiedAd);
            } else {
                this.Log(classifiedAd);
                var url = '/view-ad/' + classifiedAd.adKey;
                this.stateService.setState({ classifiedAd: classifiedAd, reloadClassifiedAd: true });
                this.stateService.setProps({ classifiedAdUrl: url });
                this.goChangeLocation();
            }
        }

        $event.preventDefault();
    }

    play($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        } 

        this.playVideo(player);
        $event.stopPropagation();
    }

    pause($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.pauseVideo(player);
        $event.stopPropagation();
    }

    mute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.muteAudio(player);
        $event.stopPropagation();
    }

    unmute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.unMuteAudio(player);
        $event.stopPropagation();
    }

    //slideChanged($event) {
    //    this.Log("Slide changed event fired");
    //    this.Log($event);
    //    this.Log(this.slider);
    //    this.slider.getActiveIndex().then(index => {
    //        this.currentSlideIndex = index;
    //    });
    //}

    //slideTaped($event) {
    //    this.Log($event);
    //    this.Log("Slide taped");
    //    this.slider.getActiveIndex().then(index => {
    //        this.currentSlideIndex = index;
    //    });
    //}

    //changeSlideIndex(num: number) {
    //    this.Log("Slide index function  is called", num);
    //    if (num > 0) {
    //        this.currentSlideIndex += num;
    //    } else if (num < 0 && this.currentSlideIndex > 0) {
    //        this.currentSlideIndex -= num;
    //    }
    //}

    //changeSlideIndex($event) {
    //    this.Log("Slide index function  is called", $event);
    //}

    slideChange(slider: IonSlides) {       
        this.Log(slider);
        slider.getActiveIndex().then(index => {
            //this.Log("Get index completed");
            ////this.currentSlideIndex = index;
            //this.Log(this.currentSlideIndex);
            //this.Log(this.slides);
        });

        if (this.didScroll && !this.doNotCallScroll) {
            this.Log("Calling didscroll ");
            this.didScroll.emit({ adPlayers: this.adPlayers });
        } else if (this.doNotCallScroll) {
            this.checkPlayers(this.adPlayers);
        }
    }

    public getAssetPosterUrl(assets: any, index: any): string {
        if (assets && assets.length > index && index >= 0) {
            for (var i = index; i < assets.length; i++) {
                if (assets[i].isPoster) {
                    return environment.classifiedAdsResourcesBaseUrl + this.classifiedAd.id + "/" + assets[i].name;
                }
            }
        } 

        return "";
    }

    public getAssetPosterUrlV2(assets: any, index: any): string {
        if (assets) {
            var assetsList = assets.split(',');
            if (assetsList && assetsList.length > index && index >= 0) {
                return assetsList[index];
            }
        }

        return "";
    }

    public getClassifiedResourceUrl(classifiedAd: any, assetName: string): string {
        return environment.classifiedAdsResourcesBaseUrl + this.classifiedAd.id + "/" + assetName;
    }


    public getBaseUrl(): string {
        return environment.classifiedAdsResourcesBaseUrl + this.classifiedAd.id + "/";
    }

    public getClassifiedAdResourceUrl(classifiedAd: any, index: any = 0): string {
        return environment.classifiedAdsResourcesBaseUrl + this.classifiedAd.id + "/" + classifiedAd.imagesAndVideos[index].name;
    }

    public logClassifiedAdImpression(classifiedAd: any) {
        if (!classifiedAd.impressionCounted) {
            var data = {};
            var service = this.getService();
            if (service) {
                data = { id: classifiedAd.id };
            } else {
                data = { id: classifiedAd.id };
            }

            this.personaliseService.logInHeaders("default", "classified-ad-impression", data);
            this.classifiedAdsService.logClassifiedAdEvent(this.classifiedAd.id, 'classified-ad-impression', null, 'classified-ad-impression');
            classifiedAd.impressionCounted = true;
            this.Log("classified-ad-impression", this.classifiedAd);
        } else {
            this.Log("impression already logged", this.classifiedAd);
        }
    }

    public async unPublishClassifiedAd($event: any, classifiedAd: any) {
        $event.stopPropagation();

        this.confirm(
            "Un-publish Classified Ad",
            null,
            "Are you sure you want to un-publish your ad?",
            async () => {
                await this.showLoader();
                this.classifiedAdsService.unPublishClassifiedAd(classifiedAd.id).subscribe(result => {
                    this.classifiedAd = result;
                    classifiedAd = result;
                },
                    error => {
                        this.handleError(error);
                    },
                    () => {
                        this.hideLoader();
                    });
            },
            () => {
                this.hideLoader();
            });
    }

    public async publishClassifiedAd($event: any, classifiedAd: any) {
        $event.stopPropagation();

        this.confirm(
            "Publish Classified Ad",
            null,
            "Are you sure you want to publish your ad?",
            async () => {
                await this.showLoader();
                this.classifiedAdsService.publishClassifiedAd(classifiedAd.id).subscribe(result => {
                    this.classifiedAd = result;
                    classifiedAd = result;
                },
                    error => {
                        this.handleError(error);
                    },
                    () => {
                        this.hideLoader();
                    });
            },
            () => {
                this.hideLoader();
            });        
    }

    public async soldClassifiedAd($event: any, classifiedAd: any) {
        $event.stopPropagation();

        this.confirm(
            "Classified Ad Update",
            null,
            "That is great news! We will remove your ad and you will not be able to receive any further queries. Are you sure to proceed?",
            async () => {
                await this.showLoader();
                this.classifiedAdsService.soldClassifiedAd(classifiedAd.id).subscribe(result => {
                    this.classifiedAd = result;
                    classifiedAd = result;
                },
                    error => {
                        this.handleError(error);
                    },
                    () => {
                        this.hideLoader();
                    });
            },
            () => {
                this.hideLoader();
            }); 
    }

    public async unSoldClassifiedAd($event: any, classifiedAd: any) {
        $event.stopPropagation();

        this.confirm(
            "Classified Ad Update",
            null,
            "Are you sure you want to mark this ad as unsold?",
            async () => {
                await this.showLoader();
                this.classifiedAdsService.unSoldClassifiedAd(classifiedAd.id).subscribe(result => {
                    this.classifiedAd = result;
                    classifiedAd = result;
                },
                    error => {
                        this.handleError(error);
                    },
                    () => {
                        this.hideLoader();
                    });
            },
            () => {
                this.hideLoader();
            }); 
    }

    public isSold(classifiedAd: any): boolean {
        return classifiedAd.status == ClassifiedAdStatus.Sold;
    }

    public canBeUnSold(classifiedAd: any): boolean {
        return !classifiedAd.isExpired && !classifiedAd.isDisabled && !classifiedAd.isClosed && !classifiedAd.isAdPublished && classifiedAd.status == ClassifiedAdStatus.Sold;
    }

    public canBeEdited(classifiedAd: any): boolean {
        return !classifiedAd.isExpired && !classifiedAd.isDisabled && !classifiedAd.isClosed && !classifiedAd.isAdPublished && classifiedAd.status == ClassifiedAdStatus.Sold;
    }

    public showClassifiedAdActionButtons(classifiedAd: any) {
        return !classifiedAd.isExpired && !classifiedAd.isDisabled && !classifiedAd.isClosed;
    }    

    async showFullScreenViewer() {
        //if (this.modelLoaded) {
        //    return;
        //}

        //this.modelLoaded = true;

        //const modal = await this.modalController.create({
        //    component: ImageFullScreenViewer,
        //    componentProps: {
        //        ad: this.classifiedAd,
        //        baseUrl: this.getBaseUrl(),
        //        data: this.classifiedAd.imagesAndVideos
        //    },
        //    cssClass: "image-editor-model-full-black"
        //});

        //modal.onDidDismiss()
        //    .then((result: any) => {
        //        this.modelLoaded = false;
        //    });

        ////this.displayActionSheet("News", "");
        //await modal.present();

        this.setFullImageSlider(true);
    }
}