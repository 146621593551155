<ion-grid fixed class="filter-categories">
    <ion-row>
        <ion-col size="12">
            <ion-segment class="template-0-segment" scrollable *ngIf="templateId==0">
                <ion-segment-button style="padding: 5px; margin-top:0px; margin-bottom:0px;" *ngFor="let item of categories; let index = index" (click)="search(item)">
                    <ion-label>{{this.toCapitalise(item.name)}}</ion-label>
                </ion-segment-button>
            </ion-segment>
            <ion-segment class="template-1-segment" scrollable *ngIf="templateId==1">
                <ion-segment-button style="padding: 0px; margin-top:0px; margin-bottom:0px;" *ngFor="let item of categories; let index = index" (click)="search(item)">
                    <div>
                        <img [src]="this.getBaseUrl(item)" style="width:60px; height:60px; " [attr.data-noimageurl]="getNoImageUrl()" onerror="this.onerror = null; this.src = this.dataset.noimageurl" />
                    </div>
                    <ion-label>{{this.toCapitalise(item.name)}}</ion-label>
                </ion-segment-button>
            </ion-segment>
            <ion-segment class="template-2-segment" scrollable style="margin:0px; padding:0px" *ngIf="templateId==2">
                <ion-segment-button style="padding: 0px; margin-top:0px; margin-bottom:0px;" *ngFor="let item of categories; let index = index" (click)="search(item)">
                    <span>{{this.toCapitalise(item.name)}}</span>
                </ion-segment-button>
            </ion-segment>
            <ion-segment class="template-1-segment" scrollable *ngIf="templateId==3">
                <ion-segment-button style="padding-top: 5px; padding-bottom: 5px; padding-right: 0px; padding-left: 0px; margin-top: 0px; margin-bottom: 0px; " *ngFor="let item of categories; let index = index" (click)="search(item)">
                    <!--<div style="background: rgb(69, 107, 217); width: 4.5em; height: 4.5em">
                <img [src]="this.getBaseUrl(item)" style="width:60px; height:60px; " [attr.data-noimageurl]="getNoImageUrl()" onerror="this.onerror = null; this.src = this.dataset.noimageurl" />
            </div>
            <ion-label>{{this.toCapitalise(item.name)}}</ion-label>-->
                    <div class="filter-circle">
                        <ion-img class="circle-icon" [src]="getBaseUrl(item)" *ngIf="getBaseUrl(item)"></ion-img>
                    </div>
                    <div style="margin-left: auto; margin-right: auto; max-width: 50px; " [ngClass]="{'select-option': isCurrent(item)}">
                        <ion-label style="font-size: 13px !important; ">{{this.toCapitalise(item.name)}}</ion-label>
                    </div>
                </ion-segment-button>
            </ion-segment>
            <ion-segment class="template-1-segment" scrollable *ngIf="templateId==4">
                <ion-segment-button style="padding: 0px; margin-top:0px; margin-bottom:0px; max-width: 50px;" *ngFor="let item of categories; let index = index" (click)="search(item)">
                    <div class="filter-circle">
                        <ion-img class="circle-icon" [src]="getBaseUrl(item)" *ngIf="getBaseUrl(item)"></ion-img>
                    </div>
                    <div style="margin-left: auto; margin-right: auto; " [ngClass]="{'select-option': isCurrent(item)}">
                        <ion-label style="font-size:13px !important;">{{this.toCapitalise(item.name)}}</ion-label>
                    </div>
                </ion-segment-button>
            </ion-segment>
        </ion-col>
    </ion-row>
</ion-grid>
