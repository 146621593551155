import { Component, ViewChild, ChangeDetectorRef, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController, IonSlides } from '@ionic/angular';
import { NavController, ModalController } from '@ionic/angular';
import { ObjectBase } from '../../object.base';
import { UiStateService } from '../../../services/ui.state.service';
import { UserService } from '../../../services/user.services';
import * as moment from 'moment';

@Component({
  selector: 'taeam-cookie-consent',
  templateUrl: 'cookie.consent.html',
  styleUrls: ['./cookie.consent.scss'],
})
export class CookieConsent extends ObjectBase implements OnInit {

  public showCookieConsent: boolean;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected uiStateService: UiStateService,
    protected cdr: ChangeDetectorRef,
    protected navCtrl: NavController,
    protected userService: UserService,
    public modalController: ModalController) {
    super(router, route, uiStateService, cdr, navCtrl, userService);
    this.showCookieConsent = false;
    this.Log("Cookie consent");
  }

  startApp() {
  }

  ngOnInit() {
    this.checkConsent();
  }

  ionViewWillEnter() {
    this.checkConsent();
  }

  ionViewDidLeave() {
  }

  public agreeToConsent() {
      this.Log("User agreed to cookie consent");
      this.personaliseService.logEvent("cookie-consent-agreed", JSON.stringify({}));
    var cookieConsent = { expiryDate: moment().add(1, 'days') }
    this.stateService.setState({ cookieConsent: cookieConsent });
    this.stateService.setDevice({ cookieConsent: cookieConsent });
    this.showCookieConsent = false;
  }

  private async checkConsent() {
    this.Log("Check for cookie consent");
    var deviceInfo = await this.localDeviceService.getDeviceInfo();
    if (deviceInfo.platform == 'web') {
      var cookieConsent = this.stateService.device$.cookieConsent;
      this.Log(cookieConsent);

      if (cookieConsent) {
        var currentDate = moment();
        this.Log("Current date:");
        this.Log(currentDate);
        if (currentDate.isAfter(cookieConsent.expiryDate)) {
            this.showCookieConsent = true;
            this.personaliseService.logEvent("cookie-consent-presented", JSON.stringify({}));
        } else {
          this.showCookieConsent = false;
        }
      } else {
        this.showCookieConsent = true;
      }
    }
  }
}
