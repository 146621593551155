import { Injectable } from '@angular/core';
import { JoinOurNetwork, DeliveryPartner } from '../shared/models/shared.model';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class SupportService extends ServiceBase {

    private joinOurNetworkApiUrl: string;
    private verifyJoinOurNetworkApiUrl: string;
    private deliveryPartnerApiUrl: string;
    private getTermsApiUrl: string;
    private getOurStoryApiUrl: string;
    private getPrivacyPolicyApiUrl: string;
    private getCookiePolicyApiUrl: string;
    private getJoiningTermsApiUrl: string;
    private getSubscriptionListApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.joinOurNetworkApiUrl = environment.supportApiUrl + 'Support/Joinournetwork';
        this.verifyJoinOurNetworkApiUrl = environment.supportApiUrl + 'Support/Joinournetwork-confirm/';
        this.deliveryPartnerApiUrl = environment.supportApiUrl + 'Support/DeliveryPartner';
        this.getTermsApiUrl = environment.supportApiUrl + 'terms';
        this.getOurStoryApiUrl = environment.supportApiUrl + 'ourstory';
        this.getPrivacyPolicyApiUrl = environment.supportApiUrl + 'privacypolicy';
        this.getCookiePolicyApiUrl = environment.supportApiUrl + 'cookies';
        this.getJoiningTermsApiUrl = environment.supportApiUrl + 'joining-terms';
        this.getSubscriptionListApiUrl = environment.supportApiUrl + 'subscription/list';
    }

    public joinOurNetwork(joinOurNetwork: JoinOurNetwork): Observable<any> {
        return this.post(this.joinOurNetworkApiUrl, joinOurNetwork);
    }

    public verifyJoinOurNetwork(id: any): Observable<any> {
        return this.post(this.verifyJoinOurNetworkApiUrl + this.getId() + "/" + id, {});
    }

    public deliveryPartner(deliveryPartner: DeliveryPartner): Observable<any> {
        return this.post(this.deliveryPartnerApiUrl, deliveryPartner);
    }

    public getTerms(): Observable<any> {
        return this.get(this.getTermsApiUrl);
    }

    public getJoiningTerms(): Observable<any> {
        return this.get(this.getJoiningTermsApiUrl);
    }

    public getPrivacyPolicy(): Observable<any> {
        return this.get(this.getPrivacyPolicyApiUrl);
    }

    public getCookiePolicy(): Observable<any> {
        return this.get(this.getCookiePolicyApiUrl);
    }

    public getOurStory(): Observable<any> {
        return this.get(this.getOurStoryApiUrl);
    }

    public getSubscriptionList(): Observable<any> {
        return this.get(this.getSubscriptionListApiUrl);
    }
}