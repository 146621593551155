import { Injectable } from '@angular/core';
import { ToastController, Platform } from '@ionic/angular';
import { DeviceService } from './device.service';
import { environment } from 'src/environments/environment';
import { ToasOptions } from '../shared/models/shared.model';

@Injectable()
export class ToastNotificationService {

    private static _instance: ToastNotificationService;
    private toastController: ToastController;
    private deviceService: DeviceService;
    

    constructor() {
        this.toastController = new ToastController();
        this.deviceService = DeviceService.Instance;
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        var obj = this._instance || (this._instance = new this());
        return obj;
    }

    public showToastMessage(message: string, duration: number = environment.toastNotificationDuration) {
        this.deviceService.getDeviceInfo().then(device => {

            if (device.platform == 'android') {
                this.toastController.create({
                    message: message,
                    duration: duration,
                    position: 'top'
                }).then((toast) => {
                    toast.present();
                });
            } else {
                this.toastController.create({
                    message: message,
                    duration: duration
                }).then((toast) => {
                    toast.present();
                });
            }
        })
    }

    public showToastMessage2(message: string, position: ToasOptions, duration: number = environment.toastNotificationDuration) {
        this.deviceService.getDeviceInfo().then(device => {
            this.toastController.create({
                message: message,
                duration: duration,
                position: position
            }).then((toast) => {
                toast.present();
            });
        })
    }
}
