<div class="your-preferences">
    <div><h2>Your Location</h2></div>
    <div style="margin-bottom:10px;">Please enter your address in below text box and press enter key to search</div>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group pt10">
                    <input class="form-control address" [(ngModel)]="address" placeholder="Type here to search" (keyup.enter)="search()" (focus)="this.address='';">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="find-restaurant">
                    <button (click)="dismiss()">Close</button>
                    <button (click)="search()">Search</button>
                </div>
            </div>
        </div>
    </div>
    <div #Map class="map"></div>
    <div class="centerMarker"></div>
</div>

