import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ServiceBase } from './service.base';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { environment } from '../../environments/environment';
import { HTTP } from '@ionic-native/http/ngx';
import { Common } from '../shared/common';

@Injectable({
    providedIn: "root"
})
export class WeatherService extends ServiceBase {

  private static _instance: WeatherService;

  constructor(protected http: HttpClient,
    protected httpNative: HTTP,
    private geolocation: Geolocation) {
    super(http, httpNative);
  }

  public static get Instance() {
    // Do you need arguments? Make it a regular method instead.
    return this._instance || (this._instance = new this(Common.InjectorInstance.get<HttpClient>(HttpClient), Common.InjectorInstance.get<HTTP>(HTTP), Common.InjectorInstance.get<Geolocation>(Geolocation)));
  }

  getWeatherByPostcode(postcode: string): Observable<any> {
    var url = environment.weatherApiUrl + "&q=" + postcode;
        return this.get(url);
  }

  getWeatherByLocation(latitude: any, longitude: any): Observable<any> {
    var url = environment.weatherApiUrl + "&q=" + latitude + "," + longitude;
    return this.get(url);
  }

  getWeather() {
    if (this.stateService.props$.geo && this.stateService.props$.geo.coords && !this.stateService.props$.weatherData) {
      var url = environment.weatherApiUrl + "&q=" + this.stateService.props$.geo.coords.latitude + "," + this.stateService.props$.geo.coords.longitude;
      this.get(url).subscribe(result => {
        this.Trace("Weather data");
        this.Trace(result);
        this.stateService.setProps({ weatherData: result });
      });
    }
    }

    getWeatherByLatLong(lat, long) {
        var url = environment.weatherApiUrl + "&q=" + lat + "," + long;
        this.get(url).subscribe(result => {
            this.Trace("Weather data");
            this.Trace(result);
            this.stateService.setProps({ weatherData: result });
        });
    }

  clearWeatherData() {
    this.stateService.setProps({ weatherData: null });
  }
}
