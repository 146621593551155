import { Component, NgZone } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoginService } from '../services/login.service';
import { RemoteNotificationService } from '../services/remote.notification.service';
import { LocationService } from '../services/location.service';
import { StateService } from '../services/state.service';
import { BasketService } from '../services/basket.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { PersonlisationService } from '../services/personalisation.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Plugins } from '@capacitor/core';
import { Router } from '@angular/router';
import 'capacitor-plugin-app-tracking-transparency'; // only if you want web support

import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { RestaurantService } from '../services/restaurant.service';
import { environment } from '../../environments/environment';
import { DeliveryMethods } from '../shared/models/shared.model';

const { App } = Plugins;

const nothing: any = {};

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    private stateService: StateService;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private loginService: LoginService,
        private locationService: LocationService,
        private personalisationService: PersonlisationService,
        private basketService: BasketService,
        private restaurantService: RestaurantService,
        private deeplinks: Deeplinks,
        private remoteNotificationService: RemoteNotificationService,
        private screenOrientation: ScreenOrientation,
        private navController: NavController,
        protected navCtrl: NavController,
        private geolocation: Geolocation,
        private zone: NgZone,
        private router: Router
    ) {
        this.stateService = StateService.Instance;
        this.personalisationService = PersonlisationService.Instance;
        this.restaurantService = RestaurantService.Instance;
        this.splashScreen.show();
        this.initializeApp();
    }

    async initializeApp() {
        this.platform.ready().then(async () => {
            RemoteNotificationService.setInstance(this.remoteNotificationService);            
            this.stateService.setDevice({ forceAskForLocation: true });
            this.stateService.setDevice({ marketingAds: null, taeamServices: null });
            this.locationService.saveLocation();
            
            //if (environment.wlaActive && this.stateService.device$.restaurant) {
            //    this.stateService.setState({ restaurant: this.stateService.device$.restaurant });
            //    this.stateService.setProps({ restaurant: this.stateService.device$.restaurant });
            //}
            //else if (environment.wlaActive) {
            //    this.restaurantService.getRestaurant(environment.wlaRestaurantId).subscribe(result => {
            //        this.stateService.setState({ restaurant: result });
            //        this.stateService.setProps({ restaurant: result });
            //        this.stateService.setDevice({ restaurant: result });
            //    });
            //}


            //const delay = ms => new Promise(res => setTimeout(res, ms));
            //await delay(3000);
            // this.personalisationService.getMarketingAds().subscribe(
            //     result => {
            //         this.stateService.setProps({ marketingAds: result });
            //     },
            //     error => {
            //     }
            // );

            // this.personalisationService.getTaeamServices().subscribe(
            //     result => {
            //         if (!result || !result.length || result.length == 0) {
            //             result = new Array<any>();
            //             result.push(this.personalisationService.getRestaurantService())
            //         }

            //         if (result.length == 1) {
            //             this.stateService.setProps({ manualServiceViewCall: true });
            //         }

            //         this.stateService.setDevice({ taeamServices: result });
            //     },
            //     error => {
            //     }
            // );
            //this.basketService.restoreBasketFromServer();

            this.geolocation.getCurrentPosition()
                .then(async (result) => {
                    this.stateService.setDevice({ geo: result });
                })
                .catch(async (error) => {
                });
            this.statusBar.styleDefault();
            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
            this.setupDeepLinks();
            this.zone.run(async () => {
                if (this.stateService.device$ && !this.stateService.device$.appTrackingTransparency) {
                    if (this.platform.is("ios")) {
                        const response = await this.requestPermission();
                      }    
                }
            });

            if (environment.wlaActive && !this.isNullOrEmpty(environment.wlaServiceId)) {
                this.personalisationService.getTaeamService(environment.wlaServiceId).subscribe(result => {
                    if (result) {
                        this.stateService.setDevice({ selectedService: result });
                    }
                });
            }

            this.personalisationService.logEventIfFirstRunAfterAppInstalled();
            this.splashScreen.hide();
        });
    }

    protected navigateWithState(url: string, state: any = null, forward: boolean = true) {
        if (state) {
            this.stateService.setState(state);
        }

        //this.router.navigate([url]);
        if (forward) {
            this.navCtrl.navigateForward(url);
        } else {
            this.navCtrl.navigateBack(url);
        }
    }

    public go(url: string, forward: boolean = true) {
        this.navigateWithState(url, null, forward);
    }

    public isNullOrEmpty(val: any): boolean {
        if (val == null || val == undefined || val.length == null || (val.trim && (val.trim() == "" || val.trim().length == 0))) {
            return true;
        } else {
            return false;
        }
    }

    setupDeepLinks() {
        App.addListener('appUrlOpen', (data: any) => {
            const path = data.url.replace("http://www.crazon.io", "").replace("https://www.crazon.io", "").split("/").slice(1);
            const page = path.join("/");
            const action = page.split("/");

            const actionName: string = action[0].toLowerCase();
            const id: string = action[1];

            if (actionName == "appstore" && id) {
                this.personalisationService.logInHeaders('marketing', 'qrcode-scan', JSON.stringify({ id: id }));
                this.stateService.setDevice({ appmId: id });
                //alert(actionName + " - " + id );
            } else if (actionName == "apporder" && id) {
                this.personalisationService.logInHeaders('marketing', 'qrcode-scan', JSON.stringify({ orderId: id }));
                this.stateService.setDevice({ appmOrderId: id });
                //alert(actionName + " - " + id );
            } else if (actionName == "appmenu" && id) {
                this.personalisationService.logInHeaders('marketing', 'qrcode-scan', JSON.stringify({ restaurantId: id }));
                //alert(actionName + " - " + id );
                this.stateService.setDevice({ appmRestaurantId: id });
            }

            this.zone.run(() => {
                this.router.navigateByUrl("/home");
            });
        });

        // this.deeplinks.route({
        //     '/appStore/:id': nothing,
        //     '/appOrder/:orderId': nothing,
        //     '/appMenu/:restaurantId': nothing
        // }).subscribe(match => {
        //     if (match.$args && match.$args["id"]) {
        //         this.personalisationService.logInHeaders('marketing', 'qrcode-scan', JSON.stringify({ id: match.$args["id"] }));
        //         this.stateService.setDevice({ appmId: match.$args["id"] });
        //     }

        //     if (match.$args && match.$args["orderId"]) {
        //         this.personalisationService.logInHeaders('marketing', 'qrcode-scan', JSON.stringify({ orderId: match.$args["orderId"] }));
        //         this.stateService.setDevice({ appmOrderId: match.$args["orderId"] });
        //     }

        //     if (match.$args && match.$args["restaurantId"]) {
        //         this.personalisationService.logInHeaders('marketing', 'qrcode-scan', JSON.stringify({ restaurantId: match.$args["restaurantId"] }));
        //         this.stateService.setDevice({ appmRestaurantId: match.$args["restaurantId"] });
        //     }
        // }, nomatch => {
        //     // nomatch.$link - the full link data
        // });
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public async requestPermission(): Promise<AppTrackingStatusResponse> {
        const response = await AppTrackingTransparency.requestPermission();
      
        this.stateService.setDevice({ appTrackingTransparency: response });
        // { status: 'authorized' } for example
      
        return response;
    }
}