import { Injectable } from '@angular/core';
import { ServiceBase } from './service.base';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './user.services';
import { HTTP } from '@ionic-native/http/ngx';
import { SplashScreen } from '@capacitor/splash-screen';

@Injectable()
export class LoginService extends ServiceBase {
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        private userService: UserService,
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
    }

    login() {
        this.Log('*** Login ****');
        this.Log(this.stateService.device$);
        if (this.stateService.device$ && this.stateService.device$.mobileNo) {
            this.mobileLogin();
        } else {
            this.deviceService.getDeviceInfo().then((data: any) => {
                this.Log(data);
                this.stateService.setDevice(data);
                this.Log(this.stateService.device$);
                if (this.stateService.device$.platform != 'web') {
                    SplashScreen.hide();
                }
                this.goToNext('tfa/generate');
            });
        }
    }

    mobileLogin() {
        this.userService.mobileLogin(this.stateService.device$.mobileNo, this.stateService.device$.uuid)
            .subscribe(
                result => {
                    if (this.stateService.device$.platform != 'web') {
                        SplashScreen.hide();
                    }
                    //this.Log(result);
                    this.stateService.setProps({ user: result, token: result.token, loginTime: result.loggedInAt });
                    //this.Log(this.stateService.props$);
                    this.goToNext('home');
                },
                error => {
                    if (this.stateService.device$.platform != 'web') {
                        SplashScreen.hide();
                    }
                    this.Log(error);
                    if (error.error.status == 401) {
                        this.stateService.clearDevice();
                        this.goToNext('tfa/generate');
                    }
                    else {
                        if (this.stateService.device$.platform != 'web') {
                            SplashScreen.hide();
                        }
                        this.handleError(error);
                    }
                });
    }

    protected goToNext(route: string) {
        setTimeout(() => {
            this.Log("GoToNext is called: ", route);
            this.router.navigate([route]);
        }, 1000);  //1s
    }
}
