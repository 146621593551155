import { Injectable } from '@angular/core';
import { User } from '../shared/models/shared.model';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class UserService extends ServiceBase {

    //private serviceBaseRoute: string = ':7076/api/';
    //private registerApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'register';
    //private updateUserApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'user/profile';
    //private getUserInfoApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'user/profile';
    //private loginApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'login';
    //private mobileLoginApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'mobile';
    //private tfaCodeGeneratorApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'tfacode/generate';
    //private tfaCodeValidatorApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'tfacode/validate';

    private registerApiUrl: string;
    private updateUserApiUrl: string;
    private updatePntApiUrl: string;
    private getUserInfoApiUrl: string;
    private loginApiUrl: string;
    private webLoginApiUrl: string;
    private mobileLoginApiUrl: string;
    private tfaCodeGeneratorApiUrl: string;
    private tfaCodeValidatorApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.registerApiUrl = environment.identityApiUrl + 'register';
        this.updateUserApiUrl = environment.identityApiUrl + 'user/profile';
        this.updatePntApiUrl = environment.identityApiUrl + 'user/profile/pnt';
        this.getUserInfoApiUrl = environment.identityApiUrl + 'user/profile';
        this.loginApiUrl = environment.identityApiUrl + 'login';
        this.webLoginApiUrl = environment.identityApiUrl + 'web';
        this.mobileLoginApiUrl = environment.identityApiUrl + 'mobile';
        this.tfaCodeGeneratorApiUrl = environment.identityApiUrl + 'tfacode/generate';
        this.tfaCodeValidatorApiUrl = environment.identityApiUrl + 'tfacode/validate';

        this.Log(this.mobileLoginApiUrl);
    }

    public register(user: User): Observable<any> {
        this.Log(this.registerApiUrl);
        return this.post(this.registerApiUrl, user);
    }

    public updateUser(user: User): Observable<any> {
        this.Log(this.updateUserApiUrl);
        return this.put(this.updateUserApiUrl, user);
    }

    public updatePNT(token: string): Observable<any> {
        return this.put(this.updatePntApiUrl, token);
    }

    public getUserInfo(): Observable<any> {
        return this.get(this.getUserInfoApiUrl);
    }

    public login(username: string, password: string): Observable<any> {
        return this.post(this.loginApiUrl, { username: username, password: password });
    }

    public webLogin(): Observable<any> {
        return this.post(this.webLoginApiUrl, {});
    }

    public mobileLogin(mobileNo: string, id: string): Observable<any> {
        return this.post(this.mobileLoginApiUrl, { mobileNo: mobileNo, id: id });
    }

    public tfaGenerateCode(mobileNo: string, deviceId: string): Observable<any> {
        return this.post(this.tfaCodeGeneratorApiUrl, { key: mobileNo, deviceId: deviceId });
    }

    public tfaValidateCode(mobileNo: string, code: string, deviceInfo: any): Observable<any> {
        return this.post(this.tfaCodeValidatorApiUrl, { key: mobileNo, code: code, deviceInfo: deviceInfo });
    }

    public getAuthenticatedUser() {
        return null;
    }

    public logout() {
        if (this.stateService.props$) {
            var userToken = { user: null, token: null, loginTime: null };
            this.stateService.setProps(userToken);
            this.stateService.setDevice(userToken);
        }

        this.webLogin().subscribe(result => {
            this.stateService.setProps({ user: result, token: result.token, loginTime: result.loggedInAt });
            this.stateService.setDevice({ user: result.userToken, token: result.token, loginTime: result.loggedInAt });
        });
    }
}
