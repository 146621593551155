import { Injectable } from '@angular/core';
import { ServiceBase } from './service.base';
import { MenuItem } from '../shared/models/shared.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { Common } from '../shared/common';
import { PersonlisationService } from './personalisation.service';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { MenuTemplateIds } from '../shared/models/constants.model';
@Injectable({
    providedIn: 'root'
})
export class MenuTemplateService extends ServiceBase {

    private static _instance: MenuTemplateService;

    constructor(
        protected http: HttpClient,
        private personalisationService: PersonlisationService,
        private appAvailability: AppAvailability,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.personalisationService = PersonlisationService.Instance;
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance || (this._instance = Common.InjectorInstance.get<MenuTemplateService>(MenuTemplateService));
    }

    public areMoreThanOnOptionsForItemDelivery(restaurant: any): boolean {
        var counter = 0;
        if (restaurant.deliveryAvailable) {
            counter++;
        }

        if (restaurant.collectionAvailable) {
            counter++;
        }

        if (restaurant.dineInAvailable) {
            counter++;
        }

        return counter > 1;
    }

    public hasOnlyOneCategory(menu: any): boolean {
        if (menu && menu.menuCategories && menu.menuCategories.length > 0) {
            var categories = menu.menuCategories.filter(x => x.menuCategoryName != "Our Recommendation");
            if (categories && categories.length == 1) {
                this.stateService.setProps({ hideMenuCategoriesBar: true });
                return true;
            }
        }

        return false;
    }

    public getFirstCategoryOnly(menu: any): any {
        if (menu && menu.menuCategories && menu.menuCategories.length > 0) {
            var categories = menu.menuCategories.filter(x => x.menuCategoryName != "Our Recommendation");
            if (categories && categories.length == 1) {
                this.stateService.setProps({ hideMenuCategoriesBar: true });
                return categories[0];
            }
        }

        return null;
    }

    public setupProviderAssets(restaurant: any): any {
        restaurant.assetUrls = this.getMultipleAssetUrls(restaurant.multipleDisplayAssetUrls);
        if (restaurant.assetUrls && restaurant.assetUrls.length > 0) {
            for (var i = 0; i < restaurant.assetUrls.length; i++) {
                restaurant.hasMultipleAssets = restaurant.assetUrls.length > 1;
                if (restaurant.assetUrls[i].isVideo && !restaurant.assetVideoUrl) {
                    restaurant.assetVideoUrl = restaurant.assetUrls[i].url;
                }

                if (!restaurant.assetUrls[i].isVideo && !restaurant.assetImageUrl) {
                    restaurant.assetImageUrl = restaurant.assetUrls[i].url;
                }

                if (restaurant.assetImageUrl && restaurant.assetVideoUrl) {
                    break;
                }
            }
        }

        return restaurant;
    }

    public getMultipleAssetUrls(urls: string): Array<any> {
        var result: Array<any> = null;

        if (urls) {
            var items = urls.split(',');
            result = new Array<any>();

            for (var i = 0; i < items.length; i++) {
                result.push({ url: items[i].trim(), isVideo: (items[i].toLowerCase().indexOf('video') > -1 || items[i].toLowerCase().indexOf('mp4') > -1) });
            }
        }

        this.Log("getMultipleAssetUrls");
        this.Log(result);
        return result;
    }

    configureMenu(restaurant: any, menu: any): any {
        var popularMenuItems = new Array<MenuItem>();
        var bestSellersMenuItems = new Array<MenuItem>();
        var featuredCategories = new Array<MenuItem>();
        var collectionCategories = new Array<MenuItem>();        

        var menuCategoriesExceptRecommended = new Array<any>();
        var recommendedMenuCategory: any = null;

        if (menu && menu.menuCategories) {
            menuCategoriesExceptRecommended = [];

            var counter = 0;
            var categoryCounter = 0;
            var itemsCountInOneBlock = menu.itemsCountInOneBlock;

            if (!itemsCountInOneBlock || itemsCountInOneBlock == 0) {
                if (this.contains(restaurant.menuTemplateId, "2cat-per-row")) {
                    itemsCountInOneBlock = 2;
                } else if (this.contains(restaurant.menuTemplateId, "4cat-per-row")) {
                    itemsCountInOneBlock = 4;
                } else if (this.contains(restaurant.menuTemplateId, "6cat-per-row")) {
                    itemsCountInOneBlock = 6;
                }
            }

            //this.menu.menuCategories.splice(6, 1);
            //this.menu.menuCategories.push(this.clone(this.menu.menuCategories[1]));
            var totalCategoriesExceptRecommended = menu.menuCategories.length - 1;

            for (var i = 0; i < menu.menuCategories.length; i++) {

                if (menu.menuCategories[i].menuCategoryName == "Our Recommendation") {
                    recommendedMenuCategory = menu.menuCategories[i];
                } else {
                    counter++;
                    categoryCounter++;
                    var tempCategory: any = menu.menuCategories[i];
                    var remainingItems = (totalCategoriesExceptRecommended - (categoryCounter));
                    if (itemsCountInOneBlock > 0 && ((counter > itemsCountInOneBlock && remainingItems > itemsCountInOneBlock) || (counter > itemsCountInOneBlock && remainingItems >= 2) || (this.isOdd(counter) && remainingItems == 0))) {
                        tempCategory.isOneColumn = true;
                        counter = 0;
                    } else {
                        tempCategory.isTwoColumn = true;
                    }

                    //menuCategoriesExceptRecommended.push(this.menu.menuCategories[i]);
                    menuCategoriesExceptRecommended.push(tempCategory);
                }

                if (menu.menuCategories[i].menuItems && menu.menuCategories[i].menuItems.length > 0) {
                    for (var j = 0; j < menu.menuCategories[i].menuItems.length; j++) {
                        if (menu.menuCategories[i].menuItems[j].tags && menu.menuCategories[i].menuItems[j].tags.indexOf("popular") > -1) {
                            popularMenuItems.push(menu.menuCategories[i].menuItems[j]);
                        }

                        if (menu.menuCategories[i].menuItems[j].tags && (menu.menuCategories[i].menuItems[j].tags.indexOf("best-seller") > -1 || menu.menuCategories[i].menuItems[j].tags.indexOf("best seller") > -1)) {
                            var bestSellerItems = bestSellersMenuItems.filter(x => x.menuItemName == menu.menuCategories[i].menuItems[j].menuItemName);
                            this.Log(menu.menuCategories[i].menuItems[j].menuItemName, bestSellerItems);
                            if (!bestSellerItems || bestSellerItems.length == 0) {
                                bestSellersMenuItems.push(menu.menuCategories[i].menuItems[j]);
                            }
                        }
                    }
                }                

                if (menu.menuCategories[i].featureTags && menu.menuCategories[i].featureTags.length > 0) {
                    if (menu.menuCategories[i].featureTags.indexOf("featured") > -1 && menu.menuCategories[i].imagesAndVideos && menu.menuCategories[i].imagesAndVideos.length > 0) {
                        featuredCategories.push(menu.menuCategories[i]);
                    }

                    if (menu.menuCategories[i].featureTags.indexOf("collection") > -1 && menu.menuCategories[i].imagesAndVideos && menu.menuCategories[i].imagesAndVideos.length > 0) {
                        collectionCategories.push(menu.menuCategories[i]);
                    }
                }
            }

            if (popularMenuItems && popularMenuItems.length == 0) {
                popularMenuItems = null;
            }

            if (bestSellersMenuItems && bestSellersMenuItems.length == 0) {
                bestSellersMenuItems = null;
            }

            if (featuredCategories && featuredCategories.length == 0) {
                featuredCategories = null;
            }

            if (collectionCategories && collectionCategories.length == 0) {
                collectionCategories = null;
            }

            this.stateService.setProps(
                {
                    menuCategories: menu.menuCategories,
                    recommendedMenuCategory: recommendedMenuCategory,
                    menuCategoriesExceptRecommended: menuCategoriesExceptRecommended,
                    popularMenuItems: popularMenuItems,
                    bestSellersMenuItems: bestSellersMenuItems,
                    featuredCategories: featuredCategories,
                    collectionCategories: collectionCategories
                });
        }

        menu.recommendedMenuCategory = recommendedMenuCategory;
        menu.menuCategoriesExceptRecommended = menuCategoriesExceptRecommended;
        menu.popularMenuItems = popularMenuItems;
        menu.bestSellersMenuItems = bestSellersMenuItems;
        menu.featuredCategories = featuredCategories;
        menu.collectionCategories = collectionCategories;

        this.stateService.setState({ menu: menu });

        return menu;
    }

    public resetConfiguredMenu(menu: any): any {
        this.stateService.setProps(
            {
                menuCategories: null,
                recommendedMenuCategory: null,
                menuCategoriesExceptRecommended: null,
                popularMenuItems: null,
                bestSellersMenuItems: null,
                featuredCategories: null,
                collectionCategories: null,
            });

        menu.recommendedMenuCategory = null;
        menu.menuCategoriesExceptRecommended = null;
        menu.popularMenuItems = null;
        menu.bestSellersMenuItems = null;
        menu.featuredCategories = null;
        menu.collectionCategories = null;

        return menu;
    }

    public getRecommendedMenuCategory(): any {
        if (this.stateService.props$ && this.stateService.props$.recommendedMenuCategory) {
            return this.stateService.props$.recommendedMenuCategory;
        }

        return null;
    }

    public getMenuItemThumbnailImageUrl(restaurant: any, item: any, forceAnyImage: boolean = false): string {
        var image: any = null;
        if (item && item.imagesAndVideos && item.imagesAndVideos.length > 0) {
            for (var i = 0; i < item.imagesAndVideos.length; i++) {
                if (item.imagesAndVideos[i].isThumbnail) {
                    image = item.imagesAndVideos[i];
                }
            }

            if (image) {
                return this.getImageUrl(restaurant, item.name);
            } else if (item.menuItemImageUrl) {
                return this.marketplaceResourcesBaseUrl + restaurant.id + '/assets/menuitem/' + item.menuItemImageUrl;
            } else if (forceAnyImage == true) {
                return this.getImageUrl(restaurant, item.imagesAndVideos[0].name);
            }
        }

        return null;
    }

    public getImageUrl(restaurant: any, name: any) {
        if (name) {
            return environment.marketplaceResourcesBaseUrl + restaurant.id + "/assets/menuitem/" + name;
        }
    }

    public getThumbnailUrl(restaurant: any, menuItem: any) {
        return this.marketplaceResourcesBaseUrl + restaurant.id + "/assets/menuitem/" + menuItem.menuItemImageUrl;
    }

    public isDefaultListTemplate(restaurant: any): boolean {
        this.commonTemplateValidation();
        return restaurant && (restaurant.menuTemplateId == MenuTemplateIds.DefaultList || restaurant.menuTemplateId == MenuTemplateIds.DefaultListLeftImage);
    }

    public isListTemplate(restaurant: any): boolean {
        this.commonTemplateValidation();
        if (restaurant) {
            if (restaurant.menuTemplateId == MenuTemplateIds.FashionList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuDefaultList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuGrid2Column ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuGrid3Column ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuDefaultListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnWithoutCategoryInMenu) {
                return true;
            } else if (restaurant.menuTemplateId == MenuTemplateIds.FashionListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuDefaultListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuDefaultListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnWithoutCategoryInMenuLeftImage) {
                return true;
            }


        }

        return false;
    }

    public isGridListTemplate(restaurant: any): boolean {
        this.commonTemplateValidation();
        if (restaurant) {
            if (restaurant.menuTemplateId == MenuTemplateIds.FashionGridList ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowList ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowList ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuDefaultList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuGrid2Column ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2Column ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2Column ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2Column ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuList ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuList ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGridListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuDefaultListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuListWithoutCategoryInMenu ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuListWithoutCategoryInMenu) {
                return true;
            } else if (restaurant.menuTemplateId == MenuTemplateIds.FashionGridListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuDefaultListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuListLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGridListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuDefaultListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGridMenuListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuListWithoutCategoryInMenuLeftImage ||
                restaurant.menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuListWithoutCategoryInMenuLeftImage) {
                return true;
            }
        }

        return false;
    }

    public isMenuDefaultListTemplate(restaurant: any, menuCategory: any): boolean {
        this.commonTemplateValidation();
        var menuTemplateId = restaurant.menuTemplateId;
        if (menuCategory && !this.isNullOrEmpty(menuCategory.menuTemplateId)) {
            menuTemplateId = menuCategory.menuTemplateId
        }

        if (menuTemplateId == MenuTemplateIds.DefaultList ||
            menuTemplateId == MenuTemplateIds.MainListMenuDefaultList ||
            menuTemplateId == MenuTemplateIds.MainGridMenuDefaultList ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultList ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultList ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultList ||
            menuTemplateId == MenuTemplateIds.MainListMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultListWithoutCategoryInMenu) {
            return true;
        } else if (menuTemplateId == MenuTemplateIds.DefaultListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuDefaultListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuDefaultListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage) {
            return true;
        }

        return false;
    }

    public isMenuListTemplate(restaurant: any, menuCategory: any): boolean {
        this.commonTemplateValidation();
        var menuTemplateId = restaurant.menuTemplateId;
        if (menuCategory && !this.isNullOrEmpty(menuCategory.menuTemplateId)) {
            menuTemplateId = menuCategory.menuTemplateId
        }

        if (menuTemplateId == MenuTemplateIds.FashionGridList ||
            menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowList ||
            menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowList ||
            menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowList ||
            menuTemplateId == MenuTemplateIds.FashionList ||
            menuTemplateId == MenuTemplateIds.MainGridMenuList ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuList ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuList ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuList ||
            menuTemplateId == MenuTemplateIds.FashionGridListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.FashionListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuListWithoutCategoryInMenu) {
            return true;
        } else if (menuTemplateId == MenuTemplateIds.FashionGridListLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowListLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowListLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowListLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuListLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuListLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGridListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuListWithoutCategoryInMenuLeftImage) {
            return true;
        }

        return false;
    }

    public isMenuGridTemplate(restaurant: any, menuCategory: any): boolean {
        this.commonTemplateValidation();
        var menuTemplateId = restaurant.menuTemplateId;
        if (menuCategory && !this.isNullOrEmpty(menuCategory.menuTemplateId)) {
            menuTemplateId = menuCategory.menuTemplateId
        }

        if (menuTemplateId == MenuTemplateIds.MainListMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenu) {
            return true;
        } else if (menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage) {
            return true;
        }

        return false;
    }

    public isLeftImage(restaurant: any, menuCategory: any = null): boolean {
        this.commonTemplateValidation();
        var menuTemplateId = restaurant.menuTemplateId;
        if (menuCategory && !this.isNullOrEmpty(menuCategory.menuTemplateId)) {
            menuTemplateId = menuCategory.menuTemplateId
        } else if (menuCategory && !this.isNullOrEmpty(menuCategory.menuTemplateId)) {
            menuTemplateId = menuCategory.menuTemplateId
        }

        if (menuTemplateId && this.contains(menuTemplateId, "left-image")) {
            return true;
        }

        return false;
    }


    public showMenuCategory(restaurant: any, menuCategory: any): boolean {
        this.commonTemplateValidation();
        var menuTemplateId = restaurant.menuTemplateId;
        if (menuCategory && !this.isNullOrEmpty(menuCategory.menuTemplateId)) {
            menuTemplateId = menuCategory.menuTemplateId
        }

        if (menuTemplateId == MenuTemplateIds.FashionGridListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.FashionListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainListMenuDefaultListWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnWithoutCategoryInMenu) {
            return false;
        } else if (menuTemplateId == MenuTemplateIds.FashionGridListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid2CatPerRowListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid4CatPerRowListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionGrid6CatPerRowListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.FashionListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuDefaultListWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnWithoutCategoryInMenuLeftImage) {
            return false;
        }

        return true;
    }

    public showMenuCategoryItemIn2Columns(restaurant: any, menuCategory: any): boolean {
        this.commonTemplateValidation();
        var menuTemplateId = restaurant.menuTemplateId;
        if (menuCategory && !this.isNullOrEmpty(menuCategory.menuTemplateId)) {
            menuTemplateId = menuCategory.menuTemplateId
        }

        if (menuTemplateId == MenuTemplateIds.MainGridMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid2Column ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnWithoutCategoryInMenu) {
            return true;
        } else if (menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid2ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid2ColumnWithoutCategoryInMenuLeftImage) {
            return true;
        }

        return false;
    }

    public showMenuCategoryItemIn3Columns(restaurant: any, menuCategory: any): boolean {
        this.commonTemplateValidation();
        var menuTemplateId = restaurant.menuTemplateId;
        if (menuCategory && !this.isNullOrEmpty(menuCategory.menuTemplateId)) {
            menuTemplateId = menuCategory.menuTemplateId
        }

        if (menuTemplateId == MenuTemplateIds.MainGridMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenu ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3Column ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnWithoutCategoryInMenu) {
            return true;
        } else if (menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGridMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid2CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid4CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainGrid6CatPerRowMenuGrid3ColumnWithoutCategoryInMenuLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnLeftImage ||
            menuTemplateId == MenuTemplateIds.MainListMenuGrid3ColumnWithoutCategoryInMenuLeftImage) {
            return true;
        }

        return false;
    }

    getGridColumnSize(menuCategory: any): number {
        if (menuCategory.isTwoColumn == true) {
            return 6;
        } else if (menuCategory.isOneColumn == true) {
            return 12;
        } else {
            return 6;
        }
    }

    public isPropertyInvestmentTemplate(restaurant: any): boolean {
        return restaurant && restaurant.menuTemplateId == MenuTemplateIds.PropertyInvestment;
    }

    //This functions is where we add all common templates code
    commonTemplateValidation() {
    }

    testMenuTemplateAndThemesCode(restaurant: any) {
        //Delete below test line code
        if (environment.production == false && restaurant) {
            //restaurant.menuTemplateId = MenuTemplateIds.MainListMenuGrid2Column;
            //restaurant.standaloneTheme = MarketplaceThemes.Feature;
            //this.stateService.setState({ restaurant: restaurant });
        }
    }
}
