<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start" style="margin-left:10px;">
            <!--<ion-back-button text="" icon="contact" (click)="go('/profile')"></ion-back-button>-->
            <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title class="ion-text-center" *ngIf="!wlaActive">
            <img class="title-image" src="{{logoUrl}}" width="96" height="55" *ngIf="logoUrl" />
        </ion-title>
        <ion-title class="ion-text-center" *ngIf="wlaActive">Home</ion-title>

        <ion-buttons slot="end" style="margin-right:10px;">
            <ion-back-button text="" icon="search" (click)="displaySearchTextBox()"></ion-back-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<div style="background-color:white" *ngIf="showSearch">
    <ion-searchbar (search)="searchRestaurants($event)" #searchBox placeholder="{{this.getService().serviceSearchTextBoxMessage}}"></ion-searchbar>
</div>
<div style="background-color:white" *ngIf="!wlaActive && foodTypes && foodTypes.length > 1">
    <taeam-filter-catagories [foodTypes]="foodTypes" templateId="{{this.getService().serviceTypeTemplateId}}"></taeam-filter-catagories>
</div>
<div class="ion-padding-horizontal" style="background-color:white">
    <div style="margin-top:10px;">
        <div style="float:left; margin-bottom: 0px;" *ngIf="!wlaActive"><h2 class="screen-title">{{this.getRestaurantsCount()}} {{this.getService().servicePluralName}}</h2></div>
        <div style="float:left; margin-bottom: 0px;" *ngIf="wlaActive"><h2 class="screen-title"></h2></div>
        <div style="margin-bottom: 0px;" *ngIf="this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation && this.stateService.device$.userPreferences.userLocation.city" class="change-location">
            <a (click)="changeLocation()">{{getSelectedServiceLocationText()}}</a>
        </div>
        <div style="margin-bottom: 0px;" *ngIf="!this.stateService.device$ || !this.stateService.device$.userPreferences || !this.stateService.device$.userPreferences.deliveryMethod || !this.stateService.device$.userPreferences.userLocation || !this.stateService.device$.userPreferences.userLocation.city" class="change-location">
            <a (click)="changeLocation()">{{this.getService().serviceChangePreferencesText}}</a>
        </div>
    </div>
</div>
<ion-content class="ion-padding-horizontal" *ngIf="this.datafound || hideSkeleton" [scrollEvents]="true" (ionScroll)="didScroll($event)">
    <ion-refresher slot="fixed" (ionRefresh)="refreshData($event)">
        <ion-refresher-content pullingIcon="arrow-dropdown"
                               pullingText="Pull to refresh"
                               refreshingSpinner="crescent"
                               refreshingText="Please wait...">
        </ion-refresher-content>
    </ion-refresher>
    <div id="top"></div>
    <ion-grid fixed *ngIf="!this.datafound && hideSkeleton">
        <ion-row>
            <ion-col size="12">
                {{locationErrorMessage}}
            </ion-col>
        </ion-row>
    </ion-grid>
    <taeam-marketing-data *ngIf="!this.wlaActive && dataFeetchProcessingCompleted && marketingDataCommingSoonShowAtTop"></taeam-marketing-data>
    <div class="restaurant-card-details" *ngIf="recommendedRestaurants && recommendedRestaurants.length > 0">
        <div class="block-text-title">
            <h1>{{this.getService().serviceRecommendedForYouText}}</h1>
        </div>
        <ion-slides [options]="defaultSliderSettings" (ionSlideTransitionEnd)="didScroll(null)" *ngIf="recommendedRestaurants.length > 1">
            <ion-slide *ngFor="let restaurant of recommendedRestaurants; let index = index">
                <provider-view #providerView style="width:100%; max-width:100%" [restaurant]="restaurant" [player]="this.currentPlaying" hideSliderView="true"></provider-view>
            </ion-slide>
        </ion-slides>
        <provider-view #providerView style="width:100%; max-width:100%" [restaurant]="recommendedRestaurants[0]" [player]="this.currentPlaying" hideSliderView="true" *ngIf="recommendedRestaurants.length == 1"></provider-view>
    </div>
    <div class="ad-card-details" *ngIf="topSliderAds && topSliderAds.length > 1">
        <div class="block-text-title">
            <h1>{{this.getService().serviceSliderAdsSectionText}}</h1>
        </div>
        <ion-slides [options]="getAdSliderSettings(topSliderAds, null, false)">
            <ion-slide *ngFor="let ad of topSliderAds; let index = index">
                <ad-view #adView style="width:100%; max-width:100%" [ad]="ad"></ad-view>
            </ion-slide>
        </ion-slides>
    </div>
    <div class="ad-card-details" *ngIf="topSliderAds && topSliderAds.length == 1">
        <ad-view #adView [ad]="this.topSliderAds[0]"></ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.topAd && this.topAd.mobileVideoUrl">
        <video-ad-view #videoAdView [ad]="this.topAd"></video-ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.topAd && this.topAd.imageUrl">
        <ad-view #adView [ad]="this.topAd"></ad-view>
    </div>
    <div class="restaurant-card-details" *ngIf="topCategoriesTitles && topCategoriesTitles.length > 0">
        <div *ngFor="let category of topCategoriesTitles; let cindex = cindex">
            <div class="block-text-title">
                <h1>{{category.listCategoryTitle}}</h1>
            </div>
            <ion-slides [options]="defaultSliderSettings" *ngIf="category.restaurants.length > 1" (ionSlideTransitionEnd)="didScroll(null)">
                <ion-slide *ngFor="let restaurant of category.restaurants; let crindex = crindex">
                    <provider-view #providerView style="width:100%; max-width:100%" [restaurant]="restaurant" [player]="this.currentPlaying" hideSliderView="true"></provider-view>
                </ion-slide>
            </ion-slides>
            <provider-view #providerView style="width:100%; max-width:100%" *ngIf="category.restaurants.length == 1" [restaurant]="category.restaurants[0]" [player]="this.currentPlaying" hideSliderView="true"></provider-view>
        </div>
    </div>
    <div *ngIf="displayRestaurants && displayRestaurants.length > 0">
        <div class="block-text-title">
            <h1>{{this.getService().serviceNearestTitle}}</h1>
        </div>
        <div style="margin-bottom: 10px;" *ngFor="let restaurant of displayRestaurants; let index = index;">
            <video-ad-view #videoAdView *ngIf="restaurant.ad && restaurant.ad.mobileVideoUrl" [ad]="restaurant.ad"></video-ad-view>
            <div style="margin-bottom: 15px !important" *ngIf="restaurant.ad && restaurant.ad.mobileVideoUrl"></div>
            <ad-view #adView *ngIf="restaurant.ad && restaurant.ad.imageUrl" [ad]="restaurant.ad"></ad-view>
            <div style="margin-bottom: 15px !important" *ngIf="restaurant.ad && restaurant.ad.imageUrl"></div>
            <provider-view #providerView style="width:100%; max-width:100%" *ngIf="!restaurant.ad" [restaurant]="restaurant" [player]="this.currentPlaying" [hideSliderView]="false" (didScroll)="didScroll($event)"></provider-view>
        </div>
        <ion-infinite-scroll threshold="100px" (ionInfinite)="loadDisplayData($event)" *ngIf="this.dataLoadCounter < this.restaurants.length" style="height:1px;">
            <ion-infinite-scroll-content loadingSpinner="bubbles"
                                         loadingText="Loading...">
            </ion-infinite-scroll-content>
        </ion-infinite-scroll>
    </div>
    <div class="ad-card-details" *ngIf="bottomSliderAds && bottomSliderAds.length > 1 && this.dataLoadCounter >= this.restaurants.length">
        <div class="block-text-title">
            <h1>{{this.getService().serviceSliderAdsSectionText}}</h1>
        </div>
        <ion-slides [options]="getAdSliderSettings(bottomSliderAds, null, false)">
            <ion-slide *ngFor="let ad of bottomSliderAds; let index = index">
                <ad-view #adView style="width:100%; max-width:100%" [ad]="ad"></ad-view>
            </ion-slide>
        </ion-slides>
    </div>
    <div class="ad-card-details" *ngIf="bottomSliderAds && bottomSliderAds.length == 1 && this.dataLoadCounter >= this.restaurants.length">
        <ad-view #adView [ad]="this.bottomSliderAds[0]"></ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.bottomAd && this.bottomAd.mobileVideoUrl && this.dataLoadCounter >= this.restaurants.length">
        <video-ad-view #videoAdView [ad]="this.bottomAd"></video-ad-view>
    </div>
    <div class="ad-card-details" *ngIf="this.bottomAd && this.bottomAd.imageUrl && this.dataLoadCounter >= this.restaurants.length">
        <ad-view #adView [ad]="this.bottomAd"></ad-view>
    </div>

    <div class="restaurant-card-details" *ngIf="bottomCategoriesTitles && bottomCategoriesTitles.length > 0 && this.dataLoadCounter >= this.restaurants.length">
        <div *ngFor="let category of bottomCategoriesTitles; let bcindex = bcindex">
            <div class="block-text-title">
                <h1>{{category.listCategoryTitle}}</h1>
            </div>
            <ion-slides [options]="defaultSliderSettings" (ionSlideTransitionEnd)="didScroll(null)">
                <ion-slide *ngFor="let restaurant of category.restaurants; let crindex = crindex" (click)="showRestaurantMenuWithLocationCheck(restaurant)">
                    <provider-view #providerView style="width:100%; max-width:100%" [restaurant]="restaurant" [player]="this.currentPlaying" hideSliderView="true"></provider-view>
                </ion-slide>
            </ion-slides>
        </div>
    </div>
    <taeam-marketing-data *ngIf="!this.wlaActive && dataFeetchProcessingCompleted && marketingDataCommingSoonShowAtBottom"></taeam-marketing-data>
    <div *ngIf="comingSoonRestaurants && comingSoonRestaurants.length > 0 && this.dataLoadCounter >= this.restaurants.length">
        <div class="text-block">
            <div class="inner-text-block">
                <h1>{{this.getService().serviceLookingForMoreTitle}}</h1>
                <p>{{this.getService().serviceLookingForMoreDescription}}</p>
            </div>
        </div>
    </div>
    <div class="restaurant-card-details" *ngIf="comingSoonRestaurants && comingSoonRestaurants.length > 0 && this.dataLoadCounter >= this.restaurants.length">
        <ion-slides [options]="defaultSliderSettings" (ionSlideTransitionEnd)="didScroll(null)" *ngIf="comingSoonRestaurants.length > 1">
            <ion-slide *ngFor="let restaurant of comingSoonRestaurants; let index = index" (click)="showRestaurantMenuWithLocationCheck(restaurant)">
                <provider-view #providerView style="width:100%; max-width:100%" [restaurant]="restaurant" [player]="this.currentPlaying" hideSliderView="true"></provider-view>
            </ion-slide>
        </ion-slides>
        <provider-view #providerView style="width:100%; max-width:100%" [restaurant]="comingSoonRestaurants[0]" [player]="this.currentPlaying" hideSliderView="true" *ngIf="comingSoonRestaurants.length == 1"></provider-view>
    </div>

    <div *ngIf="closedRestaurants && closedRestaurants.length > 0 && this.dataLoadCounter >= this.restaurants.length && this.dataLoadCounter >= this.restaurants.length">
        <div class="text-block">
            <div class="inner-text-block">
                <h1 style="padding-bottom:5px;">{{this.getService().serviceNotTakingOrdersTitle}}</h1>
            </div>
        </div>
    </div>
    <div *ngIf="!wlaActive && closedRestaurants && closedRestaurants.length > 0 && this.dataLoadCounter >= this.restaurants.length && this.dataLoadCounter >= this.restaurants.length">
        <div class="restaurant-card-details" *ngIf="closedRestaurants && closedRestaurants.length > 0 && this.dataLoadCounter >= this.restaurants.length">
            <ion-slides [options]="defaultSliderSettings" *ngIf="closedRestaurants.length > 1">
                <ion-slide *ngFor="let restaurant of closedRestaurants; let index = index" (click)="showRestaurantMenu(restaurant)">
                    <provider-view #providerView style="width:100%; max-width:100%" [restaurant]="restaurant" [player]="this.currentPlaying" hideSliderView="true"></provider-view>
                </ion-slide>
            </ion-slides>
            <provider-view #providerView style="width:100%; max-width:100%" [restaurant]="closedRestaurants[0]" [player]="this.currentPlaying" hideSliderView="true" *ngIf="closedRestaurants.length == 1"></provider-view>
        </div>
    </div>
    <div *ngIf="wlaActive && closedRestaurants && closedRestaurants.length > 0 && this.dataLoadCounter >= this.restaurants.length && this.dataLoadCounter >= this.restaurants.length">
        <div style="margin-bottom: 10px;" *ngFor="let restaurant of closedRestaurants; let index = index;">
            <video-ad-view #videoAdView *ngIf="restaurant.ad && restaurant.ad.mobileVideoUrl" [ad]="restaurant.ad"></video-ad-view>
            <div style="margin-bottom: 15px !important" *ngIf="restaurant.ad && restaurant.ad.mobileVideoUrl"></div>
            <ad-view #adView *ngIf="restaurant.ad && restaurant.ad.imageUrl" [ad]="restaurant.ad"></ad-view>
            <div style="margin-bottom: 15px !important" *ngIf="restaurant.ad && restaurant.ad.imageUrl"></div>
            <provider-view #providerView style="width:100%; max-width:100%" *ngIf="!restaurant.ad" [restaurant]="restaurant" [player]="this.currentPlaying" [hideSliderView]="false" (didScroll)="didScroll($event)"></provider-view>
        </div>
    </div>
    <div class="service-card-button" *ngIf="this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 1 && this.dataLoadCounter >= this.restaurants.length">
        <div class="service-card-cover" (click)="showServices()">
            <img src="{{this.getMoreServicesImageUrl()}}" alt="">
        </div>
    </div>
    <taeam-recommend-restaurant *ngIf="!this.wlaActive && this.dataLoadCounter >= this.restaurants.length"></taeam-recommend-restaurant>
</ion-content>
<!--<ion-content *ngIf="!this.datafound && hideSkeleton">
    <ion-grid fixed>
        <ion-row>
            <ion-col size="12">
                {{locationErrorMessage}}
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>-->
<ion-content *ngIf="!this.datafound && !hideSkeleton">
    <ion-grid fixed>
        <ion-row>
            <ion-col size="12">
                <ion-segment scrollable>
                    <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
                </ion-segment>
            </ion-col>
        </ion-row>
    </ion-grid>

    <!-- Skeleton screen -->
    <h2 class="screen-title"><ion-skeleton-text animated style="height: 20%"></ion-skeleton-text></h2>
    <div class="product-card">
        <div class="product-cover ion-padding custom-skeleton">
            <ion-skeleton-text animated style="height: 100%"></ion-skeleton-text>
        </div>
        <div class="product-detail">
            <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
            <p>
                <ion-skeleton-text animated></ion-skeleton-text>
            </p>
        </div>
    </div>
    <div class="product-card">
        <div class="product-cover ion-padding custom-skeleton">
            <ion-skeleton-text animated style="height: 100%"></ion-skeleton-text>
        </div>
        <div class="product-detail">
            <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
            <p>
                <ion-skeleton-text animated></ion-skeleton-text>
            </p>
        </div>
    </div>
    <div class="product-card">
        <div class="product-cover ion-padding custom-skeleton">
            <ion-skeleton-text animated style="height: 100%"></ion-skeleton-text>
        </div>
        <div class="product-detail">
            <h2><ion-skeleton-text animated></ion-skeleton-text></h2>
            <p>
                <ion-skeleton-text animated></ion-skeleton-text>
            </p>
        </div>
    </div>
</ion-content>
<!--<app-tab-menu></app-tab-menu>-->
<taeam-cookie-consent></taeam-cookie-consent>