import { Component, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { ObjectBase } from '../../object.base';
import { UiStateService } from '../../../services/ui.state.service';
import { UserService } from '../../../services/user.services';
import { FavouritesService } from '../../../services/favourites.service';
import { LocationService } from '../../../services/location.service';
import { ClassifiedAdsService } from '../../../services/classified.ads.service';
import { ServiceResourceService } from '../../../services/serviceresource.service';

@Component({
    selector: 'taeam-favourites-icon',
    templateUrl: 'favourities.button.html',
    styleUrls: ['./favourities.button.scss'],
})
export class FavouriteIcon extends ObjectBase implements OnInit {

    @Input() data: any;
    @Input() dataType: string;
    private geo: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        public modalController: ModalController,
        public favouritesService: FavouritesService,
        public classifiedAdsService: ClassifiedAdsService,
        public serviceResourceService: ServiceResourceService,
        public geoService: LocationService
    ) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.favouritesService = FavouritesService.Instance;
        this.serviceResourceService = ServiceResourceService.Instance;
    }

    ngOnInit() {
    }

    ionViewWillEnter() {
    }

    ionViewDidLeave() {
    }

    addOrRemoveFromFavourite($event) {
        this.Log($event);
        if (this.data) {
            this.personaliseService.logEvent("favourites-add-or-remove", JSON.stringify({ restaurantId: this.data.id, geoLocation: this.getGeolocation() }));
            if (!this.exists() && this.dataType == 'classifiedad') {
                if (!this.isMyClassifiedAd(this.data)) {
                    this.classifiedAdsService.logClassifiedAdEvent(this.data.id, 'classified-ad-favourite', null, 'classified-ad-favourite');
                }
            } else if (!this.exists() && this.dataType == 'serviceresourcead') {
                if (!this.isMyClassifiedAd(this.data)) {
                    this.serviceResourceService.logServiceResourceAdEvent(this.data.id, 'service-resource-ad-favourite', null, 'service-resource-ad-favourite');
                }
            }
            this.favouritesService.addOrRemove(this.data, this.dataType);
        }

        $event.stopPropagation();
    }

    public exists(): boolean {
        var result = this.favouritesService.exists(this.data, this.dataType);
        return result;
    }

    getGeolocation() {
        //if (this.geo && this.geo.coords) {
        //    return { longitude: this.geo.coords.longitude, latitude: this.geo.coords.latitude }
        //}
        return this.geoService.getUserLocation(this.geo);
    }
}
