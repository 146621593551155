import { extend } from 'webdriver-js-extender';

export class Ad {
  imageUrl: string;
  //imageAltText: string;
  name: string;
  heading: string;
  description: string;
}

//export class MenuItem {
//  menuItemId: string;
//  menuItemName: string;
//  menuItemIconUrl: string;
//  menuItemImageUrl: string;
//  menuItemDescription: string;
//  menuItemWarningMessage: string;
//  menuItemPrice: number;
//  menuItemTax: number;
//  menuItemPriceSymbol: string;

//  menuItemOptions: MenuItemOption[];
//  displayFurtherInstructionsInput: boolean;
//  generalInstructions: string;
//}

//export class MenuCategory {
//  restaurantId: string;
//  menuCategoryId: string;
//  menuCategoryName: string;
//  menuCategoryImageUrl: string;
//  menuItems: MenuItem[];
//}

export class MenuItem {
    menuItemId: string;
    menuItemCode: string;
    menuItemName: string;
    tags: string[];
    menuItemDescription: string;
    menuItemWarningMessage: string;
    menuItemPriceSymbol: string;
    menuItemPrice: number;
    menuItemTax: number;
    menuItemIconUrl: string;
    menuItemImageUrl: string;
    menuItemOptions?: MenuItemOption[];
    menuItemAdditionalDetails: any;
    displayFurtherInstructionsInput: boolean;
    generalInstructions: string;
    eventId: string;
    hasOptions: boolean;
    hasAdditionalDetails: boolean;
    menuCategoryId: string;
    skuNo: string;
    origionMenuCategoryId: string;
    actualPrice: number;
    discountInPercentage: number;
}

export class Menu {
    restaurantId: string;
    itemsCountInOneBlock: number = 0;
  menuCategories: MenuCategory[];
}

export class MenuCategory {
  restaurantId: string;
  menuCategoryId: string;
  menuCategoryName: string;
  menuCategoryImageUrl: string;
    parentMenuCategoryId: string;
    menuTemplateId: string;
    menuItems: MenuItem[];
    parentCategoryTags: Array<any>;
    featureTags: Array<any>;
    imagesAndVideos: Array<any>;
}

export class ItemOption {
    index: number;
    //menuItemOption?: MenuItemOption;
    optionCategoryName: string;
    optionId: string;
    optionName: string;
    optionCategoryItemName?: string;
    price: number;
    tax: number;
    netTotal: number;
    priceSymbol: string;
    qty: number;
    maxQty: number;
    qtyOptionAvailable: boolean;
    showQtySelection: boolean;
    checked: boolean;
}

export class MenuItemOption {
    index: number;
  optionCategoryScreenLabel?: string;
  optionCategoryItemName?: string;
  optionCategoryName: string;
  optionCategoryInfo: string;
  optionCategoryType: number;
  maxItemOptionsAllowed: number;
  counter: number;
  disableInput: boolean;
  itemOptions?: ItemOption[];
    displayOrder?: number = 0;
    canMoveToNextOption: boolean = false;
}

export class MenuDishType {
  dishTypeId: string;
  dishTypeName: string;
}

export class MenuDish {
  dishTypeId: string;
  dishId: string;
  restaurantId: string;
  menuCategoryId: string;
  menuItemId: string;
  dishName: string;
  franchiseName: string;
  imageUrl: string;
  price: number;
  priceSymbol: string;
}

export class FoodType {
  name: string;
  imageName: string;
}

export class Restaurant {
    id: string;
    restaurantId: string;
    restaurantGroupId: string;
    name: string;
    tags: string[];
    foodTypes: string[];
    imageUrl: string;
    logoUrl: string;
    summaryimageUrl?: string;
    heading: string;
    description: string;
    address?: string;
    town?: string;
    country: string;
    postcode?: string;
    contactNo?: string;
    openingTime?: string;
    deliveryCharges?: number;
    minimumOrder?: string;
    email?: string;
    priceSymbol?: string;
    specialOfferNote?: string;
    avgOrderReadyTimeInMinutes?: number;
    firstOrderTime?: string;
    lastOrderTime?: string;
    isClosed: boolean;
    foodAllergyNote: string;
    offerMessage: string;
    collectionAvailable: boolean;
    deliveryAvailable: boolean;
    dineInAvailable: boolean;
    isCardAllowed: boolean;
    isCashAllowed: boolean;
    orderReadyFor: any;
    reviewStars: string;
    reviewRating: string;
    reviewCount: string;
    eventId: string;
    region: string;
    farePerKm: number;
    farePerMile: number;
    fixedFare: number;
    fixedFareDistanceUnit: string;
    serviceFee: number;
    dineInServiceFeeInPercentage: number;
    dineInServiceFeeInFixedAmount: number;
    hygieneRating: string;
    hygieneRatingText: string;
    hygieneRatingImageUrl: string;
    hygieneRatingAgencyUrl: string;
    hygieneRatingLastUpdatedDate: string;
    latitude: number;
    longitude: number;
    status: string;
    nameKey: string;
    deliveryRadius: number;
    location: any;
    minOrderAmount: number;
    deliveryChargesLimit1Radius: number;
    deliveryChargesLimit1Charges: number;
    deliveryChargesLimit2Radius: number;
    deliveryChargesLimit2Charges: number;
    deliveryChargesLimit3Radius: number;
    deliveryChargesLimit3Charges: number;
    adMessage: string;
    menuMessage: string;
    orderMessage: string;
    cashPaymentDiscountInPercentage: number;
    distance: number;
    timeZone: string;
    listCategoryTitle: string;
    listCategoryTitleIndex: number;
    listCategoryTitlePosition: number;
    videoUrl: string;
    multipleDisplayAssetUrls: string;
    multipleDisplayVideoPosterUrls: string;
    hasMultipleAssets: boolean; //temp field
    assetUrls: Array<any>; //temp field
    assetPosterUrls: Array<any>; //temp field
    assetVideoUrl: string; //temp field
    assetImageUrl: string; //temp field
    serviceName: string;
    noteTitle: string;
    menuTemplateId: string;
    isWholesaler: boolean;
    allowCredit: boolean;
    creditTerms: string;
    params: string;
    marketplaceTheme: string;
    standaloneTheme: string;
}

export class RestaurantAd extends Restaurant {
}

export class Testimonial {
  name: string;
  comments: string;
  noOfStars: number;
  imageUrl: string;
}

export class BasketItem {
    id: string;
    restaurant: any;
    restaurantId: string;
    restaurantGroupId: string;
    restaurantName: string;
    restaurantAddress: string;
    restaurantTown: string;
    restaurantPostcode: string;
    restaurantCountry: string;
    restaurantContactNo: string;
    restaurantDeliveryMethod: number;
    restaurantDeliveryCharges: number;
    restaurantDeliveryDistanceInMiles: number;
    restaurantRegion: string;
    restaurantFarePerKm: number;
    restaurantFarePerMile: number;
    restaurantFixedFare: number;
    restaurantFixedFareDistanceUnit: string;
    restaurantLatitude: number;
    restaurantLongitude: number;
    orderNotes: string;
    restaurantNotes: string;

    menuItem: MenuItem;
    itemOptions: ItemOption[];
    qty: number;
    price: number;
    optionsTotal: number;
    netPrice: number;
    tax: number;
    grossTotal: number;
    discountPercent: number;
    discountAmount: number;
    voucherCode: string;
    voucherId: string;
    voucherRestaurantId: string;
    voucherMinOrderAmount: number;
    netTotal: number;
    notes: string;
    timeZone: string;
}

export class Basket {
    id: string;
    version: number;
    loaded: boolean;
    items: BasketItem[];
    voucherCode: string;
    grossTotal: number;
    discountPercent: number;
    discountAmount: number;
    deliveryCharges: number;
    deliveryMethod: string;
    serviceFee: number;
    dineInServiceFee: number;
    totalServiceFee: number;
    netTotal: number;
    priceSymbol: string;
    currency: string;
    totalItemsInBasket: number;
    deliveryDistanceInMiles: number;
    paymentCurrency: string;
    netTotalInOtherCurrency: number;
    showSupportedCurrency: boolean;
}

export class Voucher {
    id: string;
    restaurantId: string;
    code: string;
    description: string;
    terms: string;
    discount: number;
    minOrderAmount: number;
}

export class OrderItem {
  menuItem: MenuItem;
  ItemOptions: ItemOption[];
  qty: number;
  price: number;
  tax: number;
  grossTotal: number;
  discountPercent: number;
  discountAmount: number;
  netTotal: number;
}

export class Order {
    id: string;
    shortId: string;
    multipleOrdersShortId: string;
    restaurantId: string;
    restaurantName: string;
    restaurantAddress: string;
    restaurantContactNo: string;
    restaurantRegion: string;
    restaurantFarePerKm: number;
    restaurantFarePerMile: number;
    restaurantFixedFare: number;
    restaurantLatitude: number;
    restaurantLongitude: number;
    description: string = 'Crazon Order';
    customer: Customer;
    //billingAddress: Address;
    //deliveryAddress: Address;

    //orderItems: OrderItem[];
    //grossTotal: number;
    //deliveryCharges: number;
    //voucherCode: string;
    //discountPercent: number;
    //discountAmount: number;
    //netTotal: number;
    basket: Basket;
    deliveryTime: string;
    merchantResponse: any;
    paymentType: string;
    deliveryMethod: string;
    notes: string;
    source: string;
    taeamFeedback: string;
    restaurantFeedback: string;
    restaurantNotes: string;
    userLocation: any;
    paymentIntentId: string;
    clientSecret: string;
    groupOrderNo: string;
    dineInSlot: string;
    noOfGuests: string;
    deliveryDistanceInMiles: number;
    restaurantOrderMessageAccepted: boolean;
    charityName: string;
    amountDonated: number;
    taeamReview: string;
    orderReview: string;
    cpa: string;
    serviceName: string;
    appId: string;
    paymentGateway: string;
    clientToken: string;
    deviceData: string;
}

export class Customer {
  id: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  mobileNumber: string;
  billingAddress: Address;
  deliveryAddress: Address;
}

export class Address {
  id: string;
  type: number;
  line1: string;
  line2: string;
  city: string;
  postcode: string;
  country: string;
    phoneNumber: string;
    latitude: number;
    longitude: number;
}

export class CustomerAddress {
  id: string;
  customerId: string;
  address: string;
}

export class User {
  id: string;
  shortId: string;
  firstName: string;
  surname: string;
  email: string;
  phoneNumber: string;
  agreeToTermsAndConditions: boolean;
  agreeToMarketingAndPromotionalOffers: boolean;
  deviceInfo: any;
}

export class JoinOurNetwork {
    id: string;
    shortId: string;
    registeredBusinessName: string;
    restaurantName: string;
    contactName: string;
    contactNumber: string;
    email: string;
    streetNameAndNumber: string;
    postcode: string;
    townOrCity: string;
    country: string;
    howDidYouHearAboutUs: string;
    termsAndConditionsAccepted: boolean;
    servingHalalOnly: boolean;
    salesRepFullName: string;
    salesRepCode: string;
    userLocation: any;
    latitude: any;
    longitude: any;
    serviceName: any;
    subscription: any;
    newServiceName: string;
}

export class DeliveryPartner {
  id: string;
  shortId: string;
  company: string;
  name: string;
  surname: string;
  email: string;
  streetNameAndNumber: string;
  postcode: string;
  townOrCity: string;
  country: string;
  phoneNumber: string;
  rideInCountry: string;
  rideInCity: string;
  rideInArea: string;
  howDidYouHearAboutUs: string;
  eligibleToWork: boolean;
  termsAndConditionsAccepted: boolean;
}

export class UserToken {
  fullName: string;
  token: string;
  expiration: string;
  loggedInAt: string;
  redirectUrl: string;
}

export class KeyValuePair {
    key: string;
    value: any;
}

//optionCategoryTypes
//1 - Single - Radio button
//2 - Multiple - Checkbox
//3 - Dropdown - Single
//4 - Dropdown - Multiple
export enum MenuItemInputTypeEnum { Single = 1, Multiple, Dropdown, DropdownMultiSelect };

export enum AddressType { Delivery = 1, Billing };

export enum DeliveryMethods { Collection = "Collection", Delivery = "Delivery", DineIn = "Dine-In" };

export enum PaymentTypes { Card = "Card", Cash = "Cash", Credit = "Credit" }

export enum PaymentGateway { StripeCard = "s-c-p", StripeWebCard = "s-c-w-p", EasypaisaCard = "e-p-p", BraintreeCard = "b-t-p" }

export enum DeliveryTime { ASAP = "ASAP", OneHour = "In one hour", TwoHour = "In two hours" };

export enum ToasOptions { Top = 'top', Middle = 'middle', Bottom = 'bottom' };

export enum FeedbackOptions { Happy = 'happy', Neutral = 'neutral', Sad = 'sad' }

export enum FeedbackType { TaeamFeedback = "Crazon", RestaurantFeedback = "Restaurant", CrazonClassifiedAd = "Crazon Classified Ad", CrazonServiceResourceAd = "Crazon Service Resource Ad", CrazonServiceCustomerRequest = "Crazon Service Customer Request" }

export enum RestaurantStatus { CommingSoon = 'comming-soon' };

export enum DisplayStyles { Default = "default", TwoColumnsGrid = "two-column-grid", ThreeColumnsGrid = "three-column-grid", LeftImageViewList = "left-image-view-list" }

export enum BespokeServiceTemplate { Default = '', ClassifiedAds = 'classified-ads', ServiceResource = 'serviceresource' };