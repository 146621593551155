<div class="{{getCardViewCss(false, 'provider-view-root')}}" *ngIf="restaurant">
    <div [ngClass]="applyPadding ? 'product-card' : 'product-card'" *ngIf="!restaurant.videoUrl && !restaurant.assetVideoUrl && !restaurant.assetImageUrl">
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" (click)="showMenuWithLocationCheck(restaurant)">
            <img [src]="resourcesBaseUrl + restaurant.imageUrl" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/taeam-restaurant-loading.jpg'" alt="">
            <div class="free-simple-top" *ngIf="!wlaActive && restaurant.tagText && restaurant.tagTextIsOffer">{{restaurant.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showSocialSharingForRestaurant($event, restaurant)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && restaurant.tagText && !restaurant.tagTextIsOffer"><span class="fa fa-tag"></span> {{restaurant.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status && restaurant.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status != 'comming-soon' && restaurant.isClosed">Not accepting orders</div>
            <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod != 'Dine-In' && !restaurant.isClosed && showOrderReadyFor">Order for {{restaurant.orderReadyFor.format('h:mm a')}}</div>
            <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod == 'Dine-In' && !restaurant.isClosed && showOrderReadyFor">Dine-In</div>
            <div *ngIf="restaurant.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + restaurant.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="restaurant" dataType="marketplace" *ngIf="!wlaActive"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="restaurant" dataType="marketplace" *ngIf="isUserLoggedIn() && restaurant.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div [ngClass]="applyPadding && !isCardView ? 'product-detail with-padding' : 'product-detail'" *ngIf="restaurant">
            <ion-grid class="no-padding">
                <ion-row class="no-padding">
                    <ion-col class="no-padding"><h2 *ngIf="showName()">{{restaurant.name}}</h2></ion-col>
                    <ion-col style="text-align:right" *ngIf="this.wlaActive && this.wlaShowChangeLocation && this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location"><a (click)="goChangeLocation()">{{this.getLocationText()}}</a></ion-col>
                </ion-row>
            </ion-grid>
            <p class="{{getSummaryViewCss(false, applyPadding)}}">
                <span *ngIf="restaurant.reviewStars"><ion-icon name="star"></ion-icon><span>&nbsp;{{restaurant.reviewStars}}&nbsp;</span> {{restaurant.reviewRating}} ({{restaurant.reviewsCount}})</span>
                {{this.getHeading(restaurant)}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="restaurant.adMessage">
                {{restaurant.adMessage}}
            </p>
        </div>
    </div>
    <div [ngClass]="applyPadding ? 'product-card' : 'product-card'" *ngIf="restaurant.videoUrl && !restaurant.hasMultipleAssets">
        <div [ngClass]="applyPadding && !isCardView ? 'product-cover with-padding' : 'product-cover'">
            <div class="clickable-div" (click)="showMenuWithLocationCheck(restaurant)"></div>
            <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(restaurant.multipleDisplayVideoPosterUrls, 0)">
                <source [src]="restaurant.videoUrl" type="video/mp4" />
            </video>
            <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="free-simple-top" *ngIf="!wlaActive && restaurant.tagText && restaurant.tagTextIsOffer">{{restaurant.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showSocialSharingForRestaurant($event, restaurant)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && restaurant.tagText && !restaurant.tagTextIsOffer"><span class="fa fa-tag"></span> {{restaurant.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status && restaurant.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status != 'comming-soon' && restaurant.isClosed">Not accepting orders</div>
            <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod != 'Dine-In' && !restaurant.isClosed && showOrderReadyFor && restaurant.orderReadyFor">Order for {{restaurant.orderReadyFor.format('h:mm a')}}</div>
            <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod == 'Dine-In' && !restaurant.isClosed && showOrderReadyFor">Dine-In</div>
            <div *ngIf="restaurant.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + restaurant.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="restaurant" dataType="marketplace" *ngIf="!wlaActive"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="restaurant" dataType="marketplace" *ngIf="isUserLoggedIn() && restaurant.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div [ngClass]="applyPadding ? 'product-detail' : 'product-detail'" *ngIf="restaurant">
            <ion-grid class="no-padding">
                <ion-row class="no-padding">
                    <ion-col class="no-padding"><h2 [ngClass]="applyPadding ? 'with-padding' : ''" *ngIf="showName()">{{restaurant.name}}</h2></ion-col>
                    <ion-col style="text-align:right" *ngIf="this.wlaActive && this.wlaShowChangeLocation && this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location"><a (click)="goChangeLocation()">{{this.getLocationText()}}</a></ion-col>
                </ion-row>
            </ion-grid>
            <p class="{{getSummaryViewCss(false, applyPadding)}}">
                <span *ngIf="restaurant.reviewStars"><ion-icon name="star"></ion-icon><span>&nbsp;{{restaurant.reviewStars}}&nbsp;</span> {{restaurant.reviewRating}} ({{restaurant.reviewsCount}})</span>
                {{this.getHeading(restaurant)}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="restaurant.adMessage">
                {{restaurant.adMessage}}
            </p>
        </div>
    </div>
    <div [ngClass]="applyPadding ? 'product-card' : 'product-card'" *ngIf="!restaurant.videoUrl && (!restaurant.hasMultipleAssets || hideSliderView) && !this.isNullOrEmpty(restaurant.assetVideoUrl)">
        <div [ngClass]="applyPadding && !isCardView ? 'product-cover with-padding' : 'product-cover'" (click)="showMenuWithLocationCheck(restaurant)">
            <div class="clickable-div" (click)="showMenuWithLocationCheck(restaurant)"></div>
            <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(restaurant.multipleDisplayVideoPosterUrls, 0)">
                <source [src]="restaurant.assetVideoUrl" type="video/mp4" />
            </video>
            <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="free-simple-top" *ngIf="!wlaActive && restaurant.tagText && restaurant.tagTextIsOffer">{{restaurant.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showSocialSharingForRestaurant($event, restaurant)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && restaurant.tagText && !restaurant.tagTextIsOffer"><span class="fa fa-tag"></span> {{restaurant.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status && restaurant.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status != 'comming-soon' && restaurant.isClosed">Not accepting orders</div>
            <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod != 'Dine-In' && !restaurant.isClosed && showOrderReadyFor && restaurant.orderReadyFor">Order for {{restaurant.orderReadyFor.format('h:mm a')}}</div>
            <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod == 'Dine-In' && !restaurant.isClosed && showOrderReadyFor">Dine-In</div>
            <div *ngIf="restaurant.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + restaurant.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="restaurant" dataType="marketplace" *ngIf="!wlaActive"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="restaurant" dataType="marketplace" *ngIf="isUserLoggedIn() && restaurant.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div [ngClass]="applyPadding ? 'product-detail' : 'product-detail'" *ngIf="restaurant">
            <ion-grid class="no-padding">
                <ion-row class="no-padding">
                    <ion-col class="no-padding"><h2 [ngClass]="applyPadding ? 'with-padding' : ''" *ngIf="showName()">{{restaurant.name}}</h2></ion-col>
                    <ion-col style="text-align:right" *ngIf="this.wlaActive && this.wlaShowChangeLocation && this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location"><a (click)="goChangeLocation()">{{this.getLocationText()}}</a></ion-col>
                </ion-row>
            </ion-grid>
            <p class="{{getSummaryViewCss(false, applyPadding)}}">
                <span *ngIf="restaurant.reviewStars"><ion-icon name="star"></ion-icon><span>&nbsp;{{restaurant.reviewStars}}&nbsp;</span> {{restaurant.reviewRating}} ({{restaurant.reviewsCount}})</span>
                {{this.getHeading(restaurant)}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="restaurant.adMessage">
                {{restaurant.adMessage}}
            </p>
        </div>
    </div>
    <div [ngClass]="applyPadding ? 'product-card' : 'product-card'" *ngIf="!restaurant.videoUrl && (!restaurant.hasMultipleAssets || hideSliderView) && !restaurant.assetVideoUrl && !this.isNullOrEmpty(restaurant.assetImageUrl)">
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" (click)="showMenuWithLocationCheck(restaurant)">
            <img [src]="restaurant.assetImageUrl" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/taeam-restaurant-loading.jpg'" alt="">
            <div class="free-simple-top" *ngIf="!wlaActive && restaurant.tagText && restaurant.tagTextIsOffer">{{restaurant.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showSocialSharingForRestaurant($event, restaurant)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && restaurant.tagText && !restaurant.tagTextIsOffer"><span class="fa fa-tag"></span> {{restaurant.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status && restaurant.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status != 'comming-soon' && restaurant.isClosed">Not accepting orders</div>
            <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod != 'Dine-In' && !restaurant.isClosed && showOrderReadyFor">Order for {{restaurant.orderReadyFor.format('h:mm a')}}</div>
            <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod == 'Dine-In' && !restaurant.isClosed && showOrderReadyFor">Dine-In</div>
            <div *ngIf="restaurant.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + restaurant.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="restaurant" dataType="marketplace" *ngIf="!wlaActive"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="restaurant" dataType="marketplace" *ngIf="isUserLoggedIn() && restaurant.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div [ngClass]="applyPadding ? 'product-detail' : 'product-detail'" *ngIf="restaurant">
            <ion-grid class="no-padding">
                <ion-row class="no-padding">
                    <ion-col class="no-padding"><h2 class="{{getSummaryViewCss(false, applyPadding)}}" *ngIf="showName()">{{restaurant.name}}</h2></ion-col>
                    <ion-col style="text-align:right" *ngIf="this.wlaActive && this.wlaShowChangeLocation && this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location"><a (click)="goChangeLocation()">{{this.getLocationText()}}</a></ion-col>
                </ion-row>
            </ion-grid>
            <p class="{{getSummaryViewCss(false, applyPadding)}}">
                <span *ngIf="restaurant.reviewStars"><ion-icon name="star"></ion-icon><span>&nbsp;{{restaurant.reviewStars}}&nbsp;</span> {{restaurant.reviewRating}} ({{restaurant.reviewsCount}})</span>
                {{this.getHeading(restaurant)}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="restaurant.adMessage">
                {{restaurant.adMessage}}
            </p>
        </div>
    </div>
    <div [ngClass]="applyPadding ? 'product-card' : 'product-card'" *ngIf="!restaurant.videoUrl && restaurant.multipleDisplayAssetUrls && restaurant.hasMultipleAssets && !hideSliderView">
        <div [ngClass]="applyPadding && !isCardView ? 'product-cover with-padding' : 'product-cover'"  (click)="showMenuWithLocationCheck(restaurant)">
            <!--<img src="{{resourcesBaseUrl}}{{restaurant.imageUrl}}" alt="">-->
            <ion-slides #slider [options]="slideOptions" (ionSlideTransitionEnd)="slideChange(slider)">
                <ion-slide #slide *ngFor="let asset of restaurant.assetUrls; let aindex = index">
                    <div class="clickable-div" (click)="showMenuWithLocationCheck(restaurant)"></div>
                    <img [src]="asset.url" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/taeam-restaurant-loading.jpg'" alt="" *ngIf="!asset.isVideo">
                    <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(restaurant.multipleDisplayVideoPosterUrls, aindex)" *ngIf="asset.isVideo">
                        <source [src]="asset.url" type="video/mp4" />
                    </video>
                    <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="free-simple-top" *ngIf="!wlaActive && restaurant.tagText && restaurant.tagTextIsOffer">{{restaurant.tagText}}</div>
                    <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                        <ion-icon name="md-share" (click)="showSocialSharingForRestaurant($event, restaurant)"></ion-icon>
                    </div>
                    <div class="offer-top" *ngIf="!wlaActive && restaurant.tagText && !restaurant.tagTextIsOffer"><span class="fa fa-tag"></span> {{restaurant.tagText}}</div>
                    <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status && restaurant.status == 'comming-soon'">Coming soon</div>
                    <div class="restaurant-card-bottom-bar warning" *ngIf="restaurant.status != 'comming-soon' && restaurant.isClosed">Not accepting orders</div>
                    <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod != 'Dine-In' && !restaurant.isClosed && showOrderReadyFor && restaurant.orderReadyFor">Order for {{restaurant.orderReadyFor.format('h:mm a')}}</div>
                    <div class="restaurant-card-bottom-bar" *ngIf="(this.getService().showOrderReadyMessageBar || wlaShowOrderReadyFor) && restaurant.status != 'comming-soon' && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod == 'Dine-In' && !restaurant.isClosed && showOrderReadyFor">Dine-In</div>
                    <div *ngIf="restaurant.logoUrl" class="restaurant-card-bottom-bar-logo"
                         [style.background]="'url(' + resourcesBaseUrl + restaurant.logoUrl + ')'"
                         [style.background-size]="'100% 100%'">
                    </div>
                    <taeam-favourites-icon [data]="restaurant" dataType="marketplace" *ngIf="!wlaActive"></taeam-favourites-icon>
                    <taeam-request-credit-icon [data]="restaurant" dataType="marketplace" *ngIf="isUserLoggedIn() && restaurant.allowCredit && showRequestCredit"></taeam-request-credit-icon>
                </ion-slide>
            </ion-slides>
        </div>
        <div [ngClass]="applyPadding ? 'product-detail' : 'product-detail'" *ngIf="restaurant">
            <ion-grid class="no-padding">
                <ion-row class="no-padding">
                    <ion-col class="no-padding"><h2 [ngClass]="applyPadding ? 'with-padding' : ''" *ngIf="showName()">{{restaurant.name}}</h2></ion-col>
                    <ion-col style="text-align:right" *ngIf="this.wlaActive && this.wlaShowChangeLocation && this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location"><a (click)="goChangeLocation()">{{this.getLocationText()}}</a></ion-col>
                </ion-row>
            </ion-grid>
            <p class="{{getSummaryViewCss(false, applyPadding)}}">
                <span *ngIf="restaurant.reviewStars"><ion-icon name="star"></ion-icon><span>&nbsp;{{restaurant.reviewStars}}&nbsp;</span> {{restaurant.reviewRating}} ({{restaurant.reviewsCount}})</span>
                {{this.getHeading(restaurant)}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="restaurant.adMessage">
                {{restaurant.adMessage}}
            </p>
        </div>
    </div>
</div>
