import { Component, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { NavController, MenuController } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { LocationService } from 'src/app/services/location.service';

@Component({
    selector: 'app-powered-by-message',
    templateUrl: 'powered.by.message.html',
    styleUrls: ['./powered.by.message.scss']
})
export class PoweredByMessage extends ObjectBase {

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        public geoService: LocationService,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
    }
}
