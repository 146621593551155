import { Injectable, Inject } from '@angular/core';
import { Order, Basket } from '../shared/models/shared.model';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { OrderSources } from '../shared/models/constants.model';
import { HTTP } from '@ionic-native/http/ngx';
import * as moment from 'moment';

@Injectable()
export class OrderService extends ServiceBase {

    //private serviceBaseRoute: string = ':7072/api/';
    //private placeOrderApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'Restaurant/Order/place';
    //private myOrderApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'Restaurant/Orders/my';
    private placeOrderApiUrl: string;
    private placeBasketApiUrl: string;
    private getBasketApiUrl: string;
    private getNewBasketApiUrl: string;
    private orderPaymentRequestApiUrl: string;
    private myOrderApiUrl: string;
    private orderFeedbackApiUrl: string;
    private getCharitiesApiUrl: string;
    private getUserRestaurantOrdersCountApiUrl: string;
    private getPaymentTokenApiUrl: string;
    private getPaymentGatewayDetailsApiUrl: string;

    constructor(
        protected http: HttpClient, 
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.placeOrderApiUrl = environment.orderApiUrl + 'Restaurant/Order/place';
        this.placeBasketApiUrl = environment.orderApiUrl + 'Restaurant/Order/basket/place';
        this.getBasketApiUrl = environment.orderApiUrl + 'Restaurant/Order/basket/get';
        this.getNewBasketApiUrl = environment.orderApiUrl + 'Restaurant/Order/basket/get/new';
        this.orderPaymentRequestApiUrl = environment.orderApiUrl + 'Restaurant/Order/paymentrequest';
        this.myOrderApiUrl = environment.orderApiUrl + 'User/Orders/my';
        this.orderFeedbackApiUrl = environment.orderApiUrl + 'order/taeam/feedback/submit';
        this.getCharitiesApiUrl = environment.orderApiUrl + 'User/Orders/charities';
        this.getUserRestaurantOrdersCountApiUrl = environment.orderApiUrl + 'User/Orders/'; //Its not full path, check function
        this.getPaymentTokenApiUrl = environment.orderApiUrl + 'restaurant/order/payment/token';
        this.getPaymentGatewayDetailsApiUrl = environment.orderApiUrl + 'user/Orders/payment/gatewaydetails';
        
    }

    public placeOrder(order: Order): Observable<any> {
        if (this.stateService.device$.platform != 'web') {
            order.source = OrderSources.Mobile;
        } else {
            order.source = OrderSources.WebMobile;
        }

        order.userLocation = this.stateService.props$.userLocation;

        return this.post(this.placeOrderApiUrl, order);
    }

    public placeBasket(basket: Basket): Observable<any> {
        if (!basket.version) {
            basket.version = 0;
        }

        basket.version++;
        this.Log("Place basket");
        this.Log(basket);
        return this.post(this.placeBasketApiUrl, basket);
    }

    public getBasket(): Observable<any> {
        return this.get(this.getBasketApiUrl);
    }

    public getNewBasket(): Observable<any> {
        return this.get(this.getNewBasketApiUrl);
    }

    public getPaymentToken(restaurantId: any = null): Observable<any> {
        if (this.isNullOrEmpty(restaurantId)) {
            return this.get(this.getPaymentTokenApiUrl);
        } else {
            return this.get(this.getPaymentTokenApiUrl + "/" + restaurantId);
        }
    }

    public getPaymentGatewayDetails(restaurantId: any = null, requestType: string = "default"): Observable<any> {
        var headers = this.addHeader('x-pgrt', requestType);
        if (this.isNullOrEmpty(restaurantId)) {
            return this.get(this.getPaymentGatewayDetailsApiUrl, headers);
        } else {
            return this.get(this.getPaymentGatewayDetailsApiUrl + "/" + restaurantId, headers);
        }
    }

    public orderPaymentRequest(order: Order): Observable<any> {
        order.source = OrderSources.Web;
        order.userLocation = this.stateService.props$.userLocation;
        return this.post(this.orderPaymentRequestApiUrl, order);
    }

    public myOrders(): Observable<any> {
        return this.get(this.myOrderApiUrl);
    }

    public orderFeedback(order: Order, feedback: string, feedbackType: string, feedbackComments: string = null) {
        return this.post(this.orderFeedbackApiUrl, { orderId: order.id, shortId: order.shortId, review: feedback, feedbackType: feedbackType, feedbackComments: feedbackComments });
    }

    public getCharities(): Observable<any> {
        return this.get(this.getCharitiesApiUrl);
    }

    public getUserRestaurantOrdersCount(restaurantId: string): Observable<any> {
        return this.get(this.getUserRestaurantOrdersCountApiUrl + restaurantId + '/my');
    }

    getOrderDay(order: any): string {
        var dt = moment(order.orderDate, "YYYY-MM-DD HH:mm:ss");
        return dt.format('dddd');
    }

    getOrderDate(order: any): string {
        var dt = moment(order.orderDate, "YYYY-MM-DD HH:mm:ss");
        return dt.format('DD-MM-YYYY');
    }

    getOrderTime(order: any): string {
        var dt = moment(order.orderDate, "YYYY-MM-DD HH:mm:ss");
        return dt.format('DD-MM-YYYY');
    }
}
