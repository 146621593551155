import { Plugins } from '@capacitor/core';
import { Injectable, Inject } from '@angular/core';
import { ServiceBase } from './service.base';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
const { LocalNotifications } = Plugins;

@Injectable()
export class NotificationService extends ServiceBase {

    constructor(protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
    }

    public sendTestNotification(title: string, body: string) {
        var device = this.deviceService.getDeviceTypeId()
            .then(res => {
                this.Log(res);
                if (res === 2 || res === 3) {
                    LocalNotifications.schedule({
                        notifications: [
                            {
                                title: title,
                                body: body,
                                id: 1,
                                schedule: { at: new Date(Date.now() + 1000 * 5) },
                                sound: null,
                                attachments: null,
                                actionTypeId: "",
                                extra: null
                            }
                        ]
                    });
                }
            });
    }
}