<div (click)="showClassifiedAdWithLocationCheck($event, classifiedAd)" [ngClass]="applyPadding ? 'product-card' : 'product-card'" *ngIf="classifiedAd">
    <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasMultipleImagesOrVideos(classifiedAd.imagesAndVideos)">
        <!--<img src="{{resourcesBaseUrl}}{{classifiedAd.imageUrl}}" alt="">-->
        <ion-slides #slider [options]="slideOptions" (ionSlideTransitionEnd)="slideChange(slider)">
            <ion-slide #slide *ngFor="let asset of classifiedAd.imagesAndVideos; let aindex = index">
                <div class="clickable-div" (click)="showClassifiedAdWithLocationCheck($event, classifiedAd)"></div>
                <img [src]="getClassifiedResourceUrl(classifiedAd, asset.name)" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/classified-ad-loading.jpg'" alt="" *ngIf="!asset.isVideo">
                <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(classifiedAd.multipleDisplayVideoPosterUrls, aindex)" *ngIf="asset.isVideo">
                    <source [src]="getClassifiedResourceUrl(classifiedAd, asset.name)" type="video/mp4" />
                </video>
                <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.paused">
                    <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                </div>
                <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.paused">
                    <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                </div>
                <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.muted">
                    <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                </div>
                <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.muted">
                    <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                </div>
                <div class="free-simple-top" *ngIf="!wlaActive && classifiedAd.tagText && classifiedAd.tagTextIsOffer">{{classifiedAd.tagText}}</div>
                <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                    <ion-icon name="md-share" (click)="showClassifiedAdSocialSharing($event, classifiedAd)"></ion-icon>
                </div>
                <div class="offer-top" *ngIf="!wlaActive && classifiedAd.tagText && !classifiedAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{classifiedAd.tagText}}</div>
                <div class="restaurant-card-bottom-bar warning" *ngIf="classifiedAd.status && classifiedAd.status == 'comming-soon'">Coming soon</div>
                <div *ngIf="classifiedAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                        [style.background]="'url(' + resourcesBaseUrl + classifiedAd.logoUrl + ')'"
                        [style.background-size]="'100% 100%'">
                </div>
                <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive"></taeam-favourites-icon>
                <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
            </ion-slide>
        </ion-slides>
    </div>
    <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasImageOnly(classifiedAd.imagesAndVideos)">
        <img [src]="getClassifiedAdResourceUrl(classifiedAd)" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/classified-ad-loading.jpg'" alt="">
        <div class="free-simple-top" *ngIf="!wlaActive && classifiedAd.tagText && classifiedAd.tagTextIsOffer">{{classifiedAd.tagText}}</div>
        <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
            <ion-icon name="md-share" (click)="showClassifiedAdSocialSharing($event, classifiedAd)"></ion-icon>
        </div>
        <div class="offer-top" *ngIf="!wlaActive && classifiedAd.tagText && !classifiedAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{classifiedAd.tagText}}</div>
        <div class="restaurant-card-bottom-bar warning" *ngIf="classifiedAd.status && classifiedAd.status == 'comming-soon'">Coming soon</div>
        <div *ngIf="classifiedAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                [style.background]="'url(' + resourcesBaseUrl + classifiedAd.logoUrl + ')'"
                [style.background-size]="'100% 100%'">
        </div>
        <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive"></taeam-favourites-icon>
        <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
    </div>
    <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasVideoOnly(classifiedAd.imagesAndVideos)">
        <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(classifiedAd.multipleDisplayVideoPosterUrls, 0)">
            <source [src]="getClassifiedAdResourceUrl(classifiedAd)" type="video/mp4" />
        </video>
        <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
            <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
            <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
            <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
            <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="free-simple-top" *ngIf="!wlaActive && classifiedAd.tagText && classifiedAd.tagTextIsOffer">{{classifiedAd.tagText}}</div>
        <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
            <ion-icon name="md-share" (click)="showClassifiedAdSocialSharing($event, classifiedAd)"></ion-icon>
        </div>
        <div class="offer-top" *ngIf="!wlaActive && classifiedAd.tagText && !classifiedAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{classifiedAd.tagText}}</div>
        <div class="restaurant-card-bottom-bar warning" *ngIf="classifiedAd.status && classifiedAd.status == 'comming-soon'">Coming soon</div>
        <div *ngIf="classifiedAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                [style.background]="'url(' + resourcesBaseUrl + classifiedAd.logoUrl + ')'"
                [style.background-size]="'100% 100%'">
        </div>
        <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive"></taeam-favourites-icon>
        <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
    </div>
</div>
