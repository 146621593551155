import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Basket, BasketItem, ItemOption, MenuItem, Restaurant, Voucher, KeyValuePair, DeliveryMethods } from '../shared/models/shared.model';
import { ServiceBase } from './service.base';
import { HttpClient } from '@angular/common/http';
import { OrderService } from './order.service';
import { Common } from '../shared/common';
import { PersonlisationService } from './personalisation.service';
import { HTTP } from '@ionic-native/http/ngx';

declare var google: any;

@Injectable({
    providedIn: 'root'
})
export class BasketService extends ServiceBase {

    private static _instance: BasketService;

    private basketSubject = new Subject<Basket>();
    private itemOptionSelectorSubject = new Subject();
    private itemOptionPublisherSubject = new Subject<ItemOption>();
    private itemOptions: ItemOption[];
    private isBasketSideViewVisible = false;

    basket: Basket;
    restaurantBasket: Array<any>;
    vouchers: Array<any>;

    Basket = this.basketSubject.asObservable();
    ItemOptionSelector = this.itemOptionSelectorSubject.asObservable();
    ItemOptionPublisher = this.itemOptionPublisherSubject.asObservable();

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP,
        private orderService: OrderService,
        private personaliserService: PersonlisationService
    ) {
        super(http, httpNative);
        this.personaliserService = PersonlisationService.Instance;
        this.restoreBasket();
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance || (this._instance = new this(Common.InjectorInstance.get<HttpClient>(HttpClient), Common.InjectorInstance.get<HTTP>(HTTP), Common.InjectorInstance.get<OrderService>(OrderService), Common.InjectorInstance.get<PersonlisationService>(PersonlisationService)));
    }

    getBasket(): Basket {
        return this.basket;
    }

    setBasket(basket: Basket) {
        this.basket = basket;
        this.publish();
    }

    getTotalItemsInBasket(): number {
        if (this.basket != null) {
            return this.basket.totalItemsInBasket;
        } 

        return 0;
    }

    getRestaurantBasket(): Array<any> {
        return this.restaurantBasket;
    }

    haveMultipleRestaurants(): boolean {
        return this.restaurantBasket && this.restaurantBasket.length > 1;
    }

    haveRestaurantOrderMessage(): boolean {
        if (this.restaurantBasket && this.restaurantBasket.length > 0) {
            for (var i = 0; i < this.restaurantBasket.length; i++) {
                if (this.restaurantBasket[i].restaurant && this.restaurantBasket[i].restaurant.orderMessage) {
                    return true;
                }
            }
        }

        return false;
    }

    getBasketVouchers(): Array<any> {
        return this.vouchers;
    }

    getRestaurantExistingBasketVoucherCode(restaurantId: string): any {
        if (this.vouchers) {
            var voucher = this.vouchers.find(x => x.restaurantId == restaurantId);
            if (voucher) {
                return voucher;
            } else {
                return this.vouchers.find(x => x.voucherRestaurantId == null)
            }
        }
    }

    getMenuItemOptions(basketItem: BasketItem): string {
        //return basketItem.ItemOptions.map(x => x.optionName).join(', ') + (basketItem.note ? ' | ' + basketItem.note : '');
        if (basketItem.itemOptions) {
            return basketItem.itemOptions.map(x => x.optionCategoryItemName ? x.optionCategoryItemName + '-' + x.optionName : x.optionCategoryName + '-' + x.optionName).join(', ') + (basketItem.notes ? ' | ' + basketItem.notes : '');
        } else {
            return '';
        }
    }


    basketContains(menuItem: MenuItem): boolean {
        var basketItems = this.basket.items.filter((_item) => _item.menuItem.menuItemName == menuItem.menuItemName);
        return basketItems && basketItems.length > 0;
    }

    addMenuItem(restaurant: Restaurant, menuItem: MenuItem, itemOptions: ItemOption[], qty: number = 1, notes: string) {
        if (itemOptions && itemOptions.length > 0) {
            itemOptions = itemOptions.sort((n1, n2) => {
                if (n1.index > n2.index) {
                    return 1;
                }

                if (n1.index < n2.index) {
                    return -1;
                }

                return 0;
            });
        }

        if (this.basket && (!this.basket.priceSymbol || (this.basket.items && this.basket.items.length == 0 && this.basket.priceSymbol != restaurant.priceSymbol))) {
            this.basket.priceSymbol = restaurant.priceSymbol;
        }

        var basketItems = this.basket.items.filter((_item) => _item.menuItem.menuItemName == menuItem.menuItemName && _item.restaurantId == restaurant.id && (!itemOptions || itemOptions.length == 0));
        if (basketItems && basketItems.length > 0 && basketItems[0].notes == notes) {
            basketItems[0].qty += qty;
            this.publish();
        } else {
            var basketItem = new BasketItem();
            basketItem.id = this.getId();
            basketItem.restaurant = restaurant;
            var deliveryDetails = this.getDeliveryDetails(restaurant);
            basketItem.restaurantId = restaurant.id;
            basketItem.restaurantGroupId = restaurant.restaurantGroupId;
            basketItem.restaurantName = restaurant.name;
            basketItem.restaurantAddress = restaurant.address;
            basketItem.restaurantTown = restaurant.town;
            basketItem.restaurantPostcode = restaurant.postcode;
            basketItem.restaurantCountry = restaurant.country;
            basketItem.restaurantContactNo = restaurant.contactNo;
            basketItem.restaurantRegion = restaurant.region;
            basketItem.restaurantFarePerKm = restaurant.farePerKm;
            basketItem.restaurantFarePerMile = restaurant.farePerMile;
            basketItem.restaurantFixedFare = restaurant.fixedFare;
            basketItem.restaurantFixedFareDistanceUnit = restaurant.fixedFareDistanceUnit;
            basketItem.restaurantLatitude = restaurant.latitude;
            basketItem.restaurantLongitude = restaurant.longitude;
            basketItem.timeZone = restaurant.timeZone;
            basketItem.menuItem = this.clone(menuItem);
            basketItem.menuItem.menuItemOptions = null; //Not required
            basketItem.itemOptions = itemOptions;
            basketItem.qty = qty;
            basketItem.price = menuItem.menuItemPrice;
            basketItem.tax = menuItem.menuItemTax;
            if (deliveryDetails) {
                basketItem.restaurantDeliveryCharges = deliveryDetails.deliveryCharges;
                basketItem.restaurantDeliveryDistanceInMiles = deliveryDetails.distanceInMiles;
            }

            basketItem.grossTotal = (basketItem.price + (basketItem.tax ? basketItem.tax : 0)) * basketItem.qty;
            basketItem.netTotal = basketItem.grossTotal - (basketItem.discountAmount && basketItem.discountAmount > 0 ? basketItem.discountAmount : 0);
            basketItem.notes = notes;
            this.addItem(basketItem);
            this.Log(basketItem);
        }

        this.SaveBasket();
    }

    addItem(item: BasketItem) {
        //if (!this.basket.items) {
        //    this.basket.items = new Array();
        //};

        var voucher = this.getRestaurantExistingBasketVoucherCode(item.restaurantId);
        if (voucher) {
            item.voucherId = voucher.voucherId;
            item.voucherCode = voucher.voucherCode;
            item.discountPercent = voucher.voucherDiscountPercent;
        }

        this.basket.items.push(item);

        this.Log(this.basket);
        this.publish();
        this.personaliserService.logEvent("basket-item-added", JSON.stringify({ restaurantId: item.restaurant.id, menuItemCode: item.menuItem.menuItemCode, menuItemName: item.menuItem.menuItemName, totalItems: this.basket.totalItemsInBasket, amount: this.basket.netTotal }));
        if (item.restaurant.eventId) {
            this.personaliserService.reward("basket-item-added", item.restaurant.eventId, item.restaurant.id, 0.50);
        }
        if (item.menuItem.eventId) {
            this.personaliserService.reward("recommended-basket-item-added", item.menuItem.eventId, item.restaurant.id, 1.0);
        }
    }

    changeQty(basketItem: BasketItem, qty: number) {
        if ((qty < 1 && basketItem.qty > 1) || (qty > 0)) {
            basketItem.qty += qty;
        } else if (basketItem.qty == 1 && qty == -1) {
            this.removeItem(basketItem);
            return;
        }

        if (qty < 0) {
            this.resetVoucher(basketItem);
        }

        this.publish();
        this.SaveBasket();

        if (qty > 0) {
            this.personaliserService.logEvent("basket-item-change-qty-inc", JSON.stringify({ restaurantId: basketItem.restaurant.id, menuItemCode: basketItem.menuItem.menuItemCode, menuItemName: basketItem.menuItem.menuItemName, totalItems: this.basket.totalItemsInBasket, amount: this.basket.netTotal }));
        }
        else {
            this.personaliserService.logEvent("basket-item-change-qty-dec", JSON.stringify({ restaurantId: basketItem.restaurant.id, menuItemCode: basketItem.menuItem.menuItemCode, menuItemName: basketItem.menuItem.menuItemName, totalItems: this.basket.totalItemsInBasket, amount: this.basket.netTotal }));
        }
    }

    resetVoucher(basketItem: BasketItem) {
        var restaurantGrossTotal = this.getRestaurantTotal(basketItem.restaurantId);
        this.Log("reset voucher");
        this.Log(basketItem.voucherMinOrderAmount);
        this.Log(restaurantGrossTotal.grossTotal);
        this.Log((restaurantGrossTotal.grossTotal - basketItem.netPrice));

        if (basketItem.voucherMinOrderAmount > 0 && ((restaurantGrossTotal.grossTotal - basketItem.netPrice) < basketItem.voucherMinOrderAmount)) {
            //basketItem.discountPercent = 0;

            var items = this.basket.items.filter(x => x.restaurantId == basketItem.restaurantId);
            if (items) {
                items.forEach(x => {
                    x.discountPercent = 0;
                });
            }
        }
    }

    public applyDeliveryCharges(deliveryMethod: any) {
        this.basket.deliveryMethod = deliveryMethod; //(+ for typecasting to number)
        this.Log(this.basket.deliveryMethod);
        this.publish();
    }

    public getDeliveryMethods(): any {
        var result = { collectionAvailable: true, deliveryAvailable: true, dineInAvailable: true, isCardAllowed: true, isCashAllowed: true };
        this.restaurantBasket.forEach(rest => {
            if (result.collectionAvailable && !rest.restaurant.collectionAvailable) {
                result.collectionAvailable = false;
            }

            if (result.deliveryAvailable && !rest.restaurant.deliveryAvailable) {
                result.deliveryAvailable = false;
            }

            if (result.dineInAvailable && !rest.restaurant.dineInAvailable) {
                result.dineInAvailable = false;
            }

            if (result.isCardAllowed && !rest.restaurant.isCardAllowed) {
                result.isCardAllowed = false;
            }

            if (result.isCashAllowed && !rest.restaurant.isCashAllowed) {
                result.isCashAllowed = false;
            }
        });

        return result;
    }

    applyVoucherCode(voucher: Voucher) {
        //todo: check with server and get discount
        //if (this.basket.netTotal > 0) {
        //  this.basket.discountPercent = voucher.discount;
        //  this.basket.discountAmount = ((this.basket.grossTotal / 100) * this.basket.discountPercent);
        //  this.calculateBasket();
        //}
        this.Log("Apply voucher code function");
        this.basket.items.forEach(x => {
            if (!voucher.restaurantId || (voucher.restaurantId && x.restaurantId == voucher.restaurantId)) {
                var grossTotalResult = this.getRestaurantTotal(x.restaurantId);

                this.Log(grossTotalResult.grossTotal);
                this.Log(voucher.minOrderAmount);

                if (grossTotalResult.grossTotal >= voucher.minOrderAmount) {
                    this.Log("inside");
                    x.voucherId = voucher.id;
                    x.voucherCode = voucher.code;
                    x.discountPercent = voucher.discount;
                    x.voucherRestaurantId = voucher.restaurantId;
                    x.voucherMinOrderAmount = voucher.minOrderAmount;
                } else {
                    this.Log("else");
                    x.voucherId = voucher.id;
                    x.voucherCode = voucher.code;
                    x.discountPercent = 0;
                    x.voucherRestaurantId = voucher.restaurantId;
                    x.voucherMinOrderAmount = voucher.minOrderAmount;
                }
            }

            this.Log(x);
        });

        this.calculateBasket();
    }

    applyCashDiscount() {
        this.Log("Apply cash discount function");
        if (this.restaurantBasket && this.restaurantBasket.length > 0) {
            for (var i = 0; i < this.restaurantBasket.length; i++) {
                var rest = this.restaurantBasket[i];
                this.Log(rest);
                this.basket.items.forEach(x => {
                    if (x.restaurantId == rest.restaurant.id && rest.restaurant.cashPaymentDiscountInPercentage > 0) {
                        this.Log("Now inside cash discount apply process");
                        var grossTotalResult = this.getRestaurantTotal(rest.restaurant.id);
                        if (grossTotalResult.grossTotal >= rest.restaurant.minOrderAmount) {
                            this.Log("Gross amount is greater than min order amount");
                            x.voucherId = rest.restaurant.id + '-CASH';
                            x.voucherCode = 'CASH';
                            x.discountPercent = rest.restaurant.cashPaymentDiscountInPercentage;
                            x.voucherRestaurantId = rest.restaurant.id;
                            x.voucherMinOrderAmount = rest.restaurant.minOrderAmount;
                        } else {
                            this.Log("Gross amount is less than min order amount");
                            x.discountPercent = 0;
                        }
                    }
                });
            }

            this.calculateBasket();
        }
    }

    removeCashDiscount() {
        this.Log("Apply cash discount function");
        if (this.restaurantBasket && this.restaurantBasket.length > 0) {
            for (var i = 0; i < this.restaurantBasket.length; i++) {
                var rest = this.restaurantBasket[i];
                this.Log(rest);
                this.basket.items.forEach(x => {
                    if (x.restaurantId == rest.restaurant.id && rest.restaurant.cashPaymentDiscountInPercentage > 0) {
                        this.Log("Now inside cash discount removal process");
                        if (x.voucherId == rest.restaurant.id + '-CASH') {
                            x.voucherId = null;
                            x.voucherCode = null;
                            x.discountPercent = 0;
                            x.voucherRestaurantId = null;
                            x.voucherMinOrderAmount = 0;
                        }
                    }
                });
            }

            this.calculateBasket();
        }
    }

    public getDeliveryDetails(restaurant: Restaurant): any {
        this.Log("Inside getDeliveryDetails function");

        if (this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.deliveryMethod != DeliveryMethods.Delivery) {
            this.Log("Delivery metohd is not delivery");
            return null;
        } else {
            if (
                !this.stateService.device$ ||
                !this.stateService.device$.userPreferences ||
                !this.stateService.device$.userPreferences.userLocation) {
                this.Log("Location data not found");
                return null;
            }

            this.Log("Delivery metohd is delivery");
            var latLngA = new google.maps.LatLng(this.stateService.device$.userPreferences.userLocation.latitude, this.stateService.device$.userPreferences.userLocation.longitude);
            var latLngB = new google.maps.LatLng(restaurant.latitude, restaurant.longitude);
            this.Log(latLngA);
            this.Log(latLngB);
            var distance = google.maps.geometry.spherical.computeDistanceBetween(latLngA, latLngB);
            var distanceInMiles = distance * 0.000621371192;

            var deliveryDetails: any = {};
            deliveryDetails.distanceInMiles = distanceInMiles;

            if (distanceInMiles && distanceInMiles <= restaurant.deliveryChargesLimit1Radius) {
                deliveryDetails.deliveryCharges = restaurant.deliveryChargesLimit1Charges;
            } else if (distanceInMiles && distanceInMiles <= restaurant.deliveryChargesLimit2Radius) {
                deliveryDetails.deliveryCharges = restaurant.deliveryChargesLimit2Charges;
            } else if (distanceInMiles && distanceInMiles <= restaurant.deliveryChargesLimit3Radius) {
                deliveryDetails.deliveryCharges = restaurant.deliveryChargesLimit3Charges;
            }

            return deliveryDetails;
        }
    }

    getRestaurantTotal(restaurantId: string): any {
        var result = { grossTotal: 0 };

        //result.grossTotal = this.restaurantBasket
        //    .filter(x => x.key == restaurantId)
        //    .reduce((sum, current) => sum + current.grossTotal, 0);
        result.grossTotal = 0;
        this.restaurantBasket.forEach(x => {
            if (x.key == restaurantId) {
                x.items.forEach(item => {
                    result.grossTotal += item.grossTotal;
                });
            }
        });

        return result;
    }

    removeItem(item: BasketItem) {
        this.personaliserService.logEvent("basket-item-removed", JSON.stringify({ restaurantId: item.restaurant.id, menuItemCode: item.menuItem.menuItemCode, menuItemName: item.menuItem.menuItemName, totalItems: this.basket.totalItemsInBasket, amount: this.basket.netTotal }));

        //this.basket.items = this.basket.items.filter((_item) => _item.menuItem.menuItemName !== item.menuItem.menuItemName && _item.restaurantId == item.restaurantId);
        this.basket.items = this.basket.items.filter((_item) => _item.id !== item.id);

        this.resetVoucher(item);

        this.publish();
        this.SaveBasket();
    }

    requestOptionSelectorToPublishItemOptions() {
        this.itemOptions = new Array();
        this.itemOptionSelectorSubject.next();
    }

    InitItemOptions(menuItem: MenuItem, restaurant: Restaurant) {
        this.itemOptions = new Array();
        this.itemOptionSelectorSubject.next();

        //var basketItem = new BasketItem();
        //basketItem.restaurantId = restaurant.id;
        //basketItem.menuItem = menuItem;
        //basketItem.ItemOptions = this.getItemOptions();
        //basketItem.qty = 1;
        //basketItem.price = menuItem.menuItemPrice;
        //basketItem.tax = menuItem.menuItemTax;
        //basketItem.grossTotal = (basketItem.price + (basketItem.tax ? basketItem.tax : 0)) * basketItem.qty;
        //basketItem.netTotal = basketItem.grossTotal - (basketItem.discountAmount && basketItem.discountAmount > 0 ? basketItem.discountAmount : 0);
        //this.addItem(basketItem);
    }


    addToItemOptions(itemOption: ItemOption) {
        this.itemOptions.push(itemOption);
    }

    getItemOptions(): ItemOption[] {
        return this.itemOptions;
    }

    publishItemOptions(itemOption: ItemOption) {
        this.itemOptionPublisherSubject.next(itemOption);
    }

    clearBasket() {
        this.basket = new Basket();
        this.basket.items = [];
        this.restoreBasketFromServer();
    }

    setBasketVisibilitySettings(isVisible: boolean) {
        this.isBasketSideViewVisible = isVisible;
    }

    getBasketVisibilitySettings(): boolean {
        return this.isBasketSideViewVisible;
    }

    restoreBasket() {
        this.restoreBasketFromServer();
    }

    restoreBasketFromServer() {
        this.Log("Requesting basket from server");
        this.orderService.getBasket().subscribe(
            result => {
                this.Log("Response from get basket api");
                this.Log(result);
                this.basket = result;
                if (!this.basket.items) {
                    this.basket.items = [];
                }
                this.publish(true);
            },
            error => {
                //TODO: Log this error
                this.Log(error);
            }
        );
    }

    public getOptionsTotal(itemOptions: Array<any>): number {
        var totalOptionsAmount = 0;

        if (itemOptions && itemOptions.length > 0) {
            itemOptions.forEach(io => {
                if (io.price) {
                    io.netTotal = (io.price + (io.tax ? io.tax : 0)) * (io.qty ? io.qty : 1);
                    totalOptionsAmount += io.netTotal;
                }
            });
        }

        return totalOptionsAmount;
    }

    public calculateBasket() {
        if (!this.basket) return;

        this.Log("Calculate basket triggered");
        this.Log(this.restaurantBasket);
        if (!this.restaurantBasket) {
            this.restaurantBasket = new Array<any>();
        }

        var tempRestaurantBasket = this.restaurantBasket;
        this.restaurantBasket = new Array<any>();

        this.basket.grossTotal = 0;
        this.basket.serviceFee = 0;
        this.basket.netTotal = 0;
        this.basket.totalItemsInBasket = 0;
        this.vouchers = new Array<any>();

        if (this.basket.items) {
            this.basket.items.forEach(x => {
                var totalOptionsAmount = 0;
                if (x.itemOptions) {
                    x.itemOptions.forEach(io => {
                        if (io.price) {
                            io.netTotal = io.price + (io.tax ? io.tax : 0) * (io.qty ? io.qty : 1);
                            totalOptionsAmount += io.netTotal;
                        }
                    })
                }

                x.optionsTotal = totalOptionsAmount;
                x.netPrice = x.price + x.optionsTotal;
                x.grossTotal = x.qty * x.netPrice;
                x.netTotal = x.grossTotal + x.tax;

                if (x.discountPercent) {
                    x.discountAmount = (x.netTotal / 100) * x.discountPercent;
                    x.netTotal = x.netTotal - x.discountAmount;
                    var restaurantVoucher = this.vouchers.find(v => v.voucherCode == x.voucherCode && v.restaurantId == x.restaurantId);

                    if (!restaurantVoucher) {
                        this.vouchers.push({ voucherId: x.voucherId, voucherCode: x.voucherCode, voucherDiscountAmount: x.discountAmount, voucherDiscountPercent: x.discountPercent, voucherRestaurantId: x.voucherRestaurantId, restaurantId: x.restaurantId });
                    } else {
                        restaurantVoucher.voucherDiscountAmount += x.discountAmount;
                    }
                }

                this.basket.grossTotal += x.grossTotal;
                this.basket.netTotal += (x.netTotal && x.netTotal > 0 ? x.netTotal : 0);
                this.basket.totalItemsInBasket += 1;

                var tempRest = tempRestaurantBasket.find(rest => rest.key == x.restaurantId);
                var rest = this.restaurantBasket.find(rest => rest.key == x.restaurantId);
                x.restaurantNotes = (tempRest ? tempRest.restaurantNotes : "");
                this.Log("restaurant notes");
                this.Log(rest);

                if (!rest) {
                    this.restaurantBasket.push({ key: x.restaurantId, restaurant: x.restaurant, restaurantNotes: x.restaurantNotes, restaurantName: x.restaurantName, restaurantAddress: x.restaurantAddress, restaurantTown: x.restaurantTown, restaurantPostcode: x.restaurantPostcode, restaurantCountry: x.restaurantCountry, restaurantContactNo: x.restaurantContactNo, deliveryCharges: x.restaurantDeliveryCharges, deliveryMethod: x.restaurantDeliveryMethod, items: [x] });
                } else {
                    rest.items.push(x);
                }

                this.Log(this.restaurantBasket);
            });

            //this.basket.deliveryCharges = 0;
            //this.restaurantBasket.forEach(x => {
            //    if (x.deliveryMethod == DeliveryMethods.Delivery) {
            //        this.basket.deliveryCharges += x.deliveryCharges;
            //    }
            //});

            this.basket.deliveryCharges = 0;
            if (this.basket.deliveryMethod == DeliveryMethods.Delivery) {
                this.restaurantBasket.forEach(x => {
                    //this.basket.deliveryCharges += x.deliveryCharges;
                    var restaurantDeliveryDetails = this.getDeliveryDetails(x.restaurant)
                    if (restaurantDeliveryDetails) {
                        this.basket.deliveryDistanceInMiles = restaurantDeliveryDetails.distanceInMiles;
                        if (restaurantDeliveryDetails.deliveryCharges > 0) {
                            this.basket.deliveryCharges += restaurantDeliveryDetails.deliveryCharges;
                        }
                    }
                });
            }

            //if (this.basket.discountAmount > 0 && this.basket.netTotal > 0) {
            //    this.basket.netTotal -= this.basket.discountAmount;
            //}

            this.Log("Current state of vouchers:");
            this.Log(this.vouchers);
            this.Log("Current state of restaurantBasket:");
            this.Log(this.restaurantBasket);

            this.basket.serviceFee = this.getServiceFee();
            this.basket.dineInServiceFee = this.getDineInServiceFee(this.basket.netTotal);
            this.basket.totalServiceFee = this.basket.serviceFee + this.basket.dineInServiceFee;
            this.basket.netTotal += this.basket.deliveryCharges;
            this.basket.netTotal += this.basket.totalServiceFee;
        }
    }

    public BasketMeetsMinOrderRequirements(): boolean {
        if (this.stateService.device$.userPreferences.deliveryMethod != DeliveryMethods.Delivery) {
            return true;
        }

        if (this.basket && this.basket.items && this.basket.items.length > 0) {
            if (this.basket.netTotal < this.basket.items[0].restaurant.minOrderAmount) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    public SaveBasket() {
        this.Log("Saving basket on server");
        if (this.basket) {
            this.orderService.placeBasket(this.basket).subscribe(
                result => {
                    this.Log("Result from place basket");
                    this.Log(result);
                },
                error => {
                    this.Log(error);
                }
            );
        }
    }

    public forceClearBasket() {
        this.Log("Force clear basket");
        this.basket = new Basket();
        this.basket.items = [];
        this.orderService.getNewBasket().subscribe(
            result => {
                this.Log(result);
                this.basket = result;
                if (this.basket) {
                    this.basket.items = [];
                } else {
                    this.basket = new Basket();
                    this.basket.items = [];
                }
                this.publish(true);
            },
            error => {
                //TODO: Log this error
                this.Log(error);
            }
        );
    }

    private getServiceFee(): number {
        var serviceFee = 0;
        if (this.restaurantBasket && this.restaurantBasket.length > 0) {
            this.restaurantBasket.forEach(rest => {
                if (rest.restaurant && rest.restaurant.serviceFee && rest.restaurant.serviceFee > 0) {
                    serviceFee = rest.restaurant.serviceFee;
                }
            });
        }

        return serviceFee;
    }

    private getDineInServiceFee(orderTotalAmount: number): number {
        var dineInServiceFee = 0;
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod == DeliveryMethods.DineIn) {
            if (orderTotalAmount > 0 && this.restaurantBasket && this.restaurantBasket.length > 0) {
                this.restaurantBasket.forEach(rest => {
                    if (rest.restaurant && rest.restaurant.dineInServiceFeeInPercentage > 0) {
                        dineInServiceFee = (orderTotalAmount / 100) * rest.restaurant.dineInServiceFeeInPercentage;
                    } else if (rest.restaurant && rest.restaurant.dineInServiceFeeInFixedAmount > 0) {
                        dineInServiceFee = rest.restaurant.dineInServiceFeeInFixedAmount;
                    }
                });
            }
        }

        return dineInServiceFee;
    }

    private publish(calculate: boolean = true) {
        if (calculate) {
            this.calculateBasket();
        }
 
        this.Log("Basket is publishing event");
        this.Log(this.basket);
        this.basketSubject.next(this.basket);
    }
}