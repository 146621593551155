<div class="provider-view-root" *ngIf="data && data.imagesAndVideos && data.imagesAndVideos.length > 0">
    <div [ngClass]="applyPadding ? 'product-card with-padding' : 'product-card'" *ngIf="isOneImageOnly()">
        <div class="product-cover">
            <img #img1 [src]="getImageUrl(this.assets[0].name)" alt="">
        </div>
        <div>
            <ion-row class="no-padding">
                <ion-col>
                    <ion-button class="filter-btn buttonActive" size="default" fill="solid" (click)="delete(0)">Remove</ion-button>
                </ion-col>
                <ion-col *ngIf="!this.isVideo(assets[0].name) && this.assets[0].base64">
                    <ion-button class="filter-btn buttonActive" size="default" fill="solid" (click)="presentAppImageUploaderModal(this.assets[0], 0)">Edit</ion-button>
                </ion-col>
            </ion-row>
        </div>
    </div>
    <div [ngClass]="applyPadding ? 'product-card with-padding' : 'product-card'" *ngIf="isOneVideoOnly()">
        <div class="product-cover">
            <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrl(this.assets, 0)">
                <source [src]="getImageUrl(this.assets[0].name)" type="video/mp4" />
            </video>
            <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
        </div>
        <ion-grid>
            <ion-row class="no-padding">
                <ion-col>
                    <ion-button class="filter-btn buttonActive" size="default" fill="solid" (click)="delete(0)">Remove</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
    <div [ngClass]="applyPadding ? 'product-card with-padding' : 'product-card'" *ngIf="areMultipleImagesOrVideos()">
        <div class="product-cover">
            <ion-slides #slider [options]="slideOptions">
                <ion-slide #slide *ngFor="let asset of this.assets; let aindex = index">
                    <ion-grid class="no-padding">
                        <ion-row class="no-padding">
                            <ion-col class="no-padding">
                                <img #img2 [src]="getImageUrl(asset.name)" alt="" *ngIf="!this.isVideo(asset.name)">
                                <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrl(this.assets, aindex)" *ngIf="this.isVideo(asset.name)">
                                    <source [src]="getImageUrl(asset.name)" type="video/mp4" />
                                </video>
                                <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="this.isVideo(asset.name) && adPlayer && !adPlayer.nativeElement.paused">
                                    <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                                </div>
                                <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="this.isVideo(asset.name) && adPlayer && adPlayer.nativeElement.paused">
                                    <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                                </div>
                                <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="this.isVideo(asset.name) && adPlayer && !adPlayer.nativeElement.muted">
                                    <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                                </div>
                                <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="this.isVideo(asset.name) && adPlayer && adPlayer.nativeElement.muted">
                                    <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row class="no-padding">
                            <ion-col>
                                <ion-button class="filter-btn buttonActive" size="default" fill="solid" (click)="delete(aindex)">Remove</ion-button>
                            </ion-col>
                            <ion-col *ngIf="!this.isVideo(asset.name) && this.asset.base64">
                                <ion-button class="filter-btn buttonActive" size="default" fill="solid" (click)="presentAppImageUploaderModal(this.asset, aindex)">Edit</ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>
            </ion-slides>
        </div>
    </div>
</div>
