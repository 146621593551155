import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { Restaurant, Menu, DeliveryMethods } from '../shared/models/shared.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import * as moment from 'moment';
import 'moment-timezone';

declare var google: any;

@Injectable()
export class RestaurantService extends ServiceBase {

    private static _instance: RestaurantService;

    private searchRestaurantsSubject = new Subject<Array<Restaurant>>();

    //private serviceBaseRoute: string = ':7071/api/';
    //private getRestaurantApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'Restaurant/';
    //private searchRestaurantsApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'Restaurants/';
    //private searchRestaurantsByTagApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'Restaurants/tags/';
    //private searchRestaurantsByFoodTypeApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'Restaurants/foodtypes/';
    //private nearestRestaurantsApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'Restaurants/nearest/';
    //private getRestaurantMenuApiUrl: string = this.serviceBaseUrl + this.serviceBaseRoute + 'Restaurant/Menu/';

    private getRestaurantApiUrl: string;
    private searchRestaurantsApiUrl: string;
    private searchRestaurantsByGeolocationApiUrl: string;
    private searchRestaurantsByTagApiUrl: string;
    private searchRestaurantsByFoodTypeApiUrl: string;
    private nearestRestaurantsApiUrl: string;
    private getRestaurantMenuApiUrl: string;
    private getRestaurantDineInSlotsApiUrl: string;
    private getRestaurantWithMenuApiUrl: string;
    private getRestaurantMenuItemOptionsApiUrl: string;
    private getRestaurantMenuItemAdditionalDetailsApiUrl: string;
    private getRestaurantMenuItemSpecSheetApiUrl: string;
    private getGroupRestaurantApiUrl: string;

    private nearestRestaurantsList: Array<Restaurant>;
    SearchResults = this.searchRestaurantsSubject.asObservable();

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.getRestaurantApiUrl = environment.restaurantApiUrl + 'Restaurant/';
        this.getGroupRestaurantApiUrl = environment.restaurantApiUrl + 'group-restaurant/';
        this.searchRestaurantsApiUrl = environment.restaurantApiUrl + 'Restaurants/';
        this.searchRestaurantsByTagApiUrl = environment.restaurantApiUrl + 'Restaurants/tags/';
        this.searchRestaurantsByGeolocationApiUrl = environment.restaurantApiUrl + 'Restaurants/nearest/';
        this.searchRestaurantsByFoodTypeApiUrl = environment.restaurantApiUrl + 'Restaurants/foodtypes/';
        this.nearestRestaurantsApiUrl = environment.restaurantApiUrl + 'Restaurants/nearest/';
        this.getRestaurantMenuApiUrl = environment.restaurantApiUrl + 'Restaurant/Menu/';
        this.getRestaurantWithMenuApiUrl = environment.restaurantApiUrl + 'Restaurant/Menu/plus/';
        this.getRestaurantDineInSlotsApiUrl = environment.restaurantApiUrl + 'Restaurant/dinein/slots/';
        this.getRestaurantMenuItemOptionsApiUrl = environment.restaurantApiUrl + 'Restaurant/menu/item/options/';
        this.getRestaurantMenuItemAdditionalDetailsApiUrl = environment.restaurantApiUrl + 'Restaurant/menu/item/additionaldetails/';
        this.getRestaurantMenuItemSpecSheetApiUrl = environment.restaurantApiUrl + 'Restaurant/menu/item/specsheet/';

        if (!RestaurantService._instance) {
            RestaurantService._instance = this;
        }
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance;
    }

    public getRestaurant(id: string): Observable<Restaurant> {
        return this.get(this.getRestaurantApiUrl + id);
    }

    public getGroupRestaurants(id: string): Observable<Array<any>> {
        return this.get(this.getGroupRestaurantApiUrl + id);
    }

    public searchRestaurants(value: string): Observable<Array<Restaurant>> {
        value = encodeURIComponent(value);
        this.stateService.props$.searchText = value;
        //return this.get(this.searchRestaurantsApiUrl + value);
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchRestaurantsApiUrl + value, headers);
        } else {
            return Observable.of([]);
        }
    }

    public searchRestaurantsByTag(value: string): Observable<Array<Restaurant>> {
        value = encodeURIComponent(value);
        this.stateService.props$.searchText = value;
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchRestaurantsByTagApiUrl + value, headers);
            //var result = this.get(this.searchRestaurantsByTagApiUrl + value);
            //result.subscribe(x => this.searchRestaurantsSubject.next(x));
            //return result;
        } else {
            return Observable.of([]);
        }
    }

    public searchRestaurantsByFoodType(value: string, searchText: string): Observable<Array<Restaurant>> {
        var result = Observable.of([]);
        value = encodeURIComponent(value);
        searchText = encodeURIComponent(searchText);

        this.Log("searchRestaurantsByFoodType");
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchRestaurantsByFoodTypeApiUrl + value + "/" + searchText, headers);
        } else {
            return Observable.of([]);
        }
    }

    //public searchRestaurantsByTag(value: string): Observable<Array<Restaurant>> {
    //  if (this.stateService.props$ && this.stateService.props$.token) {
    //    return this.get(this.searchRestaurantsByTagApiUrl + value);
    //  } else {
    //    return Observable.of([]);
    //  }
    //}

    public nearestRestaurants(value: string): Observable<Array<Restaurant>> {
        value = encodeURIComponent(value);
        this.Log('nearestRestaurants');
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.nearestRestaurantsApiUrl + value, headers);
        } else {
            return Observable.of([]);
        }
    }

    public searchRestaurantsByGeolocation(longitude: any, latitude: any): Observable<Array<Restaurant>> {
        this.Log("Calling search svr");
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            var result = this.get(this.searchRestaurantsByGeolocationApiUrl + latitude + '/' + longitude, headers);
            //result.subscribe(x => this.searchRestaurantsSubject.next(x));
            return result;
        } else {
            return Observable.of([]);
        }
    }

    public getRestaurantMenu(id: string): Observable<Menu> {
        var headers = this.getPDHeader(true);
        return this.get(this.getRestaurantMenuApiUrl + id, headers);
    }

    public getRestaurantWithMenu(nameKey: string, deliveryMethod: string): Observable<any> {
        this.Log("Calling getRestaurantWithMenu service");
        var headers = this.getPDHeader(true);
        return this.get(this.getRestaurantWithMenuApiUrl + nameKey + '/' + deliveryMethod, headers);
    }

    public nearestRestaurantsFoodTypes(): Array<string> {
        var foodTypes = new Array<string>();
        this.nearestRestaurantsList.forEach(x => x.foodTypes.forEach(f => { if (foodTypes.indexOf(f) < 0) { foodTypes.push(f) } }));
        return foodTypes;
    }

    public isOpen(restaurant: Restaurant): boolean {
        var firstOrderTime = moment.tz(restaurant.firstOrderTime, 'h:mma', restaurant.timeZone);
        var lastOrderTime = moment.tz(restaurant.lastOrderTime, 'h:mma', restaurant.timeZone);

        var currentTime = moment().tz(restaurant.timeZone);

        //this.Log("Is Open first order time", firstOrderTime.tz(restaurant.timeZone));
        //this.Log("Is Open last order time", firstOrderTime.tz(restaurant.timeZone));
        //this.Log("current time", currentTime.tz(restaurant.timeZone));
        //this.Log(restaurant.name, restaurant.firstOrderTime, restaurant.lastOrderTime);
        //this.Log(restaurant.name, firstOrderTime, lastOrderTime, currentTime);
        //this.Log(restaurant.name, firstOrderTime.format("h:mma"), lastOrderTime.format("h:mma"), currentTime.format("h:mma"));
        //this.Log(restaurant.name, currentTime.isAfter(firstOrderTime), currentTime.isBefore(lastOrderTime));
        //this.Log("inside isopen");
        //this.Log(restaurant.firstOrderTime);
        //this.Log(restaurant.lastOrderTime);
        //this.Log(firstOrderTime.toDate());
        //this.Log(lastOrderTime.toDate());
        //this.Log(currentTime.toDate());

        if (restaurant.lastOrderTime.indexOf("am") > 0) {
            return (currentTime.isAfter(firstOrderTime) || currentTime.isBefore(lastOrderTime));
        } else {
            return (currentTime.isAfter(firstOrderTime) && currentTime.isBefore(lastOrderTime))
        }
    }

    public isClosed(restaurant: Restaurant): boolean {
        return !this.isOpen(restaurant);
    }

    public canDeliver(restaurant: Restaurant): boolean {
        this.Log("Inside can deliver function");
        this.Log(restaurant);
        this.Log(this.stateService.device$.userPreferences);
        this.Log(restaurant.location);
        if (this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.deliveryMethod != DeliveryMethods.Delivery) {
            this.Log("Delivery metohd is not delivery");
            return true;
        } else {
            if (
                !this.stateService.device$ ||
                !this.stateService.device$.userPreferences ||
                !this.stateService.device$.userPreferences.userLocation) {
                this.Log("Location data not found");
                return false;
            }

            this.Log("Delivery metohd is delivery");
            var latLngA = new google.maps.LatLng(this.stateService.device$.userPreferences.userLocation.latitude, this.stateService.device$.userPreferences.userLocation.longitude);
            var latLngB = new google.maps.LatLng(restaurant.latitude, restaurant.longitude);
            this.Log(latLngA);
            this.Log(latLngB);
            var distance = google.maps.geometry.spherical.computeDistanceBetween(latLngA, latLngB);
            var distanceInMiles = distance * 0.000621371192;
            this.Log(distance);
            this.Log(distanceInMiles);
            this.Log(restaurant.deliveryRadius);

            this.Log(latLngA);
            this.Log(latLngB);
            this.Log(distanceInMiles > restaurant.deliveryRadius);

            if (distanceInMiles && (restaurant.deliveryRadius <= 0 || distanceInMiles <= restaurant.deliveryChargesLimit1Radius || distanceInMiles <= restaurant.deliveryChargesLimit2Radius || distanceInMiles <= restaurant.deliveryChargesLimit3Radius)) {
                return true;
            } else {
                return false;
            }
        }
    }

    public orderReadyFor(restaurant: Restaurant, applyTimeRounding: boolean = false): any {
        var currentTime: moment.Moment = moment.tz(restaurant.timeZone).add(restaurant.avgOrderReadyTimeInMinutes ? restaurant.avgOrderReadyTimeInMinutes : 30, 'minutes');

        if (applyTimeRounding) {
            var intervals = Math.floor(currentTime.minutes() / 15);
            if (currentTime.minutes() % 15 != 0)
                intervals++;
            if (intervals == 4) {
                currentTime.add('hours', 1);
                intervals = 0;
            }
            currentTime.minutes(intervals * 15);
            currentTime.seconds(0);
        }

        return currentTime;
        //return moment.tz(restaurant.timeZone).add(restaurant.avgOrderReadyTimeInMinutes ? restaurant.avgOrderReadyTimeInMinutes : 30, 'minutes');
    }

    publish(restaurant: any) {
        this.searchRestaurantsSubject.next(restaurant);
    }

    public getDineInSlots(restaurantId: string = null): Observable<Array<string>> {
        var headers = this.getPDHeader(true);
        return this.get(this.getRestaurantDineInSlotsApiUrl + restaurantId, headers);
    }

    public getRestaurantMenuItemOptions(restaurantId: string, categoryId: string, itemId: string): Observable<any> {
        return this.get(this.getRestaurantMenuItemOptionsApiUrl + restaurantId + '/' + categoryId + '/' + itemId + '/' + this.stateService.device$.userPreferences.deliveryMethod);
    }

    public getRestaurantMenuItemAdditionalDetails(restaurantId: string, categoryId: string, itemId: string): Observable<any> {
        return this.get(this.getRestaurantMenuItemAdditionalDetailsApiUrl + restaurantId + '/' + categoryId + '/' + itemId + '/' + this.stateService.device$.userPreferences.deliveryMethod);
    }

    public getRestaurantMenuItemSpecSheet(restaurantId: string, spectSheetName: string): Observable<any> {
        spectSheetName = spectSheetName.split(".")[0];
        return this.get(this.getRestaurantMenuItemSpecSheetApiUrl + restaurantId + '/' + spectSheetName);
    }

    public validateRestaurantObject(restaurant: any, service: any): any {

        //restaurant.foodTypes.forEach(f => {
        //    if (!this.foodTypes.find(ft => ft.name === f)) {
        //        this.foodTypes.push({ name: f, imageName: f.replace(' ', '').toLowerCase() });
        //    }
        //});

        restaurant.isClosed = this.isClosed(restaurant);
        restaurant.orderReadyFor = this.orderReadyFor(restaurant, service.applyOrderReadyForTimeRounding);
        if (restaurant.orderReadyFor == null || restaurant.orderReadyFor == undefined) {
            //alert('missing order ready for ' + restaurant.name);
        }

        restaurant.assetUrls = this.getMultipleAssetUrls(restaurant.multipleDisplayAssetUrls);
        if (restaurant.assetUrls && restaurant.assetUrls.length > 0) {
            restaurant.hasMultipleAssets = restaurant.assetUrls.length > 1;
            for (var i = 0; i < restaurant.assetUrls.length; i++) {
                if (restaurant.assetUrls[i].isVideo && !restaurant.assetVideoUrl) {
                    restaurant.assetVideoUrl = restaurant.assetUrls[i].url;
                }

                if (!restaurant.assetUrls[i].isVideo && !restaurant.assetImageUrl) {
                    restaurant.assetImageUrl = restaurant.assetUrls[i].url;
                }

                if (restaurant.assetImageUrl && restaurant.assetVideoUrl) {
                    break;
                }
            }
        }

        return restaurant;
    }

    public getMultipleAssetUrls(urls: string): Array<any> {
        var result: Array<any> = null;

        if (urls) {
            var items = urls.split(',');
            result = new Array<any>();

            for (var i = 0; i < items.length; i++) {
                result.push({ url: items[i].trim(), isVideo: (items[i].toLowerCase().indexOf('video') > -1 || items[i].toLowerCase().indexOf('mp4') > -1) });
            }
        }
        return result;
    }
}
