<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start" style="margin-left:10px;">
            <ion-back-button text="" icon="contact"></ion-back-button>
        </ion-buttons>
        <ion-title class="ion-text-center">Basket</ion-title>
        <ion-buttons slot="end" style="margin-right:10px;">
            <ion-button text="" icon="search" (click)="goSearch()"></ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>