<div [ngClass]="applyPadding ? 'product-detail with-padding' : 'product-detail'" *ngIf="restaurant">
    <ion-grid class="no-padding">
        <ion-row class="no-padding">
            <ion-col class="no-padding"><h2 style="padding:0px;" *ngIf="showName()">{{restaurant.name}}</h2></ion-col>
            <ion-col style="text-align:right" *ngIf="this.wlaActive && this.wlaShowChangeLocation && this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location"><a (click)="goChangeLocation()">{{this.getLocationText()}}</a></ion-col>
        </ion-row>
    </ion-grid>
    <p class="{{getSummaryViewCss(false, applyPadding)}}">
        <span *ngIf="restaurant.reviewStars"><ion-icon name="star"></ion-icon><span>&nbsp;{{restaurant.reviewStars}}&nbsp;</span> {{restaurant.reviewRating}} ({{restaurant.reviewsCount}})</span>
        {{this.getHeading(restaurant)}}
    </p>
    <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="restaurant.adMessage">
        {{restaurant.adMessage}}
    </p>
</div>
