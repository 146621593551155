import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageKeys } from '../shared/storage.keys';
import { environment } from '../../environments/environment';
import { Common } from '../shared/common';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({
    providedIn: 'root'
})
export class LocalDbService {

    private static _instance: LocalDbService;
    private localDb: Storage;

    device$: any;
    props$: any;
    state$: any;
    private stateSubject = new Subject<any>();
    OnState = this.stateSubject.asObservable();

    counter: number = 0;

    constructor() {
        this.clearState();
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        if (this._instance) {
            return this._instance;
        } else {
            this._instance = Common.InjectorInstance.get<LocalDbService>(LocalDbService);
            Promise.resolve(this._instance.init());
            return this._instance;
        }
    }

    clearState(forcedClear: boolean = false) {
        if (!this.state$ || forcedClear) {
            this.state$ = new Object();
        }

        this.publishState();
    }

    public setState(state: any) {
        //this.state$ = state;

        if (!this.state$) {
            this.state$ = new Object();
        }

        if (state) {
            for (let key of Object.keys(state)) {
                this.state$[key] = state[key];
            }
        }
    }

    public clearDevice() {
        localStorage.removeItem(this.getStorageKey(StorageKeys.Device));
        this.removeItem(this.getStorageKey(StorageKeys.Device));
    }

    public setDevice(props: any) {
        //this.Log("Inside setdevice");

        if (!this.device$) {
            this.device$ = new Object();
        }

        if (props) {
            for (let key of Object.keys(props)) {
                this.device$[key] = props[key];
            }
        }

        //localStorage.setItem(StorageKeys.Device, JSON.stringify(this.device$));
        this.setItem(this.getStorageKey(StorageKeys.Device), JSON.stringify(this.device$)).then(result => { });

        //this.Log(this.device$);
    }

    async init() {
        if (this.localDb) { return; }
        //this.counter++;
        //alert(this.counter);
        const storage = Common.InjectorInstance.get<Storage>(Storage);
        await storage.defineDriver(CordovaSQLiteDriver);
        this.localDb = await storage.create();
        //alert("Created");
        //alert(this.localDb);
    }


    public setProps(props: any) {
        if (!this.props$) {
            this.props$ = new Object();
        }

        for (let key of Object.keys(props)) {
            this.props$[key] = props[key];
        }
    }

    public getState(): any {
        return this.state$;
    }

    private publishState() {
        this.stateSubject.next(this.state$);
    }

    public async setItem(key: string, data: any): Promise<any> {
        try {
            if (!this.localDb || this.localDb == undefined) {
                await this.init();
            }
            var item = await this.localDb.set(key, data);
            return item;
        } catch (error) {
            //alert("Unknown error occured during storing your data. Please try again");
        }
    }

    public async getItem(key: string): Promise<any> {
        try {
            if (!this.localDb || this.localDb == undefined) {
                await this.init();
            }
            var item = await this.localDb.get(key);
            return item;
        } catch (error) {
            //alert("Unknown error occured during storing your data. Please try again");
        }
    }

    public async removeItem(key: string): Promise<any> {
        try {
            if (!this.localDb || this.localDb == undefined) {
                await this.init();
            }
            var item = await this.localDb.remove(key);
            return item;
        } catch (error) {
            //alert("removeitem - Unknown error occured during storing your data. Please try again");
        }
    }

    protected Log(message: any) {
        if (environment.production == false) {
            console.log(message);
        }
    }

    protected getStorageKey(key: string): string {
        if (environment.wlaActive) {
            return key + '_' + environment.appVariant;
        }

        return key;
    }
}
