import { Component, ChangeDetectorRef, Renderer2, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController } from '@ionic/angular';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { ServiceResourceService } from '../../../services/serviceresource.service';
import { ServiceResourceAdBase } from '../../service.resource.ad.base';

@Component({
    selector: 'service-resource-ad-images-videos-slider',
    templateUrl: 'service.resource.ad.images.videos.slider.html',
    styleUrls: ['service.resource.ad.images.videos.slider.scss']
})
export class ServiceResourceAdImagesVideosSlider extends ServiceResourceAdBase implements OnInit {

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected geolocation: Geolocation,
        public geoService: LocationService,
        protected basketService: BasketService,
        protected deviceService: DeviceService,
        protected appVersionService: AppVersionService,
        protected serviceResourceService: ServiceResourceService,
        //private loadingController: LoadingController,
        protected videoPlayer: VideoPlayer,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, geolocation, geoService, basketService, deviceService, appVersionService, serviceResourceService, videoPlayer, menu, renderer);
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
    }

    ngOnInit() {
    }
}