import { Component, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, Renderer2, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController, IonSlides } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ClassifiedAdsService } from '../../../services/classified.ads.service';

@Component({
    selector: 'taeam-image-full-screen-viewer',
    templateUrl: 'image.full.screen.viewer.html',
    styleUrls: ['image.full.screen.viewer.scss']
})
export class ImageFullScreenViewer extends ObjectBase implements OnInit {

    @Input() ad: any;
    @Input() baseUrl: any;
    @Input() data: any;

    @Output("didScroll") didScroll: EventEmitter<any> = new EventEmitter();

    currentSlideIndex: number = 0;

    @ViewChildren('adPlayer') adPlayers: QueryList<any>;
    @ViewChildren('slide') slides: QueryList<any>;

    @ViewChild("adPlayer") adPlayer: any;
    @ViewChild("slider") slider: IonSlides;

    public slideOptions = {
        slidesPerView: 1.05,
        initialSlide: 0,
        speed: 400,
        spaceBetween: 10
    };


    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private restaurantService: RestaurantService,
        protected userService: UserService,
        private geolocation: Geolocation,
        public geoService: LocationService,
        private basketService: BasketService,
        private deviceService: DeviceService,
        private appVersionService: AppVersionService,
        private classifiedAdsService: ClassifiedAdsService,
        //private loadingController: LoadingController,
        private videoPlayer: VideoPlayer,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.restaurantService = RestaurantService.Instance;
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
        this.classifiedAdsService = ClassifiedAdsService.Instance;
    }

    ngOnInit() {
    }

    ionViewDidEnter() {
    }

    public getImageUrl(name: any) {
        if (name) {
            if (!this.isNullOrEmpty(this.baseUrl) && this.baseUrl.charAt(this.baseUrl.length - 1) != '/') {
                this.baseUrl = this.baseUrl + '/';
            }

            if (this.contains(this.baseUrl, environment.serviceResourcesBaseUrl)) {
                return this.baseUrl + name;
            }

            return environment.serviceResourcesBaseUrl + this.baseUrl + name;
        }
    }

    isOneImageOnly(): boolean {
        if (this.data) {
            return this.data.length == 1 && !this.isVideo(this.data[0].name);
        }

        return false;
    }

    isOneVideoOnly(): boolean {
        if (this.data) {
            return this.data.length == 1 && this.isVideo(this.data[0].name);
        }

        return false;
    }

    areMultipleImagesOrVideos(): boolean {
        if (this.data) {
            return this.data.length > 1;
        }

        return false;
    }

    play($event, player) {
        this.playVideo(player);
        $event.stopPropagation();
    }

    pause($event, player) {
        this.pauseVideo(player);
        $event.stopPropagation();
    }

    mute($event, player) {
        this.muteAudio(player);
        $event.stopPropagation();
    }

    unmute($event, player) {
        this.unMuteAudio(player);
        $event.stopPropagation();
    }

    public getAssetPosterUrl(assets: any, index: any): string {
        if (assets && assets.length > index && index >= 0) {
            if (!this.isNullOrEmpty(this.baseUrl) && this.baseUrl.charAt(this.baseUrl.length - 1) != '/') {
                this.baseUrl = this.baseUrl + '/';
            }
            for (var i = index; i < assets.length; i++) {
                if (assets[i].isPoster) {
                    return environment.serviceResourcesBaseUrl + this.baseUrl + assets[i].name;
                }
            }
        }

        return "";
    }

    dismiss(data: any = null) {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        if (!data) {
            data = { 'dismissed': true };
        }

        this.modalController.dismiss(data);
    }

    getBaseUrl() {
        if (this.ad && this.ad.id) {
            return this.getService().bespokeServiceTemplateUrl + '/' + this.ad.id + '/';
        } else if (this.ad && this.ad.tempId) {
            return this.getService().bespokeServiceTemplateUrl + '/' + this.ad.tempId + '/';
        }

        return "";
    }
}