import { environment } from '../../environments/environment';
import { MonitoringService } from '../services/monitoring.service';
import { StateService } from '../services/state.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { Common } from './common';
import { DeliveryMethods } from './models/shared.model';
import { ViewChild, ElementRef, Directive, Injectable } from '@angular/core';
import { Share } from '@capacitor/share';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import 'moment-timezone';

declare function gtag_report_conversion(url): any;

@Directive()
export class ObjectCore {

    public loader: HTMLIonLoadingElement;
    protected monitoringService: MonitoringService;
    public locationErrorOccurred: boolean = true;
    public locationErrorMessage: string = "You need to permit the location permission to search your nearest marketplaces or you can just type the town or city name or type what you're looking for in search text box";

    public textToShare: string;
    public urlToShare: string;
    public imageToShare: string;
    public canShareTwitter: boolean;
    public canShareFacebook: boolean;
    public canShareInstagram: boolean;
    public canShareEmail: boolean;
    public canSocialShare: boolean = true;
    public socialSharing: SocialSharing;
    public actionSheetController: ActionSheetController;
    public alertController: AlertController;
    public topAd: any = null;
    public bottomAd: any = null;
    public topSliderAds: Array<any> = null;
    public bottomSliderAds: Array<any> = null;
    public topSliderSettings: any = null;
    public bottomSliderSettings: any = null;
    public youTubeAd: any = null;
    public logoUrl: string = environment.logoUrl;
    public appName: string = environment.appName;
    public wlaActive: boolean = environment.wlaActive;
    public wlaRestaurantGroupId: string = environment.wlaRestaurantGroupId;
    public wlaRestaurantId: string = environment.wlaRestaurantId;
    public wlaServiceId: string = environment.wlaServiceId;
    public wlaAllowDineIn: boolean = environment.wlaAllowDineIn;
    public wlaAllowCollection: boolean = environment.wlaAllowCollection;
    public wlaShowPowerByCrazon: boolean = environment.wlaShowPowerByCrazon;
    public wlaShowName: boolean = environment.wlaShowName;
    public wlaShowAddress: boolean = environment.wlaShowAddress;
    public wlaShowChangeLocation: boolean = environment.wlaShowChangeLocation;
    public wlaShowFeaturedText: boolean = environment.wlaShowFeaturedText;
    public recentlyViewedLimit: number = environment.recentlyViewedLimit;
    public rememberMyLocationLabelText: string = environment.rememberMyLocationLabelText;
    public wlaRecentlyViewedLimit: number = environment.wlaRecentlyViewedLimit;
    public rememberMyLocation: boolean;
    public wlaShowChangeLocationInRecommendation: boolean = environment.wlaShowChangeLocationInRecommendation;
    public wlaShowOrderReadyFor: boolean = environment.wlaShowOrderReadyFor;
    public dataloaded: boolean = false;
    public selectedIndex: number;

    stateService: StateService;

    public currentPlaying = null;
    public stickyVideo: HTMLVideoElement = null;
    public stickyPlaying = false;
    @ViewChild('stickyplayer') stickyPlayer: ElementRef;

    public defaultSliderSettings = {
        slidesPerView: 1.05,
        initialSlide: 0,
        speed: 400,
        spaceBetween: 1,
        autoHeight: true
    };

    constructor() {
        this.alertController = new AlertController();
        this.stateService = StateService.Instance;
        this.monitoringService = MonitoringService.Instance;
        this.socialSharing = this.socialSharing || (this.socialSharing = Common.InjectorInstance.get<SocialSharing>(SocialSharing));
        this.actionSheetController = this.actionSheetController || (this.actionSheetController = Common.InjectorInstance.get<ActionSheetController>(ActionSheetController));
        this.topSliderSettings = this.defaultSliderSettings;
        this.bottomSliderSettings = this.defaultSliderSettings;
    }

    public loadTestData() {
        //this.youTubeAd = { mobileVideoUrl: "", youTubeId: "", streamType: 'youtube' };
    }

    public async capacitorShare(title: string = 'Crazon - An Online Marketplace for Everything - Order Now') {
        await Share.share({
            title: title,
            text: this.textToShare,
            url: this.urlToShare,
            dialogTitle: 'Crazon - Share'
        });
    }

    public copyToClipboard(data: string) {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', data);
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    };

    public isCrypto(currency: string): boolean {
        if (currency && (currency.toLowerCase() == 'bitcoin' || currency.toLowerCase() == 'ether' || currency.toLowerCase().indexOf('crypto') > -1)) {
            return true;
        } else {
            return false;
        }
    }

    public videoEnded() {
        this.Log("Video is ended");
        this.currentPlaying = null;
    }

    public doWeKnowUserLocation(): boolean {
        var result = (this.stateService.device$ && !this.stateService.device$.forceAskForLocation && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation);
        //if (!result) {
        //    alert("We don't know location");
        //}

        return result;
    }

    public isNullOrEmpty(val: any): boolean {
        if (val == null || val == undefined || val.length == null || (val.trim && (val.trim() == "" || val.trim().length == 0))) {
            return true;
        } else {
            return false;
        }
    }

    public isNumeric(val: unknown): val is string | number {
        if (!val) {
            return false;
        }

    return (!isNaN(Number(Number.parseFloat(String(val)))) && isFinite(Number(val)));
}

    public hasContent(val: any): boolean {
        if (this.isNullOrEmpty(val)) {
            return false;
        } else {
            return true;
        }
    }

    public getEmptyIfNull(val: any, emptyVal: string = " "): any {
        if (val == null || val == undefined || val.length == null || (val.trim && (val.trim() == "" || val.trim().length == 0))) {
            return emptyVal;
        } else {
            return val;
        }
    }

    public getStringWithCR(val: string): any {
        if (val == null || val == undefined || val.length == null || (val.trim && (val.trim() == "" || val.trim().length == 0))) {
            return val;
        } else {
            val = val.replace(/(?:\r\n|\r|\n)/g, ' ');
            return val;
        }
    }

    public replaceSpaces(val: string, replaceWith: string = "-"): string {
        if (val) {
            val = val.replace(new RegExp(' ', 'g'), replaceWith);
            val = val.toLowerCase();
        }

        return val;
    }

    public getAd(index: number): any {
        if (!this.stateService.props$.marketingAds || this.stateService.props$.marketingAds.length == 0) {
            return null;
        } 

        var adsCount = this.stateService.props$.marketingAds.length;
        for (var i = 0; i < adsCount; i++) {
            var ad = this.stateService.props$.marketingAds[i];
            if (ad && ad.positionIndex == index) {
                return { ad: ad, hasMore: index < adsCount - 1 };
            }
        }

        return null;
    }

    public getServiceAd(index: number): any {
        if (!this.stateService.props$.servicesMarketingAds || this.stateService.props$.servicesMarketingAds.length == 0) {
            return null;
        }

        var adsCount = this.stateService.props$.servicesMarketingAds.length;
        for (var i = 0; i < adsCount; i++) {
            var ad = this.stateService.props$.servicesMarketingAds[i];
            if (ad && ad.positionIndex == index) {
                return { ad: ad, hasMore: index < adsCount - 1 };
            }
        }

        return null;
    }

    public findPageAds() {
        if (!this.stateService.props$.marketingAds || this.stateService.props$.marketingAds.length == 0) {
            this.topAd = null;
            this.bottomAd = null;
            this.topSliderAds = null;
            this.bottomSliderAds = null;
            return;
        }

        var adsCount = this.stateService.props$.marketingAds.length;
        this.topAd = null;
        this.bottomAd = null;
        this.topSliderAds = new Array<any>();
        this.bottomSliderAds = new Array<any>();

        for (var i = 0; i < adsCount; i++) {
            var ad = this.stateService.props$.marketingAds[i];
            if (ad && ad.positionIndex == -1 && !ad.isSliderAd) {
                this.topAd = ad;
                //this.topAd.videoUrl = 'https://assets.crazon.io/videos/ad1.mp4';
                //this.topAd.videoType = 'video/mp4';
            } else if (ad && ad.positionIndex == -1 && ad.isSliderAd) {
                this.topSliderAds.push(this.stateService.props$.marketingAds[i]);
            } else if (ad && ad.positionIndex == -2 && !ad.isSliderAd) {
                this.bottomAd = ad;
                //this.bottomAd.videoUrl = 'https://assets.crazon.io/videos/ad1.mp4';
                //this.bottomAd.videoType = 'video/mp4';
            } else if (ad && ad.positionIndex == -2 && ad.isSliderAd) {
                this.bottomSliderAds.push(this.stateService.props$.marketingAds[i]);
            }
        }

        if (this.topSliderAds && this.topSliderAds.length > 1) {
            this.topSliderAds.sort(this.adComparer);
        }

        if (this.bottomSliderAds && this.bottomSliderAds.length > 1) {
            this.bottomSliderAds.sort(this.adComparer);
        }

        this.Log("Page ads");
        this.Log(this.stateService.props$.marketingAds);
        this.Log(this.topSliderAds);
        this.Log(this.bottomSliderAds);
    }

    public findServicesPageAds() {
        if (!this.stateService.props$.servicesMarketingAds || this.stateService.props$.servicesMarketingAds.length == 0) {
            this.topAd = null;
            this.bottomAd = null;
            this.topSliderAds = null;
            this.bottomSliderAds = null;
            return;
        }

        var adsCount = this.stateService.props$.servicesMarketingAds.length;
        this.topAd = null;
        this.bottomAd = null;
        this.topSliderAds = new Array<any>();
        this.bottomSliderAds = new Array<any>();

        var servicesLength = 0;

        if (this.stateService.props$.taeamServices) {
            servicesLength = this.stateService.props$.taeamServices.length;
        } else if (this.stateService.device$.taeamServices) {
            servicesLength = this.stateService.device$.taeamServices.length;
            this.stateService.setProps({ taeamServices: this.stateService.device$.taeamServices });
        }

        var haveAdsInMiddle = false;
        var serviceAds = [];
        var firstService = null;

        if (this.stateService.props$.taeamServices && this.stateService.props$.taeamServices.length > 0) {
            firstService = this.stateService.props$.taeamServices[0];
        }

        if (firstService && !firstService.adsPerView) {
            firstService.adsPerView = this.defaultSliderSettings.slidesPerView;
        }

        for (var i = 0; i < adsCount; i++) {
            var ad = this.stateService.props$.servicesMarketingAds[i];
            this.defaultSliderSettings.slidesPerView = 3;

            if (ad && ad.positionIndex == -1 && !ad.isSliderAd) {
                this.topAd = ad;
                //this.topAd.videoUrl = 'https://assets.crazon.io/videos/ad1.mp4';
                //this.topAd.videoType = 'video/mp4';
            } else if (ad && ad.positionIndex == -1 && ad.isSliderAd) {
                this.topSliderAds.push(this.stateService.props$.servicesMarketingAds[i]);
            } else if (ad && ad.positionIndex == -2 && !ad.isSliderAd) {
                this.bottomAd = ad;
                //this.bottomAd.videoUrl = 'https://assets.crazon.io/videos/ad1.mp4';
                //this.bottomAd.videoType = 'video/mp4';
            } else if (ad && ad.positionIndex == -2 && ad.isSliderAd) {
                this.bottomSliderAds.push(this.stateService.props$.servicesMarketingAds[i]);
            } else if (ad && ad.positionIndex >= 0 && servicesLength > 0 && ad.positionIndex <= servicesLength) {
                if (this.stateService.device$.taeamServices) {
                    haveAdsInMiddle = true;
                    if (!this.stateService.device$.taeamServices[ad.positionIndex].ads) {
                        this.stateService.device$.taeamServices[ad.positionIndex].ads = [];
                    }

                    this.stateService.device$.taeamServices[ad.positionIndex].ads.push(ad);
                }
            }
        }

        this.topSliderSettings.slidesPerView = firstService.adsPerView;
        if (this.isNullOrEmpty(this.topSliderSettings.slidesPerView)) {
            this.topSliderSettings.slidesPerView = this.defaultSliderSettings.slidesPerView;
        }


        //if (this.topSliderAds && this.topSliderAds.length > 0) {
        //    if (this.topSliderAds.length < this.topSliderSettings.slidesPerView) {
        //        if (this.topSliderAds.length == 1) {
        //            this.topSliderSettings.slidesPerView = this.defaultSliderSettings.slidesPerView;
        //        } else {
        //            this.topSliderSettings.slidesPerView = this.topSliderAds.length;
        //        }
        //    }
        //}

        this.bottomSliderSettings.slidesPerView = firstService.adsPerView;
        if (this.isNullOrEmpty(this.topSliderSettings.slidesPerView)) {
            this.bottomSliderSettings.slidesPerView = this.defaultSliderSettings.slidesPerView;
        }

        //if (this.bottomSliderAds && this.bottomSliderAds.length > 0) {
        //    if (this.bottomSliderAds.length < this.bottomSliderSettings.slidesPerView) {
        //        if (this.bottomSliderAds.length == 1) {
        //            this.bottomSliderSettings.slidesPerView = this.defaultSliderSettings.slidesPerView;
        //        } else {
        //            this.bottomSliderSettings.slidesPerView = this.bottomSliderAds.length;
        //        }
        //    }
        //}

        if (haveAdsInMiddle) {
            var services = this.stateService.device$.taeamServices;
            for (var i = 0; i < servicesLength; i++) {
                if (services[i] && services[i].ads && services[i].ads.length > 0) {
                    var item: any = {};
                    item.ads = services[i].ads;
                    item.columnsToMerge = 12;
                    serviceAds.push(item);
                }

                services[i].ads = null;
                serviceAds.push(services[i]);
            }

            this.stateService.setProps({ taeamServices: serviceAds });
            //this.stateService.setDevice({ taeamServices: this.clone(newServices) });
            
            this.Log("Ads added in service");
            this.Log(services);
            this.Log(serviceAds);
            this.debugCore();
        } 

        if (this.topSliderAds && this.topSliderAds.length > 1) {
            this.topSliderAds.sort(this.adComparer);
        }

        if (this.bottomSliderAds && this.bottomSliderAds.length > 1) {
            this.bottomSliderAds.sort(this.adComparer);
        }

        this.Log("Services Page ads");
        this.Log(this.stateService.props$.servicesMarketingAds);
        this.Log(this.topSliderAds);
        this.Log(this.bottomSliderAds);
    }

    public getAdSliderSettings(dataList: Array<any>, service: any, isServiceHomeScreen: boolean = false, settings: any = null): any {
        if (!settings) {
            settings = {
                slidesPerView: 1.05,
                initialSlide: 0,
                speed: 400,
                spaceBetween: 1,
                autoHeight: true
            };
        }

        if (this.stateService.device$ && this.stateService.device$.selectedService) {
            service = this.stateService.device$.selectedService;
        } else if (!service && this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
            service = this.stateService.device$.taeamServices[0];
        }

        if (dataList && dataList.length <= 1) {
            settings.slidesPerView = 1;
            settings.spaceBetween = 0;
            settings.centeredSlides = true;
        } else if (dataList && dataList.length > 1) {
            if (isServiceHomeScreen && dataList[0].serviceHomeAdsPerView) {
                settings.slidesPerView = dataList[0].serviceHomeAdsPerView;
            } else if (dataList[0].marketplaceAdsPerView) {
                settings.slidesPerView = dataList[0].marketplaceAdsPerView;
            } else if (service && service.adPerView) {
                settings.slidesPerView = service.adsPerView;
            }
        } else if (service && service.adPerView) {
            settings.slidesPerView = service.adsPerView;
        }

        return settings;
    }

    public hasServiceAds(index: any): boolean {
        this.debugCore();
        if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices[index] && this.stateService.device$.taeamServices[index].ads && this.stateService.device$.taeamServices[index].ads.length > 0) {
            return true;
        }

        return false;
    }

    protected setSocialSharingData(restaurant: any) {
        // this.textToShare = 'Hey, I am using Ta\'eam and its great app, you can find all your nearest halal restaurants and takeaways and place an order immediately for collection, delivery or dine-in. Give a try you would not be disappointed!';
        // this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
        // this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';

        if (this.wlaActive && restaurant) {
            this.textToShare = 'Hey, I am using ' + restaurant.name + ' and its great app, you can find everything. Place an order for now.';
            this.urlToShare = 'https://www.crazon.io/appMenu/' + restaurant.id;
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else if (restaurant && !this.isNullOrEmpty(restaurant.name)) {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can find anything from food, groceries to looking for gifts or electronic items or services used or new in your local area as well as your desired location. Simply your virtual high street on your finger tip!. Place an order for collection, delivery or if you are looking for dine-in just booked your table as well. I am ordering with ' + restaurant.name + '. Give a try you would not be disappointed!';
            this.urlToShare = 'https://www.crazon.io/appMenu/' + restaurant.id;
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can find anything from food, groceries to looking for gifts or electronic items or services of different kind in your local area. Simply everything on your finger tip!. Give a try you would not be disappointed!';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        }
    }

    protected initDefaultSocialSharingData() {
        if (this.wlaActive && this.stateService.props$ && this.stateService.props$.restaurant) {
            this.textToShare = 'Hey, I am using ' + this.stateService.props$.restaurant.name + ' and its great app, you can find everything. Place an order for now.';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can find anything from food, groceries to looking for gifts or electronic items or services used or new in your local area as well as your desired location. Simply your virtual high street on your finger tip!. Place an order for collection, delivery or if you are looking for dine-in just booked your table as well.';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        }
        this.textToShare = 'Hey, I am using Crazon and its great app, you can find anything from food, groceries to looking for gifts or electronic items or services used or new in your local area as well as your desired location. Simply your virtual high street on your finger tip!. Place an order for collection, delivery or if you are looking for dine-in just booked your table as well.';
        this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
        //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';

        this.canSocialShare = true;
        // Check if sharing via Twitter, Facebook and email is supported:
        this.socialSharing.canShareVia('twitter', this.textToShare, null, this.urlToShare).then(() => {
            // Sharing via twitter is possible
            this.canShareTwitter = true;
            this.canSocialShare = true;
        }).catch(() => {
            // Sharing via twitter is not possible
            this.canShareTwitter = false;
        });

        this.socialSharing.canShareVia('facebook', this.textToShare, this.imageToShare, this.urlToShare).then(() => {
            this.canShareFacebook = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareFacebook = false;
        });

        this.socialSharing.canShareVia('instagram', this.textToShare, this.imageToShare).then(() => {
            this.canShareInstagram = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareInstagram = false;
        });

        this.socialSharing.canShareViaEmail().then(() => {
            this.canShareEmail = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareEmail = false;
        });
    }

    protected setClassifiedAdSocialSharingData(classifiedAd: any) {
        // this.textToShare = 'Hey, I am using Ta\'eam and its great app, you can find all your nearest halal restaurants and takeaways and place an order immediately for collection, delivery or dine-in. Give a try you would not be disappointed!';
        // this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
        // this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';

        if (this.wlaActive && classifiedAd) {
            this.textToShare = 'Hey, I am using Crazon App and this Classified Ad (' + classifiedAd.title + ') seems interesting, worth to check it. You can find everything here in Crazon Marketplace and Classified Ads and much more.';
            this.urlToShare = 'https://www.crazon.io/appMenu/' + classifiedAd.adKey;
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else if (classifiedAd) {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can buy or sell any anything here with simple and easy few taps! Give a try you would not be disappointed!';
            this.urlToShare = 'https://www.crazon.io/appMenu/' + classifiedAd.adKey;
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can buy or sell any anything here with simple and easy few taps! Give a try you would not be disappointed!';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        }
    }

    protected initClassifiedAdDefaultSocialSharingData() {
        if (this.wlaActive && this.stateService.props$ && this.stateService.props$.classifiedAd) {
            this.textToShare = 'Hey, I am using Crazon App and this Classified Ad (' + this.stateService.props$.classifiedAd.title + ') seems interesting, worth to check it. You can find everything here in Crazon Marketplace and Classified Ads and much more.';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can buy or sell any anything here with simple and easy few taps! Give a try you would not be disappointed!';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        }
        this.textToShare = 'Hey, I am using Crazon and its great app, you can find anything from food to looking for gifts or electronic items or services used or new in your local area as well as your desired location. Simply your virtual high street on your finger tip!. Place an order for collection, delivery or if you are looking for dine-in just booked your table as well.';
        this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
        //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';

        this.canSocialShare = true;
        // Check if sharing via Twitter, Facebook and email is supported:
        this.socialSharing.canShareVia('twitter', this.textToShare, null, this.urlToShare).then(() => {
            // Sharing via twitter is possible
            this.canShareTwitter = true;
            this.canSocialShare = true;
        }).catch(() => {
            // Sharing via twitter is not possible
            this.canShareTwitter = false;
        });

        this.socialSharing.canShareVia('facebook', this.textToShare, this.imageToShare, this.urlToShare).then(() => {
            this.canShareFacebook = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareFacebook = false;
        });

        this.socialSharing.canShareVia('instagram', this.textToShare, this.imageToShare).then(() => {
            this.canShareInstagram = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareInstagram = false;
        });

        this.socialSharing.canShareViaEmail().then(() => {
            this.canShareEmail = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareEmail = false;
        });
    }

    protected setServiceResourceAdSocialSharingData(serviceResourceAd: any) {
        // this.textToShare = 'Hey, I am using Ta\'eam and its great app, you can find all your nearest halal restaurants and takeaways and place an order immediately for collection, delivery or dine-in. Give a try you would not be disappointed!';
        // this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
        // this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';

        if (this.wlaActive && serviceResourceAd) {
            this.textToShare = 'Hey, I am using Crazon App and this Ad (' + serviceResourceAd.title + ') seems interesting, worth to check it. You can find everything here in Crazon Superapp.';
            this.urlToShare = 'https://www.crazon.io/appMenu/' + serviceResourceAd.adKey;
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else if (serviceResourceAd) {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can find any service here with simple and easy few taps! Give a try you would not be disappointed!';
            this.urlToShare = 'https://www.crazon.io/appMenu/' + serviceResourceAd.adKey;
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can find any service here with simple and easy few taps! Give a try you would not be disappointed!';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        }
    }

    protected initServiceResourceAdDefaultSocialSharingData() {
        if (this.wlaActive && this.stateService.props$ && this.stateService.props$.serviceResourceAd) {
            this.textToShare = 'Hey, I am using Crazon App and this Ad (' + this.stateService.props$.serviceResourceAd.title + ') seems interesting, worth to check it. You can find everything here in Crazon Superapp.';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        } else {
            this.textToShare = 'Hey, I am using Crazon and its great app, you can find any service here with simple and easy few taps! Give a try you would not be disappointed!';
            this.urlToShare = 'https://www.crazon.io/appStore/bad3d58c-56e8-4eed-8e25-e69c0f80b677';
            //this.imageToShare = 'https://assets.crazon.io/mobile/app/taeam-app.gif';
        }

        this.canSocialShare = true;
        // Check if sharing via Twitter, Facebook and email is supported:
        this.socialSharing.canShareVia('twitter', this.textToShare, null, this.urlToShare).then(() => {
            // Sharing via twitter is possible
            this.canShareTwitter = true;
            this.canSocialShare = true;
        }).catch(() => {
            // Sharing via twitter is not possible
            this.canShareTwitter = false;
        });

        this.socialSharing.canShareVia('facebook', this.textToShare, this.imageToShare, this.urlToShare).then(() => {
            this.canShareFacebook = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareFacebook = false;
        });

        this.socialSharing.canShareVia('instagram', this.textToShare, this.imageToShare).then(() => {
            this.canShareInstagram = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareInstagram = false;
        });

        this.socialSharing.canShareViaEmail().then(() => {
            this.canShareEmail = true;
            this.canSocialShare = true;
        }).catch(() => {
            this.canShareEmail = false;
        });
    }

    public async showSocialSharingForTaeam($event) {
        this.initDefaultSocialSharingData();
        await this.showSharingOptions();
        $event.stopPropagation();
    }

    public async showSocialSharingForRestaurant($event, restaurant: any = null) {
        this.setSocialSharingData(restaurant);
        await this.showSharingOptions();
        $event.stopPropagation();
    }

    public async showClassifiedAdSocialSharing($event, classifiedAd: any = null) {
        this.setClassifiedAdSocialSharingData(classifiedAd);
        await this.showSharingOptions('Crazon App for Classified Ads - Start buying and selling now!');
        $event.stopPropagation();
    }

    public async showServiceResourceAdSocialSharing($event, serviceResourceAd: any = null) {
        this.setServiceResourceAdSocialSharingData(serviceResourceAd);
        await this.showSharingOptions('Crazon App for Classified Ads - Start buying and selling now!');
        $event.stopPropagation();
    }

    public async showSharingOptions(title: string = 'Crazon - An Online Marketplace for Everything - Order Now') {
        try
        {
            await this.capacitorShare(title);
            //await this.ionicSocialSharing();    
        } catch (error) {
            //this.displayError(error);
            //this.displayError("Social Sharing is only available on Mobile App. Please download the Taeam app from App Store");
        }
    }

    public async displayError(message: string) {
        this.hideLoader();
        const alert = await this.alertController.create({
            header: "Error",
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

    protected hideLoader(loader: HTMLIonLoadingElement = null) {
        if (loader) {
            loader.dismiss();
            loader = null;
        }

        if (this.loader) {
            this.loader.dismiss();
            this.loader = null;
        }

        if (this.stateService.props$.loading) {
            //alert(this.stateService.props$.loading.dismiss);
            if (this.stateService.props$.loading.dismiss) {
                this.stateService.props$.loading.dismiss();
            }
            this.stateService.setProps({ loading: null });
        }
    }

    public async ionicSocialSharing() {

        var buttons: Array<any> = [];

        // Creating a list of buttons depending on the apps available
        if (this.canShareTwitter) {
            buttons.push({
                text: 'Twitter',
                handler: () => this.socialSharing.shareViaTwitter(this.textToShare, null, this.urlToShare)
            });
        }

        if (this.canShareFacebook) {
            buttons.push({
                text: 'Facebook',
                handler: () => this.socialSharing.shareViaFacebook(this.textToShare, this.imageToShare, this.urlToShare)
            });
        }

        if (this.canShareInstagram) {
            buttons.push({
                text: 'Instagram',
                handler: () => this.socialSharing.shareViaInstagram(this.textToShare, this.imageToShare)
            });
        }

        if (this.canShareEmail) {
            buttons.push({
                text: 'Email',
                handler: () => this.socialSharing.shareViaEmail(this.textToShare, 'Crazon- An Online Marketplace for Everything', [''], null, null, [this.imageToShare])
            });
        }

        buttons.push({
            text: 'Cancel',
            role: 'cancel',
            handler: () => { }
        });

        const actionSheet = await this.actionSheetController.create({
            header: 'Share',
            buttons: buttons
        });

        await actionSheet.present();
    }

    protected debugCore(clear: boolean = false) {
        if (clear) {
            this.LogClear();
        }

        if (environment.production == false) {
            this.Log("****************");
            this.Log("Debug core data");
            this.Log("****************");
            this.Log("Device:")
            this.Log(this.stateService.device$);
            this.Log("State:")
            this.Log(this.stateService.state$);
            this.Log("Props:")
            this.Log(this.stateService.props$);
            this.Log("****************");
        }
    }

    protected getDeliveryMethod(deliveryMethod: string): string {
        if (!deliveryMethod) {
            return DeliveryMethods.Delivery;
        }

        if (deliveryMethod.toLowerCase() == DeliveryMethods.Delivery.toLowerCase()) {
            return DeliveryMethods.Delivery;
        } else if (deliveryMethod.toLowerCase() == DeliveryMethods.Collection.toLowerCase()) {
            return DeliveryMethods.Collection;
        } else if (deliveryMethod.toLowerCase() == DeliveryMethods.DineIn.toLowerCase() || deliveryMethod.toLowerCase() == "dine" || deliveryMethod.toLowerCase() == "dinein") {
            return DeliveryMethods.DineIn;
        } else {
            return DeliveryMethods.Delivery;
        }
    }

    public getSelectedDeliveryMethod(): string {
        if (this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            return this.getDeliveryMethod(this.stateService.device$.userPreferences.deliveryMethod);
        } 

        return this.getDeliveryMethod(DeliveryMethods.Delivery);
    }

    protected setDeliveryMethod(deliveryMethod: string) {
        var userPreferences = { userLocation: this.stateService.props$.userLocation, deliveryMethod: deliveryMethod };
        this.stateService.setProps({ userPreferences });
        this.stateService.setDevice({ userPreferences });
    }

    public getDate(date: any): any {
        var dateParts: any = {};
        if (date) {
            dateParts = date.split('T');
        }

        var dt = dateParts[0].split('-');
        return dt[2] + '/' + dt[1] + '/' + dt[0];
    }

    public getTime(date: any): any {
        var dateParts: any = {};
        if (date) {
            dateParts = date.split('T');
        }

        var dt = dateParts[1].split(':');
        return dt;
    }

    public getTodayDate(): any {
        return moment();
    }

    public getIsoTodayDate(): any {
        return moment().toISOString();
    }

    public getIsoDate(date: any): any {
        return moment(date).toISOString();
    }

    public getPreviousDayIsoDate(date: any): any {
        var result = moment(date).add(-1, 'days');
        return result.toISOString();
    }

    public getDays(date1, date2, inclusive: boolean = true): any {

        if (this.isNullOrEmpty(date1) || this.isNullOrEmpty(date2)) {
            return null;
        }

        var dt1 = moment(date1);
        var dt2 = moment(date2);
        var days = 0;

        if (dt1 > dt2) {
            days = dt1.diff(dt2, 'days');
        } else {
            days = dt2.diff(dt1, 'days');
        }

        if (inclusive) {
            days++; //Add a day in total to include start date as first day
        }

        return days;
    }

    protected Log(message: any, ...optionalParams: any[]) {
        if (environment.production == false) {
            if (optionalParams && optionalParams.length > 0) {
                console.log(message, optionalParams);
            } else {
                console.log(message);
            }
        }
    }

    protected LogCurrent(message: any, ...optionalParams: any[]) {
        this.Log(this.clone(message), optionalParams);
    }

    protected LogClear() {
        if (environment.production == false) {
            console.clear();
        }
    }


    protected Trace(message: any) {
        if (environment.production == true) {
            this.monitoringService.logTrace(message);
        }

        if (environment.production == false) {
            console.log(message);
        }
    }

    protected delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public clone(obj: any): any {
        var cloneObj = {};

        if (obj) {
            //for (let key of Object.keys(obj)) {
            //    cloneObj[key] = obj[key];
            //}
            cloneObj = JSON.parse(JSON.stringify(obj));
            this.Log(cloneObj);
        }

        return cloneObj;
    }

    public dateClone(obj: any): any {
        if (obj) {
            return moment(obj);
        }

        return obj;
    }

    public getJson(data: any): string {
        if (data) {
            return JSON.stringify(data);
        }

        return null;
    }

    public reportConversion() {
        gtag_report_conversion(window.location.href);
    }

    public comparer(n1, n2) {
        if (n1 > n2) {
            return 1;
        }

        if (n1 < n2) {
            return -1;
        }

        return 0;
    }

    public dateComparer(n1, n2) {

        var dt1 = moment(n1);
        var dt2 = moment(n2);

        if (dt1 > dt2) {
            return 1;
        }

        if (dt1 < dt2) {
            return -1;
        }

        return 0;
    }

    public adComparer(n1, n2) {
        if (n1.sliderAdOrder > n2.sliderAdOrder) {
            return 1;
        }

        if (n1.sliderAdOrder < n2.sliderAdOrder) {
            return -1;
        }

        return 0;
    }

    public weekDayNumberComparer(n1, n2) {
        if (n1.dayNumber > n2.dayNumber) {
            return 1;
        }

        if (n1.dayNumber < n2.dayNumber) {
            return -1;
        }

        return 0;
    }

    public getString(val: any): string {
        if (this.isNullOrEmpty(val)) {
            return "";
        }

        return val;
    }

    protected getId(): string {
        var id = Guid.create().toString();
        return id; //id.replace("-", "");
    }

    public getFileExtension(name: string) {
        if (name) {
            var arr = name.split('.');
            return "." + arr[arr.length - 1];
        }

        return "";
    }

    public isEven(n):boolean {
        return n % 2 == 0;
    }

    public isOdd(n): boolean {
        return Math.abs(n % 2) == 1;
    }

    public getRandomNumber(list: Array<any> = []): any {
        if (list && list.length > 0) {
            var result = list.sort(() => Math.random() - 0.5);
            return result[0];
        }

        return 0;
    }

    public contains(source: string, value: string): boolean {
        if (!this.isNullOrEmpty(source) && !this.isNullOrEmpty(value)) {
            return source.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        }

        return false;
    }

    protected getStorageKey(key: string): string {
        if (environment.wlaActive) {
            return key + '_' + environment.appVariant;
        }

        return key;
    }

    public showName(): boolean {
        if (this.wlaActive == false) {
            return true;
        } else if (this.wlaActive && this.wlaShowName) {
            return true;
        } 

        return false;
    }

    public showAddress(): boolean {
        if (this.wlaActive == false) {
            return true;
        } else if (this.wlaActive && this.wlaShowAddress) {
            return true;
        }

        return false;
    }

    public hasProperty(obj: any, propertyName: string): boolean {
        if (obj && !this.isNullOrEmpty(propertyName)) {
            if (obj[propertyName] != null && obj[propertyName] != undefined) {
                return true;
            }
        }

        return false;
    }

    public getRestaurant(restaurant: any): any {
        if (!restaurant) {
            restaurant = this.stateService.props$.restaurant;
        }

        return restaurant;
    }

    public checkIfLocationPreferenceToShow(): boolean {
        if (this.stateService.device$ && this.stateService.device$.rememberMyLocation == true) {
            if (this.stateService.device$.userPreferences) {
                this.stateService.setProps({ userPreferences: this.stateService.device$.userPreferences });
                this.stateService.setProps({ userLocation: this.stateService.device$.userPreferences.userLocation });

                this.stateService.setProps({ defaultCountry: this.stateService.device$.userPreferences.userLocation.countryName });

                if (!this.isNullOrEmpty(this.stateService.device$.userPreferences.userLocation.countryName)) {
                    this.stateService.setProps({ defaultCountry: this.stateService.device$.userPreferences.userLocation.countryName });
                } else {
                    var addr = this.stateService.device$.userPreferences.userLocation.formatted_address.toLowerCase();
                    var countryName = this.getCountryName(addr);
                    this.stateService.setProps({ defaultCountry: countryName });
                }
            }

            if (this.isNullOrEmpty(this.stateService.device$.deliveryMethod)) {
                this.stateService.setDevice({ deliveryMethod: DeliveryMethods.Delivery });
            }

            this.setDeliveryMethod(this.stateService.device$.deliveryMethod);
            this.stateService.setProps({ marketingAds: null });
            this.stateService.setDevice({ forceAskForLocation: null });

            return false;
        }

        return true;
    }

    public loadMyLocationPreferences() {
        if (this.stateService.device$ && this.stateService.device$.rememberMyLocation == true) {
            this.rememberMyLocation = true;
        } else {
            this.rememberMyLocation = false;
        }
    }

    public setMyLocationPreferences($event: any) {
        var rememberMyLocation: boolean = false;
        if ($event.detail.checked) {
            rememberMyLocation = true;
        }

        this.stateService.setDevice({ rememberMyLocation: rememberMyLocation })
    }

    protected getLocalTimezone(): string {
        var timezone = moment.tz.guess();
        if (this.isNullOrEmpty(timezone)) {
            return "";
        }

        return timezone;
    }

    public getLocalCountry(): string {
        var localTimeZone = this.getLocalTimezone();
        var localZone: any = moment.tz.zone(localTimeZone);
        var countries = localZone.countries();
        if (countries && countries.length > 0) {
            return countries[0];
        } else {
            return null;
        }
    }

    public getUserIp(): string {
        if (window) {
            var ip: string = window["userIp"];
            return this.toHex(ip);
        } else {
            return "";
        }
    }

    public toHex(str): string {
        var result = '';
        for (var i = 0; i < str.length; i++) {
            result += str.charCodeAt(i).toString(16);
        }
        return result;
    }

    public hexToUtf8(hex) {
        return decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'));
    }

    public toCapitalise(text: string): string {

        if (this.isNullOrEmpty(text)) return null;

        text = text.toLowerCase();

        const arr = text.split(" ");

        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

        }

        return arr.join(" ");
    }

    public toLower(text: string, emptyTextIfNull: string = "", postfixText: string = ""): string {

        if (this.isNullOrEmpty(text)) return emptyTextIfNull;
        var result: string = text.toLowerCase() + " " + postfixText;
        return result.trim();
    }

    public getCountryName(countryName: any) {
        if (countryName) {
            var addr = countryName.toLowerCase();
            if (addr.indexOf('uk') > -1 || addr.indexOf('gb') > -1 || addr.indexOf('united kingdom') > -1 || addr.indexOf('britain') > -1) {
                return "United Kingdom";
            } else if (addr.indexOf('pakistan') > -1 || addr.indexOf('pk') > -1) {
                return "Pakistan";
            } else if (addr.indexOf('united states') > -1 || addr.indexOf('usa') > -1) {
                return "United States";
            } else if (addr.indexOf('canada') > -1 || addr.indexOf('gb') > -1 || addr.indexOf('britain') > -1) {
                return "Canada";
            } else {
                return "United Kingdom";
            }
        } else {
            return "United Kingdom";
        }
    }

    public isSame(value1: string, value2: string, allowMultiple: boolean = false): boolean {
        if (!allowMultiple) {
            if (!this.isNullOrEmpty(value1) && !this.isNullOrEmpty(value2)) {
                value1 = value1.toLowerCase();
                value2 = value2.toLowerCase();
            }

            return value1 === value2;
        } else {
            if (!this.isNullOrEmpty(value1) && !this.isNullOrEmpty(value2)) {
                return this.contains(value1, value2);
            }
        }

        return false;
    }

    public isSameIndex(index1: number, index2: number): boolean {
        return index1 == index2;
    }

    public isSelectedIndex(index1: number): boolean {
        return index1 == this.selectedIndex;
    }

    public setValue(obj: any, field: string, value: any, allowMultiple: boolean, maxOptionsAllowed: number) {
        if (!obj) {
            return;
        }

        if (allowMultiple) {
            if (this.contains(obj[field], value)) {
                obj[field] = obj[field].replace(value + ", ", "");
                obj[field] = obj[field].replace(value + ",", "");
                obj[field] = obj[field].replace(value, "");
            } else {
                if (maxOptionsAllowed > 0) {
                    var length = this.csvLength(obj[field]);
                    if (length < maxOptionsAllowed) {
                        obj[field] = this.addCsv(obj[field], value);
                    } else {
                        this.displayError("Maximum options allowed are " + maxOptionsAllowed + ". Un-select if you would like to change your options");
                    }
                    
                } else {
                    obj[field] = this.addCsv(obj[field], value);
                }
            }
        } else {
            obj[field] = value;
        }        
    }

    public addCsv(source: string, value: string): string {
        if (this.isNullOrEmpty(value)) {
            return source;
        }

        if (this.isNullOrEmpty(source)) {
            source = value;
        } else {
            source = source + ", " + value;
        }

        return source;
    }

    public csvLength(source: string): number {
        if (this.isNullOrEmpty(source)) {
            return 0;
        }

        return source.split(',').length;
    }

    public scrambleText(source: string, howManyToShow: number = 3, charToDisplay: string = '*'): string {
        if (this.isNullOrEmpty(source)) {
            return source;
        }

        var length = source.length;
        if (length > howManyToShow) {
            return source.substring(0, howManyToShow) + this.getRepeatString(charToDisplay, length - howManyToShow);
        } else {
            return source;
        }        
    }

    public getRepeatString(textToRepeat: string, times: number, minTimes = 5) {
        var result = "";
        if (times < minTimes) {
            times = minTimes;
        }

        for (var i = 0; i < times; i++) {
            result += textToRepeat;
        }

        return result;
    }

    protected updateLocalDataSources(dataSource: string, csvValues: string) {
        this.debugCore();
        if (this.stateService && this.stateService.props$) {
            if (!this.stateService.props$.localDataSources) {
                this.stateService.setProps({ localDataSources: [] });
            }

            var found = false;
            for (var i = 0; i < this.stateService.props$.localDataSources.length; i++) {
                var localDataSource = this.stateService.props$.localDataSources[i];
                if (localDataSource.dataSource == dataSource) {
                    localDataSource.csvValues = csvValues;
                    found = true;
                    break;
                }
            }

            if (!found) {
                localDataSource = { dataSource, csvValues };
                var localDataSources = this.stateService.props$.localDataSources;
                localDataSources.push(localDataSource);
                this.stateService.setProps({ localDataSources: localDataSources })
            }
        }
    }

    protected getLocalDataSources(dataSource: string): string {
        this.debugCore();
        if (this.stateService && this.stateService.props$) {
            if (!this.stateService.props$.localDataSources) {
                this.stateService.setProps({ localDataSources: [] });
            }

            for (var i = 0; i < this.stateService.props$.localDataSources.length; i++) {
                var localDataSource = this.stateService.props$.localDataSources[i];
                if (localDataSource.dataSource == dataSource) {
                    return localDataSource.csvValues;
                }
            }

            return null;
        }
    }

    public hasData(data: any): boolean {
        if (data && data.length > 0) {
            return true;
        }

        return false;
    }

    public getAbsoluteYears(months: any): any {
        var years = Math.floor(months / 12);
        return years;
    }

    public getYears(months: any): any {
        var years = (months / 12).toFixed(1);
        return years;
    }

    public getMonths(years: any): any {
        var months = years * 12;
        return months;
    }

    public getAbsoluteNumberInThousands(value: any): any {
        var result = Math.floor(value / 1000).toFixed(1);
        return result;
    }

    public getAllWeekDays(): any {
        var data = [];
        data.push({ dayNumber: 1, shortName: 'Mon', fullName: 'Monday' });
        data.push({ dayNumber: 2, shortName: 'Tue', fullName: 'Tuesday' });
        data.push({ dayNumber: 3, shortName: 'Wed', fullName: 'Wednesday' });
        data.push({ dayNumber: 4, shortName: 'Thu', fullName: 'Thursday' });
        data.push({ dayNumber: 5, shortName: 'Fri', fullName: 'Friday' });
        data.push({ dayNumber: 6, shortName: 'Sat', fullName: 'Saturday' });
        data.push({ dayNumber: 7, shortName: 'Sun', fullName: 'Sunday' });
        return data;
    }

    public getDayObj(dayName: string): any {
        if (this.isNullOrEmpty(dayName)) {
            return null;
        }

        if (dayName == "Mon") {
            return { dayNumber: 1, shortName: 'Mon', fullName: 'Monday' };
        } else if (dayName == "Tue") {
            return { dayNumber: 2, shortName: 'Tue', fullName: 'Tuesday' };
        } else if (dayName == "Wed") {
            return { dayNumber: 3, shortName: 'Wed', fullName: 'Wednesday' };
        } else if (dayName == "Thu") {
            return { dayNumber: 2, shortName: 'Thu', fullName: 'Thursday' };
        } else if (dayName == "Fri") {
            return { dayNumber: 2, shortName: 'Fri', fullName: 'Friday' };
        } else if (dayName == "Sat") {
            return { dayNumber: 2, shortName: 'Sat', fullName: 'Saturday' };
        } else if (dayName == "Sun") {
            return { dayNumber: 2, shortName: 'Sun', fullName: 'Sunday' };
        }

        return null;
    }

    public listContains(dataList: any, fieldName: string, value: any): boolean {
        if (!this.hasData(dataList) || this.isNullOrEmpty(fieldName) || this.isNullOrEmpty(value)) {
            return false;
        }

        var temp = dataList.filter((x) => x[fieldName] == value);
        if (temp && temp.length > 0) {
            return true;
        }

        return false;
    }

    public getDateOnly(value: any): moment.Moment {
        var date = moment(value);
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        return date;
    }

    public isSameDate(date1: any, date2: any): boolean {
        var dt1 = this.getDateOnly(date1);
        var dt2 = this.getDateOnly(date2);
        //alert(dt1);
        //alert(dt2);
        return dt1.isSame(dt2);
    }

    public mergeString(value1: string, value2: string, value3: string = null, value4: string = null): string {
        var result = null;

        result = this.addCsv(value1, value2);
        result = this.addCsv(result, value3);
        result = this.addCsv(result, value4);

        return result;
    }

    public hasFeature(csvSource: string, feature: string): boolean {
        if (!this.isNullOrEmpty(csvSource) && !this.isNullOrEmpty(feature)) {
            return this.contains(csvSource, feature);
        }

        return false;
    }

    public getTimeOnlyIfValid(date: any): string {
        if (date) {
            var dt = moment(date);
            if (dt.isValid() && dt.year() != 1600) {
                return dt.format("hh:mm A");
            }
        }

        return null;
    }

    public isWithinMinutes(date: any, minutes: number = 15): boolean {
        if (date) {
            var dt = moment(date);
            var end = moment();
            var duration = moment.duration(dt.diff(end));
            var diffMinutes = duration.asMinutes();
            //alert(diffMinutes);
            if (diffMinutes <= minutes) {
                return true;
            } 
        }

        return false;
    }

    public hasId(obj: any): boolean {
        if (obj) {
            return !this.isNullOrEmpty(obj.id);
        }

        return false;
    }

    public setFlags(flagName: string, flagValue) {
        if (flagName && flagValue) {
            var flags = this.stateService.device$.flags;

            if (!flags) {
                flags = {};
            }

            flags[flagName] = flagValue;

            this.stateService.setDevice({ flags: flags });
        }
    }

    public hasFlag(flagName: string, value: any = null): boolean {
        var flags = this.stateService.device$.flags;

        //if (flags) {
        //    if (!this.isNullOrEmpty(value)) {
        //        return this.isSame(flags[flagName], value);
        //    } else {
        //        return !this.isNullOrEmpty(flags[flagName]);
        //    }
        //}

        if (flags) {
            if (flags[flagName] == value) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }

    public getFlagValue(flagName: string): any {
        var flags = this.stateService.device$.flags;

        if (flags || !this.isNullOrEmpty(flagName)) {
            return flags[flagName];
        }

        return null;
    }

    public getServiceFlagName(flagName: string): string {
        var serviceName = this.getServiceName();
        if (!this.isNullOrEmpty(serviceName) && !this.isNullOrEmpty(flagName)) {
            return serviceName + "-" + flagName;
        }

        return flagName;
    }

    public setServiceFlags(flagName: string, flagValue) {
        this.setFlags(this.getServiceFlagName(flagName), flagValue);
    }

    public hasServiceFlag(flagName: string, value: any = null): boolean {
        return this.hasFlag(this.getServiceFlagName(flagName), value);
    }

    public getServiceFlagValue(flagName: string): any {
        return this.getFlagValue(this.getServiceFlagName(flagName));
    }

    public getServiceName(): string {
        if (this.stateService.device$ && this.stateService.device$.serviceName) {
            return this.stateService.device$.selectedService.serviceName;
        }

        return null;
    }

    public isServiceAppVersionCompatible(service: any): boolean {
        if (service && !this.isNullOrEmpty(service.serviceRelease)) {
            return service.serviceRelease <= environment.release;
        } else if (this.stateService.device$ && this.stateService.device$.selectedService && !this.isNullOrEmpty(this.stateService.device$.selectedService.serviceRelease)) {
            return this.stateService.device$.selectedService.serviceRelease <= environment.release;
        }

        return true; //default is valid api version to avoid conflicts
    }

    public combine(val1: any, val2: any, ): string {
        var id = "";

        if (val1) {
            id = id + val1.toString()
        }

        if (val2) {
            id = id + "-" + val2.toString()
        }

        return id;
    }
}