import { Component, ChangeDetectorRef, Input, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ObjectBase } from 'src/app/shared/object.base';
import { NavController, ModalController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationService } from 'src/app/services/location.service';
import { UserService } from '../../../services/user.services';
import { SupportService } from '../../../services/support.service';

declare var google: any;
declare var $: any;

@Component({
    selector: 'app-custom-address-locator',
    templateUrl: 'custom.address.locator.html',
    styleUrls: ['custom.address.locator.scss']
})
export class CustomAddressLocator extends ObjectBase implements OnInit, OnDestroy {

    @ViewChild('Map', { static: false }) mapElement: ElementRef;
    @Input() goToCreateAd;
    @Input() title = "Your Location";
    @Input() titleDescription = "Please enter your address in the below text box and press enter key to search";

    map: any;
    mapOptions: any;
    location = { lat: null, lng: null };
    markerOptions: any = { position: null, map: null, title: null };
    marker: any;
    public address: string;
    private geo: any;
    public tempDeliveryMethod: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        private geolocation: Geolocation,
        private locationService: LocationService,
        private supportService: SupportService,
        public modalController: ModalController,
        private sanitizer: DomSanitizer) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.geo = this.stateService.props$.geo;
    }
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }

    async ionViewWillEnter() {
        await this.showLoader();
        this.load();
        await this.initMap();
    }

    ionViewDidEnter() {
    }

    ngOnDestroy() {
    }

    showlocation() {
        if ("geolocation" in navigator) {
            /* geolocation is available */
            // One-shot position request.
            navigator.geolocation.getCurrentPosition(this.callback, this.error);
        } else {
            /* geolocation IS NOT available */
            console.warn("geolocation IS NOT available");
        }
    }

    error(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
    }

    callback(position) {

        var lat = position.coords.latitude;
        var lon = position.coords.longitude;

        //this.address = lat + ", " + lon;

        var latLong = new google.maps.LatLng(lat, lon);
        this.map.setZoom(16);
        this.map.setCenter(latLong);
    }

    async initMap() {
        this.Log("initMap is called");

        try {
            this.geo = await this.geolocation.getCurrentPosition();
        } catch (ex) {
            this.Log("Exception found during get current position");
            this.hideLoader();
        }

        /*Map options*/
        if (this.location) {
            this.mapOptions = {
                center: this.location,
                zoom: 16,
                mapTypeControl: false,
                disableDefaultUI: true
            };
        } else {
            this.mapOptions = {
                zoom: 16,
                mapTypeControl: false,
                disableDefaultUI: true
            };
        }

        setTimeout(async () => {
            this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);

            google.maps.event.addListener(this.map, 'dragend', async () => {
                this.Log("dragend event is fired");
                var customLocation = { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() };
                this.stateService.setProps({ customLocation: customLocation });
                await this.locationService.saveCustomLocationByLatLong(this.map.getCenter().lat(), this.map.getCenter().lng());
                if (this.stateService.props$.customGeoLocation) {
                    this.address = this.stateService.props$.customGeoLocation.address; // + " - " + this.userLocation.lat + ", " + this.userLocation.lng;
                }
            });

            this.hideLoader();
        }, 500);
    }

    async search(address: string = null) {
        this.Log("search is called");
        this.debugCore();

        if (this.isNullOrEmpty(address)) {
            address = this.address;
        }

        await this.locationService.saveCustomLocationByAddress(address);

        this.callback(this.stateService.props$.customGeo);

        if (this.stateService.props$.customLocation) {
            this.location.lat = this.stateService.props$.customLocation.latitude;
            this.location.lng = this.stateService.props$.customLocation.longitude;
            this.address = this.stateService.props$.customLocation.address;
            if (this.goToCreateAd) {
                this.goToNext('classified-ads/add-ad');
            }
        }

        this.Log("after search is called");
        this.debugCore();
    }

    async setMapDefaultLocation(location: any = null) {
        if (this.stateService.props$ && this.stateService.props$.customGeo && this.stateService.props$.customGeo.coords) {
            this.location.lat = this.stateService.props$.customGeo.coords.latitude;
            this.location.lng = this.stateService.props$.customGeo.coords.longitude;
        } else if (this.stateService.device$ && this.stateService.device$.geo && this.stateService.device$.geo.coords) {
            this.location.lat = this.stateService.device$.geo.coords.latitude;
            this.location.lng = this.stateService.device$.geo.coords.longitude;
        } else {
            this.location = { lat: 51.5287718, lng: -0.2416815 };
        }

        this.mapOptions = {
            center: this.location,
            zoom: 16,
            mapTypeControl: false,
            disableDefaultUI: true
        };

        setTimeout(() => {
            this.Log("Setting up map object");
            this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapOptions);

            google.maps.event.addListener(this.map, 'dragend', async () => {
                this.Log("dragend event is fired");
                var customLocation = { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() };
                this.stateService.setProps({ customLocation: customLocation });
            });

            this.hideLoader();
        }, 500);
    }

    getGeolocation() {
        if (this.geo && this.geo.coords) {
            return { longitude: this.geo.coords.longitude, latitude: this.geo.coords.latitude }
        }
    }

    load() {
        this.Log("Load called");

        if (this.stateService.props$ && this.stateService.props$.customGeo && this.stateService.props$.customGeo.coords) {
            this.location.lat = this.stateService.props$.customGeo.coords.latitude;
            this.location.lng = this.stateService.props$.customGeo.coords.longitude;
        } else if (this.stateService.device$ && this.stateService.device$.geo && this.stateService.device$.geo.coords) {
            this.location.lat = this.stateService.device$.geo.coords.latitude;
            this.location.lng = this.stateService.device$.geo.coords.longitude;
        } else {
            this.location = { lat: 51.5287718, lng: -0.2416815 };
        }
    }

    goBack() {
        this.goBack();
    }

    dismiss() {
        this.hideLoader();
        this.modalController.dismiss({
            'dismissed': true
        });

        if (this.stateService.props$.customLocation) {
            this.location.lat = this.stateService.props$.customLocation.latitude;
            this.location.lng = this.stateService.props$.customLocation.longitude;
            this.address = this.stateService.props$.customLocation.address;
            if (this.goToCreateAd) {
                var ad: any = this.stateService.props$.classifiedAd;

                if (!ad) {
                    ad = {};
                }

                if (this.stateService.props$ && this.stateService.props$.classifiedAd) {
                    ad.adLocationAddress = this.stateService.props$.customLocation.address;
                    ad.address = this.stateService.props$.customLocation.first_line;
                    ad.postcode = this.stateService.props$.customLocation.postcode;
                    ad.town = this.stateService.props$.customLocation.city;
                    ad.country = this.stateService.props$.customLocation.countryName;
                }

                if (!ad.adTypesData) {
                    ad.adTypesData = this.stateService.props$.categoryFields;
                } 

                this.stateService.setProps({ classifiedAd: ad });
                this.goToNext('classified-ads/add-ad');
            }
        }
    }
}