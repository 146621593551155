<div class="{{getCardViewCss(false, 'ad-card')}}">
    <div class="restaurant-ad">
        <video #adPlayer playsinline preload="auto" [ad]="ad" (click)="videoAdClicked(ad, true, checkForServiveAdLink)" (ended)="videoEnded()" [poster]="getString(ad.videoPosterUrl)">
            <source [src]="ad.mobileVideoUrl" [type]="ad.mobileVideoType" />
        </video>
        <div class="video-icons-bottom-right playPause" (click)="pauseVideo(adPlayer)" *ngIf="adPlayer && !adPlayer.paused">
            <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right play" (click)="playVideo(adPlayer)" *ngIf="adPlayer && adPlayer.paused">
            <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right speaker" (click)="muteAudio(adPlayer)" *ngIf="adPlayer && !adPlayer.muted">
            <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
        </div>
        <div class="video-icons-bottom-right speaker" (click)="unMuteAudio(adPlayer)" *ngIf="adPlayer && adPlayer.muted">
            <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
        </div>
    </div>
</div>