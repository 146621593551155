<div class="provider-view-root">
    <div [ngClass]="applyPadding ? 'product-card with-padding' : 'product-card'" *ngIf="isOneImageOnly()">
        <div class="product-cover">
            <img [src]="getImageUrl(this.assets[0].name)" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/taeam-restaurant-loading.jpg'" alt="">
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showSocialSharingForRestaurant($event, restaurant)"></ion-icon>
            </div>
        </div>
    </div>
    <div [ngClass]="applyPadding ? 'product-card with-padding' : 'product-card'" *ngIf="isOneVideoOnly()">
        <div class="product-cover">            
            <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrl(this.assets, 0)">
                <source [src]="getImageUrl(this.assets[0].name)" type="video/mp4" />
            </video>
            <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showSocialSharingForRestaurant($event, restaurant)"></ion-icon>
            </div>
        </div>
    </div>
    <div [ngClass]="applyPadding ? 'product-card with-padding' : 'product-card'" *ngIf="areMultipleImagesOrVideos()">
        <div class="product-cover">
            <ion-slides #slider [options]="slideOptions" (ionSlideTransitionEnd)="slideChange(slider)">
                <ion-slide #slide *ngFor="let asset of this.assets; let aindex = index">
                    <img [src]="getImageUrl(asset.name)" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/taeam-restaurant-loading.jpg'" alt="" *ngIf="!this.isVideo(asset.name)">
                    <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrl(this.assets, aindex)" *ngIf="this.isVideo(asset.name)">
                        <source [src]="getImageUrl(asset.name)" type="video/mp4" />
                    </video>
                    <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="this.isVideo(asset.name) && adPlayer && !adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="this.isVideo(asset.name) && adPlayer && adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="this.isVideo(asset.name) && adPlayer && !adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="this.isVideo(asset.name) && adPlayer && adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                        <ion-icon name="md-share" (click)="showSocialSharingForRestaurant($event, restaurant)"></ion-icon>
                    </div>
                </ion-slide>
            </ion-slides>
        </div>
    </div>
</div>
