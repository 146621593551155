import { AfterContentInit, AfterViewInit, ChangeDetectorRef, ElementRef, Injectable, QueryList, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import { CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { Plugins } from '@capacitor/core';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ActionSheetController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Base64 } from 'js-base64';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { CalendarService } from '../services/calendar.service';
import { DeviceService } from '../services/device.service';
import { FavouritesService } from '../services/favourites.service';
import { MonitoringService } from '../services/monitoring.service';
import { PersonlisationService } from '../services/personalisation.service';
import { RecentlyViewedService } from '../services/recently.viewed.service';
import { RemoteNotificationService } from '../services/remote.notification.service';
import { StateService } from '../services/state.service';
import { UiStateService } from '../services/ui.state.service';
import { UserService } from '../services/user.services';
import { Common } from './common';
import { MarketplaceThemes } from './models/constants.model';
import { DisplayStyles } from './models/shared.model';
import { ObjectCore } from './object.core';
const { Camera } = Plugins;

// ionic cordova plugin add cordova-plugin-fcm-with-dependecy-updated
// npm install @ionic-native/fcm

@Injectable()
export class ObjectBase extends ObjectCore implements AfterViewInit, AfterContentInit {

    protected monitoringService: MonitoringService;
    protected uiStateSubscription: Subscription;
    public isFullScreen: boolean;
    public viewStateId: number = 1;
    public show2ndLevelCaptcha: boolean = false;
    public vaidated2ndLevelCaptcha: boolean = false;
    protected favouriteService: FavouritesService;
    protected recentlyViewedService: RecentlyViewedService;
    public showNewsModel: boolean = false;
    public newsData: any;
    state$: any;
    version: string = environment.version;
    showDebug: boolean = environment.showDebug;
    captchaKey: string = environment.captchaKey;
    public resourcesBaseUrl: string = environment.resourcesBaseUrl;
    public marketingResourcesBaseUrl: string = environment.marketingResourcesBaseUrl;
    public marketplaceResourcesBaseUrl: string = environment.marketplaceResourcesBaseUrl;
    public classifiedAdsResourcesBaseUrl: string = environment.classifiedAdsResourcesBaseUrl;
    public isCardView: boolean = environment.isCardView;
    captchaToken: any;
    errorOccurred: boolean;
    message: string;
    showWaitMessage: boolean = false;
    canContinue: boolean = true;
    public currentRoute: string;
    //alertController: AlertController;
    public stateService: StateService;
    localDeviceService: DeviceService;
    protected loadingController: LoadingController;
    protected personaliseService: PersonlisationService;

    navOptions = { animation: 'ios-transition' };
    protected inAppBrowser: InAppBrowser;
    protected modalController: ModalController;
    private appRate: AppRate;
    protected domSanit: DomSanitizer;
    public safeUrl: any;
    public browser: any;
    protected callNumber: CallNumber;

    pageTemplateId: string = "list" //list, card
    public homePageDisplayStyle: string = environment.homePageDisplayStyle;
    protected calendarService: CalendarService;
    public showLoadingMetadata: boolean;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected renderer: Renderer2 = null,
        protected actionSheetCtrl: ActionSheetController = null) {
        super();
        // this.alertController = new AlertController();
        this.uiStateService = UiStateService.Instance;
        this.stateService = StateService.Instance;
        this.localDeviceService = DeviceService.Instance;
        this.loadingController = new LoadingController;
        this.monitoringService = MonitoringService.Instance;
        this.personaliseService = PersonlisationService.Instance;
        this.inAppBrowser = Common.InjectorInstance.get<InAppBrowser>(InAppBrowser);
        this.domSanit = Common.InjectorInstance.get<DomSanitizer>(DomSanitizer);
        this.modalController = Common.InjectorInstance.get<ModalController>(ModalController);
        this.favouriteService = FavouritesService.Instance;
        this.recentlyViewedService = RecentlyViewedService.Instance;
        this.appRate = Common.InjectorInstance.get<AppRate>(AppRate);
        this.callNumber = Common.InjectorInstance.get<CallNumber>(CallNumber);
        this.actionSheetCtrl = Common.InjectorInstance.get<ActionSheetController>(ActionSheetController);
        this.calendarService = CalendarService.Instance;

        //this.state$ = this.stateService.getState();    
        this.loadState();
        this.uiStateSubscription = this.uiStateService.UiState
            .subscribe(() => {
                this.isFullScreen = this.uiStateService.getfullScreenStatus();
            });

        if (!(this.stateService.props$ && this.stateService.props$.token)) {
            this.getToken();
        }

        this.setCurrentUrl();
    }

    protected setCurrentUrl() {
        this.router.events.subscribe(event => {
            if (!(event instanceof NavigationEnd)) {
                if (this.isNullOrEmpty(this.currentRoute)) {
                    this.currentRoute = this.router.url;
                }
                return;
            }
            this.currentRoute = event.url;
            if (this.isNullOrEmpty(this.currentRoute)) {
                this.currentRoute = this.router.url;
            }
        });
    }

    protected addTag(campaign, event, data, pixelName) {
        this.personaliseService.addTag(campaign, event, data, pixelName);
    }

    protected getTagUrl(campaign, event, data, pixelName) {
        return this.personaliseService.getTagUrl(campaign, event, data, pixelName);
    }

    protected getSafeUrl(url: string): any {
        return this.domSanit.bypassSecurityTrustResourceUrl(url);
    }

    protected async getToken() {
        var result = await this.userService.webLogin().toPromise();
        if (result) {
            this.Log("New token has been generated");
            this.Log(result);
            this.stateService.setProps({ user: result, token: result.token, loginTime: result.loggedInAt });
            this.stateService.setDevice({ user: result.userToken, token: result.token, loginTime: result.loggedInAt });
            //if (this.stateService.props$.returnUrl) {
            //    this.goToNext(this.stateService.props$.returnUrl)
            //} else {
            //    this.goToNext("/tabs/home")
            //}
        }
    }

    protected getTagUrlV2(campaign, event, data, pixelName) {
        var dataStr = JSON.stringify(data).replace(" ", "").replace("\n", "");
        var url = this.personaliseService.getImpressionUrl(campaign, event, dataStr, null);
        return url;
    }

    protected async showLoader(show: boolean = true, message: string = "Please wait..."): Promise<HTMLIonLoadingElement> {
        //if (!show) return null;

        //if (this.loader) {
        //    this.stateService.setProps({ loading: this.loader });
        //    return this.loader;
        //}

        //if (this.stateService && this.stateService.props$ && this.stateService.props$.loading) {
        //    this.loader = this.stateService.props$.loading;
        //    return this.stateService.props$.loading;
        //}

        //const loading = await this.loadingController.create({
        //    message: message,
        //    spinner: 'crescent'
        //});

        //this.loader = loading;
        //this.stateService.setProps({ loading: loading });

        //await loading.present();

        //return loading;


        if (this.stateService && this.stateService.props$ && this.stateService.props$.loading) {
            return this.stateService.props$.loading;
        }

        const loading = await this.loadingController.create({
            message: message,
            spinner: 'crescent'
        });

        this.stateService.setProps({ loading: loading });
        loading.present();
        //loading.present().then(x => {
        //    this.debugCore(true);
        //    this.Log(x);
        //});
        return this.stateService.props$.loading
    }

    //protected showLoader() {
    //    //const loading = await this.loadingController.create({
    //    //    spinner: 'dots',
    //    //    message: 'Please wait...'
    //    //});
    //    //await this.loader.present();
    //    this.loadingController.create({
    //        spinner: 'dots',
    //        message: 'Please wait...'
    //    }).then((result) => {
    //        this.loader = result;
    //        this.Log('loading controller created');
    //        this.loader.present().finally(() => { });
    //        });
    //}

    //protected hideLoader() {
    //    if (this.loader) {
    //        this.loader.dismiss();
    //    }
    //}

    public getService(): any {
        //Commenting below if block because when in classified ad user is returning from payment page it is loading locally and taeamservices is null in device storage-
        //not sure why we need it so check carefuly if we need it
        //if (!this.wlaActive && !this.isSelectedServiceValid()) {
        //    this.stateService.setDevice({ selectedService: null });
        //}

        if (this.stateService.device$ && this.stateService.device$.selectedService) {
            return this.stateService.device$.selectedService;
        } else if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
            this.stateService.setDevice({ selectedService: this.stateService.device$.taeamServices[0] });
            return this.stateService.device$.selectedService;
        } else if (this.stateService.device$ && this.stateService.device$.defaultService) {
            this.stateService.setDevice({ selectedService: this.stateService.device$.defaultService });
            return this.stateService.device$.selectedService;
        } else {
            if (!this.wlaActive) {
                this.stateService.setDevice({ selectedService: this.personaliseService.getRestaurantService() });
                return this.stateService.device$.selectedService;
            } else {
                if (!this.isNullOrEmpty(this.wlaServiceId) && !this.stateService.device$.selectedService) {
                    return this.personaliseService.getRestaurantService();
                    //this.personaliseService.getTaeamService(this.wlaServiceId).subscribe(result => {
                    //    if (result) {
                    //        this.stateService.setDevice({ selectedService: result });
                    //    }
                    //});
                }
                return this.personaliseService.getRestaurantService();
            }
            //return this.personaliseService.getRestaurantService();
        }
    }

    public getServiceByName(serviceName: string): any {
        if (this.isNullOrEmpty(serviceName)) {
            return null;
        }

        if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
            for (var i = 0; i < this.stateService.device$.taeamServices.length; i++) {
                if (this.stateService.device$.taeamServices[i].serviceName == serviceName) {
                    return this.stateService.device$.taeamServices[i];
                }
            }
        }

        return null;
    }

    public isSelectedServiceValid(): boolean {
        if (this.stateService.device$ && this.stateService.device$.selectedService && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
            for (var i = 0; i < this.stateService.device$.taeamServices.length; i++) {
                if (this.stateService.device$.taeamServices[i].serviceName == this.stateService.device$.selectedService.serviceName) {
                    return true;
                }
            }
        }

        return false;
    }

    public canShowOtherCurrencies(): boolean {
        var service = this.getService();
        return (service && service.supportedCurrencies && service.supportedCurrencies.trim().length > 0 && service.checkOutCurrencyQuestion && service.checkOutCurrencyQuestion.trim().length > 0);
    }

    public getSupportedCurrencies(): Array<any> {
        if (this.canShowOtherCurrencies()) {
            var currencies: Array<any> = new Array<any>();
            var supportedCurrencies = this.getService().supportedCurrencies;
            if (supportedCurrencies) {
                var items = supportedCurrencies.split(',');
                for (var i = 0; i < items.length; i++) {
                    currencies.push(items[i].trim());
                }
            }

            return currencies;
        }

        return null;
    }

    public hasMoreThanOneSupportedCurrencies(): boolean {
        var items = this.getSupportedCurrencies();

        if (this.hasData(items) && items.length > 1) {
            return true;
        }

        return false;
    }

    public getHeading(restaurant): string {

        var result = null;
        if (this.showName() && restaurant) {
            if (restaurant.heading && restaurant.heading.trim().length > 0 && restaurant.town) {
                result = restaurant.heading + " - " + restaurant.town;
            } else if (restaurant.heading && restaurant.heading.trim().length > 0 && !restaurant.town) {
                result = restaurant.heading;
            } else if ((!restaurant.heading || restaurant.heading.trim().length == 0) && restaurant.town) {
                result = restaurant.town;
            }
        }

        if (!this.isNullOrEmpty(result)) {
            return this.toCapitalise(result);
        }

        return "";
    }

    public isUserLoggedIn(): boolean {
        var result = (this.stateService.props$ && this.stateService.props$.user && this.stateService.props$.token && this.stateService.props$.user.fullname != "Web User");
        return result;
    }

    public adImpression(ad: any) {
        var eventData = "";
        var service = this.getService();
        if (service) {
            eventData = JSON.stringify({ id: ad.id, marketingCampaignId: ad.marketingCampaignId, serviceName: service.serviceName, geoLocation: this.stateService.props$.geolocation, userLocation: this.stateService.props$.geolocation });
        } else {
            eventData = JSON.stringify({ id: ad.id, marketingCampaignId: ad.marketingCampaignId, geoLocation: this.stateService.props$.geolocation, userLocation: this.stateService.props$.geolocation });
        }
       
        this.personaliseService.logEvent("marketing-ad-impression", eventData);
    }

    public getImpressionUrl(ad: any): string {
        var data = "";
        var service = this.getService();
        if (service) {
            data = JSON.stringify({ id: ad.id, marketingCampaignId: ad.marketingCampaignId, serviceName: service.serviceName }).replace(" ", "").replace("\n", "");
        } else {
            data = JSON.stringify({ id: ad.id, marketingCampaignId: ad.marketingCampaignId }).replace(" ", "").replace("\n", "");
        }
        //var data = { id: ad.id, marketingCampaignId: ad.marketingCampaignId };
        var url = this.personaliseService.getImpressionUrl("default", "marketing-ad-impression", data, null);
        return url;
    }

    public logAdImpression(ad: any) {
        var data = {};
        var service = this.getService();
        if (service) {
            data = { id: ad.id, marketingCampaignId: ad.marketingCampaignId, serviceName: service.serviceName };
        } else {
            data = { id: ad.id, marketingCampaignId: ad.marketingCampaignId };
        }

        this.personaliseService.logInHeaders("default", "marketing-ad-impression", data);
    }

    public getImpressionBlankImageUrl(ad: any): string {
        return environment.marketingResourcesBaseUrl + "no-logo.png"
    }

    public videoAdImpression(ad: any) {
        if (!ad) {
            return;
        }

        //var eventData = JSON.stringify({ id: ad.id, marketingCampaignId: ad.marketingCampaignId, geoLocation: this.stateService.props$.geolocation, userLocation: this.stateService.props$.geolocation });
        var eventData = JSON.stringify({ id: ad.id, marketingCampaignId: ad.marketingCampaignId });
        this.personaliseService.logInHeaders("default", "marketing-video-ad-impression", eventData);
    }

    public getVideoImpressionUrl(ad: any): string {
        if (!ad) {
            return;
        }
        var data = "";
        var service = this.getService();
        if (service) {
            data = JSON.stringify({ id: ad.id, marketingCampaignId: ad.marketingCampaignId, serviceName: service.serviceName }).replace(" ", "").replace("\n", "");
        } else {
            data = JSON.stringify({ id: ad.id, marketingCampaignId: ad.marketingCampaignId }).replace(" ", "").replace("\n", "");
        }

        
        //var data = { id: ad.id, marketingCampaignId: ad.marketingCampaignId };
        var url = this.personaliseService.getImpressionUrl("default", "marketing-video-ad-impression", data, null);
        return url;
    }

    public async videoAdClicked(ad: any, showAlertData = true, checkForServiveAdLink: boolean = false) {
        var eventData = JSON.stringify({ adId: ad.id, marketingCampaignId: ad.marketingCampaignId });
        if (!ad.videoClickCounted) {
            this.personaliseService.logEvent("marketing-video-ad-clicked", eventData);
            ad.videoClickCounted = true;
        }

        if (showAlertData) {
            if (ad.adHeadingText && ad.adBodyText) {
                this.alert(this.appName, ad.adHeadingText, ad.adBodyText).then(() => {
                    this.adCheckForLinks(ad, checkForServiveAdLink);
                });
            } else {
                this.adCheckForLinks(ad, checkForServiveAdLink);
            }
        } else {
            this.adCheckForLinks(ad, checkForServiveAdLink);
        }
    }

    public async adClicked(ad: any, checkForServiveAdLink: boolean) {
        //var eventData = JSON.stringify({ adId: ad.id, marketingCampaignId: ad.marketingCampaignId, geoLocation: this.stateService.props$.geolocation, userLocation: this.stateService.props$.geolocation });
        var eventData = JSON.stringify({ adId: ad.id, marketingCampaignId: ad.marketingCampaignId });
        if (!ad.clickCounted) {
            this.personaliseService.logEvent("marketing-ad-clicked", eventData);
            ad.clickCounted = true;
        }

        if (ad.adHeadingText && ad.adBodyText) {
            this.alert(this.appName, ad.adHeadingText, ad.adBodyText).then(() => {
                this.adCheckForLinks(ad, checkForServiveAdLink);
            });
        } else {
            this.adCheckForLinks(ad, checkForServiveAdLink);
        }        
    }

    public async logClickedEvent(source: string, id: any) {
        var eventData = JSON.stringify({ source: source, id: id });
        this.personaliseService.logEvent(source + "-clicked", eventData);
    }

    public checkPlayers(adPlayers: QueryList<any>) {
        if (adPlayers && adPlayers.length > 0) {
            adPlayers.forEach(player => {
                this.checkPlayer(player);
            });
        }
    }

    public checkPlayer(player: any) {
        if (this.currentPlaying) {
            return;
        }

        const nativeElement = player.nativeElement;
        const inView = this.isElementInViewPort(nativeElement);

        if (this.stickyVideo && this.stickyVideo.src == nativeElement.src) {
            return;
        }

        if (inView) {
            if (nativeElement.ad) {
                if (!nativeElement.impressionCounted) {
                    this.Log("Impression counted: No");
                    this.videoAdImpression(nativeElement.ad);
                    nativeElement.impressionCounted = true;
                } else {
                    this.Log("Impression counted: Yes");
                }
            }
            this.currentPlaying = nativeElement;
            this.currentPlaying.muted = true;
            this.currentPlaying.play();
        }
    }

    public isElementInViewPort(elem) {
        const rect = elem.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    public openFullScreen(elem) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitEnterFullscreen) {
            elem.webkitEnterFullscreen();
            elem.enterFullscreen();
        }
    }

    public playInLine(elem) {

    }

    public playOnSide(elem) {
        if (this.stickyVideo) {
            this.renderer.removeChild(this.stickyPlayer.nativeElement, this.stickyVideo);
        }

        this.stickyVideo = elem.cloneNode(true);
        this.renderer.appendChild(this.stickyPlayer.nativeElement, this.stickyVideo);

        if (this.currentPlaying) {
            const playPosition = this.currentPlaying.currentTime;
            this.currentPlaying.pause();
            this.currentPlaying = null;
            this.stickyVideo.currentTime = playPosition;
        }

        this.stickyVideo.muted = false;
        this.stickyVideo.play();
        this.stickyPlaying = true;
    }

    public playOrPauseSticky() {
        if (this.stickyPlaying) {
            this.stickyVideo.pause();
            this.stickyPlaying = false;
        } else {
            this.stickyVideo.play();
            this.stickyPlaying = true;
        }
    }

    public closeSticky() {
        if (this.stickyVideo) {
            this.renderer.removeChild(this.stickyPlayer.nativeElement, this.stickyVideo);
            this.stickyVideo = null;
            this.stickyPlaying = false;
        }
    }

    public pauseVideo(player: any) {
        this.Log("Pause is called");
        if (player) {
            player.pause()
        }
    }

    public playVideo(player: any) {
        this.Log("Play is called");

        if (player) {
            player.play();
        }
    }

    public muteAudio(player: any) {
        if (player) {
            player.muted = true;
        }
    }

    public unMuteAudio(player: any) {
        if (player) {
            player.muted = false;
        }
    }

    adCheckForLinks(ad: any, checkForServiveAdLink: boolean) {
        if (checkForServiveAdLink && !this.isNullOrEmpty(ad.adServiceLink)) {
            var service = this.getServiceByName(ad.adServiceLink);
            if (service) {
                if (service.hasServices) {
                    this.showLoader();
                    this.personaliseService.getTaeamServices(service.serviceName).subscribe(
                        result => {
                            this.stateService.setDevice({ taeamServices: result });
                            this.stateService.setProps({ taeamServices: result });
                        },
                        error => { this.hideLoader(); },
                        () => { this.hideLoader(); });
                } else {
                    this.stateService.resetProps();
                    this.stateService.setDevice({ selectedService: service });
                    this.stateService.setProps({ forceSearchRestaurant: true, marketingAds: null });
                    if (service.isBespokeService && !this.isNullOrEmpty(service.bespokeServiceTemplateUrl)) {
                        this.go(service.bespokeServiceTemplateUrl);
                    } else {
                        this.go("home");
                    }
                }
            }
        } else if (ad.adInternalLink && ad.adInternalLink.length > 0) {
            this.goToNext(ad.adInternalLink);
        }
        else if (ad.adExternalLink && ad.adExternalLink.length > 0) {
            this.goExternalLink(ad.adExternalLink);
        }
    }

    goExternalLink(url: string) {
        const options: InAppBrowserOptions = {
            zoom: 'no'
        }

        // Opening a URL and returning an InAppBrowserObject
        const browser = this.inAppBrowser.create(url, '_system', options);

        // Inject scripts, css and more with browser.X
    }

    async makeCallBase(contactNo: string) {
        this.callNumber.callNumber(contactNo, true)
            .then(res => this.Log('Launched dialer!', res))
            .catch(err => this.Log('Error launching dialer', err));
    }

    async openWhatsAppBase(contactNo: string, text: string) {
        var urlBase = "https://api.whatsapp.com/";
        //var urlBase = "whatsapp://";
        //if (this.localDeviceService.isWeb) {
        //    urlBase = "https://api.whatsapp.com/";
        //}

        contactNo = contactNo.replace(/-/gi, "");
        contactNo = contactNo.replace(/ /gi, "");

        var urlPath = "send?phone=" + contactNo + "&text=" + encodeURIComponent("Crazon Classified Ad: " + text);
        var url = urlBase + urlPath;
        await Browser.open({ url: url });
    }

    async browse(url: string) {
        let target = "_blank";
        if (this.localDeviceService.isWeb) {
            target = "_self";
        }
        const options: any = {
            zoom: 'no',
            hideurlbar: 'yes',
            location: 'no',
            toolbar: 'no',
            useWideViewPort: 'yes',
            usewkwebview: 'yes'
        }

        await Browser.removeAllListeners();
        await Browser.addListener("browserFinished", () => {
            //alert("browserFinished");
        });
        await Browser.addListener("browserPageLoaded", () => {
            //alert("browserPageLoaded");
        });

        await Browser.open({ url: url });
    }

    openExternalLink(url: string, successValidationText: string, successUrl: string, cancelValidationText: string, cancelUrl: string) {
        //const options: InAppBrowserOptions = {
        //    location: 'yes',//Or 'no' 
        //    hidden: 'no', //Or  'yes'
        //    clearcache: 'yes',
        //    clearsessioncache: 'yes',
        //    zoom: 'yes',//Android only ,shows browser zoom controls 
        //    hardwareback: 'yes',
        //    mediaPlaybackRequiresUserAction: 'no',
        //    shouldPauseOnSuspend: 'no', //Android only 
        //    closebuttoncaption: 'Close', //iOS only
        //    disallowoverscroll: 'no', //iOS only 
        //    toolbar: 'yes', //iOS only 
        //    enableViewportScale: 'no', //iOS only 
        //    allowInlineMediaPlayback: 'no',//iOS only 
        //    presentationstyle: 'pagesheet',//iOS only 
        //    fullscreen: 'no',//Windows only     
        //};
        let target = "_blank";
        if (this.localDeviceService.isWeb) {
            target = "_self";
        }

        const options: InAppBrowserOptions = {
            zoom: 'no',
            hideurlbar: 'yes',
            location: 'no',
            toolbar: 'no',
            useWideViewPort: 'yes',
            usewkwebview: 'yes'
        }

        // Opening a URL and returning an InAppBrowserObject
        this.browser = this.inAppBrowser.create(url, target, options);
        this.browser.on('loadstart').subscribe(event => { this.browserCallback(event, successValidationText, successUrl, cancelValidationText, cancelUrl); });

        //this.browser.on('loadstart').subscribe(event => { alert(JSON.stringify(event)); });
        //this.browser.on('loadstop').subscribe(event => { alert(JSON.stringify(event)); });
        //this.browser.on('loaderror').subscribe(event => { alert(JSON.stringify(event)); });
        //this.browser.on('beforeload').subscribe(event => { alert(JSON.stringify(event)); });
        //this.browser.on('message').subscribe(event => { alert(JSON.stringify(event)); });

        //var eventTypes: InAppBrowserEventType[] = ['loadstart', 'loadstop', 'beforeload', 'exit', 'loaderror'];
        //eventTypes.forEach(eventType => {
        //    this.browser.on(eventType).subscribe(event => {
        //        alert("event type is " + eventType);
        //        console.log(`Event ${eventType} fired!`); // Doesn't get here
        //        console.log(`URL: [${event.url}]`);       // or here (obviously).
        //        if (event.url && event.url.includes('web-payment-success')) {
        //            alert('User has cometed');
        //        } else if (event.url && event.url.includes('web-payment-error')) {
        //            alert('User has cancelled');
        //        } 
        //    });
        //});

        // Inject scripts, css and more with browser.X
    }

    public browserCallback(event: any, successValidationText: string, successUrl: string, cancelValidationText: string, cancelUrl: string) {
        if (event && !this.isNullOrEmpty(event.url)) {
            var url = event.url;
            if (url.includes(successValidationText)) {
                this.browser.close();
                this.go(successUrl);
            } else if (url.includes(cancelValidationText)) {
                this.browser.close();
                //this.go("/order/web-payment-cancel");
                this.go(cancelUrl);
            }
        }
    }

    protected get remoteNotificationService() {
        return RemoteNotificationService.Instance;
    }

    protected loadState() {
        this.state$ = this.stateService.getState();
    }

    ngAfterViewInit() {
    }

    ngAfterContentInit(): void {
        this.uiStateService.publish();
    }

    //protected InitPushNotification(onPushNotificationReceived: NotificationReceived, onRegistered: PushNotificationRegistered) {
    //    this.Log('InitPushNotification first in object base');

    //    this.localDeviceService.getDeviceInfo().then((deviceInfo) => {
    //        console.log('InitPushNotification');
    //        if (deviceInfo.platform != 'web') {
    //            this.RegisterPushNotification();
    //            // Show us the notification payload if the app is open on our device
    //            PushNotifications.addListener('pushNotificationReceived',
    //                (notification: PushNotification) => {
    //                    if (onPushNotificationReceived) {
    //                        onPushNotificationReceived(notification);
    //                    } else {
    //                        //this.customModal.showMessage({ title: notification.title, message: notification.body });
    //                        this.alert(notification.title, null, notification.body);
    //                    }
    //                }
    //            );

    //            if (onRegistered) {
    //                PushNotifications.addListener('registration',
    //                    (token: PushNotificationToken) => {
    //                        onRegistered(token);
    //                    }
    //                );
    //            }
    //        }
    //    });
    //}

    async alert(header: string, subHeader: string, message: string) {
        this.hideLoader();
        const alert = await this.alertController.create({
            header: header,
            subHeader: subHeader,
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

    async confirm(header: string, subHeader: string, message: string, okHandler: any, cancelHandler: any) {
        this.hideLoader();
        const alert = await this.alertController.create({
            header: header,
            subHeader: subHeader,
            message: message,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        cancelHandler();
                    }
                },
                {
                    text: 'Ok',
                    handler: () => {
                        okHandler();
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmWithInput(header: string, subHeader: string, message: string, okHandler: any, cancelHandler: any) {
        this.hideLoader();
        const alert = await this.alertController.create({
            header: header,
            subHeader: subHeader,
            message: message,
            inputs: [
                {
                    name: 'userOption',
                    placeholder: 'Option'
                }
            ],
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: (data) => {
                        cancelHandler(data);
                    }
                },
                {
                    text: 'Ok',
                    handler: (data) => {
                        okHandler(data);
                    }
                }
            ]
        });

        await alert.present();
    }

    async confirmWithInputs(header: string, subHeader: string, message: string, inputs: Array<any>, okHandler: any, cancelHandler: any) {
        this.hideLoader();
        const alert = await this.alertController.create({
            header: header,
            subHeader: subHeader,
            message: message,
            inputs: inputs,
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: (data) => {
                        cancelHandler(data);
                    }
                },
                {
                    text: 'Ok',
                    handler: (data) => {
                        okHandler(data);
                    }
                }
            ]
        });

        await alert.present();
    }

    protected debug(value: any) {
        if (!value) return;

        if (environment.production == false) {
            var msg = JSON.stringify(value);
            this.debugAlert(msg);
        }
    }

    async debugAlert(message: string) {
        this.hideLoader();
        const alert = await this.alertController.create({
            header: "Debug",
            subHeader: null,
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

    // async displayError(message: string) {
    //     const alert = await this.alertController.create({
    //         header: "Error",
    //         message: message,
    //         buttons: ['OK']
    //     });

    //     await alert.present();
    // }

    async displayInfomation(message: string) {
        this.hideLoader();
        const alert = await this.alertController.create({
            header: "Information",
            message: message,
            buttons: ['OK']
        });

        await alert.present();
    }

    //protected RegisterPushNotification() {
    //    // Register with Apple / Google to receive push via APNS/FCM
    //    PushNotifications.register();
    //}

    protected navigateWithState(url: string, state: any = null, forward: boolean = true) {
        if (state) {
            this.stateService.setState(state);
        }

        //this.router.navigate([url]);
        if (forward) {
            this.navCtrl.navigateForward(url);
        } else {
            this.navCtrl.navigateBack(url);
        }

        this.hideLoader();
    }

    public go(url: string, forward: boolean = true, returnUrl: string = null) {
        this.stateService.setProps({ returnUrl: returnUrl });
        this.navigateWithState(url, null, forward);
    }

    public goWithReturn(url: string) {
        this.Log("Go with return is called");
        this.stateService.setProps({ returnUrl: this.currentRoute });
        this.Log("Return url is ", this.currentRoute);
        this.navigateWithState(url, null, true);
    }

    public goSearch() {
        this.stateService.setProps({ showSearch: true });
        this.navigateWithState('/home');
    }

    public goToLogin(returnUrl: string = null) {
        this.stateService.setProps({ returnUrl: returnUrl, loginReturnRequest: true });
        //this.router.navigate(['/tabs/tfa']);
        this.navCtrl.navigateRoot('/tabs/tfa');
    }

    public goToLoginWithReturn() {
        if (this.isNullOrEmpty(this.currentRoute)) {
            this.currentRoute = this.router.url;
        }
        this.stateService.setProps({ returnUrl: this.currentRoute, loginReturnRequest: true });
        //this.router.navigate(['/tabs/tfa']);
        this.navCtrl.navigateRoot('/tabs/tfa');
    }

    //This is only called if user click on auth required page but decided not to proceed further.
    public resetLoginReturnUrl() {
        this.debugCore(true);
        if (this.stateService && this.stateService.props$ && this.stateService.props$.loginReturnRequest) {
            this.stateService.setProps({ returnUrl: null, loginReturnRequest: null });
        }
    }

    public setReturnUrl(url: string) {
        this.stateService.setProps({ returnUrl: url });
    }

    public setForceReturnUrl(url: string) {
        this.stateService.setProps({ forceReturnUrl: url });
    }

    public goExternalUrl(url: string) {
        window.location.href = url;
    }

    public goToReturnUrlIfAny(reset: boolean = false): boolean {
        if (this.stateService.props$ && !this.isNullOrEmpty(this.stateService.props$.returnUrl)) {
            var url: string = this.stateService.props$.returnUrl;
            if (reset) {
                this.stateService.setProps({ returnUrl: null });
            }
            this.go(url);
            return true;
        } else if (this.stateService.props$ && !this.isNullOrEmpty(this.stateService.props$.forceReturnUrl)) {
            var url: string = this.stateService.props$.forceReturnUrl;
            if (reset) {
                this.stateService.setProps({ forceReturnUrl: null });
            }
            this.go(url);
            return true;
        }

        this.debugCore(true);
        return false;
    }

    goBack() {
        let animations: any = {
            animated: true,
            animationDirection: "back"
        }
        this.navCtrl.back(animations)
    }

    protected delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    protected handleResponse(response: any): boolean {
        if (!Array.isArray(response)) {
            //this.customModal.showMessage({ title: 'Error', message: response.message });
            return false;
        }

        return true;
    }

    protected handleError(error: any, url: string = null) {
        this.Log("HandleError funciton is called");
        this.Log(error);
        
        this.hideLoader();

        if (error && error.error == "cordova_not_available") {
            return;
        }

        if (!this.isNullOrEmpty(url) && error.error && error.error.status == "401") {
            this.getToken().then(() => {
                this.go(url);
            });
        } else {

            if (error.error && error.error._body) {
                this.monitoringService.logException(error.error);
                this.Log(error.error._body);
                var body = JSON.parse(error.error._body);
                this.message = body.message;
            }
            //else if (error.error.status == "401") {
            //    this.getToken().then(() => {
            //        this.go("/tabs/home");
            //    });
            //}
            else {
                this.monitoringService.logException(error.error ? error.error : error);
                if (error && error.error && error.error.status && error.error.status.Text) {
                    this.message = 'Please retry or contact support team. Status: ${error.error.status} - ${error.error.statusText}';
                } else {
                    this.message = 'Please retry again.';
                }
                this.errorOccurred = true;
                //this.customModal.showMessage({ title: 'Error', message: this.message });
                this.displayError(this.message);
            }
        }
    }

    protected scrollToTheTop() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }

            var scrollToTop = window.setInterval(function () {
                var pos = window.pageYOffset;
                if (pos > 0) {
                    window.scrollTo(0, pos - 20); // how far to scroll on each step
                } else {
                    window.clearInterval(scrollToTop);
                }
            }, 16); // how fast to scroll (this equals roughly 60 fps)
        });
    }

    public scrollIntoView(anchorHash, useTimer: boolean = true) {

        if (useTimer) {
            setTimeout(() => {
                const anchor = document.getElementById(anchorHash);
                if (anchor) {
                    anchor.focus();
                    //anchor.scrollIntoView({ block: "center", behavior: "smooth" }); // This is hiding top header
                    anchor.scrollIntoView(true);
                }
            });
        } else {
            const anchor = document.getElementById(anchorHash);
            if (anchor) {
                anchor.focus();
                //anchor.scrollIntoView({ block: "center", behavior: "smooth" }); // This is hiding top header
                anchor.scrollIntoView(true);
            }
        }
    }

    protected openMyModal(event) {
        document.querySelector('#' + event).classList.add('md-show');
    }

    protected closeMyModal(event) {
        ((event.target.parentElement.parentElement).parentElement).classList.remove('md-show');
    }

    protected goHome() {
        //this.router.navigate(['/']);
        //this.navCtrl.navigateRoot('/');
        if (this.stateService.props$.returnUrl) {
            var url = this.stateService.props$.returnUrl;
            this.stateService.props$({ returnUrl: null });
            this.go(url);
        } else {
            this.navCtrl.navigateRoot('home');
        }

        //this.navCtrl.navigateRoot('home');
    }

    protected goDefaultHome() {
        if (this.stateService.props$.returnUrl) {
            var url = this.stateService.props$.returnUrl;
            this.stateService.props$({ returnUrl: null });
            this.go(url);
        } else if (!this.wlaActive) {
            if (!this.isClassifiedAdsService() && !this.isServiceResourceService()) {
                this.navCtrl.navigateRoot('home');
            } else if (this.isClassifiedAdsService()) {
                this.navCtrl.navigateRoot('classified-ads');
            } else if (this.isServiceResourceService()) {
                this.navCtrl.navigateRoot('serviceresource');
            }
        } else if (this.wlaActive) {
            if (this.stateService.props$.wlaMultipleRestaurants) {
                this.navCtrl.navigateRoot('home');
            } else if (!this.stateService.props$.wlaMultipleRestaurants && this.isNullOrEmpty(this.stateService.props$.statusDescription)) {
                this.navCtrl.navigateRoot('restaurant/menu');
            } else if (!this.stateService.props$.wlaMultipleRestaurants && !this.isNullOrEmpty(this.stateService.props$.statusDescription)) {
                this.navCtrl.navigateRoot('status-response');
            }
        }
    }

    protected resetReturnUrls() {
        this.stateService.setProps({ returnUrl: null, navigateUrl: null });
    }

    public goServices() {
        if (this.stateService.props$.returnUrl) {
            this.Log("Return url is ", this.stateService.props$.returnUrl);
            var url = this.stateService.props$.returnUrl;
            this.stateService.setProps({ returnUrl: null });
            this.Log("Now going to ", url);
            this.go(url);
        } else {
            var service = this.getLoadAsDefaultService();
            if (service) {
                if (this.isServiceAppVersionCompatible(service)) {
                    this.stateService.resetProps();
                    this.stateService.setDevice({ selectedService: service });
                    this.stateService.setProps({ forceSearchRestaurant: true, marketingAds: null });
                    if (service.isBespokeService && !this.isNullOrEmpty(service.bespokeServiceTemplateUrl)) {
                        this.go(service.bespokeServiceTemplateUrl);
                    } else {
                        this.go("home");
                    }
                } else {
                    this.alert(this.toCapitalise(service.serviceSingularName), null, "Your app version is not compatible to access " + this.toLower(service.serviceSingularName, "service", "service") + ". Please update your app and try again.");
                }
            } else {
                this.go('services', false);
            }
        }
    }

    protected getLoadAsDefaultService(): any {
        if (this.stateService.device$ && this.hasData(this.stateService.device$.taeamServices)) {
            for (var i = 0; i < this.stateService.device$.taeamServices.length; i++) {
                if (this.stateService.device$.taeamServices[i].loadAsDefault) {
                    return this.stateService.device$.taeamServices[i];
                }
            }
        }

        return null;
    }

    protected hasSelectedServiceId(): boolean {
        var service = this.getService();
        if (service && !this.isNullOrEmpty(service.selectedServiceId)) {
            return true;
        }

        return false;
    }

    protected resetSelectedMenuCategories() {
        this.stateService.setProps({ menuCategories: null });
    }

    protected goToNext(route: string) {
        setTimeout(() => {
            //this.router.navigate([route]);
            this.navCtrl.navigateForward(route);
            this.hideLoader();
        }, 1000);  //1s
    }

    protected encode(data: string): string {
        return Base64.encode(data);
    }

    protected decode(data: string): string {
        return Base64.decode(data);
    }

    public getResourceUrl(data: string) {
        //return "https://assets.crazon.io/mobile/restaurants/sample10.jpg";
        return this.resourcesBaseUrl + data;
    }

    public goChangeLocation() {
        this.navigateWithState('/preferences');
    }

    public getAutoplay(conditionalIndex: number, currentIndex: number): string {
        if (conditionalIndex == currentIndex) {
            //return "autoplay='autoplay'";
            return "autoplay";
        } else {
            "false";
        }
    }

    public getRestaurantAssetUrl(restaurantId: string, assetName: string) {
        return this.marketplaceResourcesBaseUrl + restaurantId + "/assets/menuitem/" + assetName;
    }

    public getRestaurantSpecSheetAssetUrl(restaurantId: string, assetName: string) {
        return this.marketplaceResourcesBaseUrl + restaurantId + "/assets/specsheet/" + assetName;
    }

    public isVideo(name: any): boolean {
        return (name.toLowerCase().indexOf('video') > -1 || name.toLowerCase().indexOf('mp4') > -1);
    }

    public hasMultipleImagesOrVideos(imagesOrVideo: any): boolean {
        if (imagesOrVideo && imagesOrVideo.length > 1) {
            return true;
        }

        return false;
    }

    public hasImageOnly(imagesOrVideo: any): boolean {
        if (imagesOrVideo && imagesOrVideo.length == 1 && !this.isVideo(imagesOrVideo[0].name)) {
            return true;
        }

        return false;
    }

    public hasVideoOnly(imagesOrVideo: any): boolean {
        if (imagesOrVideo && imagesOrVideo.length == 1 && this.isVideo(imagesOrVideo[0].name)) {
            return true;
        }

        return false;
    }

    public hasFirstVideo(imagesOrVideo: any): boolean {
        if (imagesOrVideo && imagesOrVideo.length > 0 && this.isVideo(imagesOrVideo[0].name)) {
            return true;
        }

        return false;
    }

    public hasFirstImage(imagesOrVideo: any): boolean {
        if (imagesOrVideo && imagesOrVideo.length > 0 && !this.isVideo(imagesOrVideo[0].name)) {
            return true;
        }

        return false;
    }

    public getSingleImageOrVideoUrl(assetBaseUrl: string, imagesOrVideo: any): string {
        if (imagesOrVideo && imagesOrVideo.length == 1) {
            return assetBaseUrl + imagesOrVideo[0].name;
        }

        return null;
    }

    public getMultipleAssetUrls(urls: string): Array<any> {
        var result: Array<any> = null;

        if (urls) {
            var items = urls.split(',');
            result = new Array<any>();

            for (var i = 0; i < items.length; i++) {
                result.push({ url: items[i].trim(), isVideo: (items[i].toLowerCase().indexOf('video') > -1 || items[i].toLowerCase().indexOf('mp4') > -1) });
            }
        }
        return result;
    }

    public async displayActionSheet(title: string, text: any) {
        const actionSheet = await this.actionSheetController.create({
            header: title,
            cssClass: 'news-popup',
            buttons: [{
                text: text
            }]
        });
        await actionSheet.present();
    }

    async presentModal(page: any, cssClass: string) {
        const modal = await this.modalController.create({
            component: page,
            cssClass: cssClass
        });
        await modal.present();
    }

    public checkForAppRate() {
        if (this.stateService.device$ && !this.stateService.device$.appReviewed && this.stateService.device$.runCounter > 3) {
            this.appRate.preferences = {
                usesUntilPrompt: 5,
                storeAppURL: {
                    ios: '1550205085',
                    android: 'market://details?id=com.crazon.app'
                }
            }

            this.appRate.promptForRating(true);
        }
    }

    public getLocationText(): string {
        if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
            for (let i = 0; i < this.stateService.device$.taeamServices.length; i++) {
                var service = this.stateService.device$.taeamServices[i];
                if (service && service.isNotShoppingService) {
                    return (this.stateService.device$.userPreferences.userLocation.first_line == null ? this.stateService.device$.userPreferences.userLocation.city : this.stateService.device$.userPreferences.userLocation.first_line);
                }
            }
        }

        return this.stateService.device$.userPreferences.deliveryMethod + ", " + (this.stateService.device$.userPreferences.userLocation.first_line == null ? this.stateService.device$.userPreferences.userLocation.city : this.stateService.device$.userPreferences.userLocation.first_line);
    }

    public getSelectedServiceLocationText(): string {
        if (this.getService().isNotShoppingService) {
            return (this.stateService.device$.userPreferences.userLocation.first_line == null ? this.stateService.device$.userPreferences.userLocation.city : this.stateService.device$.userPreferences.userLocation.first_line);
        } else {
            return this.stateService.device$.userPreferences.deliveryMethod + ", " + (this.stateService.device$.userPreferences.userLocation.first_line == null ? this.stateService.device$.userPreferences.userLocation.city : this.stateService.device$.userPreferences.userLocation.first_line);
        }
    }

    public isMarketplaceService(): boolean {
        return this.getService().serviceTemplateId == "marketplace";
    }

    public isPropertyInvestMentService(): boolean {
        return this.getService().serviceTemplateId == "property-investment";
    }

    public isClassifiedAdsService(): boolean {
        return this.getService().serviceTemplateId == "classified-ads";
    }

    public isServiceResourceService(): boolean {
        return this.getService().serviceTemplateId == "serviceresource";
    }

    public isNotShoppingService(): boolean {
        return this.getService().isNotShoppingService && !this.isClassifiedAdsService();
    }

    public showMenuItemPrice(item: any): boolean {
        if (this.isNotShoppingService() && item && item.menuItemPrice == 0) {
            return false;
        }

        return true;
    }

    public showActualPrice(menuItem: any): boolean {
        return menuItem.actualPrice && menuItem.actualPrice > 0 && menuItem.actualPrice != menuItem.menuItemPrice
    }

    public loadWlaParams(restaurant: any) {
        if (this.wlaActive && restaurant && !this.isNullOrEmpty(restaurant.params)) {

            var wlaParams = JSON.parse(restaurant.params);

            if (wlaParams && this.hasProperty(wlaParams, "wlaAllowDineIn")) {
                environment.wlaAllowDineIn = wlaParams.wlaAllowDineIn;
                this.wlaAllowDineIn = wlaParams.wlaAllowDineIn;
            }
            if (wlaParams && this.hasProperty(wlaParams, "wlaAllowCollection")) {
                environment.wlaAllowCollection = wlaParams.wlaAllowCollection;
                this.wlaAllowCollection = wlaParams.wlaAllowCollection;
            }
            if (wlaParams && this.hasProperty(wlaParams, "wlaShowPowerByCrazon")) {
                environment.wlaShowPowerByCrazon = wlaParams.wlaShowPowerByCrazon;
                this.wlaShowPowerByCrazon = wlaParams.wlaShowPowerByCrazon;
            }
            if (wlaParams && this.hasProperty(wlaParams, "wlaShowName")) {
                environment.wlaShowName = wlaParams.wlaShowName;
                this.wlaShowName = wlaParams.wlaShowName;
            }
            if (wlaParams && this.hasProperty(wlaParams, "wlaShowAddress")) {
                environment.wlaShowAddress = wlaParams.wlaShowAddress;
                this.wlaShowAddress = wlaParams.wlaShowAddress;
            }
            if (wlaParams && this.hasProperty(wlaParams, "wlaShowChangeLocation")) {
                environment.wlaShowChangeLocation = wlaParams.wlaShowChangeLocation;
                this.wlaShowChangeLocation = wlaParams.wlaShowChangeLocation;
            }
            if (wlaParams && this.hasProperty(wlaParams, "wlaRecentlyViewedLimit")) {
                environment.wlaRecentlyViewedLimit = wlaParams.wlaRecentlyViewedLimit;
                this.wlaRecentlyViewedLimit = wlaParams.wlaRecentlyViewedLimit;
            }
            if (wlaParams && this.hasProperty(wlaParams, "wlaShowFeaturedText")) {
                environment.wlaShowFeaturedText = wlaParams.wlaShowFeaturedText;
                this.wlaShowFeaturedText = wlaParams.wlaShowFeaturedText;
            }
            if (wlaParams && this.hasProperty(wlaParams, "wlaShowOrderReadyFor")) {
                environment.wlaShowOrderReadyFor = wlaParams.wlaShowOrderReadyFor;
                this.wlaShowOrderReadyFor = wlaParams.wlaShowOrderReadyFor;
            }
        } else if (this.wlaActive == false) {
            //environment.wlaAllowDineIn = false;
            //this.wlaAllowDineIn = false;
            //environment.wlaAllowCollection = false;
            //this.wlaAllowCollection = false;
            //environment.wlaShowPowerByCrazon = false;
            //this.wlaShowPowerByCrazon = false;
            //environment.wlaShowName = false;
            //this.wlaShowName = false;
            //environment.wlaShowAddress = false;
            //this.wlaShowAddress = false;
            //environment.wlaShowChangeLocation = false;
            //this.wlaShowChangeLocation = false;
            //environment.wlaShowFeaturedText = false;
            //this.wlaShowFeaturedText = false;

            if (this.hasProperty(wlaParams, "recentlyViewedLimit")) {
                environment.recentlyViewedLimit = wlaParams.recentlyViewedLimit;
                this.recentlyViewedLimit = wlaParams.recentlyViewedLimit;
            }

            if (this.hasProperty(wlaParams, "wlaShowFeaturedText")) {
                environment.wlaShowFeaturedText = wlaParams.wlaShowFeaturedText;
                this.wlaShowFeaturedText = wlaParams.wlaShowFeaturedText;
            }
        }
    }

    public getActiveThemeName(restaurant: any): string {
        if (restaurant && this.wlaActive == true) {
            return restaurant.standaloneTheme;
        } else if (restaurant) {
            return restaurant.marketplaceTheme;
        }

        return "None";
    }

    isSameTheme(restaurant: any, themeName: string): boolean {
        restaurant = this.getRestaurant(restaurant);
        if (restaurant && this.wlaActive == true) {
            if (restaurant.standaloneTheme == themeName) {
                return true;
            }
        } else if (restaurant) {
            if (restaurant.marketplaceTheme == themeName) {
                return true;
            }
        }

        return false;
    }

    isDefaultTheme(restaurant: any): boolean {
        var result = this.isSameTheme(restaurant, MarketplaceThemes.Default);
        if (result == false && this.wlaActive == false && this.isNullOrEmpty(restaurant.marketplaceTheme)) {
            result = true; //override in case marketplace existing shops do not have the theme value
        }

        return result;
    }

    isFeatureTheme(restaurant: any = null): boolean {
        if (!restaurant && this.stateService.props$) {
            restaurant = this.stateService.props$.restaurant;
        }

        return this.isSameTheme(restaurant, MarketplaceThemes.Feature);
    }

    public canShowCategoryNavBarInTabsPage(): boolean {
        if (this.stateService && this.stateService.props$ && this.stateService.props$.menuCategories) {
            if (this.isFeatureTheme()) {
                return false;
            } 

            var result = !this.stateService.props$.hideMenuCategoriesBar;
            if (result == true && !this.stateService.props$.restaurant && this.wlaActive) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }    

    public getSliderSettings(dataList: Array<any>, settings: any = null): any {
        if (!settings) {
            settings = {
                slidesPerView: 1.05,
                initialSlide: 0,
                speed: 400,
                spaceBetween: 1,
                autoHeight: true
            };
        }
        if (!dataList) {
            return settings;
        }

        if (dataList && dataList.length <= 1) {
            settings.slidesPerView = 1;
            settings.spaceBetween = 0;
            settings.centeredSlides = true;
        }

        return settings;
    }

    public getCardViewCss(isCardView: boolean, currentCssClasses: string = null): string {
        if (this.isNullOrEmpty(currentCssClasses)) {
            currentCssClasses = "";
        }

        if (isCardView) {
            return currentCssClasses + " card";
        }

        if (this.isCardView) {
            return currentCssClasses + " card";
        }

        return currentCssClasses;
    }

    public getSummaryViewCss(isCardView: boolean, applyPadding: boolean, currentCssClasses: string = null): string {
        if (this.isNullOrEmpty(currentCssClasses)) {
            currentCssClasses = "";
        }

        if (applyPadding) {
            if (isCardView) {
                return currentCssClasses + " name-and-address-section-with-padding";
            }

            if (this.isCardView) {
                return currentCssClasses + " name-and-address-section-with-padding";
            }

            return currentCssClasses + " name-and-address-section-with-padding";
        } else {
            return currentCssClasses + " name-and-address-section";
        }
    }

    public getTagsFromString(tagsString: string): Array<string> {
        if (this.isNullOrEmpty(tagsString)) {
            return null;
        }

        var splitChar = '|';
        tagsString = tagsString.replace("#", splitChar);
        tagsString = tagsString.replace(" ", splitChar);

        return tagsString.split(splitChar);
    }

    public getHashTag(tag: string, includeHashSymbol: boolean = false) {
        if (includeHashSymbol) {
            return "#" + tag;
        }

        return tag;
    }

    public getItemsAsArray(options: string, splitChar: string = ','): Array<string> {
        if (this.isNullOrEmpty(options)) {
            return null;
        }

        var result = options.split(splitChar);
        for (var i = 0; i < result.length; i++) {
            result[i] = result[i].trim();
        }

        return result;;
    }

    protected setPageTemplateId(templateId: string) {
        this.pageTemplateId = templateId;
    }

    public isListTemplate(): boolean {
        return this.isSame(this.pageTemplateId, "list")
    }

    public isCardTemplate(): boolean {
        return this.isSame(this.pageTemplateId, "card")
    }

    protected startTimeElapsed(startTimerId: any, startTimerType: any) {
        var startTimer = performance.now();
        this.stateService.setProps({ startTimer: startTimer, startTimerId: startTimerId, startTimerType: startTimerType });
        this.debugCore();
    }

    protected getTimeElapsed(): number {
        if (this.stateService && this.stateService.props$ && this.stateService.props$.startTimer) {
            var currentTimer = performance.now();
            var ms = currentTimer - this.stateService.props$.startTimer;
            return ms;
        }

        return 0;
    }

    protected resetTimeElapsed() {
        this.stateService.setProps({ startTimer: null, startTimerId: null, startTimerType: null }); 
    }

    public logElapsedTimerEvent(service: any) {
        if (this.stateService && this.stateService.props$ && this.stateService.props$.startTimer) {
            var id = this.stateService.props$.startTimerId;
            var type = this.stateService.props$.startTimerType;
            var timeSpentInMs = this.getTimeElapsed();
            if (timeSpentInMs > 0) {
                if (!this.isNullOrEmpty(id) && !this.isNullOrEmpty(type) && type == 'classifiedad-view') {
                    service.logClassifiedAdEvent(id, 'classified-ad-avg-time-spent', timeSpentInMs.toString(), 'classified-ad-avg-time-spent');
                }
            }

            this.resetTimeElapsed();
        }
    }

    //protected logElapsedTimerEvent?(): void;

    //This function is called on tab click
    public sharedTabActions(service: any) {
        this.debugCore();
        this.logElapsedTimerEvent(service);
    }

    //This can be in classified ad base but there are components which need this functionality and not using classified ad base class
    public isMyClassifiedAd(classifiedAd: any): boolean {
        //return false;
        if (classifiedAd && classifiedAd.userId && this.stateService.props$ && this.stateService.props$.user && this.stateService.props$.user.id) {
            if (classifiedAd.userId == this.stateService.props$.user.id) {
                return true;
            }
        }

        return false;
    }

    public isMyServiceResourceAd(serviceResourceAd: any): boolean {
        //return false;
        if (serviceResourceAd && serviceResourceAd.userId && this.stateService.props$ && this.stateService.props$.user && this.stateService.props$.user.id) {
            if (serviceResourceAd.userId == this.stateService.props$.user.id) {
                return true;
            }
        }

        return false;
    }

    public displayStyleIsDefault(styleName: string) {
        return this.homePageDisplayStyle == DisplayStyles.Default;
    }

    public displayStyleIsTwoColumnsGrid(styleName: string) {
        return this.homePageDisplayStyle == DisplayStyles.TwoColumnsGrid;
    }

    public displayStyleIsThreeColumnsGrid(styleName: string) {
        return this.homePageDisplayStyle == DisplayStyles.ThreeColumnsGrid;
    }

    public displayStyleIsLeftImageViewList(styleName: string) {
        return this.homePageDisplayStyle == DisplayStyles.LeftImageViewList;
    }

    public showFullImageSlider(): boolean {
        if (this.stateService.props$.showFullImageSlider) {
            return true;
        }

        return false;
    }

    public setFullImageSlider(state: boolean) {
        this.stateService.setProps({ showFullImageSlider: state });
    }

    async showImageSource(fileInput: ElementRef, callback: (source: CameraSource) => void) {
        var buttons = [];

        if (this.localDeviceService.isWeb) {
            buttons = [
                {
                    text: 'Photo Library',
                    icon: 'image',
                    handler: async () => {
                        await callback(CameraSource.Photos);
                    }
                },
                {
                    text: 'Take Photo',
                    icon: 'camera',
                    handler: async () => {
                        await callback(CameraSource.Camera);
                    }
                },
                {
                    text: 'Browse',
                    icon: 'attach',
                    handler: () => {
                        fileInput.nativeElement.click();
                    }
                }
            ];
        } else {
            buttons = [
                {
                    text: 'Photo Library',
                    icon: 'image',
                    handler: async () => {
                        await callback(CameraSource.Photos);
                    }
                },
                {
                    text: 'Take Photo',
                    icon: 'camera',
                    handler: async () => {
                        await callback(CameraSource.Camera);
                    }
                },
                {
                    text: 'Browse',
                    icon: 'attach',
                    handler: () => {
                        fileInput.nativeElement.click();
                    }
                }
            ];
        }

        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Select Source',
            buttons
        });
        await actionSheet.present();
    }

    async getImageObj(source: CameraSource): Promise<any> {
        var cameraParams: any = {};

        if (source == CameraSource.Camera) {
            cameraParams = {
                quality: 100,
                allowEditing: false,
                direction: CameraDirection.Rear,
                format: "image/x-png,image/gif,image/jpeg,.mp4",
                resultType: CameraResultType.DataUrl,
                source
            };
        } else {
            cameraParams = {
                quality: 100,
                allowEditing: false,
                format: "image/x-png,image/gif,image/jpeg,.mp4",
                resultType: CameraResultType.DataUrl,
                source
            };
        }

        const image = await Camera.getPhoto(cameraParams);

        var base64String = null;
        if (image.base64String) {
            base64String = image.base64String;
        } else {
            base64String = image.dataUrl;
        }

        var imageObj = { id: this.getId(), name: image.name, contents: base64String, path: null };

        if (!imageObj.name) {
            imageObj.name = imageObj.id + "." + image.format;
        }

        return imageObj;
    }

    //public getSafeImageBase64(imageBase: string): SafeUrl {
    //    var base64Image = this.sanitizer.bypassSecurityTrustUrl(imageBase);
    //    return base64Image;
    //}

    public getServiceResourceTitle(prefix: string = null, postfix: string = null): string {
        var value = this.getEmptyIfNull(prefix, "") + " " + this.getService().serviceSingularName + this.getEmptyIfNull(postfix, "");
        if (value) {
            return value.trim();
        }

        return null;
    }

    public getServiceAttribute(name: string, inLowerCase: boolean = false): any {
        var service = this.getService();
        if (service) {
            var attribute = service[name];
            if (attribute) {
                if (inLowerCase) {
                    attribute = attribute.toLowerCase();
                }

                return attribute;
            }
        }

        return null;
    }
     

    public getServiceSingularName(inLowerCase: boolean): string {
        var name: string = this.getService().serviceSingularName;

        if (inLowerCase && !this.isNullOrEmpty(name)) {
            name = name.toLowerCase();
        }

        return name;
    }

    public getServicePluralName(inLowerCase: boolean): string {
        var name: string = this.getService().servicePluralName;

        if (inLowerCase && !this.isNullOrEmpty(name)) {
            name = name.toLowerCase();
        }

        return name;
    }

    public getDefaultServiceRadius(): any {
        return this.getService().defaultServiceRadius;
    }

    public getCheckoutButtonText(): any {
        return this.getService().checkoutButtonText;
    }

    public getCheckoutThankYouMessageLine1(): any {
        return this.getService().checkoutThankYouMessageLine1;
    }

    public getCheckoutThankYouMessageLine2(): any {
        return this.getService().checkoutThankYouMessageLine2;
    }

    public getCheckoutThankYouMessageLine3(): any {
        return this.getService().checkoutThankYouMessageLine3;
    }

    public getConfirmationMessageBeforePlacingAdOrOrder(): any {
        return this.getService().confirmationMessageBeforePlacingAdOrOrder;
    }

    public getCustomerRequestCheckoutButtonText(): any {
        return this.getService().customerRequestCheckoutButtonText;
    }

    public getCustomerRequestCheckoutThankYouMessageLine1(): any {
        return this.getService().customerRequestCheckoutThankYouMessageLine1;
    }

    public getCustomerRequestCheckoutThankYouMessageLine2(): any {
        return this.getService().customerRequestCheckoutThankYouMessageLine2;
    }

    public getCustomerRequestCheckoutThankYouMessageLine3(): any {
        return this.getService().customerRequestCheckoutThankYouMessageLine3;
    }

    public getCustomerRequestConfirmationMessageBeforePlacingAdOrOrder(): any {
        return this.getService().customerRequestConfirmationMessageBeforePlacingAdOrOrder;
    }

    public getCustomerMyServiceActionTitle(lowerCase: boolean = false): any {

        var name = this.getService().customerMyServiceActionTitle;

        if (lowerCase && !this.isNullOrEmpty(name)) {
            return name.toLowerCase();
        }

        return name;
    }

    public getProviderMyServiceActionTitle(lowerCase: boolean = false): any {
        var name = this.getService().providerMyServiceActionTitle;

        if (lowerCase && !this.isNullOrEmpty(name)) {
            return name.toLowerCase();
        }

        return name;
    }

    public getProviderMyAdTitle(lowerCase: boolean = false): any {

        var name = this.getService().providerMyAdsActionTitle;

        if (lowerCase && !this.isNullOrEmpty(name)) {
            return name.toLowerCase();
        }

        return name;
    }

    public getJoinServiceActionTitle(): any {
        return this.getService().joinServiceActionTitle;
    }

    public getShowServiceResourceTerms(): boolean {
        return this.getService().showServiceResourceTerms;
    }

    public getComingSoonImageUrl(): string {
        var url = "https://assets.crazon.io/mobile/default-loading.jpg";
        if (!this.isNullOrEmpty(this.getService().comingSoonImageUrl)) {
            url = this.getService().comingSoonImageUrl;
        }

        return url;
    }

    public onImageError(img: any) {
        //this.onerror=null; this.src='{{getComingSoonImageUrl()}}'
        img.onerror = null;
        img.src = this.getComingSoonImageUrl();
    }

    getDayNumber(date: any): any {
        return this.calendarService.getDayNumber(date);
    }

    public setHomeUrl(url: string) {
        this.stateService.setProps({ homeReturnUrl: url });
    }

    public resetHomeUrl() {
        this.stateService.setProps({ homeReturnUrl: null });
    }

    public getHomeUrl() {
        return this.stateService.props$.homeReturnUrl;
    }

    public goServiceHome() {
        var service = this.getService();

        if (service.isBespokeService && !this.isNullOrEmpty(service.bespokeServiceTemplateUrl)) {
            this.go(service.bespokeServiceTemplateUrl);
        } else {
            this.go("home");
        }
    }

    public getServiceProviderNameTitle(inLowerCase: boolean): string {
        var name: string = this.getService().serviceProviderNameTitle;

        if (inLowerCase && !this.isNullOrEmpty(name)) {
            name = name.toLowerCase();
        }

        return name;
    }
}
