import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import * as moment from 'moment';
import 'moment-timezone';
import { Common } from '../shared/common';
import { ClassifiedAdStatus } from '../shared/models/constants.model';

declare var google: any;

@Injectable()
export class ClassifiedAdsService extends ServiceBase {

    private static _instance: ClassifiedAdsService;

    private searchClassifiedAdsSubject = new Subject<Array<any>>();
    SearchResults = this.searchClassifiedAdsSubject.asObservable();

    private createClassifiedAdApiUrl: string;
    private getAdCategoriesApiUrl: string;
    private getAdCategoryFieldsApiUrl: string;
    private getAdTypesApiUrl: string;
    private getClassifedAdCountriesApiUrl: string;
    private getMyClassifiedAdsApiUrl: string;
    private saveClassifiedAdImagesApiUrl: string;
    private getAdPackagesApiUrl: string;
    private getClassifiedAdApiUrl: string;
    private getClassifiedAdByNameKeyApiUrl: string;
    private getClassifiedAdInsightsApiUrl: string;
    private logClassifiedAdEventApiUrl: string;
    private updateClassifiedAdStatusApiUrl: string;
    private getPaymentGatewayDetailsApiUrl: string;
    private paymentRequestApiUrl: string;
    private getContactNoApiUrl: string;
    private getPaymentTokenApiUrl: string;
    private getFieldLookupValuesApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.createClassifiedAdApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/create';
        this.getAdCategoriesApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/category/get';
        this.getAdCategoryFieldsApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/category-fields/get/';
        this.getAdTypesApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/type/get';
        this.getClassifedAdCountriesApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/countries/get';
        this.getMyClassifiedAdsApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/my/';
        this.saveClassifiedAdImagesApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/upload/image/';
        this.getAdPackagesApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/packages/get';        
        this.getClassifiedAdApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/get/';
        this.getClassifiedAdByNameKeyApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/get/nk/';
        this.getClassifiedAdInsightsApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/cai/';
        this.logClassifiedAdEventApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/cae/';
        this.updateClassifiedAdStatusApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/status/';
        this.getPaymentGatewayDetailsApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/payment/gatewaydetails/';
        this.paymentRequestApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/payment/request/';
        this.getContactNoApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/contactno/get/';
        this.getPaymentTokenApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/payment/token/';
        this.getFieldLookupValuesApiUrl = environment.classifiedAdsApiUrl + 'classified-ads/ctf/get/';

        if (!ClassifiedAdsService._instance) {
            ClassifiedAdsService._instance = this;
        }
    }

    public static get Instance() {
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<ClassifiedAdsService>(ClassifiedAdsService));
        return obj;
    }

    public createClassifiedAd(ad: any): Observable<any> {
        return this.post(this.createClassifiedAdApiUrl, ad);
    }

    public getAdCategories(parentCategory: string = "default"): Observable<any> {
        parentCategory = encodeURIComponent(parentCategory);
        return this.get(this.getAdCategoriesApiUrl + '/' + parentCategory + '/');
    }

    public getAdCategoryFields(parentCategoryKey: string, categoryKey: string): Observable<any> {
        return this.get(this.getAdCategoryFieldsApiUrl + encodeURIComponent(parentCategoryKey) + '/' + encodeURIComponent(categoryKey) + '/');
    }

    public getAdTypes(): Observable<any> {
        return this.get(this.getAdTypesApiUrl);
    }

    public getAdPackages(categoryName): Observable<any> {
        categoryName = encodeURIComponent(categoryName);
        var url = this.getAdPackagesApiUrl + '/' + categoryName + '/';
        return this.get(url);
    }

    public getClassifedAdCountries(): Observable<any> {
        return this.get(this.getClassifedAdCountriesApiUrl);
    }

    public getMyClassifiedAds(): Observable<any> {
        return this.get(this.getMyClassifiedAdsApiUrl);
    }

    public getClassifiedAd(id: string): Observable<any> {
        var headers = this.getPDHeader(true);
        return this.get(this.getClassifiedAdApiUrl + id + '/', headers);
    }

    public getClassifiedAdInsights(id: string): Observable<any> {
        return this.get(this.getClassifiedAdInsightsApiUrl + id + '/');
    }

    public getClassifiedAdByNameKey(adKey: string): Observable<any> {
        var headers = this.getPDHeader(true);
        return this.get(this.getClassifiedAdByNameKeyApiUrl + adKey + '/', headers);
    }

    public saveClassifiedAdImages(request: any): Observable<any> {
        return this.post(this.saveClassifiedAdImagesApiUrl, request);
    }

    public updateClassifiedAdStatus(id: string, status: string): Observable<any> {
        return this.post(this.updateClassifiedAdStatusApiUrl + id + '/' + encodeURIComponent(status) + '/', {});
    }

    public publishClassifiedAd(id: string): Observable<any> {
        return this.updateClassifiedAdStatus(id, ClassifiedAdStatus.Published);
    }

    public unPublishClassifiedAd(id: string): Observable<any> {
        return this.updateClassifiedAdStatus(id, ClassifiedAdStatus.Draft);
    }

    public soldClassifiedAd(id: string): Observable<any> {
        return this.updateClassifiedAdStatus(id, ClassifiedAdStatus.Sold);
    }

    public unSoldClassifiedAd(id: string): Observable<any> {
        return this.updateClassifiedAdStatus(id, ClassifiedAdStatus.Published);
    }

    public logClassifiedAdEvent(id: string, insightEventName: string, insightEventData: any = null, eventName: string = null) {

        var headers = this.addHeader('x-insight-event-name', insightEventName);

        if (insightEventData) {
            headers = this.addHeader('x-insight-event-data', insightEventData, headers);
        }

        if (!this.isNullOrEmpty(eventName)) {
            headers = this.addHeader('x-event-name', eventName, headers);
        }

        this.get(this.logClassifiedAdEventApiUrl + id + '/' + this.getId(), headers).subscribe(
            result => {
            });
    }

    public getPaymentGatewayDetails(requestType: string = "default"): Observable<any> {
        var headers = this.addHeader('x-pgrt', requestType);
        return this.get(this.getPaymentGatewayDetailsApiUrl, headers);
    }

    public paymentRequest(request: any, requestType: string = "default"): Observable<any> {
        var headers = this.addHeader('x-pgrt', requestType);
        request.userLocation = this.stateService.props$.userLocation;
        return this.post(this.paymentRequestApiUrl, request, headers);
    }

    public getPaymentToken(requestType: string = "default"): Observable<any> {
        var headers = this.addHeader('x-pgrt', requestType);
        return this.get(this.getPaymentTokenApiUrl, headers);
    }

    public getContactNo(): Observable<any> {
        return this.get(this.getContactNoApiUrl);
    }

    public getFieldLookupValues(dataSource: string): Observable<any> {
        dataSource = encodeURIComponent(dataSource);
        var url = this.getFieldLookupValuesApiUrl + dataSource + '/';
        return this.get(url);
    }

    public isOpen(classifiedAd: any): boolean {
        if (this.isNullOrEmpty(classifiedAd.lastOrderTime) || this.isNullOrEmpty(classifiedAd.lastOrderTime)) {
            return true;
        }

        var firstOrderTime = moment.tz(classifiedAd.firstOrderTime, 'h:mma', classifiedAd.timeZone);
        var lastOrderTime = moment.tz(classifiedAd.lastOrderTime, 'h:mma', classifiedAd.timeZone);

        var currentTime = moment().tz(classifiedAd.timeZone);

        if (classifiedAd.lastOrderTime.indexOf("am") > 0) {
            return (currentTime.isAfter(firstOrderTime) || currentTime.isBefore(lastOrderTime));
        } else {
            return (currentTime.isAfter(firstOrderTime) && currentTime.isBefore(lastOrderTime))
        }
    }

    public isClosed(classifiedAd: any): boolean {
        return !this.isOpen(classifiedAd);
    }

    public orderReadyFor(classifiedAd: any, applyTimeRounding: boolean = false): any {
        var currentTime: moment.Moment = moment.tz(classifiedAd.timeZone).add(classifiedAd.avgOrderReadyTimeInMinutes ? classifiedAd.avgOrderReadyTimeInMinutes : 30, 'minutes');

        if (applyTimeRounding) {
            var intervals = Math.floor(currentTime.minutes() / 15);
            if (currentTime.minutes() % 15 != 0)
                intervals++;
            if (intervals == 4) {
                currentTime.add('hours', 1);
                intervals = 0;
            }
            currentTime.minutes(intervals * 15);
            currentTime.seconds(0);
        }

        return currentTime;
    }

    publish(classfiedAd: any) {
        this.searchClassifiedAdsSubject.next(classfiedAd);
    }
}
