import { Component, ChangeDetectorRef, ViewChild, ViewChildren, QueryList, Renderer2, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController, IonSlides } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppImageUploader } from '../app-image-uploader/app.image.uploader';
import { ClassifiedAdsService } from '../../../services/classified.ads.service';

@Component({
    selector: 'taeam-image-video-view',
    templateUrl: 'image.video.view.html',
    styleUrls: ['image.video.view.scss']
})
export class ImageVideoView extends ObjectBase implements OnInit {

    @Input() baseUrl: any;
    @Input() data: any;
    @Input() player: any;
    @Input() hideSliderView: boolean;
    @Input() applyPadding: boolean = false;
    @Input() doNotCallScroll: boolean = false;
    @Input() showThumbnailInSlider: boolean = false;

    @Output("didScroll") didScroll: EventEmitter<any> = new EventEmitter();

    currentSlideIndex: number = 0;

    @ViewChildren('adPlayer') adPlayers: QueryList<any>;
    @ViewChildren('slide') slides: QueryList<any>;

    @ViewChild("adPlayer") adPlayer: any;
    @ViewChild("slider") slider: IonSlides;
    public assets: Array<any>;

    //@ViewChild("adPlayer1", { static: false }) adPlayer1: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;

    public slideOptions = {
        slidesPerView: 1.05,
        initialSlide: 0,
        speed: 400,
        spaceBetween: 10
    };


    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private restaurantService: RestaurantService,
        protected userService: UserService,
        private geolocation: Geolocation,
        public geoService: LocationService,
        private basketService: BasketService,
        private deviceService: DeviceService,
        private appVersionService: AppVersionService,
        private classifiedAdsService: ClassifiedAdsService,
        //private loadingController: LoadingController,
        private videoPlayer: VideoPlayer,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.restaurantService = RestaurantService.Instance;
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
        this.classifiedAdsService = ClassifiedAdsService.Instance;
    }

    ngOnInit() {
    }

    ionViewDidEnter() {
        //this.Log("Checking for video to play");
        //this.Log(this.isOneVideoOnly());
        //this.Log(this.adPlayers);
        //if (this.isOneVideoOnly() && this.adPlayers && this.adPlayers.length > 0) {
        //    this.Log("Now playing video")
        //    this.playVideo(this.adPlayers[0]);
        //}
    }

    getImagesAndVideoAssets(imagesAndVideos: Array<any> = null): Array<any> {
        if (this.assets && imagesAndVideos && imagesAndVideos.length == this.assets.length) {
            return this.assets;
        }

        this.assets = new Array<any>();

        if (!imagesAndVideos) {
            imagesAndVideos = this.data.imagesAndVideos;
        }

        if (imagesAndVideos && imagesAndVideos.length > 0) {
            for (var i = 0; i < imagesAndVideos.length; i++) {
                if (imagesAndVideos[i].isThumbnail && this.showThumbnailInSlider) {
                    this.assets.push(imagesAndVideos[i]);
                } else if (!imagesAndVideos[i].isThumbnail && !imagesAndVideos[i].isPoster) {
                    this.assets.push(imagesAndVideos[i]);
                }
            }
        }

        this.Log(this.assets);

        return this.assets;
    }

    isOneImageOnly(): boolean {
        this.getImagesAndVideoAssets();

        //if (this.menuItem) {
        //    return this.menuItem.imagesAndVideos && this.menuItem.imagesAndVideos.length == 1 && !this.isVideo(this.menuItem.imagesAndVideos[0].name);
        //}

        if (this.assets) {
            return this.assets.length == 1 && !this.isVideo(this.assets[0].name);
        }

        return false;
    }

    isOneVideoOnly(): boolean {
        this.getImagesAndVideoAssets();
        //if (this.menuItem) {
        //    return this.menuItem.imagesAndVideos && this.menuItem.imagesAndVideos.length == 1 && this.isVideo(this.menuItem.imagesAndVideos[0].name);
        //}
        if (this.assets) {
            return this.assets.length == 1 && this.isVideo(this.assets[0].name);
        }

        return false;
    }

    areMultipleImagesOrVideos(): boolean {
        this.getImagesAndVideoAssets();

        //if (this.menuItem) {
        //    return this.menuItem.imagesAndVideos && this.menuItem.imagesAndVideos.length > 1 && !this.hideSliderView;
        //}
        if (this.assets) {
            return this.assets.length > 1 && !this.hideSliderView;
        }

        return false;
    }

    play($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        } 

        this.playVideo(player);
        $event.stopPropagation();
    }

    pause($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.pauseVideo(player);
        $event.stopPropagation();
    }

    mute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.muteAudio(player);
        $event.stopPropagation();
    }

    unmute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.unMuteAudio(player);
        $event.stopPropagation();
    }

    //slideChanged($event) {
    //    this.Log("Slide changed event fired");
    //    this.Log($event);
    //    this.Log(this.slider);
    //    this.slider.getActiveIndex().then(index => {
    //        this.currentSlideIndex = index;
    //    });
    //}

    //slideTaped($event) {
    //    this.Log($event);
    //    this.Log("Slide taped");
    //    this.slider.getActiveIndex().then(index => {
    //        this.currentSlideIndex = index;
    //    });
    //}

    //changeSlideIndex(num: number) {
    //    this.Log("Slide index function  is called", num);
    //    if (num > 0) {
    //        this.currentSlideIndex += num;
    //    } else if (num < 0 && this.currentSlideIndex > 0) {
    //        this.currentSlideIndex -= num;
    //    }
    //}

    //changeSlideIndex($event) {
    //    this.Log("Slide index function  is called", $event);
    //}

    slideChange(slider: IonSlides) {       
        this.Log(slider);
        slider.getActiveIndex().then(index => {
            this.Log("Get index completed", index);
            ////this.currentSlideIndex = index;
            //this.Log(this.currentSlideIndex);
            //this.Log(this.slides);
        });

        if (this.didScroll && !this.doNotCallScroll) {
            this.Log("Calling didscroll ");
            this.didScroll.emit({ adPlayers: this.adPlayers });
        } else if (this.doNotCallScroll) {
            this.checkPlayers(this.adPlayers);
        }
    }

    public getImageUrl(name: any) {
        if (name) {
            if (!this.isNullOrEmpty(this.baseUrl) && this.baseUrl.charAt(this.baseUrl.length - 1) != '/') {
                this.baseUrl = this.baseUrl + '/';
            }
            //alert(JSON.stringify(this.stateService.props$.classifiedAd));
            //alert(environment.serviceResourcesBaseUrl + this.baseUrl + name);
            return environment.serviceResourcesBaseUrl + this.baseUrl + name;
        }
    }

    public getAssetPosterUrl(assets: any, index: any): string {
        if (assets && assets.length > index && index >= 0) {
            if (!this.isNullOrEmpty(this.baseUrl) && this.baseUrl.charAt(this.baseUrl.length - 1) != '/') {
                this.baseUrl = this.baseUrl + '/';
            }
            for (var i = index; i < assets.length; i++) {
                if (assets[i].isPoster) {
                    return environment.serviceResourcesBaseUrl + this.baseUrl + assets[i].name;
                }
            }
        }

        return "";
    }

    public delete(index: any) {
        if (index >= 0 && this.data && this.data.imagesAndVideos && this.data.imagesAndVideos.length > 0) {
            this.data.imagesAndVideos.splice(index, 1);
            this.cdr.detectChanges();
        }
    }

    async presentAppImageUploaderModal(obj: any, index: number) {

        var path: string = this.baseUrl;
        var baseId: string = this.baseUrl.split('/')[1];
        var format: string = obj.name.split('.')[1].toLowerCase();

        if (!this.contains(obj.base64, "data:image")) {
            obj.base64 = "data:image/png:base64," + obj.base64;
        }

        var imageChangedEvent: any = { "isTrusted": true, imageBase64: obj.base64, index: index, baseId, path, format };

        const modal = await this.modalController.create({
            component: AppImageUploader,
            componentProps: {
                imageChangedEvent
            },
            cssClass: "image-editor-model-full"
        });

        modal.onDidDismiss()
            .then((result: any) => {
                if (result && result.data && !this.isNullOrEmpty(result.data.updatedBase64)) {

                    var id = this.getId();
                    var name = id + '.' + format
                    var imageObj = { id, name, contents: result.data.updatedBase64, path: result.data.path };

                    this.classifiedAdsService.saveClassifiedAdImages(imageObj).subscribe(newResult => {
                        this.data.imagesAndVideos[result.data.index].id = id;
                        this.data.imagesAndVideos[result.data.index].name = name;
                    });
                }
            });

        //this.displayActionSheet("News", "");
        await modal.present();
    }

    //async presentAppImageUploaderModal(url: any) {

    //    let image = new Image()
    //    image.crossOrigin = "Anonymous";
    //    image.src = url.src;

    //    const canvas = document.createElement('canvas');
    //    const context = canvas.getContext('2d');
    //    canvas.width = url.width;
    //    canvas.height = url.height;
    //    context.drawImage(url, 0, 0);
    //    alert(canvas.toDataURL());

    //    const modal = await this.modalController.create({
    //        component: AppImageUploader,
    //        componentProps: {
    //            //imageChangedEvent: { "isTrusted": true, imageURL: url },
    //            //imageURL: url
    //            //imageChangedEvent: url
    //            imageChangedEvent: { "isTrusted": true, sourceImage: url },
    //        }
    //    });

    //    //this.displayActionSheet("News", "");
    //    await modal.present();
    //}

    //public slideChanged($event) {
    //    this.LogClear();
    //    this.Log($event);
    //    //alert("here");
    //    //this.slider.nativeElement.getActiveIndex().then(index => {
    //    //    console.log(index);
    //    //});
    //    this.slider.getActiveIndex().then((index: number) => {
    //        this.Log(index);
    //        this.currentSlideIndex = index;
    //    });
    //}

    //public updateIndex(indexCounter) {
    //    alert("update index");
    //    alert(indexCounter);
    //    this.currentSlideIndex = this.currentSlideIndex + indexCounter;
    //    if (this.currentSlideIndex < 0) {
    //        this.currentSlideIndex = 0;
    //    }

    //    alert(this.currentSlideIndex);
    //}
}