import { ChangeDetectorRef, ViewChild, ViewChildren, QueryList, Renderer2, Input, OnInit, Output, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { NavController, MenuController, IonSlides } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { CheckoutProcess, ServiceResourceAdStatus, ServiceResourceAdThemes } from 'src/app/shared/models/constants.model';
import { environment } from '../../environments/environment';
import { LocationService } from '../services/location.service';

@Directive()
export class ServiceResourceCoreBase extends ObjectBase implements OnInit {
    @Input() serviceResourceAd: any;

    protected locationService: LocationService;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected renderer: Renderer2 = null) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.locationService = LocationService.Instance;
    }

    ngOnInit() {
    }    

    public getServiceResourceTitle(prefix: string = null, postfix: string = null): string {
        var value = this.getEmptyIfNull(prefix, "") + " " + this.getService().serviceSingularName + this.getEmptyIfNull(postfix, "");
        if (value) {
            return value.trim();
        }

        return null;
    }

    public getServiceResourceAd(serviceResourceAd: any): any {
        if (!serviceResourceAd) {
            serviceResourceAd = this.stateService.props$.serviceResourceAd;
        }

        return serviceResourceAd;
    }

    public isSameTheme(serviceResourceAd: any, themeName: string): boolean {
        serviceResourceAd = this.getServiceResourceAd(serviceResourceAd);
        if (serviceResourceAd && serviceResourceAd.adThemeId == themeName) {
            return true;
        }

        return false;
    }

    public isDefaultTheme(serviceResourceAd: any): boolean {
        var result = this.isSameTheme(serviceResourceAd, ServiceResourceAdThemes.Default);
        if (result == false && this.wlaActive == false && this.isNullOrEmpty(serviceResourceAd.adThemeId)) {
            result = true; //override in case marketplace existing shops do not have the theme value
        }

        return result;
    }

    public isCareWorkerTheme(serviceResourceAd: any): boolean {
        var result = this.isSameTheme(serviceResourceAd, ServiceResourceAdThemes.CareWorker);
        return result;
    }

    public getRate(serviceResourceAd: any): any {
        if (serviceResourceAd) {
            if (serviceResourceAd.dailyRate > 0) {
                return serviceResourceAd.priceSymbol + serviceResourceAd.dailyRate + "/pd"
            } else if (serviceResourceAd.hourlyRate > 0) {
                return serviceResourceAd.priceSymbol + serviceResourceAd.hourlyRate + "/ph"
            }
        }

        return null;
    }

    public getExperience(serviceResourceAd: any): any {
        if (serviceResourceAd) {
            if (serviceResourceAd.experienceInMonths > 0) {
                if (serviceResourceAd.experienceInMonths < 12) {
                    return serviceResourceAd.experienceInMonths + " Mths"
                } else {
                    return this.getYears(serviceResourceAd.experienceInMonths) + " Yrs"
                }
            }
        }

        return null;
    }

    public getCustomersServed(serviceResourceAd: any): any {
        if (serviceResourceAd) {
            if (serviceResourceAd.totalCustomersServed > 0) {
                if (serviceResourceAd.totalCustomersServed < 1000) {
                    return serviceResourceAd.totalCustomersServed;
                } else {
                    return this.getAbsoluteNumberInThousands(serviceResourceAd.totalCustomersServed) + "k"
                }               
            }
        }

        return "1.5k"
        return null;
    }

    public goToBookingCalendar(event: any, serviceResourceAd: any) {
        event.stopPropagation();

        this.stateService.setProps({ serviceResourceAd: serviceResourceAd, serviceCustomerRequest: null });
        this.debugCore();
        this.go('booking-calendar');
    }

    public isVisitRate(rateType: string): boolean {
        return this.isSame(rateType, "Visit");
    }

    public isHourlyRate(rateType: string): boolean {
        return this.isSame(rateType, "Hourly");
    }

    public isDailyRate(rateType: string): boolean {
        return this.isSame(rateType, "Daily");
    }

    public isMonthlyRate(rateType: string): boolean {
        return this.isSame(rateType, "Monthly");
    }

    public showAddressFeature(features: string): boolean {
        return this.hasFeature(features, "show-address");
    }

    public showCallFeature(features: string): boolean {
        return this.hasFeature(features, "show-call");
    }

    public showChatFeature(features: string): boolean {
        return this.hasFeature(features, "show-chat");
    }

    public showBookingFeature(features: string): boolean {
        return this.hasFeature(features, "show-booking");
    }

    public gotoCheckout() {
        if (this.stateService.props$ && this.isSame(this.stateService.props$.checkoutProcess, CheckoutProcess.RegisterServiceResource)) {
            this.go("/serviceresource/checkout");
        } else if (this.stateService.props$ && this.isSame(this.stateService.props$.checkoutProcess, CheckoutProcess.BookingCalendar)) {
            this.go("/serviceresource/customer-request-checkout");
        } else {
            this.go("/serviceresource/checkout");
        }
    }

    public getPaymentRequestObject(): any {
        if (this.stateService.props$ && this.isSame(this.stateService.props$.checkoutProcess, CheckoutProcess.RegisterServiceResource)) {
            return this.stateService.props$.serviceResourceAd;
        } else if (this.stateService.props$ && this.isSame(this.stateService.props$.checkoutProcess, CheckoutProcess.BookingCalendar)) {
            return this.stateService.props$.serviceCustomerRequest;
        } else {
            return this.stateService.props$.serviceResourceAd;
        }
    }

    public goAdHome() {
        var url = this.getHomeUrl();
        if (url) {
            this.resetHomeUrl();
            this.go(url);
        } else {
            this.goServiceHome();
        }
    }
}