export const environment = {
    production: false,
    appInsights: {
        instrumentationKey: 'adae7ed6-26ab-4844-b116-bacc071836ca'
    },
    traceEnabled: true,
    applicationInsightsEnabled: false,
    apiURL: 'developmentApi',
    version: '1.0 Build 1',
    release: 1, //This should be number only and counter 
    showDebug: false,
    captchaKey: '6LceUCAUAAAAAF9qwKS3x272x0lVq-SOlIg09f54',
    //stripeApiKey: 'pk_test_7zgYj8tjaXLt36QkiDyImbcE00QOhV47MA',
    //braintreeApiToken: 'sandbox_v2pft36y_97dqwx3tssfvdw65',
    googleApiKey: 'AIzaSyDl3cstvQsyKcOccEnjNKyW0BOaUf7WXdM',
    googleApiUrl: 'https://maps.google.com/maps/api/geocode/json?',
    weatherApiUrl: 'https://api.weatherapi.com/v1/current.json?key=3692248db2b64dfd9fa220551200505',
    ipstack: 'http://api.ipstack.com/###ip-address###?access_key=5589896a87fd48906675dcf60ec27703&format=1',
    ipifyApiUrl: 'https://api.ipify.org/?format=json',
    xEnvironment: 'xdev',
    toastNotificationDuration: 3000,
    taeamApimKey: '9098b92bb77e4657b833e8b4fc3149c4',
    taeamApiVersion: 'v2',
    appName: 'Crazon',
    appId: 'crazon',
    appVariant: 'crazon-app',
    applePayMerchantId: 'merchant.apple.test',
    logoUrl: '/assets/images/crazon-home-logo.svg',
    recentlyViewedLimit: 10,
    rememberMyLocationLabelText: "Remember my location",
    wlaActive: false,
    wlaServiceId: "",
    wlaRestaurantGroupId: "",
    wlaRestaurantId: "",
    wlaAllowDineIn: false,
    wlaAllowCollection: false,
    wlaShowPowerByCrazon: false,
    wlaShowName: false,
    wlaShowAddress: false,
    wlaShowChangeLocation: false,
    wlaRecentlyViewedLimit: 10,
    wlaShowFeaturedText: false,
    wlaShowChangeLocationInRecommendation: false,
    wlaShowOrderReadyFor: false,
    isCardView: true,
    homePageDisplayStyle: "default",

    //{\"wlaAllowDineIn\":false,\"wlaAllowCollection\":false,\"wlaShowPowerByCrazon\":true,\"wlaShowName\":true,\"wlaShowAddress\":true,\"wlaShowChangeLocation\":true,\"wlaShowFeaturedText\":false}

    //wlaActive: true,
    //wlaServiceId: "groceries",
    //wlaRestaurantGroupId: "e1290734-6f39-4edd-8cf1-4816b94688db",
    //wlaRestaurantId: "e1290734-6f39-4edd-8cf1-4816b94688db",
    //wlaAllowDineIn: false,
    //wlaAllowCollection: false,
    //wlaShowPowerByCrazon: true,
    //wlaShowName: true,
    //wlaShowAddress: true,
    //wlaShowChangeLocation: true,
    //wlaRecentlyViewedLimit: 10,
    //wlaShowFeaturedText: false,
    //wlaShowChangeLocationInRecommendation: false,
    //wlaShowOrderReadyFor: false,
    //isCardView: false,

    //wlaActive: true,
    //wlaServiceId: "usa-gifts-marketplace",
    //wlaRestaurantGroupId: "bd213637-e190-4fb9-9e41-5d54fc6fef4c",
    //wlaRestaurantId: "bd213637-e190-4fb9-9e41-5d54fc6fef4c",
    //wlaAllowDineIn: false,
    //wlaAllowCollection: false,
    //wlaShowPowerByCrazon: true,
    //wlaShowName: false,
    //wlaShowAddress: false,
    //wlaShowChangeLocation: true,
    //wlaRecentlyViewedLimit: 3,
    //wlaShowFeaturedText: false,
    //wlaShowChangeLocationInRecommendation: false,
    //wlaShowOrderReadyFor: false,
    //isCardView: true,

    geolocationApiUrl: 'https://api.postcodes.io/postcodes/',
    uiBaseUrl: 'https://mobile.crazon.io/',
    resourcesBaseUrl: 'https://assets.crazon.io/mobile/',
    marketingResourcesBaseUrl: "https://assets.crazon.io/marketing/",
    marketplaceResourcesBaseUrl: "https://assets.crazon.io/marketplace/",
    classifiedAdsResourcesBaseUrl: "https://assets.crazon.io/classified-ads/",
    serviceResourcesBaseUrl: "https://assets.crazon.io/",
    serviceResourceAdsResourcesBaseUrl: "https://assets.crazon.io/serviceresource/",

    apiVersioningApiUrl: 'https://api.taeam.co/msappv/',
    currencyConverterApiUrl: 'https://api.taeam.co/mscc/',
    restaurantApiUrl: 'https://api.taeam.co/msrest/',
    identityApiUrl: 'https://api.taeam.co/msid/',
    orderApiUrl: 'https://api.taeam.co/msod/',
    voucherApiUrl: 'https://api.taeam.co/msvc/',
    supportApiUrl: 'https://api.taeam.co/mssup/',
    trackingApiUrl: 'https://api.taeam.co/msp/',
    trackingQsApiUrl: 'https://api.taeam.co/mspqs/',
    favouritesApiUrl: 'https://api.taeam.co/msf/',
    creditApiUrl: 'https://api.taeam.co/mscrd/',
    smartPropertyApiUrl: 'https://api.taeam.co/mssp/',
    classifiedAdsApiUrl: 'https://api.taeam.co/mscad/',
    classifiedAdsSearchApiUrl: 'https://api.taeam.co/mscads/',
    serviceResourceAdsApiUrl: 'https://api.taeam.co/mssr/',
    serviceResourceAdsSearchApiUrl: 'https://api.taeam.co/mssrs/',

    //currencyConverterApiUrl: 'http://192.168.86.25:7083/api/',
    //restaurantApiUrl: 'http://192.168.1.242:7071/api/',
    //identityApiUrl: 'http://192.168.86.25:7076/api/'
    //orderApiUrl: 'http://192.168.86.25:7072/api/',
    //voucherApiUrl: 'http://192.168.86.25:7075/api/',
    //supportApiUrl: 'http://192.168.86.25:7073/api/',
    //trackingApiUrl: 'http://192.168.86.25:7079/api/'
    //trackingQsApiUrl: 'https://api.taeam.co/mspqs/',
    //favouritesApiUrl: 'http://192.168.86.25:7085/api/',
    //creditApiUrl: 'http://192.168.86.25:7086/api/',
    //creditApiUrl: 'http://192.168.1.242:7086/api/',
    //smartPropertyApiUrl: 'http://192.168.1.242:7089/api/',
    //classifiedAdsApiUrl'http://192.168.1.242:7091/api/',
    //serviceResourceAdsApiUrl: 'http://192.168.86.25:7094/api/',
    //serviceResourceAdsSearchApiUrl: 'http://192.168.86.25:7095/api/',

    //currencyConverterApiUrl: 'http://192.168.86.25:7083/api/',
    //restaurantApiUrl: 'http://localhost:7071/api/',
    //identityApiUrl: 'http://192.168.86.25:7076/api/'
    //orderApiUrl: 'http://192.168.86.25:7072/api/',
    //voucherApiUrl: 'http://192.168.86.25:7075/api/',
    //supportApiUrl: 'http://192.168.86.25:7073/api/',
    //trackingApiUrl: 'http://192.168.1.242:7079/api/'
    //trackingQsApiUrl: 'https://api.taeam.co/mspqs/',
    //favouritesApiUrl: 'http://192.168.86.25:7085/api/',
    //creditApiUrl: 'http://192.168.86.25:7086/api/',
    //creditApiUrl: 'http://192.168.1.242:7086/api/',
    //smartPropertyApiUrl: 'http://192.168.86.25:7089/api/',
    //classifiedAdsApiUrl: 'http://192.168.86.25:7091/api/',
    //classifiedAdsSearchApiUrl: 'http://192.168.86.25:7092/api/',
    //serviceResourceAdsApiUrl: 'http://192.168.86.25:7094/api/',
    //serviceResourceAdsSearchApiUrl: 'http://192.168.86.25:7095/api/',
};