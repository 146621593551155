import { Device, DeviceId, DeviceInfo } from '@capacitor/device';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ObjectCore } from '../shared/object.core';
import { Common } from '../shared/common';
import { App, AppInfo } from '@capacitor/app';

@Injectable({
    providedIn: 'root'
})
export class DeviceService extends ObjectCore {

    private static _instance: DeviceService;
    public deviceInfo: DeviceInfo;
    public appInfo: AppInfo;
    public deviceId: DeviceId;
    public isAndroid: boolean;
    public isiOS: boolean;
    public isWeb: boolean;

    public isAndroidDevice: boolean;
    public isiOSDevice: boolean;
    public deviceObj: any;

    constructor(protected http: HttpClient) {
        super();
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<DeviceService>(DeviceService));

        //Commenting below isWeb becuase it is not working on ios
        //obj.isWeb = true; //because it is failing for web

        //obj.Log("Device object instance created");
        obj.getDeviceInfo().then(device => {
            //obj.Log("Response from device service");
            //obj.Log(device);
            obj.deviceInfo = device;
            obj.isAndroid = device.platform == 'android';
            obj.isiOS = device.platform == 'ios';
            obj.isWeb = device.platform == 'web';
            if (device.osVersion) {
                var osVersion: string = device.osVersion;
                obj.isAndroidDevice = osVersion.toLowerCase().indexOf("android") >= 0;
            }

            if (device.model) {
                var model: string = device.model;
                obj.isiOSDevice = model.toLowerCase().indexOf("iphone") >= 0;
            }
            
            //obj.Log(obj);
        });

        //obj.Log(obj);
        return obj;
    }

    public async getDeviceTypeId() {
        const info = await this.getDeviceInfo();

        if (info) {
            if (info.platform === 'ios') {
                return 2; //ios
            }

            if (info.platform === 'android') {
                return 3; //android
            }
        }

        return 1; //web
    }

    //public async isAndroid(): Promise<boolean> {
    //    var device = await this.getDeviceInfo();
    //    return device.platform == 'android';
    //} 

    //public async isiOS(): Promise<boolean> {
    //    var device = await this.getDeviceInfo();
    //    return device.platform == 'ios';
    //} 

    public async getDeviceInfo(): Promise<any> {
        if (!this.deviceInfo) {
            this.deviceInfo = await Device.getInfo();
            this.appInfo = await App.getInfo();
            this.deviceId = await Device.getId();
            this.Log("Response from device service");
            this.Log(this.deviceInfo);
            this.isAndroid = this.deviceInfo.platform == 'android';
            this.isiOS = this.deviceInfo.platform == 'ios';
            this.isWeb = this.deviceInfo.platform == 'web';
            if (this.deviceInfo.osVersion) {
                var osVersion: string = this.deviceInfo.osVersion;
                this.isAndroidDevice = osVersion.toLowerCase().indexOf("android") >= 0;
            }

            if (this.deviceInfo.model) {
                var model: string = this.deviceInfo.model;
                this.isiOSDevice = model.toLowerCase().indexOf("iphone") >= 0;
            }

            this.Log(this.deviceInfo);
        }

        this.deviceObj = {
            model: this.deviceInfo.model,
            platform: this.deviceInfo.platform,
            //appVersion: this.deviceInfo.appVersion,
            osVersion: this.deviceInfo.osVersion,
            manufacturer: this.deviceInfo.manufacturer,
            isVirtual: this.deviceInfo.isVirtual,
            //uuid: this.deviceInfo.uuid,
            isAndroid: this.isAndroid,
            isAndroidDevice: this.isAndroidDevice,
            isiOS: this.isiOS,
            isiOSDevice: this.isiOSDevice,
            isWeb: this.isWeb
        };

        if (this.appInfo) {
            this.deviceObj.appVersion = this.appInfo.version;
        }

        if (this.deviceId) {
            this.deviceObj.uuid = this.deviceId.uuid;
        }

        return this.deviceObj;
    }
}
