import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import 'moment-timezone';

declare var google: any;

@Injectable()
export class CreditService extends ServiceBase {

    private static _instance: CreditService;

    private getMarketplaceCreditRegisterApiUrl: string;
    private getUserCreditRegisterApiUrl: string;
    private getUserCreditRequestsApiUrl: string;
    private createUserCreditRequestApiUrl: string;
    private approveUserCreditRequestApiUrl: string;
    private rejectUserCreditRequestApiUrl: string;
    private getUserMarketplaceCreditRequestsApiUrl: string;
    private canUserPayByCreditApiUrl: string;
    private makeCreditPaymentApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.getMarketplaceCreditRegisterApiUrl = environment.creditApiUrl + 'credit/marketplace/register/get';
        this.getUserCreditRegisterApiUrl = environment.creditApiUrl + 'credit/user/register/get';
        this.getUserCreditRequestsApiUrl = environment.creditApiUrl + 'credit/user/requests/get';
        this.createUserCreditRequestApiUrl = environment.creditApiUrl + 'credit/user/requests/create';
        this.approveUserCreditRequestApiUrl = environment.creditApiUrl + 'credit/user/requests/approve';
        this.rejectUserCreditRequestApiUrl = environment.creditApiUrl + 'credit/user/requests/reject';
        this.getUserMarketplaceCreditRequestsApiUrl = environment.creditApiUrl + 'credit/user/request/get';
        this.canUserPayByCreditApiUrl = environment.creditApiUrl + 'credit/user/canpay';
        this.makeCreditPaymentApiUrl = environment.creditApiUrl + 'credit/user/register/makepayment';

        if (!CreditService._instance) {
            CreditService._instance = this;
        }
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance;
    }

    public getUserCreditRegister(): Observable<any> {
        return this.get(this.getUserCreditRegisterApiUrl);
    }

    public getUserCreditRequests(): Observable<any> {
        return this.get(this.getUserCreditRequestsApiUrl);
    }

    public getMarketplaceCreditRegister(): Observable<any> {
        return this.get(this.getMarketplaceCreditRegisterApiUrl);
    }

    public createUserCreditRequest(request: any): Observable<any> {
        return this.post(this.createUserCreditRequestApiUrl, request);
    }

    public approveUserCreditRequest(request: any): Observable<any> {
        return this.post(this.approveUserCreditRequestApiUrl, request);
    }

    public rejectUserCreditRequest(request: any): Observable<any> {
        return this.post(this.rejectUserCreditRequestApiUrl, request);
    }

    public canUserPayByCredit(request: any): Observable<any> {
        return this.post(this.canUserPayByCreditApiUrl, request);
    }

    public getUserMarketplaceCreditRequest(restaurantId: string, restaurantGroupId: string): Observable<any> {
        if (restaurantId == restaurantGroupId) {
            return this.get(this.getUserMarketplaceCreditRequestsApiUrl + '/' + restaurantId);
        } else {
            return this.get(this.getUserMarketplaceCreditRequestsApiUrl + '/' + restaurantId + '/' + restaurantGroupId);
        }
    }

    public makeCreditPayment(request: any): Observable<any> {
        return this.post(this.makeCreditPaymentApiUrl, request);
    }

    public calculateUserRegisterRunningBalance(userRegister: any): any {
        if (!userRegister || userRegister.length == 0) {
            return userRegister;
        }

        var balance = 0;
        userRegister = userRegister.sort((x, y) => +new Date(x.createdOn) - +new Date(y.createdOn));
        this.Log(userRegister);
        for (var i = 0; i < userRegister.length; i++) {
            if (!userRegister[i].isRejectedPayment) {
                balance = (balance + userRegister[i].debit) - userRegister[i].credit;
                //userRegister[i].createdOn = this.addDays(i + 1);
                userRegister[i].balance = balance;
            }
        }

        return userRegister.sort((x, y) => +new Date(y.createdOn) - +new Date(x.createdOn));
    }

    addDays(days: number): Date {
        var futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + days);
        return futureDate;
    }
}
