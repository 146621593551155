<ion-menu side="start" content-id="main">
    <ion-header>
        <ion-toolbar translucent>
            <ion-title>Menu</ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <ion-list>
            <ion-item *ngIf="!isUserLoggedIn()" (click)="login()">
                <ion-icon src="/assets/icons/login.svg" slot="start"></ion-icon>
                <ion-label>Login</ion-label>
            </ion-item>
            <ion-item *ngIf="isUserLoggedIn()" (click)="showProfile()">
                <ion-icon src="/assets/icons/profile.svg" slot="start"></ion-icon>
                <ion-label>Profile</ion-label>
            </ion-item>
            <!--<ion-item (click)="showServices()">-->
            <!--*ngIf="this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 1"-->
            <!--<ion-icon name="apps" slot="start"></ion-icon>
        <ion-label>Services</ion-label>
    </ion-item>-->
            <!--<ion-item (click)="showCreditDashboard()" *ngIf="this.stateService.props$.user && this.stateService.props$.user.isCreditUser">
                <ion-icon src="/assets/icons/request-credit-v4.svg" slot="start"></ion-icon>
                <ion-label>Credit Dashboard</ion-label>
            </ion-item>-->
            <ion-item (click)="showCreditRequests()" *ngIf="this.stateService.props$.user && this.stateService.props$.user.isCreditUser">
                <ion-icon src="/assets/icons/request-credit-v4.svg" slot="start"></ion-icon>
                <ion-label>Credit Dashboard</ion-label>
            </ion-item>
            <ion-item (click)="showTerms()">
                <ion-icon src="/assets/icons/terms.svg" slot="start"></ion-icon>
                <ion-label>Terms & Conditions</ion-label>
            </ion-item>
            <ion-item (click)="showPrivacy()">
                <ion-icon src="/assets/icons/privacy.svg" slot="start"></ion-icon>
                <ion-label>Privacy Policy</ion-label>
            </ion-item>
            <ion-item (click)="showCookie()">
                <ion-icon src="/assets/icons/policy.svg" slot="start"></ion-icon>
                <ion-label>Cookie Policy</ion-label>
            </ion-item>
            <ion-item (click)="aboutUs()" *ngIf="!wlaActive">
                <ion-icon src="/assets/icons/crazon.svg" slot="start"></ion-icon>
                <ion-label>About Us</ion-label>
            </ion-item>
            <ion-item (click)="joinOurNetwork()" *ngIf="!wlaActive && showJoinNetworkOption()">
                <ion-icon src="/assets/icons/join.svg" slot="start"></ion-icon>
                <ion-label>Join Our Network</ion-label>
            </ion-item>
            <!--<ion-item (click)="showSettings()">
        <ion-icon name="settings" slot="start"></ion-icon>
        <ion-label>Settings</ion-label>
    </ion-item>-->
            <ion-item (click)="news($event)" *ngIf="!wlaActive">
                <ion-icon src="/assets/icons/newspaper.svg" slot="start"></ion-icon>
                <ion-label>News</ion-label>
            </ion-item>
            <ion-item (click)="showSocialSharingForTaeam($event)" *ngIf="!wlaActive">
                <ion-icon src="/assets/icons/share.svg" slot="start"></ion-icon>
                <ion-label>Share</ion-label>
            </ion-item>
            <ion-item *ngIf="isUserLoggedIn()" (click)="logout()">
                <ion-icon src="/assets/icons/logout.svg" slot="start"></ion-icon>
                <ion-label>Logout</ion-label>
            </ion-item>
        </ion-list>
        <app-powered-by-message></app-powered-by-message>
    </ion-content>
</ion-menu>
