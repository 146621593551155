<div class="cookie-consent" *ngIf="showCookieConsent">
  <div>
    <h3>Your privacy matters</h3>
  </div>
  <div class="cookie-text">
    <p>We use cookie and similar technologies to personalise your experience on Crazon. You accept the use of the cookie or other similar technologies by closing or dismissing this notice, by clicking on link or below button or by continuing to browse otherwise. Please <a href="/cookies" target="_blank">click here</a> to learn more about our cookie policy.</p>
  </div>
  <div>
    <button (click)="agreeToConsent()">OK</button>
  </div>
</div>
