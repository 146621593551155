import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { Common } from '../shared/common';

@Injectable({
    providedIn: 'root'
})
export class AppVersionService extends ServiceBase {

    private static _instance: AppVersionService;

    private getAppVersionApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.getAppVersionApiUrl = environment.apiVersioningApiUrl + 'app/version/user/';
    }

    public static get Instance() {
        return this._instance || (this._instance = Common.InjectorInstance.get<AppVersionService>(AppVersionService));
    }

    public getAppVersion(): Observable<any> {
        return this.get(this.getAppVersionApiUrl);
    }
}