import { Component, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ObjectBase } from 'src/app/shared/object.base';
import { NavController } from '@ionic/angular';
import { DeviceService } from 'src/app/services/device.service';
import { SupportService } from '../../../services/support.service';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../image-cropper';
import { base64ToFile } from '../image-cropper/blob.utils';

@Component({
    selector: 'app-image-uploader',
    templateUrl: 'app.image.uploader.html',
    styleUrls: ['app.image.uploader.scss']
})
export class AppImageUploader extends ObjectBase implements AfterViewInit {
    private deviceService: DeviceService;
    private deviceInfo: any;

    @Input() imageChangedEvent: any = '';
    croppedImage: any = '';
    canvasRotation = 0;
    rotation = 0;
    scale = 1;
    showCropper = false;
    containWithinAspectRatio = false;
    transform: ImageTransform = {};

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected supportService: SupportService,
        private sanitizer: DomSanitizer) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.deviceService = DeviceService.Instance;
        this.deviceService.getDeviceInfo().then((deviceInfo) => {
            this.deviceInfo = deviceInfo;
            this.version = this.deviceInfo.appVersion;
        });
    }

    ngAfterViewInit() {
        this.fileChangeEvent(this.imageChangedEvent);
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        console.log(event, base64ToFile(event.base64));
    }

    imageLoaded() {
        this.showCropper = true;
        console.log('Image loaded');
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        console.log('Cropper ready', sourceImageDimensions);
    }

    loadImageFailed() {
        console.log('Load failed');
    }

    rotateLeft() {
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight() {
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    private flipAfterRotate() {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }


    flipHorizontal() {
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical() {
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    resetImage() {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
    }

    zoomOut() {
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    zoomIn() {
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    toggleContainWithinAspectRatio() {
        this.containWithinAspectRatio = !this.containWithinAspectRatio;
    }

    updateRotation() {
        this.transform = {
            ...this.transform,
            rotate: this.rotation
        };
    }

    dismiss(data: any = null) {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        if (!data) {
            data = { 'dismissed': true };
        }

        this.modalController.dismiss(data);
    }

    update() {
        var updatedBase64 = this.croppedImage;
        var index: number = this.imageChangedEvent.index;
        var path: number = this.imageChangedEvent.path;
        this.dismiss({ 'dismissed': true, updatedBase64: updatedBase64, index, path });
    }
}
