import { Component, ChangeDetectorRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import { UiStateService } from 'src/app/services/ui.state.service';
import { NavController } from '@ionic/angular';
import { ObjectBase } from '../../object.base';

@Component({
    selector: 'taeam-marketing-data',
    templateUrl: 'marketing.data.html',
    styleUrls: ['marketing.data.scss']
})
export class MarketingData extends ObjectBase {

    public marketingData: any = {};
    @Input() showMarketingDataForm: boolean;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private restaurantService: RestaurantService,
        protected userService: UserService) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.restaurantService = RestaurantService.Instance;
        this.showMarketingDataForm = true;
    }

    async saveMarketingData() {
        const loader = await this.showLoader();
        this.Log(this.marketingData);
        if (!this.marketingData.fullName || this.marketingData.fullName.trim().length == 0) {
            this.hideLoader();
            this.displayError("Please enter your full name");
            return false;
        }

        if (!this.marketingData.email || this.marketingData.email.trim().length == 0) {
            this.hideLoader();
            this.displayError("Please enter your email address");
            return false;
        }

        if (!this.marketingData.mobileNo || this.marketingData.mobileNo.trim().length == 0) {
            this.hideLoader();
            this.displayError("Please enter your mobile");
            return false;
        }

        if (!this.marketingData.postcode || this.marketingData.postcode.trim().length == 0) {
            this.hideLoader();
            this.displayError("Please enter your postcode");
            return false;
        }

        this.marketingData.pnt = this.stateService.device$.pnt;
        this.marketingData.isRestaurantRecommendation = false;
        this.personaliseService.saveMarketingData(this.marketingData).subscribe(
            result => {
                this.stateService.setDevice({ userMrdcAlready: true });
                this.hideLoader();
                this.displayInfomation("Thank you, we will keep you posted");
            },
            error => {
                this.handleError(error);
            }
        );
    }
}