import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class MonitoringService {

    private static _instance: MonitoringService;

    appInsights: ApplicationInsights;

    constructor() {
        if (environment.applicationInsightsEnabled) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.appInsights.instrumentationKey,
                    enableAutoRouteTracking: true // option to log all route changes
                }
            });
            this.appInsights.loadAppInsights();
        }
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance || (this._instance = new this());
    }

    logPageView(name?: string, url?: string) { // option to call manually
        if (environment.applicationInsightsEnabled) {
            this.appInsights.trackPageView({
                name: name,
                uri: url
            });
        }
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        if (environment.applicationInsightsEnabled) {
            this.appInsights.trackEvent({ name: name }, properties);
        }
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (environment.applicationInsightsEnabled) {
            this.appInsights.trackMetric({ name: name, average: average }, properties);
        }
    }

    logException(exception: Error, severityLevel?: number) {
        if (environment.applicationInsightsEnabled) {
            this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        }
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (environment.applicationInsightsEnabled) {
            this.appInsights.trackTrace({ message: message }, properties);
        }
    }
}
