import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import { UiStateService } from 'src/app/services/ui.state.service';
import { NavController } from '@ionic/angular';
import { ObjectBase } from '../../object.base';

@Component({
    selector: 'taeam-recommend-restaurant',
    templateUrl: 'recommend.restaurant.html',
    styleUrls: ['recommend.restaurant.scss']
})
export class RecommendRestaurant extends ObjectBase {

    public recommendRestaurant: any = {};

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private restaurantService: RestaurantService,
        protected userService: UserService) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.restaurantService = RestaurantService.Instance;
    }

    async saveData() {
        const loader = await this.showLoader();

        if (!this.recommendRestaurant.restaurantName || this.recommendRestaurant.restaurantName.trim().length == 0) {
            this.hideLoader();
            this.displayError("Please enter " + this.getService().serviceRecommendQuestion.toLowerCase());
            return false;
        }

        if (!this.recommendRestaurant.email || this.recommendRestaurant.email.trim().length == 0) {
            this.hideLoader();
            this.displayError("Please enter your email address");
            return false;
        }

        if (!this.recommendRestaurant.mobileNo || this.recommendRestaurant.mobileNo.trim().length == 0) {
            this.hideLoader();
            this.displayError("Please enter your mobile");
            return false;
        }

        if (!this.recommendRestaurant.postcode || this.recommendRestaurant.postcode.trim().length == 0) {
            this.hideLoader();
            this.displayError("Please enter your postcode");
            return false;
        }

        this.recommendRestaurant.pnt = this.stateService.device$.pnt;
        this.recommendRestaurant.isRestaurantRecommendation = true;

        this.personaliseService.saveMarketingData(this.recommendRestaurant).subscribe(
            result => {
                this.hideLoader();
                this.displayInfomation("Thank you, we will keep you posted");
                this.recommendRestaurant = {};
            },
            error => {
                this.handleError(error);
            }
        );
    }
}