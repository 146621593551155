<!--<div *ngIf="this.stateService && this.stateService.device$.userSrvcMrdcAlready">-->
<div *ngIf="!this.stateService.device$.taeamServices || this.stateService.device$.taeamServices.length <= 1">

<div class="text-block">
    <div class="inner-text-block">
        <h1>We are coming soon in your area so keep watching this space!</h1>
        <p>We are working hard in your local area to bring the new services to you. Please provide your details and share your ideas and we will keep you informed and also share the offers that you may love!</p>
    </div>
</div>
</div>
<!--<div *ngIf="this.stateService && !this.stateService.device$.userSrvcMrdcAlready">-->
<div *ngIf="!this.stateService.device$.taeamServices || this.stateService.device$.taeamServices.length > 1">
    <div class="text-block">
        <div class="inner-text-block">
            <h1>We are working hard to introduce more services in your area so keep watching this space!</h1>
            <p>Please provide your details and share your ideas and we will keep you informed and also share the details on new services and offers that you may love!</p>
        </div>
    </div>
</div>
<div>
    <ion-grid class="basket-no-padding">
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Full name" [(ngModel)]="marketingData.fullName"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Email address" [(ngModel)]="marketingData.email"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Mobile #" [(ngModel)]="marketingData.mobileNo"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Postcode" [(ngModel)]="marketingData.postcode"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <p>Which services would you like to see here?</p>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-textarea type="text" [(ngModel)]="marketingData.restaurantsLikeToSee"></ion-textarea>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item lines="none">
                    <ion-button size="large" expand="block" (click)="saveMarketingData()">Submit</ion-button>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="seperator-bar">&nbsp;</div>
</div>