import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoadingController } from '@ionic/angular';
import { ObjectCore } from '../shared/object.core';

@Injectable()
export class TokenInterceptor extends ObjectCore implements HttpInterceptor {
    loaderToShow: any;
    loader: HTMLIonLoadingElement;
    loaderLoading = false;

    constructor(public loadingController: LoadingController) {
        super();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).map(event => {
            if (event instanceof HttpResponse) {
                this.Log("Response headers");
                this.Log(event.headers.get('x-token-expired'));
                this.Log(event.headers.get('x-refresh-token'));
                if (event.headers.has('x-token-expired') && event.headers.has('x-refresh-token')) {
                    this.Log("Token from response");
                    if (!this.isNullOrEmpty(event.headers.get('x-refresh-token'))) {
                        this.stateService.setProps({ token: event.headers.get('x-refresh-token') });
                    }
                }
            }
            return event;
        });
    }
}
