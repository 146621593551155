<ion-content *ngIf="this.hasNewsData()">
    <div *ngFor="let news of this.stateService.props$.newsData; let index = index">
        <div class="news-section">
            <div class="product-card with-padding">
                <div class="product-cover" (click)="showNews($event, news)" *ngIf="!news.videoUrl && news.imageUrl">
                    <img [src]="news.imageUrl" alt="">
                </div>
                <div class="product-cover" *ngIf="news.videoUrl">
                    <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getString(news.videoPosterUrl)">
                        <source [src]="news.videoUrl" type="video/mp4" />
                    </video>
                    <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                        <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                        <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                        <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                        <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                </div>
                <div class="product-detail">
                    <h2 *ngIf="news.title">{{news.title}}</h2>
                    <p style="margin-top: 5px; text-align:left;" *ngIf="news.description">
                        {{news.description}}
                    </p>
                </div>
            </div>
        </div>
        <div class="separator">&nbsp;</div>
    </div>
    <div *ngIf="!hideClose" style="padding:10px;">
        <p><ion-button (click)="dismiss()">Close</ion-button></p>
    </div>
</ion-content>
<div class="provider-view-root" *ngIf="!this.hasNewsData()">
    <div style="text-align:center">
        <p>No news or updates available</p>
    </div>
</div>

<!--<ion-content class="contents-page" *ngIf="data">
    <ion-grid>
        <div *ngFor="let news of data.paragraphs;">
            <ion-row *ngIf="news.title">
                <ion-col><h4>{{news.title}}</h4></ion-col>
            </ion-row>
            <ion-row *ngFor="let content of news.contents;">
                <ion-col>{{content}}</ion-col>
            </ion-row>
        </div>
        <ion-row>
            <ion-col>Last updated on: {{data.lastUpdatedOn | date}}</ion-col>
        </ion-row>
        <ion-row class="no-padding">
            <ion-col class="no-padding">
                <p><ion-button (click)="dismiss()">Close</ion-button></p>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>-->
