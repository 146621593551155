import { Component, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ObjectBase } from 'src/app/shared/object.base';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.services';
import { DeviceService } from 'src/app/services/device.service';
import { UiStateService } from 'src/app/services/ui.state.service';
import { LoginService } from 'src/app/services/login.service';
import { NavController, MenuController } from '@ionic/angular';

@Component({
    selector: 'app-menu',
    templateUrl: 'app.menu.html',
    styleUrls: ['./app.menu.scss']
})
export class AppMenu extends ObjectBase {

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected menuController: MenuController,
        private deviceService: DeviceService,
        private loginService: LoginService,
        private formBuilder: FormBuilder) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
    }

    login() {
        this.menuController.close();
        this.go('tabs/tfa');
        this.stateService.props$.t
    }

    logout() {
        this.personaliseService.logEvent("logout", JSON.stringify({}));
        this.userService.logout();
        this.menuController.close();
        this.goHome();
    }

    showProfile() {
        this.menuController.close();
        this.go('profile');
    }

    showCreditDashboard() {
        this.menuController.close();
        this.go('credit');
    }

    showCreditRequests() {
        this.menuController.close();
        this.go('credit/credit-requests');
    }

    showServices() {
        this.menuController.close();
        this.stateService.setProps({ manualServiceViewCall: true });
        this.go('services');
    }

    showPrivacy() {
        this.menuController.close();
        this.go('privacy');
    }

    showCookie() {
        this.menuController.close();
        this.go('cookie');
    }

    showTerms() {
        this.menuController.close();
        this.go('terms');
    }

    aboutUs() {
        this.menuController.close();
        this.go('about');
    }

    news($event) {
        this.menuController.close();
        this.go('news');
    }

    showSettings() {
        this.menuController.close();
        this.go('settings');
    }

    joinOurNetwork() {
        this.menuController.close();
        this.stateService.setProps({ step2: null, step3: null });
        this.go('join');
    }    

    showJoinNetworkOption() {
        if ((this.deviceService && (this.deviceService.isAndroid || this.deviceService.isWeb)) || this.stateService.device$ && this.stateService.device$.selectedService && this.stateService.device$.selectedService.showJoinNetwork) {
            return true;
        } else {
            return false;
        }
    }
}