import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class VoucherService extends ServiceBase {

    //private serviceBaseRoute: string = ':7075/api/';
    //private getVoucherApiUrl: string = this.serviceBaseUrl + 'voucher/get';

    private getVoucherApiUrl: string;
    private getVouchersApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.getVoucherApiUrl = environment.voucherApiUrl + 'voucher/get';
        this.getVouchersApiUrl = environment.voucherApiUrl + 'voucher/restaurants/get';
    }

    public getVoucher(voucherCode: string, restaurantId: string): Observable<any> {
        return this.post(this.getVoucherApiUrl, { code: voucherCode, restaurantId: restaurantId });
    }

    public getRestaurantsVoucher(voucherCode: string, restaurantId: string): Observable<any> {
        return this.post(this.getVouchersApiUrl, { code: voucherCode, restaurantId: restaurantId });
    }
}
