import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import 'moment-timezone';
import { Common } from '../shared/common';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CalendarService extends ServiceBase {

    private static _instance: CalendarService;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);

        if (!CalendarService._instance) {
            CalendarService._instance = this;
        }
    }

    public static get Instance() {
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<CalendarService>(CalendarService));
        return obj;
    }

    public getDayHours(): any {
        var data = [];

        data.push({ slotName: "Morning Slot", timeSlot: "6:00-7:00 AM" });
        data.push({ slotName: "Morning Slot", timeSlot: "7:00-8:00 AM" });
        data.push({ slotName: "Morning Slot", timeSlot: "8:00-9:00 AM" });
        data.push({ slotName: "Morning Slot", timeSlot: "9:00-10:00 AM" });
        data.push({ slotName: "Morning Slot", timeSlot: "10:00-11:00 AM" });
        data.push({ slotName: "Morning Slot", timeSlot: "11:00-12:00 PM" });

        data.push({ slotName: "Afternoon Slot", timeSlot: "12:00-1:00 PM" });
        data.push({ slotName: "Afternoon Slot", timeSlot: "1:00-2:00 PM" });
        data.push({ slotName: "Afternoon Slot", timeSlot: "2:00-3:00 PM" });
        data.push({ slotName: "Afternoon Slot", timeSlot: "3:00-4:00 PM" });
        data.push({ slotName: "Afternoon Slot", timeSlot: "4:00-5:00 PM" });
        data.push({ slotName: "Afternoon Slot", timeSlot: "5:00-6:00 PM" });

        data.push({ slotName: "Evening Slot", timeSlot: "6:00-7:00 PM" });
        data.push({ slotName: "Evening Slot", timeSlot: "7:00-8:00 PM" });
        data.push({ slotName: "Evening Slot", timeSlot: "8:00-9:00 PM" });
        data.push({ slotName: "Evening Slot", timeSlot: "9:00-10:00 PM" });
        data.push({ slotName: "Evening Slot", timeSlot: "10:00-11:00 PM" });
        data.push({ slotName: "Evening Slot", timeSlot: "11:00-12:00 AM" });

        data.push({ slotName: "Late Night Slot", timeSlot: "12:00-1:00 AM" });
        data.push({ slotName: "Late Night Slot", timeSlot: "1:00-2:00 AM" });
        data.push({ slotName: "Late Night Slot", timeSlot: "2:00-3:00 AM" });
        data.push({ slotName: "Late Night Slot", timeSlot: "3:00-4:00 AM" });
        data.push({ slotName: "Late Night Slot", timeSlot: "4:00-5:00 AM" });
        data.push({ slotName: "Late Night Slot", timeSlot: "5:00-6:00 AM" });

        return data;
    }

    public dateSlotCompare(n1, n2) {
        return this.dateComparer(n1.dateSlot, n2.dateSlot);
    }

    public getCalendarData(slotsData: any, startDate: any, endDate: any, bookingCalendarType: any): any {
        var data: any = {};

        data.startDate = startDate;
        data.endDate = endDate;
        data.bookingCalendarType = bookingCalendarType;
        data.totalMonths = 0;
        data.totalDays = 0;
        data.totalHours = 0;
        data.mondayHours = 0;
        data.tuesdayHours = 0;
        data.wednesdayHours = 0;
        data.thursdayHours = 0;
        data.fridayHours = 0;
        data.saturdayHours = 0;
        data.sundayHours = 0;

        if (slotsData) {
            data.totalMonths = this.getMonths(startDate, endDate);

            if (this.hasData(slotsData.mondayTimeslot)) {
                var list = slotsData.mondayTimeslot.sort((n1, n2) => this.dateSlotCompare);
                var previousDate = null;
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if (item && !this.isNullOrEmpty(item.dateSlot) && !this.isSame(item.dateSlot, previousDate)) {
                        data.totalDays = data.totalDays + 1;
                        previousDate = item.dateSlot;
                    }

                    data.mondayHours = data.mondayHours + 1;
                    data.totalHours = data.totalHours + 1;
                }
            }

            if (this.hasData(slotsData.tuesdayTimeslot)) {
                var list = slotsData.tuesdayTimeslot.sort((n1, n2) => this.dateSlotCompare);
                var previousDate = null;
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if (item && !this.isNullOrEmpty(item.dateSlot) && !this.isSame(item.dateSlot, previousDate)) {
                        data.totalDays = data.totalDays + 1;
                        previousDate = item.dateSlot;
                    }

                    data.tuesdayHours = data.tuesdayHours + 1;
                    data.totalHours = data.totalHours + 1;
                }
            }

            if (this.hasData(slotsData.wednesdayTimeslot)) {
                var list = slotsData.wednesdayTimeslot.sort((n1, n2) => this.dateSlotCompare);
                var previousDate = null;
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if (item && !this.isNullOrEmpty(item.dateSlot) && !this.isSame(item.dateSlot, previousDate)) {
                        data.totalDays = data.totalDays + 1;
                        previousDate = item.dateSlot;
                    }

                    data.wednesdayHours = data.wednesdayHours + 1;
                    data.totalHours = data.totalHours + 1;
                }
            }

            if (this.hasData(slotsData.thursdayTimeslot)) {
                var list = slotsData.thursdayTimeslot.sort((n1, n2) => this.dateSlotCompare);
                var previousDate = null;
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if (item && !this.isNullOrEmpty(item.dateSlot) && !this.isSame(item.dateSlot, previousDate)) {
                        data.totalDays = data.totalDays + 1;
                        previousDate = item.dateSlot;
                    }

                    data.thursdayHours = data.thursdayHours + 1;
                    data.totalHours = data.totalHours + 1;
                }
            }

            if (this.hasData(slotsData.fridayTimeslot)) {
                var list = slotsData.fridayTimeslot.sort((n1, n2) => this.dateSlotCompare);
                var previousDate = null;
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if (item && !this.isNullOrEmpty(item.dateSlot) && !this.isSame(item.dateSlot, previousDate)) {
                        data.totalDays = data.totalDays + 1;
                        previousDate = item.dateSlot;
                    }

                    data.fridayHours = data.fridayHours + 1;
                    data.totalHours = data.totalHours + 1;
                }
            }

            if (this.hasData(slotsData.saturdayTimeslot)) {
                var list = slotsData.saturdayTimeslot.sort((n1, n2) => this.dateSlotCompare);
                var previousDate = null;
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if (item && !this.isNullOrEmpty(item.dateSlot) && !this.isSame(item.dateSlot, previousDate)) {
                        data.totalDays = data.totalDays + 1;
                        previousDate = item.dateSlot;
                    }

                    data.saturdayHours = data.saturdayHours + 1;
                    data.totalHours = data.totalHours + 1;
                }
            }

            if (this.hasData(slotsData.sundayTimeslot)) {
                var list = slotsData.sundayTimeslot.sort((n1, n2) => this.dateSlotCompare);
                var previousDate = null;
                for (var i = 0; i < list.length; i++) {
                    var item = list[i];
                    if (item && !this.isNullOrEmpty(item.dateSlot) && !this.isSame(item.dateSlot, previousDate)) {
                        data.totalDays = data.totalDays + 1;
                        previousDate = item.dateSlot;
                    }

                    data.sundayHours = data.sundayHours + 1;
                    data.totalHours = data.totalHours + 1;
                }
            }
        }

        return data;
    }

    public getDaySlots(availableSlotsData: any, day: string, date: any = null): any {

        var result = null;

        if (availableSlotsData && this.isSame(day, "Mon") && this.hasData(availableSlotsData.mondayTimeslot)) {
            result = this.clone(availableSlotsData.mondayTimeslot);
        } else if (availableSlotsData && this.isSame(day, "Tue") && this.hasData(availableSlotsData.tuesdayTimeslot)) {
            result = this.clone(availableSlotsData.tuesdayTimeslot);
        } else if (availableSlotsData && this.isSame(day, "Wed") && this.hasData(availableSlotsData.wednesdayTimeslot)) {
            result = this.clone(availableSlotsData.wednesdayTimeslot);
        } else if (availableSlotsData && this.isSame(day, "Thu") && this.hasData(availableSlotsData.thursdayTimeslot)) {
            result = this.clone(availableSlotsData.thursdayTimeslot);
        } else if (availableSlotsData && this.isSame(day, "Fri") && this.hasData(availableSlotsData.fridayTimeslot)) {
            result = this.clone(availableSlotsData.fridayTimeslot);
        } else if (availableSlotsData && this.isSame(day, "Sat") && this.hasData(availableSlotsData.saturdayTimeslot)) {
            result = this.clone(availableSlotsData.saturdayTimeslot);
        } else if (availableSlotsData && this.isSame(day, "Sun") && this.hasData(availableSlotsData.sundayTimeslot)) {
            result = this.clone(availableSlotsData.sundayTimeslot);
        }

        if (result && date) {
            for (var i = 0; i < result.length; i++) {
                result[i].dateSlot = this.getIsoDate(date);
            }
        }

        return result;
    }

    public getMorningHours(availableSlotsData: any = null, day: string = null): any {
        var data = [];

        if (availableSlotsData && day) {
            data = this.getDaySlots(availableSlotsData, day);
        } else {
            data = this.getDayHours();
        }

        if (!this.hasData(data)) {
            data = [];
        }

        data = data.filter(x => x.slotName == "Morning Slot");

        return data;
    }

    public getAfternoonHours(availableSlotsData: any = null, day: string = null): any {
        var data = [];

        if (availableSlotsData && day) {
            data = this.getDaySlots(availableSlotsData, day);
        } else {
            data = this.getDayHours();
        }

        if (!this.hasData(data)) {
            data = [];
        }

        data = data.filter(x => x.slotName == "Afternoon Slot");

        return data;
    }

    public getEveningHours(availableSlotsData: any = null, day: string = null): any {
        var data = [];

        if (availableSlotsData && day) {
            data = this.getDaySlots(availableSlotsData, day);
        } else {
            data = this.getDayHours();
        }

        if (!this.hasData(data)) {
            data = [];
        }

        data = data.filter(x => x.slotName == "Evening Slot");

        return data;
    }

    public getLateNightHours(availableSlotsData: any = null, day: string = null): any {
        var data = [];

        if (availableSlotsData && day) {
            data = this.getDaySlots(availableSlotsData, day);
        } else {
            data = this.getDayHours();
        }

        if (!this.hasData(data)) {
            data = [];
        }

        data = data.filter(x => x.slotName == "Late Night Slot");

        return data;
    }

    public getMorningHoursFromData(availableSlotsData: any, day: string, date: any = null): any {
        var data = [];

        if (availableSlotsData && day) {
            data = this.getDaySlots(availableSlotsData, day, date);
        } 

        if (!this.hasData(data)) {
            data = [];
        }

        data = data.filter(x => x.slotName == "Morning Slot");

        this.Log(availableSlotsData);
        this.Log(day);
        this.Log(data);

        return data;
    }

    public getAfternoonHoursFromData(availableSlotsData: any, day: string, date: any = null): any {
        var data = [];

        if (availableSlotsData && day) {
            data = this.getDaySlots(availableSlotsData, day, date);
        } 

        if (!this.hasData(data)) {
            data = [];
        }

        data = data.filter(x => x.slotName == "Afternoon Slot");

        this.Log(availableSlotsData);
        this.Log(day);
        this.Log(data);

        return data;
    }

    public getEveningHoursFromData(availableSlotsData: any, day: string, date: any = null): any {
        var data = [];

        if (availableSlotsData && day) {
            data = this.getDaySlots(availableSlotsData, day, date);
        } 

        if (!this.hasData(data)) {
            data = [];
        }

        data = data.filter(x => x.slotName == "Evening Slot");

        this.Log(availableSlotsData);
        this.Log(day);
        this.Log(data);

        return data;
    }

    public getLateNightHoursFromData(availableSlotsData: any, day: string, date: any = null): any {
        var data = [];

        if (availableSlotsData && day) {
            data = this.getDaySlots(availableSlotsData, day, date);
        } 

        if (!this.hasData(data)) {
            data = [];
        }

        data = data.filter(x => x.slotName == "Late Night Slot");

        this.Log(availableSlotsData);
        this.Log(day);
        this.Log(data);

        return data;
    }


    public getWeekDays(date: any = null): any {
        var data = [];

        data = this.getAllWeekDays();
        
        if (date) {
            data[0].date = moment(date);
            data[1].date = moment(date).add(1, 'days');
            data[2].date = moment(date).add(2, 'days');
            data[3].date = moment(date).add(3, 'days');
            data[4].date = moment(date).add(4, 'days');
            data[5].date = moment(date).add(5, 'days');
            data[6].date = moment(date).add(6, 'days');
        }       

        return data;
    }

    public getAllowedWeekDays(date1: any = null, date2: any = null): any {
        var data = [];

        var days = this.getDays(date1, date2);

        if (days > 0 || days < 7) {
            var dt1 = moment(date1);
            var dt2 = moment(date2);

            while (dt1 <= dt2) {
                var day = this.getShortDayAsString(dt1);
                if (!this.listContains(data, "shortName", day)) {
                    data.push(this.getDayObj(day));
                }

                dt1 = dt1.add(1, 'days');
            }
        } else {
            data = this.getWeekDays();
        } 

        this.Log(data);
        return data;
    }

    public isDayWithinDateRange(date1: any, date2: any, day: string): boolean {
        var data = this.getAllowedWeekDays(date1, date2);

        if (this.listContains(data, "shortName", day)) {
            return true;
        } else {
            return false;
        }
    }

    public getFirstShortDay(date1: any, date2: any): string {
        var data = this.getAllowedWeekDays(date1, date2);

        if (data && data.length > 0) {
            data = data.sort(this.weekDayNumberComparer);
            this.Log("Here");
            this.Log(shortDayName);
            this.Log(data);
            return data[0].shortName;
        }

        var shortDayName = this.getShortDay(date1);
        if (this.isNullOrEmpty(shortDayName)) {
            shortDayName = this.getShortDay(date2);
        }

        this.Log("Here");
        this.Log(shortDayName);
        this.Log(data);
        return shortDayName;
    }

    setCalendarControlProps() {
        if (!this.stateService.props$.calendarStartDate) {
            this.stateService.setProps({ currentDate: moment(), calendarStartDate: moment(), calendarEndDate: moment() });
        }
    }

    getDay(date: any): string {
        var dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        return dt.format('dddd');
    }

    getShortDay(date: any): string {
        var dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        return dt.format('ddd');
    }

    getDate(date: any): string {
        var dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        return dt.format('MMM DD');
    }

    getDayNumber(date: any): string {
        var dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        return dt.format('DD');
    }

    getTime(date: any): string {
        var dt = moment(date, "YYYY-MM-DD HH:mm:ss");
        return dt.format('HH:mm');
    }

    getStartWeekDate(date: any = null): any {
        if (!date) {
            date = moment();
        }

        var dt = moment(date);

        return dt.startOf('isoWeek');
    }

    getEndOfWeekDate(date: any = null): any {
        if (!date) {
            date = moment();
        }
        return date.endOf('isoWeek');
    }

    getCurrentDate(timeZone: string = null): any {
        var dt = moment();

        if (timeZone) {
            dt = moment().tz(timeZone);
        }

        return dt;
    }

    getLocalDay(timeZone: string = null): string {
        var dt = moment();

        if (timeZone) {
            dt = moment().tz(timeZone);
        }

        return dt.day().toString();
    }

    getStartOfWeekAsString(date: any, prefix: string = "Week"): string {
        return prefix + " " + this.getStartWeekDate(date).format('Do MMM').toString();
    }

    getShortDayAsString(date: any): string {
        return moment(date).format('ddd').toString();
    }

    getLongDayAsString(date: any): string {
        return moment(date).format('dddd').toString();
    }

    getShortMonthAsString(date: any): string {
        return this.getStartWeekDate(date).format('MMM').toString();
    }

    getShortMonth(date: any): string {
        return date.format('MMM').toString();
    }

    public isToday(date: any): boolean {
        var today = moment();
        date = moment(date);
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        var result = today.diff(date, 'days') == 0; // today - future < 0
        return result;
    }

    public isPastDate(date: any): boolean {
        var today = moment();
        date = moment(date);
        var result = date.diff(today, 'days') < 0; // today - future < 0
        return result;
    }

    public isFutureDate(date: any): boolean {
        var today = moment();
        date = moment(date);
        var result = today.diff(date, 'days') > 0; // today - future < 0
        return result;
    }

    public isDateWithinRange(date1: any, date2: any, date: any): boolean {
        var dt1 = moment(date1);
        var dt2 = moment(date2);
        var dt = moment(date);

        if (dt1 < dt2) {
            if (dt >= dt1 && dt <= dt2) {
                return true;
            } else {
                return false;
            }
        } else {
            if (dt >= dt2 && dt <= dt1) {
                return true;
            } else {
                return false;
            }
        }

        return false;
    }

    public getDateOnlyAsString(value: any, seperator: string = "-"): string {
        if (date) {
            var date = moment(value);
            date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            return date.year + seperator + date.month + seperator + date.day;
        }

        return null;
    }
}
