import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import 'moment-timezone';
import { Common } from '../shared/common';

declare var google: any;

@Injectable()
export class ServiceResourceSearchService extends ServiceBase {

    private static _instance: ServiceResourceSearchService;

    private searchServiceResourceAdsApiUrl: string;
    private searchNearestServiceResourceAdsByGeoLocationApiUrl: string;
    private searchServiceResourceAdsByTagApiUrl: string;
    private searchServiceResourceAdsByCategoryApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.searchServiceResourceAdsApiUrl = environment.restaurantApiUrl + 'service-resource-ads/';
        this.searchNearestServiceResourceAdsByGeoLocationApiUrl = environment.serviceResourceAdsSearchApiUrl + 'service-resource-ads/nearest/';
        this.searchServiceResourceAdsByTagApiUrl = environment.serviceResourceAdsSearchApiUrl + 'service-resource-ads/tags/';
        this.searchServiceResourceAdsByCategoryApiUrl = environment.serviceResourceAdsSearchApiUrl + 'service-resource-ads/category/';

        if (!ServiceResourceSearchService._instance) {
            ServiceResourceSearchService._instance = this;
        }
    }

    public static get Instance() {
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<ServiceResourceSearchService>(ServiceResourceSearchService));
        return obj;
    }

    public searchNearestServiceResourceAdsByGeoLocation(longitude: any, latitude: any): Observable<Array<any>> {
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchNearestServiceResourceAdsByGeoLocationApiUrl + latitude + '/' + longitude);
        } else {
            return Observable.of([]);
        }
    }

    public searchServiceResourceAdsByTag(value: string): Observable<Array<any>> {
        value = encodeURIComponent(value);
        this.stateService.props$.searchText = value;
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchServiceResourceAdsByTagApiUrl + value, headers);
        } else {
            return Observable.of([]);
        }
    }

    public searchServiceResourceAdsByCategory(value: string, searchText: string): Observable<Array<any>> {
        var result = Observable.of([]);
        value = encodeURIComponent(value);
        searchText = encodeURIComponent(searchText);

        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchServiceResourceAdsByCategoryApiUrl + value + "/" + searchText, headers);
        } else {
            return Observable.of([]);
        }
    }

    public searchServiceResourceAds(value: string): Observable<Array<any>> {
        value = encodeURIComponent(value);
        this.stateService.props$.searchText = value;
        if (this.stateService.props$ && this.stateService.props$.token) {
            var headers = this.getPDHeader(true);
            return this.get(this.searchServiceResourceAdsApiUrl + value, headers);
        } else {
            return Observable.of([]);
        }
    }
}
