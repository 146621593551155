<ion-content>
    <ion-grid>
        <!--<ion-row>
        <ion-col>
            <input type="file" (change)="fileChangeEvent($event)" />
        </ion-col>
    </ion-row>-->
        <ion-row>
            <ion-col>
                <button class="toolbar-button" (click)="rotateLeft()">Rotate left</button>
            </ion-col>
            <ion-col>
                <button class="toolbar-button" (click)="rotateRight()">Rotate right</button>
            </ion-col>
            <ion-col>
                <button class="toolbar-button" (click)="flipHorizontal()">Flip horizontal</button>
            </ion-col>
            <ion-col>
                <button class="toolbar-button" (click)="flipVertical()">Flip vertical</button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="imageChangedEvent">
            <ion-col>
                <image-cropper [imageChangedEvent]="imageChangedEvent"
                               [maintainAspectRatio]="true"
                               [containWithinAspectRatio]="containWithinAspectRatio"
                               [aspectRatio]="4 / 3"
                               [resizeToWidth]="256"
                               [cropperMinWidth]="128"
                               [onlyScaleDown]="true"
                               [roundCropper]="false"
                               [canvasRotation]="canvasRotation"
                               [transform]="transform"
                               [alignImage]="'left'"
                               [style.display]="showCropper ? null : 'none'"
                               [format]="imageChangedEvent.format"
                               (imageCropped)="imageCropped($event)"
                               (imageLoaded)="imageLoaded()"
                               (cropperReady)="cropperReady($event)"
                               (loadImageFailed)="loadImageFailed()"></image-cropper>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <button class="toolbar-button" (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill':'Contain'}}</button>
            </ion-col>
            <ion-col>
                <button class="toolbar-button" (click)="zoomOut()">Zoom -</button>
            </ion-col>
            <ion-col>
                <button class="toolbar-button" (click)="zoomIn()">Zoom +</button>
            </ion-col>
            <ion-col>
                <button class="toolbar-button" (click)="resetImage()">Reset image</button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <div style="height: 170px; width: 100%; overflow: hidden;" [style.border]="croppedImage ? '1px solid black' : 'none'">
                    <img [src]="croppedImage" style="width: 100%; max-width: 100%;" [style.border]="croppedImage ? '1px solid black' : 'none'" />
                </div>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button (click)="dismiss()">Close</ion-button>
            </ion-col>
            <ion-col>
                <ion-button (click)="update()">Update</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
