import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

@Injectable()
export class TopicService extends ServiceBase {

    private getDefaultTopicsApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.getDefaultTopicsApiUrl = environment.restaurantApiUrl + 'topics/default/';
    }

    public getDefaultTopics(): Observable<Array<string>> {
        var topics = new Array<string>();

        topics.push("taeam-software-updates");
        topics.push("taeam-promotions");
        topics.push(this.getAppTopic("software-updates"));
        topics.push(this.getAppTopic("general-updates"));
        topics.push(this.getAppTopic("promotions"));
        return Observable.of(topics);;

        //return this.get(this.getDefaultTopicsApiUrl);
    }

    private getAppTopic(topicName: string): string {
        return environment.appId + "-" + topicName;
    }
}