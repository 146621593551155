import { Component } from '@angular/core';

@Component({
    selector: 'app-tab-menu',
    templateUrl: 'tab.menu.html',
    styleUrls: ['tab.menu.scss']
})
export class TabMenu {

    constructor() {
    }
}
