import { Component, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { ObjectBase } from '../../object.base';
import { FeedbackOptions, FeedbackType } from '../../models/shared.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import { NavController } from '@ionic/angular';
import { OrderService } from 'src/app/services/order.service';

@Component({
    selector: 'taeam-feedback',
    templateUrl: 'taeam.feedback.html',
    styleUrls: ['taeam.feedback.scss']
})
export class TaeamFeedback extends ObjectBase implements OnInit {

    @Input() order: any;
    @Input() classifiedAd: any;
    @Input() serviceResourceAd: any;
    @Input() serviceCustomerRequest: any;

    public feedbackReceived: boolean = false;
    public isHappy: boolean = false;
    public isNeutral: boolean = false;
    public isSad: boolean = false;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected userService: UserService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private orderService: OrderService) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
    }

    ngOnInit() {
        this.Log("Order - ngOnInit");
        this.Log(this.order);
        this.personaliseService.logEvent("crazon-feedback-requested", JSON.stringify({ shortId: this.order.shortId, multipleOrdersShortId: this.order.multipleOrdersShortId }));
    }

    async happy() {
        await this.showLoader();
        this.Log("Happy feedback");
        this.isHappy = true;
        this.isNeutral = false;
        this.isSad = false;
        if (this.order) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ shortId: this.order.shortId, multipleOrdersShortId: this.order.multipleOrdersShortId, feedback: FeedbackOptions.Happy, feedbackType: FeedbackType.TaeamFeedback }));
            this.orderService.orderFeedback(this.order, FeedbackOptions.Happy, FeedbackType.TaeamFeedback).subscribe(
                result => {
                    this.feedbackReceived = true;
                    this.hideLoader();
                },
                error => {
                    this.handleError(error);
                },
                () => {
                    this.hideLoader();
                }
            );
        } else if (this.classifiedAd) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.classifiedAd.id, feedback: FeedbackOptions.Happy, feedbackType: FeedbackType.CrazonClassifiedAd }));
            this.hideLoader();
        } else if (this.serviceResourceAd) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.serviceResourceAd.id, feedback: FeedbackOptions.Happy, feedbackType: FeedbackType.CrazonServiceResourceAd }));
            this.hideLoader();
        } else if (this.serviceCustomerRequest) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.serviceCustomerRequest.id, feedback: FeedbackOptions.Happy, feedbackType: FeedbackType.CrazonServiceCustomerRequest }));
            this.hideLoader();
        }
    }

    async neutral() {
        await this.showLoader();
        this.Log("Neutral feedback");
        this.isNeutral = true;
        this.isHappy = false;
        this.isSad = false;

        if (this.order) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ shortId: this.order.shortId, multipleOrdersShortId: this.order.multipleOrdersShortId, feedback: FeedbackOptions.Neutral, feedbackType: FeedbackType.TaeamFeedback }));
            this.orderService.orderFeedback(this.order, FeedbackOptions.Neutral, FeedbackType.TaeamFeedback).subscribe(
                result => {
                    this.feedbackReceived = true;
                    this.hideLoader();
                },
                error => {
                    this.handleError(error);
                },
                () => {
                    this.hideLoader();
                }
            );
        } else if (this.classifiedAd) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.classifiedAd.id, feedback: FeedbackOptions.Neutral, feedbackType: FeedbackType.CrazonClassifiedAd }));
            this.hideLoader();
        } else if (this.serviceResourceAd) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.serviceResourceAd.id, feedback: FeedbackOptions.Neutral, feedbackType: FeedbackType.CrazonServiceResourceAd }));
            this.hideLoader();
        } else if (this.serviceCustomerRequest) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.serviceCustomerRequest.id, feedback: FeedbackOptions.Neutral, feedbackType: FeedbackType.CrazonServiceCustomerRequest }));
            this.hideLoader();
        }
    }

    async sad() {
        await this.showLoader();
        this.Log("Sad feedback");
        this.isSad = true;
        this.isHappy = false;
        this.isNeutral = false;
        if (this.order) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ shortId: this.order.shortId, multipleOrdersShortId: this.order.multipleOrdersShortId, feedback: FeedbackOptions.Sad, feedbackType: FeedbackType.TaeamFeedback }));
            this.orderService.orderFeedback(this.order, FeedbackOptions.Sad, FeedbackType.TaeamFeedback).subscribe(
                result => {
                    this.feedbackReceived = true;
                    this.hideLoader();
                },
                error => {
                    this.handleError(error);
                },
                () => {
                    this.hideLoader();
                }
            )
        } else if (this.classifiedAd) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.classifiedAd.id, feedback: FeedbackOptions.Sad, feedbackType: FeedbackType.CrazonClassifiedAd }));
            this.hideLoader();
        } else if (this.serviceResourceAd) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.serviceResourceAd.id, feedback: FeedbackOptions.Sad, feedbackType: FeedbackType.CrazonServiceResourceAd }));
            this.hideLoader();
        } else if (this.serviceCustomerRequest) {
            this.personaliseService.logEvent("crazon-feedback-given", JSON.stringify({ id: this.serviceCustomerRequest.id, feedback: FeedbackOptions.Sad, feedbackType: FeedbackType.CrazonServiceCustomerRequest }));
            this.hideLoader();
        }
    }
}
