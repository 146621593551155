<div *ngIf="this.stateService && this.stateService.device$.userMrdcAlready">
    <div class="text-block">
        <div class="inner-text-block">
            <h1>{{this.getService().serviceNoResultTitle}}</h1>
            <p>{{this.getService().serviceNoResultDescriptionAlreadySignup}}</p>
        </div>
    </div>
</div>
<div *ngIf="this.stateService && !this.stateService.device$.userMrdcAlready">
    <div class="text-block">
        <div class="inner-text-block">
            <h1>{{this.getService().serviceNoResultTitle}}</h1>
            <p>{{this.getService().serviceNoResultDescription}}</p>
        </div>
    </div>
</div>
<div *ngIf="this.stateService && !this.stateService.device$.userMrdcAlready">
    <ion-grid class="basket-no-padding">
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Full name" [(ngModel)]="marketingData.fullName"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Email address" [(ngModel)]="marketingData.email"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Mobile #" [(ngModel)]="marketingData.mobileNo"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-input type="text" placeholder="Postcode" [(ngModel)]="marketingData.postcode"></ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <p>{{this.getService().serviceNoResultQuestion}}</p>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item>
                    <ion-textarea type="text" [(ngModel)]="marketingData.restaurantsLikeToSee"></ion-textarea>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="basket-no-padding">
            <ion-col class="basket-no-padding">
                <ion-item lines="none">
                    <ion-button size="large" expand="block" (click)="saveMarketingData()">Submit</ion-button>
                </ion-item>
            </ion-col>
        </ion-row>
    </ion-grid>
    <div class="seperator-bar">&nbsp;</div>
</div>