<div class="{{getCardViewCss(false, 'provider-view-root')}}" *ngIf="classifiedAd && !leftImageViewStyle">
    <div (click)="showClassifiedAdWithLocationCheck($event, classifiedAd)" [ngClass]="applyPadding ? 'product-card' : 'product-card'">
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasMultipleImagesOrVideos(classifiedAd.imagesAndVideos) && !hideSliderView">
            <!--<img src="{{resourcesBaseUrl}}{{classifiedAd.imageUrl}}" alt="">-->
            <ion-slides #slider [options]="slideOptions" (ionSlideTransitionEnd)="slideChange(slider)">
                <ion-slide #slide *ngFor="let asset of classifiedAd.imagesAndVideos; let aindex = index">
                    <div class="clickable-div" (click)="showClassifiedAdWithLocationCheck($event, classifiedAd)"></div>
                    <img [src]="getClassifiedResourceUrl(classifiedAd, asset.name)" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/classified-ad-loading.jpg'" alt="" *ngIf="!asset.isVideo">
                    <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(classifiedAd.multipleDisplayVideoPosterUrls, aindex)" *ngIf="asset.isVideo">
                        <source [src]="getClassifiedResourceUrl(classifiedAd, asset.name)" type="video/mp4" />
                    </video>
                    <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="free-simple-top" *ngIf="!wlaActive && classifiedAd.tagText && classifiedAd.tagTextIsOffer">{{classifiedAd.tagText}}</div>
                    <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                        <ion-icon name="md-share" (click)="showClassifiedAdSocialSharing($event, classifiedAd)"></ion-icon>
                    </div>
                    <div class="offer-top" *ngIf="!wlaActive && classifiedAd.tagText && !classifiedAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{classifiedAd.tagText}}</div>
                    <div class="restaurant-card-bottom-bar warning" *ngIf="classifiedAd.status && classifiedAd.status == 'comming-soon'">Coming soon</div>
                    <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                        <ion-grid>
                            <ion-row>
                                <ion-col class="text-only">&nbsp;&nbsp;{{classifiedAd.status}}</ion-col>
                                <ion-col class="left"></ion-col>
                                <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                                <ion-col class="left">{{classifiedAd.clicksCount}}</ion-col>
                                <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                                <ion-col class="left">{{classifiedAd.likesCount}}</ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                    <div *ngIf="classifiedAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                         [style.background]="'url(' + resourcesBaseUrl + classifiedAd.logoUrl + ')'"
                         [style.background-size]="'100% 100%'">
                    </div>
                    <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive && !isMyClassifiedAd(classifiedAd) && !showInsightsSummary"></taeam-favourites-icon>
                    <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
                </ion-slide>
            </ion-slides>
        </div>
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasImageOnly(classifiedAd.imagesAndVideos) || (hasFirstImage(classifiedAd.imagesAndVideos) && hideSliderView)">
            <img [src]="getClassifiedAdResourceUrl(classifiedAd)" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/classified-ad-loading.jpg'" alt="">
            <div class="free-simple-top" *ngIf="!wlaActive && classifiedAd.tagText && classifiedAd.tagTextIsOffer">{{classifiedAd.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showClassifiedAdSocialSharing($event, classifiedAd)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && classifiedAd.tagText && !classifiedAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{classifiedAd.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="classifiedAd.status && classifiedAd.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                <ion-grid>
                    <ion-row>
                        <ion-col class="text-only">&nbsp;&nbsp;{{classifiedAd.status}}</ion-col>
                        <ion-col class="left"></ion-col>
                        <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                        <ion-col class="left">{{classifiedAd.clicksCount}}</ion-col>
                        <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                        <ion-col class="left">{{classifiedAd.likesCount}}</ion-col>
                    </ion-row>
                </ion-grid>
            </div>
            <div *ngIf="classifiedAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + classifiedAd.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive && !isMyClassifiedAd(classifiedAd) && !showInsightsSummary"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasVideoOnly(classifiedAd.imagesAndVideos) || (hasFirstVideo(classifiedAd.imagesAndVideos) && hideSliderView)">
            <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(classifiedAd.multipleDisplayVideoPosterUrls, 0)">
                <source [src]="getClassifiedAdResourceUrl(classifiedAd)" type="video/mp4" />
            </video>
            <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="free-simple-top" *ngIf="!wlaActive && classifiedAd.tagText && classifiedAd.tagTextIsOffer">{{classifiedAd.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showClassifiedAdSocialSharing($event, classifiedAd)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && classifiedAd.tagText && !classifiedAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{classifiedAd.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="classifiedAd.status && classifiedAd.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                <ion-grid>
                    <ion-row>
                        <ion-col class="text-only">&nbsp;&nbsp;{{classifiedAd.status}}</ion-col>
                        <ion-col class="left"></ion-col>
                        <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                        <ion-col class="left">{{classifiedAd.clicksCount}}</ion-col>
                        <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                        <ion-col class="left">{{classifiedAd.likesCount}}</ion-col>
                    </ion-row>
                </ion-grid>
            </div>
            <div *ngIf="classifiedAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + classifiedAd.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive && !isMyClassifiedAd(classifiedAd) && !showInsightsSummary"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div class="product-detail">
            <ion-grid class="no-padding">
                <ion-row class="no-padding">
                    <ion-col class="no-padding"><h2 *ngIf="showName()">{{classifiedAd.title}}</h2></ion-col>
                    <ion-col style="text-align:right" *ngIf="this.wlaActive && this.wlaShowChangeLocation && this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location"><a (click)="goChangeLocation()">{{this.getLocationText()}}</a></ion-col>
                </ion-row>
            </ion-grid>
            <ion-thumbnail style="width:1px; height:1px;" *ngIf="!isMyClassifiedAd(classifiedAd)">
                <ion-img style="width:0px; height:0px;" [src]="this.getImpressionBlankImageUrl(classifiedAd)" (ionImgDidLoad)="logClassifiedAdImpression(classifiedAd)"></ion-img>
            </ion-thumbnail>
            <p class="{{getSummaryViewCss(false, applyPadding)}}">
                <span *ngIf="classifiedAd.reviewStars"><ion-icon name="star"></ion-icon><span>&nbsp;{{classifiedAd.reviewStars}}&nbsp;</span> {{classifiedAd.reviewRating}} ({{classifiedAd.reviewsCount}}) -</span>
                {{this.getClassifiedAdHeading(classifiedAd)}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="showPrice(classifiedAd) && this.isNullOrEmpty(classifiedAd.condition)">
                {{getPrice(classifiedAd) | currency: classifiedAd.priceSymbol}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="showPrice(classifiedAd) && !this.isNullOrEmpty(classifiedAd.condition)">
                {{getPrice(classifiedAd) | currency: classifiedAd.priceSymbol}} - {{classifiedAd.condition}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="classifiedAd.adMessage">
                {{classifiedAd.adMessage}}
            </p>
        </div>
        <div *ngIf="showInsightsSummary && showClassifiedAdActionButtons(classifiedAd)">
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col *ngIf="classifiedAd.isAdPublished && !isSold(classifiedAd)">
                        <ion-button size="default" fill="solid" (click)="unPublishClassifiedAd($event, classifiedAd)">Un-Publish</ion-button>
                    </ion-col>
                    <ion-col *ngIf="!classifiedAd.isAdPublished && !isSold(classifiedAd)">
                        <ion-button size="default" fill="solid" (click)="publishClassifiedAd($event, classifiedAd)">Publish</ion-button>
                    </ion-col>
                    <ion-col *ngIf="classifiedAd.isAdPublished && !isSold(classifiedAd)">
                        <ion-button size="default" fill="solid" (click)="soldClassifiedAd($event, classifiedAd)">Sold</ion-button>
                    </ion-col>
                    <ion-col *ngIf="canBeUnSold(classifiedAd)">
                        <ion-button size="default" fill="solid" (click)="unSoldClassifiedAd($event, classifiedAd)">Un-Sold</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>

    </div>
</div>
<div class="{{getCardViewCss(false, 'provider-view-root')}}" *ngIf="classifiedAd && leftImageViewStyle">
    <div (click)="showClassifiedAdWithLocationCheck($event, classifiedAd)" [ngClass]="applyPadding ? 'product-card' : 'product-card'">
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasMultipleImagesOrVideos(classifiedAd.imagesAndVideos) && !hideSliderView">
            <!--<img src="{{resourcesBaseUrl}}{{classifiedAd.imageUrl}}" alt="">-->
            <ion-slides #slider [options]="slideOptions" (ionSlideTransitionEnd)="slideChange(slider)">
                <ion-slide #slide *ngFor="let asset of classifiedAd.imagesAndVideos; let aindex = index">
                    <div class="clickable-div" (click)="showClassifiedAdWithLocationCheck($event, classifiedAd)"></div>
                    <img [src]="getClassifiedResourceUrl(classifiedAd, asset.name)" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/classified-ad-loading.jpg'" alt="" *ngIf="!asset.isVideo">
                    <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(classifiedAd.multipleDisplayVideoPosterUrls, aindex)" *ngIf="asset.isVideo">
                        <source [src]="getClassifiedResourceUrl(classifiedAd, asset.name)" type="video/mp4" />
                    </video>
                    <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="free-simple-top" *ngIf="!wlaActive && classifiedAd.tagText && classifiedAd.tagTextIsOffer">{{classifiedAd.tagText}}</div>
                    <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                        <ion-icon name="md-share" (click)="showClassifiedAdSocialSharing($event, classifiedAd)"></ion-icon>
                    </div>
                    <div class="offer-top" *ngIf="!wlaActive && classifiedAd.tagText && !classifiedAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{classifiedAd.tagText}}</div>
                    <div class="restaurant-card-bottom-bar warning" *ngIf="classifiedAd.status && classifiedAd.status == 'comming-soon'">Coming soon</div>
                    <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                        <ion-grid>
                            <ion-row>
                                <ion-col class="text-only">&nbsp;&nbsp;{{classifiedAd.status}}</ion-col>
                                <ion-col class="left"></ion-col>
                                <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                                <ion-col class="left">{{classifiedAd.clicksCount}}</ion-col>
                                <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                                <ion-col class="left">{{classifiedAd.likesCount}}</ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                    <div *ngIf="classifiedAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                         [style.background]="'url(' + resourcesBaseUrl + classifiedAd.logoUrl + ')'"
                         [style.background-size]="'100% 100%'">
                    </div>
                    <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive && !isMyClassifiedAd(classifiedAd) && !showInsightsSummary"></taeam-favourites-icon>
                    <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
                </ion-slide>
            </ion-slides>
        </div>
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasImageOnly(classifiedAd.imagesAndVideos) || (hasFirstImage(classifiedAd.imagesAndVideos) && hideSliderView)">

            <ion-item style="padding-left:0px !important;" lines="none">
                <ion-thumbnail style="width:110px; height:100px;">
                    <ion-img style="width: 110px; height: 100px; border-top-left-radius: 5px; border-bottom-left-radius: 5px; "  [src]="getClassifiedAdResourceUrl(classifiedAd)" onerror="this.onerror=null; this.src='https://assets.crazon.io/mobile/classified-ad-loading.jpg'" alt=""></ion-img>
                </ion-thumbnail>
                <ion-label class="ion-text-wrap">
                    <h1>{{this.getClassifiedAdHeading(classifiedAd)}}</h1>
                    <h2 *ngIf="showPrice(classifiedAd) && this.isNullOrEmpty(classifiedAd.condition)">{{getPrice(classifiedAd) | currency: classifiedAd.priceSymbol}}</h2>
                    <h2 *ngIf="showPrice(classifiedAd) && !this.isNullOrEmpty(classifiedAd.condition)">{{getPrice(classifiedAd) | currency: classifiedAd.priceSymbol}} - {{classifiedAd.condition}}</h2>
                </ion-label>
            </ion-item>
            <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive && !isMyClassifiedAd(classifiedAd) && !showInsightsSummary"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasVideoOnly(classifiedAd.imagesAndVideos) || (hasFirstVideo(classifiedAd.imagesAndVideos) && hideSliderView)">
            <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(classifiedAd.multipleDisplayVideoPosterUrls, 0)">
                <source [src]="getClassifiedAdResourceUrl(classifiedAd)" type="video/mp4" />
            </video>
            <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="free-simple-top" *ngIf="!wlaActive && classifiedAd.tagText && classifiedAd.tagTextIsOffer">{{classifiedAd.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showClassifiedAdSocialSharing($event, classifiedAd)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && classifiedAd.tagText && !classifiedAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{classifiedAd.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="classifiedAd.status && classifiedAd.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                <ion-grid>
                    <ion-row>
                        <ion-col class="text-only">&nbsp;&nbsp;{{classifiedAd.status}}</ion-col>
                        <ion-col class="left"></ion-col>
                        <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                        <ion-col class="left">{{classifiedAd.clicksCount}}</ion-col>
                        <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                        <ion-col class="left">{{classifiedAd.likesCount}}</ion-col>
                    </ion-row>
                </ion-grid>
            </div>
            <div *ngIf="classifiedAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + classifiedAd.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="!wlaActive && !isMyClassifiedAd(classifiedAd) && !showInsightsSummary"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="classifiedAd" dataType="classifiedad" *ngIf="isUserLoggedIn() && classifiedAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
    </div>
</div>
