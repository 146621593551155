<ion-item class="item-has-focus" *ngIf="obj && !this.isNullOrEmpty(fieldName) && (!this.isNullOrEmpty(options) || !this.isNullOrEmpty(obj.dataSource))">
    <ion-label position="floating" *ngIf="!this.isNullOrEmpty(title)">{{title}}</ion-label>
    <ion-select placeholder=" " interface="action-sheet" [(ngModel)]="obj.value" style="max-width: 100% !important;" *ngIf="isListDropDown()">
        <ion-select-option *ngFor="let item of getData(); let index = index" value="{{item}}">{{item}}</ion-select-option>
    </ion-select>
    <ion-input placeholder=" " style="display:none" *ngIf="!this.isNullOrEmpty(title) && (isListBox() || isListButton())"></ion-input>
    <ul *ngIf="isListBox()">
        <li *ngFor="let item of getData(); let index = index" [ngClass]="{'selected-value': isSame(obj[fieldName], item, allowMultiple)}" (click)="setValue(obj, fieldName, item, allowMultiple, maxOptionsAllowed)"><span>{{item}}</span></li>
    </ul>
    <ion-grid class="no-padding no-margin data-input-grid" *ngIf="isListButton()">
        <!--// && getItemsAsArrayFromDataSource(obj.dataSource, options) && this.listData && this.listData.length > 0-->
        <ion-row class="no-padding">
            <ion-col size="{{getColSize(obj)}}" *ngFor="let item of getData(); let index = index">
                <div style="font-size:14px;" [ngClass]="{'selected-value': isSame(obj[fieldName], item, allowMultiple)}" (click)="setValue(obj, fieldName, item, allowMultiple, maxOptionsAllowed)"><span>{{item}}</span></div>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>