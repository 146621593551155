<ion-content fullscreen class="ion-padding" scroll-y="false">
    <ion-slides>
        <ion-slide *ngFor="let asset of this.data; let aindex = index">
            <img [src]="getImageUrl(asset.name)" *ngIf="!this.isVideo(asset.name)">
            <video playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrl(this.data, aindex)" *ngIf="this.isVideo(asset.name)">
                <source [src]="getImageUrl(asset.name)" type="video/mp4" />
            </video>
        </ion-slide>
    </ion-slides>
    <div style="position: absolute; bottom: 20px; width:100%;">
        <ion-button (click)="dismiss()">Close</ion-button>
    </div>
</ion-content>