import { Injectable } from '@angular/core';
import { DomService } from './dom.service';

@Injectable()

export class ModalService {

  constructor(private domService: DomService) { }

  private modalElementId = 'modal-container-2';
  private overlayElementId = 'overlay';

  private animatedModalElementId = 'modal-container-2';
  private animatedModalContentsElementId = 'modal-container-2-contents';

  init(component: any, inputs: object, outputs: object) {
    let componentConfig = {
      inputs: inputs,
      outputs: outputs
    }
    this.domService.appendComponentTo(this.modalElementId, component, componentConfig);
    document.getElementById(this.modalElementId).className = 'show';
    document.getElementById(this.overlayElementId).className = 'show';
  }

  destroy() {
    this.domService.removeComponent();
    document.getElementById(this.modalElementId).className = 'hidden';
    document.getElementById(this.overlayElementId).className = 'hidden';
  }

  showModalWithName(component: any, inputs: object, outputs: object, dialogId: string) {
    this.modalElementId = dialogId;
    this.showModal(component, inputs, outputs);
  }

  showModal(component: any, inputs: object, outputs: object) {
    this.hideModal();
    let componentConfig = {
      inputs: inputs,
      outputs: outputs
    }
    this.domService.appendComponentTo(this.animatedModalElementId, component, componentConfig);
    document.querySelector('#' + this.animatedModalElementId).classList.add('md-show');
  }

  hideModal() {
    this.domService.removeComponent();
    document.querySelector('#' + this.animatedModalElementId).classList.remove('md-show');
  }


}
