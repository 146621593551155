import { Component, ChangeDetectorRef, ViewChild, ElementRef, ViewChildren, QueryList, Renderer2, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { NewsAlert } from '../../shared/components/app-news/app.news';
import { environment } from '../../../environments/environment';
import { BespokeServiceTemplate } from '../../shared/models/shared.model';
import { ClassifiedAdsService } from '../../services/classified.ads.service';
import { ServiceResourceService } from '../../services/serviceresource.service';

@Component({
    selector: 'app-services',
    templateUrl: 'services.html',
    styleUrls: ['services.scss']
})
export class Services extends ObjectBase {

    @ViewChildren('adPlayer') adPlayers: QueryList<any>;
    currentPlaying = null;
    hideSkeleton: boolean = false;
    stickyVideo: HTMLVideoElement = null;
    stickyPlaying = false;
    @ViewChild('stickyplayer') stickyPlayer: ElementRef;
    isAtRoot: boolean = true;
    serviceCategories = [];
    prevServiceCategoryName: string;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private restaurantService: RestaurantService,
        protected userService: UserService,
        private geolocation: Geolocation,
        public geoService: LocationService,
        private basketService: BasketService,
        private deviceService: DeviceService,
        private appVersionService: AppVersionService,
        private classifiedAdsService: ClassifiedAdsService,
        private serviceResourceService: ServiceResourceService,
        private videoPlayer: VideoPlayer,
        private ngZone: NgZone,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.restaurantService = RestaurantService.Instance;
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
        this.init();
    }

    init() {
        this.Log("Init");

        if (this.stateService.props$ && !this.stateService.props$.taeamServices) {
            this.stateService.setProps({ taeamServices: this.stateService.device$.taeamServices })
        }

        this.findPageAds();
        this.findServicesPageAds();
        this.initDefaultSocialSharingData();
        this.checkForAppRate();
    }

    showMenu() {
        this.menu.open("main");
    }

    async ionViewWillEnter() {
        this.showLoadingMetadata = false;
        this.resetLoginReturnUrl();
        this.Log("service loading");
        this.debugCore();
        this.isAtRoot = true;
        this.resetSelectedMenuCategories();
        if (!this.doWeKnowUserLocation()) {
            if (!this.checkIfLocationPreferenceToShow()) {
                await this.loadWlaActiveMarketplaceData();
            } else {
                this.Log("We don't know user location so asking user");
                this.stateService.setProps({ setUserLocation: true });
                this.go('preferences');
            }
        } else {
            this.stateService.setProps({ setUserLocation: null });
            this.personaliseService.getServicesMarketingAds().subscribe(result => { this.stateService.setProps({ servicesMarketingAds: result }); });
            this.personaliseService.getTaeamServices().subscribe(
                result => {
                    this.stateService.setDevice({ taeamServices: result });
                    this.findServicesPageAds();
                    this.scrollIntoView("top");
                    this.showLoadingMetadata = true;
                });
            //if (!this.stateService.device$.taeamServices || this.stateService.device$.taeamServices.length == 0) {
            //    this.personaliseService.getTaeamServices().subscribe(
            //        result => {
            //            this.stateService.setDevice({ taeamServices: result });
            //        });
            ////} else if (this.stateService.device$.taeamServices.length == 1 && !this.stateService.props$.manualServiceViewCall) {
            //}
            ////else if (this.stateService.device$.taeamServices.length == 1) {
            ////    this.showService(this.stateService.device$.taeamServices[0]);
            ////}
        }
        
    }

    async presentModal() {
        const modal = await this.modalController.create({
            component: NewsAlert,
            componentProps: { data: this.newsData },
            cssClass: 'news-model'
        });

        //this.displayActionSheet("News", "");
        await modal.present();
    }

    ionViewDidEnter() {
        this.personaliseService.logEvent("crazon-services-view", JSON.stringify({ geoLocation: this.stateService.device$.geo }));
        this.stateService.setProps({ taeamServicesUrl: null });

        if (this.stateService.device$ && this.stateService.device$.appmRestaurantId) {
            this.restaurantService.getRestaurant(this.stateService.device$.appmRestaurantId).subscribe(
                result => {
                    this.stateService.setDevice({ appmRestaurantId: null });
                    this.showRestaurantMenu(result);
                },
                error => {
                    this.stateService.setDevice({ appmRestaurantId: null });
                    if (!this.stateService.props$.manualServiceViewCall) {
                        if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
                            if (this.stateService.device$.taeamServices.length == 1) {
                                this.showService(this.stateService.device$.taeamServices[0]);
                            }
                        } else {
                            this.showService(this.personaliseService.getRestaurantService());
                        }
                    }
                });
        }

        if (!this.stateService.props$.manualServiceViewCall) {
            if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
                if (this.stateService.device$.taeamServices.length == 1) {
                    //I have commented below because people should see and choose service
                    //this.showService(this.stateService.device$.taeamServices[0]);
                }
            } else {
                //Commented below because I like users to see services page and submit marketing data
                //this.showService(this.personaliseService.getRestaurantService());
            }
        } else {
            //this.stateService.setProps({ manualServiceViewCall: null });
        }
        this.hideSkeleton = true;
    }

    ionViewDidLeave() {
        //this.stateService.setProps({ manualServiceViewCall: null });
    }

    changeLocation() {
        var url = "services";
        this.stateService.setProps({ taeamServicesUrl: url });        
        this.navigateWithState('/preferences');
    }

    async showService(service: any) {
        if (service) {
            await this.showLoader();
            if (service.hasServices) {
                this.personaliseService.getTaeamServices(service.serviceName).subscribe(
                    result => {
                        this.stateService.setDevice({ taeamServices: result });
                        this.stateService.setProps({ taeamServices: result });
                        this.isAtRoot = false;
                        this.scrollIntoView("top");
                    },
                    error => { this.hideLoader(); },
                    () => { this.hideLoader(); });
            } else {

                if (this.isServiceAppVersionCompatible(service)) {
                    this.stateService.resetProps();
                    this.stateService.setDevice({ selectedService: service });
                    this.stateService.setProps({ forceSearchRestaurant: true, marketingAds: null });
                    if (service.isBespokeService && !this.isNullOrEmpty(service.bespokeServiceTemplateUrl)) {
                        if (this.hasSelectedServiceId()) {
                            this.gotoSelectedServiceId(service);
                        } else {
                            this.go(service.bespokeServiceTemplateUrl);
                        }
                    } else {
                        if (this.hasSelectedServiceId()) {
                            this.gotoSelectedServiceId(service);
                        } else {
                            this.go("home");
                        }
                    }
                } else {
                    this.alert(this.toCapitalise(service.serviceSingularName), null, "Your app version is not compatible to access " + this.toLower(service.serviceSingularName, "service", "service") + ". Please update your app and try again.");
                }
            }            
        }
    }

    gotoSelectedServiceId(service: any) {
        if (this.isNullOrEmpty(service.bespokeServiceTemplateUrl) || this.isSame(service.bespokeServiceTemplateUrl, BespokeServiceTemplate.Default)) {
            this.restaurantService.getRestaurant(this.getService().selectedServiceId).subscribe(
                result => {
                    this.showRestaurantMenu(result);
                },
                error => {
                    this.handleError(error);
                });
        } else if (this.isSame(service.bespokeServiceTemplateUrl, BespokeServiceTemplate.ClassifiedAds)) {
            this.classifiedAdsService.getClassifiedAd(this.getService().selectedServiceId).subscribe(
                classifiedAd => {
                    this.showClassifiedAd(classifiedAd);
                },
                error => {
                    this.handleError(error);
                });
        } else if (this.isSame(service.bespokeServiceTemplateUrl, BespokeServiceTemplate.ServiceResource)) {
            this.serviceResourceService.getServiceResourceAd(this.getService().selectedServiceId).subscribe(
                serviceResourceAd => {
                    this.showServiceResourceAd(serviceResourceAd);
                },
                error => {
                    this.handleError(error);
                });
        } else {
            this.go(service.bespokeServiceTemplateUrl);
        }
    }

    async loadRootServices() {
        this.showLoader();
        try {
            this.personaliseService.getServicesMarketingAds().subscribe(result => { this.stateService.setProps({ servicesMarketingAds: result }); });
            this.personaliseService.getTaeamServices().subscribe(
                result => {
                    this.stateService.setDevice({ taeamServices: result });
                    this.stateService.setProps({ taeamServices: result });
                    this.isAtRoot = true;
                    this.findServicesPageAds();
                    this.scrollIntoView("top");
                },
                error => { this.hideLoader(); },
                () => { this.hideLoader(); });

        } finally {
            this.hideLoader();
        }
    }

    setDefaultService(service: any) {
        if (service) {
            this.stateService.setDevice({ defaultService: service });
        }
    }

    playVideoAd(adPlayer) {
        if (adPlayer) {
            adPlayer.muted = true;
            adPlayer.play();
        }
    }

    didScroll($event) {
        //console.log("Did scroll event - List of players: ", this.adPlayers);
        //console.log("Did scroll event - Current playing: ", this.currentPlaying);

        if (this.currentPlaying && this.isElementInViewPort(this.currentPlaying)) {
            return;
        } else if (this.currentPlaying && !this.isElementInViewPort(this.currentPlaying)) {
            this.currentPlaying.pause();
            this.currentPlaying = null;
        }

        if (this.adPlayers) {
            this.adPlayers.forEach(player => {
                //console.log('Player:', player);
                if (this.currentPlaying) {
                    return;
                }

                const nativeElement = player.nativeElement;
                const inView = this.isElementInViewPort(nativeElement);

                if (this.stickyVideo && this.stickyVideo.src == nativeElement.src) {
                    return;
                }

                if (inView) {
                    if (!nativeElement.impressionCounted) {
                        this.Log("Impression counted: No");
                        this.videoAdImpression(nativeElement.ad);
                        nativeElement.impressionCounted = true;
                    } else {
                        this.Log("Impression counted: Yes");
                    }
                    this.currentPlaying = nativeElement;
                    this.currentPlaying.muted = true;
                    this.currentPlaying.play();
                }
            });
        }
    }

    isElementInViewPort(elem) {
        const rect = elem.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    openFullScreen(elem) {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitEnterFullscreen) {
            elem.webkitEnterFullscreen();
            elem.enterFullscreen();
        }
    }

    playInLine(elem) {

    }

    playOnSide(elem) {
        if (this.stickyVideo) {
            this.renderer.removeChild(this.stickyPlayer.nativeElement, this.stickyVideo);
        }

        this.stickyVideo = elem.cloneNode(true);
        this.renderer.appendChild(this.stickyPlayer.nativeElement, this.stickyVideo);

        if (this.currentPlaying) {
            const playPosition = this.currentPlaying.currentTime;
            this.currentPlaying.pause();
            this.currentPlaying = null;
            this.stickyVideo.currentTime = playPosition;
        }

        this.stickyVideo.muted = false;
        this.stickyVideo.play();
        this.stickyPlaying = true;
    }

    playOrPauseSticky() {
        if (this.stickyPlaying) {
            this.stickyVideo.pause();
            this.stickyPlaying = false;
        } else {
            this.stickyVideo.play();
            this.stickyPlaying = true;
        }
    }

    closeSticky() {
        if (this.stickyVideo) {
            this.renderer.removeChild(this.stickyPlayer.nativeElement, this.stickyVideo);
            this.stickyVideo = null;
            this.stickyPlaying = false;
        }
    }

    muteAudio(ad: any) {
        if (this.currentPlaying) {
            this.currentPlaying.muted = true;
        }
        this.videoAdClicked(ad, false);
    }

    unMuteAudio(ad: any) {
        if (this.currentPlaying) {
            this.currentPlaying.muted = false;
        }
        this.videoAdClicked(ad, false);
    }

    showRestaurantMenu(restaurant: any) {
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            this.navigateWithState('/restaurant/' + restaurant.nameKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { restaurant: restaurant, reloadRestaurantMenu: true });
        } else {
            this.navigateWithState('/restaurant/' + restaurant.nameKey + '/delivery', { restaurant: restaurant, reloadRestaurantMenu: true });
        }
    }

    showClassifiedAd(classifiedAd: any) {
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            this.navigateWithState('/view-ad/' + classifiedAd.adKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { classifiedAd: classifiedAd, reloadClassifiedAd: true });
        } else {
            this.navigateWithState('/view-ad/' + classifiedAd.adKey, { classifiedAd: classifiedAd, reloadClassifiedAd: true });
        }
    }

    showServiceResourceAd(serviceResourceAd: any) {
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            this.navigateWithState('/view-sad/' + serviceResourceAd.adKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { serviceResourceAd: serviceResourceAd, reloadServiceResourceAd: true });
        } else {
            this.navigateWithState('/view-sad/' + serviceResourceAd.adKey, { serviceResourceAd: serviceResourceAd, reloadServiceResourceAd: true });
        }
    }

    async loadWlaActiveMarketplaceData() {
        if (!this.wlaActive) {
            this.personaliseService.getServicesMarketingAds().subscribe(result => { this.stateService.setProps({ servicesMarketingAds: result }); this.findServicesPageAds(); });
            this.personaliseService.getTaeamServices().subscribe(
                result => {
                    this.Log("Taeam service called", result);
                    if (result) {
                        this.Log("Taeam service called and data found", result);
                        this.stateService.setDevice({ taeamServices: result });
                        this.findServicesPageAds();

                        if (this.stateService.device$ && this.stateService.device$.appmRestaurantId) {
                            this.stateService.setDevice({ selectedService: result[0] });
                            this.stateService.setProps({ manualServiceViewCall: false, forceSearchRestaurant: true });
                            this.personaliseService.getMarketingAds().subscribe(result => { this.stateService.setProps({ marketingAds: result }); });
                            this.hideLoader();
                            this.goHome();
                        } else {
                            this.stateService.setProps({ manualServiceViewCall: true });
                            this.hideLoader();
                            this.checkForNews();
                            this.goServices();
                        }
                    } else {
                        this.Log("Taeam service called and not data found");
                    }
                });
        } else {
            this.hideSkeleton = false;
            if (!this.isNullOrEmpty(this.wlaServiceId)) {
                this.personaliseService.getTaeamService(this.wlaServiceId).subscribe(result => {
                    this.stateService.setDevice({ selectedService: result });
                    this.stateService.setProps({ selectedService: result });
                });
            }
            //this.basketService.forceClearBasket();
            this.restaurantService.getGroupRestaurants(environment.wlaRestaurantGroupId).subscribe(result => {
                if (result && result.length == 1 && !this.isNullOrEmpty(result[0].statusCode) && !this.isNullOrEmpty(result[0].statusDescription)) {
                    this.stateService.setProps({ wlaMultipleRestaurants: false, statusCode: result[0].statusCode, statusTitle: result[0].statusTitle, statusDescription: result[0].statusDescription, statusImageUrl: result[0].statusImageUrl });
                    this.go("status-response");
                } else if (result && result.length == 1) {
                    this.stateService.setState({ restaurant: result[0] });
                    this.stateService.setProps({ restaurant: result[0], wlaMultipleRestaurants: false });
                    this.stateService.setDevice({ restaurant: result[0] });
                    this.go("/restaurant/menu");
                } else if (result && result.length > 1) {
                    this.stateService.setState({ groupRestaurants: result });
                    this.stateService.setProps({ groupRestaurants: result, wlaMultipleRestaurants: true });
                    this.goHome();
                } else {
                    this.hideSkeleton = true;
                }
            });
        }
    }

    checkForNews() {
        this.ngZone.run(() => {
            this.personaliseService.getTaeamNews().subscribe(
                async data => {
                    data = this.personaliseService.checkIfNewsIdsArePresent(data);
                    if (data && data.length > 0) {
                        this.newsData = data;
                        this.showNewsModel = true;
                        await this.presentModal();
                    } else {
                        this.newsData = null;
                        this.showNewsModel = false;
                    }

                    this.stateService.setProps({ newsData: data });
                }
            );
        });

    }

    hasServices(): boolean {
        if (this.stateService.props$ && !this.stateService.props$.taeamServices) {
            this.stateService.setProps({ taeamServices: this.stateService.device$.taeamServices })
        }

        if (this.stateService.props$ && this.stateService.props$.taeamServices && this.stateService.props$.taeamServices.length > 0) {
            return true;
        }

        return false;
    }

    hasServiceCategories(): boolean {
        if (this.serviceCategories && this.hasData(this.serviceCategories)) {
            return true;
        }

        return false;
    }


    hasCategoryServices(category: any): boolean {
        if (category && this.hasData(category.taeamServices)) {
            return true;
        } 

        return false;
    }

    getBaseUrl(service: any) {
        if (service && !this.isNullOrEmpty(service.imageUrl)) {
            return service.imageUrl;
        }

        return null;
    }

    getIconUrl(service: any) {
        if (service && !this.isNullOrEmpty(service.iconUrl)) {
            return service.iconUrl;
        }

        return null;
    }

    getServiceColumnsToMerge(service: any, index: any): number {
        if (service.ads) {
            return service.columnsToMerge;
        }

        return 4;
    }

    hasServiceAds(index: any): boolean {
        if (index >= 0 && this.stateService.props$.taeamServices && this.stateService.props$.taeamServices.length > index && this.stateService.props$.taeamServices[index].ads) {
            return true;
        }

        return false;
    }    

    hasSingleServiceAds(index: any): boolean {
        if (index >= 0 && this.stateService.props$.taeamServices && this.stateService.props$.taeamServices.length > index && this.stateService.props$.taeamServices[index].ads && this.stateService.props$.taeamServices[index].ads.length == 1) {
            return true;
        }

        return false;
    }

    hasMultipleServiceAds(index: any): boolean {
        if (index >= 0 && this.stateService.props$.taeamServices && this.stateService.props$.taeamServices.length > index && this.stateService.props$.taeamServices[index].ads && this.stateService.props$.taeamServices[index].ads.length > 1) {
            return true;
        }

        return false;
    }

    getServiceAds(index: any): any {
        if (index >= 0 && this.stateService.props$.taeamServices && this.stateService.props$.taeamServices.length > index) {
            return this.stateService.props$.taeamServices[index].ads;
        }

        return null;
    }

    isDefaultServiceListTemplate(): boolean {
        var service = null;

        if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
            service = this.stateService.device$.taeamServices[0];
        }

        if (!service || !service.viewServiceListTemplateId || service.viewServiceListTemplateId == 0) {
            return true;
        }

        return false;
    }

    isIconsServiceListTemplate(): boolean {
        var service = null;

        if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
            service = this.stateService.device$.taeamServices[0];
        }

        if (service && service.viewServiceListTemplateId && service.viewServiceListTemplateId == 1) {
            return true;
        }

        return false;
    }

    isIconsServiceListWithCategoryTemplate(): boolean {
        var service = null;

        if (this.stateService.device$ && this.stateService.device$.taeamServices && this.stateService.device$.taeamServices.length > 0) {
            service = this.stateService.device$.taeamServices[0];
        }

        if (service && service.viewServiceListTemplateId && service.viewServiceListTemplateId == 2) {

            if (!this.hasData(this.serviceCategories)) {
                this.serviceCategories = this.personaliseService.convertTaeamServiceToCategoriesAndServices(this.stateService.device$.taeamServices);
            }

            this.Log("Converted services");
            this.Log(this.serviceCategories);
            return true;
        }

        return false;
    }

    public showServiceCategoryName(category: any): boolean {
        if (!this.isNullOrEmpty(category.serviceCategory) && !this.isSame(category.serviceCategory, this.prevServiceCategoryName)) {
            this.prevServiceCategoryName = category.serviceCategory;
            return true;
        }

        return false;
    }
}
