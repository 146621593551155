import { ChangeDetectorRef, ViewChild, ViewChildren, QueryList, Renderer2, Input, OnInit, Output, Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Restaurant } from 'src/app/shared/models/shared.model';
import { UiStateService } from 'src/app/services/ui.state.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController, IonSlides } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';

@Directive()
export class ProviderViewBase extends ObjectBase implements OnInit {

    @Input() restaurant: any;
    @Input() player: any;
    @Input() hideSliderView: boolean;
    @Input() applyPadding: boolean = false;
    @Input() doNotCallScroll: boolean = false;
    @Input() showOrderReadyFor: boolean = true;
    @Input() forceReloadData: boolean = false;
    @Input() logClickEvent: boolean = false;
    @Input() source: string = "";
    @Input() showRequestCredit: boolean = true;

    @Output("didScroll") didScroll: EventEmitter<any> = new EventEmitter();

    currentSlideIndex: number = 0;

    @ViewChildren('adPlayer') adPlayers: QueryList<any>;
    @ViewChildren('slide') slides: QueryList<any>;

    @ViewChild("adPlayer") adPlayer: any;
    @ViewChild("slider") slider: any;


    //@ViewChild("adPlayer1", { static: false }) adPlayer1: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;
    //@ViewChild("adPlayer2", { static: false }) adPlayer2: any;

    public slideOptions = {
        slidesPerView: 1.05,
        initialSlide: 0,
        speed: 400,
        spaceBetween: 10,
        autoHeight: true
    };


    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected restaurantService: RestaurantService,
        protected userService: UserService,
        protected geolocation: Geolocation,
        public geoService: LocationService,
        protected basketService: BasketService,
        protected deviceService: DeviceService,
        protected appVersionService: AppVersionService,
        //private loadingController: LoadingController,
        protected videoPlayer: VideoPlayer,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.restaurantService = RestaurantService.Instance;
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
    }

    ngOnInit() {
    }

    showMenu(restaurant: Restaurant) {
        //this.navigateWithState('/restaurant/menu', { restaurant: restaurant, reloadRestaurantMenu: true });
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            this.navigateWithState('/restaurant/' + restaurant.nameKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { restaurant: restaurant, reloadRestaurantMenu: true });
        } else {
            this.navigateWithState('/restaurant/' + restaurant.nameKey + '/delivery', { restaurant: restaurant, reloadRestaurantMenu: true });
        }
    }

    showMenuWithReloadRestaurantData(restaurant: Restaurant) {
        //this.navigateWithState('/restaurant/menu', { restaurant: restaurant, reloadRestaurantMenu: true });
        if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
            this.navigateWithState('/restaurant/' + restaurant.nameKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase(), { restaurant: null, reloadRestaurantMenu: true });
        } else {
            this.navigateWithState('/restaurant/' + restaurant.nameKey + '/delivery', { restaurant: null, reloadRestaurantMenu: true });
        }
    }

    showMenuWithLocationCheck(restaurant: any) {
        if (restaurant.ad) {
            this.adClicked(restaurant.ad, false).then(() => { });
            return;
        }

        if (this.logClickEvent) {
            this.logClickedEvent(this.source, restaurant.id);
        }

        if (this.forceReloadData && this.doWeKnowUserLocation()) {
            this.showMenuWithReloadRestaurantData(restaurant);
        } else if (this.doWeKnowUserLocation()) {
            this.showMenu(restaurant);
        } else {
            var url = "";
            if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod) {
                url = '/restaurant/' + restaurant.nameKey + '/' + this.stateService.device$.userPreferences.deliveryMethod.toLowerCase();
            } else {
                url = '/restaurant/' + restaurant.nameKey + '/delivery';
            }
            this.stateService.setState({ restaurant: restaurant, reloadRestaurantMenu: true });
            this.stateService.setProps({ restaurantMenuUrl: url });
            this.goChangeLocation();
        }
    }

    play($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        } 

        this.playVideo(player);
        $event.stopPropagation();
    }

    pause($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.pauseVideo(player);
        $event.stopPropagation();
    }

    mute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.muteAudio(player);
        $event.stopPropagation();
    }

    unmute($event, player) {
        if (this.didScroll && !this.doNotCallScroll) {
            this.didScroll.emit({ adPlayers: this.adPlayers });
        }

        this.unMuteAudio(player);
        $event.stopPropagation();
    }

    //slideChanged($event) {
    //    this.Log("Slide changed event fired");
    //    this.Log($event);
    //    this.Log(this.slider);
    //    this.slider.getActiveIndex().then(index => {
    //        this.currentSlideIndex = index;
    //    });
    //}

    //slideTaped($event) {
    //    this.Log($event);
    //    this.Log("Slide taped");
    //    this.slider.getActiveIndex().then(index => {
    //        this.currentSlideIndex = index;
    //    });
    //}

    //changeSlideIndex(num: number) {
    //    this.Log("Slide index function  is called", num);
    //    if (num > 0) {
    //        this.currentSlideIndex += num;
    //    } else if (num < 0 && this.currentSlideIndex > 0) {
    //        this.currentSlideIndex -= num;
    //    }
    //}

    //changeSlideIndex($event) {
    //    this.Log("Slide index function  is called", $event);
    //}

    slideChange(slider: IonSlides) {       
        this.Log(slider);
        slider.getActiveIndex().then(index => {
            //this.Log("Get index completed");
            ////this.currentSlideIndex = index;
            //this.Log(this.currentSlideIndex);
            //this.Log(this.slides);
        });

        if (this.didScroll && !this.doNotCallScroll) {
            this.Log("Calling didscroll ");
            this.didScroll.emit({ adPlayers: this.adPlayers });
        } else if (this.doNotCallScroll) {
            this.checkPlayers(this.adPlayers);
        }
    }

    public getAssetPosterUrl(assets: any, index: any): string {
        if (assets && assets.length > index && index >= 0) {
            for (var i = index; i < assets.length; i++) {
                if (assets[i].isPoster) {
                    return environment.marketplaceResourcesBaseUrl + this.restaurant.id + "/assets/menuitem/" + assets[i].name;
                }
            }
        } 

        return "";
    }

    public getAssetPosterUrlV2(assets: any, index: any): string {
        if (assets) {
            var assetsList = assets.split(',');
            if (assetsList && assetsList.length > index && index >= 0) {
                return assetsList[index];
            }
        }

        return "";
    }
}