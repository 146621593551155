import { Component, Input, ChangeDetectorRef, OnInit } from '@angular/core';
import { ObjectBase } from '../../object.base';
import { FeedbackOptions, FeedbackType } from '../../models/shared.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { UserService } from 'src/app/services/user.services';
import { NavController } from '@ionic/angular';
import { OrderService } from 'src/app/services/order.service';

@Component({
    selector: 'taeam-order-feedback',
    templateUrl: 'taeam.order.feedback.html',
    styleUrls: ['taeam.order.feedback.scss']
})
export class TaeamOrderFeedback extends ObjectBase implements OnInit {

    @Input() order: any;

    public feedbackReceived: boolean = false;
    public isHappy: boolean = false;
    public isNeutral: boolean = false;
    public isSad: boolean = false;
    public feedbackComments: string;
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected userService: UserService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private orderService: OrderService) {
        super(router, route, uiStateService, cdr, navCtrl, userService);        
    }

    ngOnInit() {
        this.Log("Order - ngOnInit");
        this.Log(this.order);
        this.personaliseService.logEvent("crazon-order-feedback-requested", JSON.stringify({ shortId: this.order.shortId, multipleOrdersShortId: this.order.multipleOrdersShortId }));
    }

    ionViewDidEnter() {
        if (this.order) {
            this.isHappy = this.order.orderReview == FeedbackOptions.Happy;
            this.isNeutral = this.order.orderReview == FeedbackOptions.Neutral;
            this.isSad = this.order.orderReview == FeedbackOptions.Sad;
        }
    }

    happy() {
        this.showLoader().then(loader => {
            this.Log("Happy feedback");
            this.isHappy = true;
            this.isNeutral = false;
            this.isSad = false;
            this.Log(this.order);
            this.personaliseService.logEvent("crazon-order-feedback-given", JSON.stringify({ shortId: this.order.shortId, multipleOrdersShortId: this.order.multipleOrdersShortId, feedback: FeedbackOptions.Happy, feedbackType: FeedbackType.RestaurantFeedback }));
            this.orderService.orderFeedback(this.order, FeedbackOptions.Happy, FeedbackType.RestaurantFeedback, this.feedbackComments).subscribe(
                result => {
                    this.feedbackReceived = true;
                    this.hideLoader();
                },
                error => {
                    this.handleError(error);
                },
                () => {
                    this.hideLoader();
                }
            )
        });
        
    }

    neutral() {
        this.showLoader().then(loader => {
            this.Log("Neutral feedback");
            this.isNeutral = true;
            this.isHappy = false;
            this.isSad = false;
            this.Log(this.order);
            this.personaliseService.logEvent("crazon-order-feedback-given", JSON.stringify({ shortId: this.order.shortId, multipleOrdersShortId: this.order.multipleOrdersShortId, feedback: FeedbackOptions.Neutral, feedbackType: FeedbackType.RestaurantFeedback }));
            this.orderService.orderFeedback(this.order, FeedbackOptions.Neutral, FeedbackType.RestaurantFeedback, this.feedbackComments).subscribe(
                result => {
                    this.feedbackReceived = true;
                    this.hideLoader();
                },
                error => {
                    this.handleError(error);
                },
                () => {
                    this.hideLoader();
                }
            )
        });
    }

    sad() {
        this.showLoader().then(loader => {
            this.Log("Sad feedback");
            this.isSad = true;
            this.isHappy = false;
            this.isNeutral = false;
            this.Log(this.order);
            this.personaliseService.logEvent("crazon-order-feedback-given", JSON.stringify({ shortId: this.order.shortId, multipleOrdersShortId: this.order.multipleOrdersShortId, feedback: FeedbackOptions.Sad, feedbackType: FeedbackType.RestaurantFeedback }));
            this.orderService.orderFeedback(this.order, FeedbackOptions.Sad, FeedbackType.RestaurantFeedback, this.feedbackComments).subscribe(
                result => {
                    this.feedbackReceived = true;
                    this.hideLoader();
                },
                error => {
                    this.handleError(error);
                },
                () => {
                    this.hideLoader();
                }
            )
        });
    }

    getExperienceLabelText() : string {
        if (!this.wlaActive) {
            return "Please share your Crazon experience";
        } else if (this.wlaActive && this.stateService.props$ && this.stateService.props$.restaurant && !this.isNullOrEmpty(this.stateService.props$.restaurant.name)) {
            return "Please share your " + this.stateService.props$.restaurant.name + " experience";
        } else {
            return "Please share your experience";
        }
    }
}
