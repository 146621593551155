import { Component, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { ObjectBase } from '../../object.base';
import { UiStateService } from '../../../services/ui.state.service';
import { UserService } from '../../../services/user.services';
import { FavouritesService } from '../../../services/favourites.service';
import { LocationService } from '../../../services/location.service';
import { CreditService } from '../../../services/credit.service';

@Component({
    selector: 'taeam-request-credit-icon',
    templateUrl: 'request.credit.button.html',
    styleUrls: ['./request.credit.button.scss'],
})
export class RequestCreditIcon extends ObjectBase implements OnInit {

    @Input() data: any;
    @Input() dataType: string;
    private geo: any;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        public modalController: ModalController,
        public favouritesService: FavouritesService,
        private creditService: CreditService,
        public geoService: LocationService
    ) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
        this.favouritesService = FavouritesService.Instance;
        this.creditService = CreditService.Instance;
    }

    ngOnInit() {
    }

    ionViewWillEnter() {
    }

    ionViewDidLeave() {
    }

    async requestCredit($event) {
        await this.showLoader();
        if (this.data) {
            this.personaliseService.logEvent("request-credit", JSON.stringify({ restaurantId: this.data.id, geoLocation: this.getGeolocation() }));
            this.creditService.getUserMarketplaceCreditRequest(this.data.id, this.data.restaurantGroupId).subscribe(result => {
                this.hideLoader();
                if (result && result.isEligibleToRaiseCreditRequest) {
                    this.navigateWithState('/credit/request-credit', { restaurant: this.data, creditRequest: result });
                } else if (result && result.message) {
                    this.alert(this.data.name, null, result.message);
                } else {
                    this.alert(this.data.name, null, "Sorry, credit facility is not available");
                }
            },
                error => {
                    this.handleError(error);
                },
                () => {
                    this.hideLoader();
                });
            //this.favouritesService.addOrRemove(this.data, this.dataType);
        }

        $event.stopPropagation();
    }

    getGeolocation() {
        //if (this.geo && this.geo.coords) {
        //    return { longitude: this.geo.coords.longitude, latitude: this.geo.coords.latitude }
        //}
        return this.geoService.getUserLocation(this.geo);
    }
}
