import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import 'moment-timezone';
import { Common } from '../shared/common';

declare var google: any;

@Injectable()
export class SmartPropertyService extends ServiceBase {

    private static _instance: SmartPropertyService;

    private createSmartPropertyApiUrl: string;
    private getLandlordPropertiesApiUrl: string;
    private getPropertyTypesApiUrl: string;
    private getPropertyOccupationTypesApiUrl: string;
    private getPropertyCountriesApiUrl: string;
    private uploadSmartPropertyImagesApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.createSmartPropertyApiUrl = environment.smartPropertyApiUrl + 'smart-property/landlord/create';
        this.getLandlordPropertiesApiUrl = environment.smartPropertyApiUrl + 'smart-property/landlord/my';
        this.getPropertyTypesApiUrl = environment.smartPropertyApiUrl + 'smart-property/property-types/get';
        this.getPropertyOccupationTypesApiUrl = environment.smartPropertyApiUrl + 'smart-property/occupation-types/get';
        this.getPropertyCountriesApiUrl = environment.smartPropertyApiUrl + 'smart-property/property-countries/get';
        this.uploadSmartPropertyImagesApiUrl = environment.smartPropertyApiUrl + 'smart-property/upload/image';

        if (!SmartPropertyService._instance) {
            SmartPropertyService._instance = this;
        }
    }

    public static get Instance() {
        var obj = this._instance || (this._instance = Common.InjectorInstance.get<SmartPropertyService>(SmartPropertyService));
        return obj;
    }

    public createSmartProperty(request: any): Observable<any> {
        return this.post(this.createSmartPropertyApiUrl, request);
    }

    public getLandlordProperties(): Observable<any> {
        return this.get(this.getLandlordPropertiesApiUrl);
    }

    public getPropertyTypes(): Observable<any> {
        return this.get(this.getPropertyTypesApiUrl);
    }

    public getPropertyOccupationTypes(): Observable<any> {
        return this.get(this.getPropertyOccupationTypesApiUrl);
    }

    public getPropertyCountries(): Observable<any> {
        return this.get(this.getPropertyCountriesApiUrl);
    }

    public uploadSmartPropertyImages(request: any): Observable<any> {
        return this.post(this.uploadSmartPropertyImagesApiUrl, request);
    }
}
