import { TopicService } from './services/topic.service';
import { NgModule, Injector, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './views/app.component';
import { BasketService } from './services/basket.service';
import { DeviceService } from './services/device.service';
import { DomService } from './services/dom.service';
import { LoginService } from './services/login.service';
import { ModalService } from './services/modal.service';
import { NotificationService } from './services/notification.service';
import { OrderService } from './services/order.service';
import { RestaurantService } from './services/restaurant.service';
import { SpinnerService } from './services/spinner.service';
import { StateService } from './services/state.service';
import { UiStateService } from './services/ui.state.service';
import { UserService } from './services/user.services';
import { VoucherService } from './services/voucher.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
//import { FCM } from '@ionic-native/fcm/ngx';
import { RemoteNotificationService } from './services/remote.notification.service';
import { Common } from './shared/common';
import { ToastNotificationService } from './services/toast.notification.service';
import { LocationService } from './services/location.service';
import { SupportService } from './services/support.service';
import { PersonlisationService } from './services/personalisation.service';
import { HTTP } from '@ionic-native/http/ngx';
import { MonitoringService } from './services/monitoring.service';
import { ErrorHandlerService } from './services/error.handler.service';
import { WeatherService } from './services/weather.service';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AppleWallet } from '@ionic-native/apple-wallet/ngx'
import { ApplePay } from '@ionic-native/apple-pay/ngx';
import { MarketingService } from './services/marketing.service';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { AppVersionService } from './services/app.version.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CurrencyConverterService } from './services/currency.converter';
import { FavouritesService } from './services/favourites.service';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { LocalDbService } from './services/local.db.service';
import { CreditService } from './services/credit.service';
import { SmartPropertyService } from './services/smart.property.service';
import { ClassifiedAdsService } from './services/classified.ads.service';
import { MenuTemplateService } from './services/menu.template.service';
import { Braintree, ApplePayOptions, PaymentUIOptions } from '@ionic-native/braintree/ngx';
import { ClassifiedAdsSearchService } from './services/classified.ads.search.service';
import { ClassifiedAdTemplateService } from './services/classified.ad.template.service';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ServiceResourceService } from './services/serviceresource.service';
import { ServiceResourceSearchService } from './services/serviceresource.search.service';
import { ServiceResourceTemplateService } from './services/serviceresource.template.service';
import { CalendarService } from './services/calendar.service';
export let InjectorInstance: Injector;

@NgModule({
    declarations: [
        AppComponent
        //,
        //TabsPage
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            name: 'crazon_db',
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
            //driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
        AppRoutingModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        //{
        //    provide: HTTP_INTERCEPTORS,
        //    useClass: LoaderInterceptor,
        //    multi: true
        //},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        BasketService,
        DeviceService,
        DomService,
        LoginService,
        ModalService,
        NotificationService,
        OrderService,
        RestaurantService,
        SpinnerService,
        StateService,
        UiStateService,
        UserService,
        SupportService,
        VoucherService,
        TopicService,
        RemoteNotificationService,
        ToastNotificationService,
        FormBuilder,
        Geolocation,
        LocationService,
        PersonlisationService,
        MonitoringService,
        WeatherService,
        MarketingService,
        AppVersionService,
        CurrencyConverterService,
        FavouritesService,
        CreditService,
        SmartPropertyService,
        ClassifiedAdsService,
        ClassifiedAdsSearchService,
        ClassifiedAdTemplateService,
        ServiceResourceService,
        ServiceResourceSearchService,
        ServiceResourceTemplateService,
        CalendarService,
        MenuTemplateService,
        LocalDbService,
        Braintree,
        CallNumber,
        NetworkInterface,
        ScreenOrientation,
        AppleWallet,
        ApplePay,
        AppAvailability,
        Deeplinks,
        SocialSharing,
        VideoPlayer,
        InAppBrowser,
        AppRate,
        HTTP
        //FCM
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        InjectorInstance = this.injector;
        Common.init(this.injector);
    }
}
