import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { Common } from '../shared/common';

@Injectable({
    providedIn: 'root'
})
export class CurrencyConverterService extends ServiceBase {

    private static _instance: CurrencyConverterService;

    private currencyConverterApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.currencyConverterApiUrl = environment.currencyConverterApiUrl + 'currency/convert/';
    }

    public static get Instance() {
        return this._instance || (this._instance = Common.InjectorInstance.get<CurrencyConverterService>(CurrencyConverterService));
    }

    public convertCurrency(from: string, to: string, amount: number): Observable<any> {
        var headers = this.addHeader("x-data", this.encode(JSON.stringify({ from: from, to: to, amount: amount })));
        return this.get(this.currencyConverterApiUrl, headers);
    }
}