import { ObjectBase } from 'src/app/shared/object.base';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.services';
import { DeviceService } from 'src/app/services/device.service';
import { UiStateService } from 'src/app/services/ui.state.service';
import { LoginService } from 'src/app/services/login.service';
import { NavController, MenuController } from '@ionic/angular';
import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: 'app.header.html',
    styleUrls: ['./app.header.scss']
})
export class AppHeader extends ObjectBase {

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        protected userService: UserService,
        protected menuController: MenuController,
        private deviceService: DeviceService,
        private loginService: LoginService) {
        super(router, route, uiStateService, cdr, navCtrl, userService);
    }
}
