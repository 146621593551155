import { Injectable } from '@angular/core';
import { ServiceBase } from './service.base';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { Common } from '../shared/common';
import { PersonlisationService } from './personalisation.service';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { ClassifiedAdsTemplateIds, MarketplaceThemes, MenuTemplateIds } from '../shared/models/constants.model';
@Injectable({
    providedIn: 'root'
})
export class ServiceResourceTemplateService extends ServiceBase {

    private static _instance: ServiceResourceTemplateService;

    constructor(
        protected http: HttpClient,
        private personalisationService: PersonlisationService,
        private appAvailability: AppAvailability,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.personalisationService = PersonlisationService.Instance;
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance || (this._instance = Common.InjectorInstance.get<ServiceResourceTemplateService>(ServiceResourceTemplateService));
    }

    public areMoreThanOnOptionsForItemDelivery(classifiedAd: any): boolean {
        var counter = 0;
        if (classifiedAd.deliveryAvailable) {
            counter++;
        }

        if (classifiedAd.collectionAvailable) {
            counter++;
        }

        if (classifiedAd.dineInAvailable) {
            counter++;
        }

        return counter > 1;
    }    

    public setupProviderAssets(classifiedAd: any): any {
        classifiedAd.assetUrls = this.getMultipleAssetUrls(classifiedAd.multipleDisplayAssetUrls);
        if (classifiedAd.assetUrls && classifiedAd.assetUrls.length > 0) {
            for (var i = 0; i < classifiedAd.assetUrls.length; i++) {
                classifiedAd.hasMultipleAssets = classifiedAd.assetUrls.length > 1;
                if (classifiedAd.assetUrls[i].isVideo && !classifiedAd.assetVideoUrl) {
                    classifiedAd.assetVideoUrl = classifiedAd.assetUrls[i].url;
                }

                if (!classifiedAd.assetUrls[i].isVideo && !classifiedAd.assetImageUrl) {
                    classifiedAd.assetImageUrl = classifiedAd.assetUrls[i].url;
                }

                if (classifiedAd.assetImageUrl && classifiedAd.assetVideoUrl) {
                    break;
                }
            }
        }

        return classifiedAd;
    }

    public getMultipleAssetUrls(urls: string): Array<any> {
        var result: Array<any> = null;

        if (urls) {
            var items = urls.split(',');
            result = new Array<any>();

            for (var i = 0; i < items.length; i++) {
                result.push({ url: items[i].trim(), isVideo: (items[i].toLowerCase().indexOf('video') > -1 || items[i].toLowerCase().indexOf('mp4') > -1) });
            }
        }

        this.Log("getMultipleAssetUrls");
        this.Log(result);
        return result;
    }

    //configureAdPage(classifiedAd: any): any {
    //    return null;
    //}

    public getImageUrl(classifiedAd: any, name: any) {
        if (name) {
            return environment.marketplaceResourcesBaseUrl + classifiedAd.id + "/assets/" + name;
        }
    }

    public getThumbnailUrl(classifiedAd: any) {
        return this.classifiedAdsResourcesBaseUrl + classifiedAd.id + "/assets/";
    }

    public isDefaultViewTemplate(classifiedAd: any): boolean {
        this.commonTemplateValidation();
        return classifiedAd && (this.isNullOrEmpty(classifiedAd.templateId) || classifiedAd.templateId == ClassifiedAdsTemplateIds.DefaultView);
    }    

    getGridColumnSize(menuCategory: any): number {
        if (menuCategory.isTwoColumn == true) {
            return 6;
        } else if (menuCategory.isOneColumn == true) {
            return 12;
        } else {
            return 6;
        }
    }

    //This functions is where we add all common templates code
    commonTemplateValidation() {
    }

    testTemplateAndThemesCode(restaurant: any) {
        //Delete below test line code
        if (environment.production == false && restaurant) {
            //restaurant.menuTemplateId = MenuTemplateIds.MainListMenuGrid2Column;
            //restaurant.standaloneTheme = MarketplaceThemes.Feature;
            //this.stateService.setState({ restaurant: restaurant });
        }
    }
}
