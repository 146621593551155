import { Component, ChangeDetectorRef, ViewChild, ViewChildren, QueryList, Renderer2, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UiStateService } from 'src/app/services/ui.state.service';
import { RestaurantService } from 'src/app/services/restaurant.service';
import { UserService } from 'src/app/services/user.services';
import 'rxjs/add/observable/interval';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController, MenuController } from '@ionic/angular';
import { ObjectBase } from 'src/app/shared/object.base';
import { LocationService } from 'src/app/services/location.service';
import { BasketService } from 'src/app/services/basket.service';
import { AppVersionService } from 'src/app/services/app.version.service';
import { DeviceService } from 'src/app/services/device.service';
import { VideoPlayer } from '@ionic-native/video-player/ngx';

@Component({
    selector: 'video-ad-view',
    templateUrl: 'video.ad.view.html',
    styleUrls: ['video.ad.view.scss']
})
export class VideoAdView extends ObjectBase implements OnInit {

    @Input() ad: any;
    @Input() player: any;
    @Input() applyPadding: boolean = false;
    @Input() checkForServiveAdLink: boolean = false;

    @ViewChild('adPlayer') adPlayer: any;
    @ViewChildren('adPlayer') adPlayers: QueryList<any>;

    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected uiStateService: UiStateService,
        protected cdr: ChangeDetectorRef,
        protected navCtrl: NavController,
        private restaurantService: RestaurantService,
        protected userService: UserService,
        private geolocation: Geolocation,
        public geoService: LocationService,
        private basketService: BasketService,
        private deviceService: DeviceService,
        private appVersionService: AppVersionService,
        //private loadingController: LoadingController,
        private videoPlayer: VideoPlayer,
        protected menu: MenuController,
        protected renderer: Renderer2) {
        super(router, route, uiStateService, cdr, navCtrl, userService, renderer);
        this.restaurantService = RestaurantService.Instance;
        this.basketService = BasketService.Instance;
        this.appVersionService = AppVersionService.Instance;
        this.deviceService = DeviceService.Instance;
    }

    ngOnInit() {
    }

    ionViewDidEnter() {
    }


}