<div class="{{getCardViewCss(false, 'provider-view-root')}}" *ngIf="serviceResourceAd && !leftImageViewStyle">
    <div (click)="showServiceResourceAdWithLocationCheck($event, serviceResourceAd)" [ngClass]="applyPadding ? 'product-card' : 'product-card'">
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasMultipleImagesOrVideos(serviceResourceAd.imagesAndVideos) && !hideSliderView">
            <!--<img src="{{resourcesBaseUrl}}{{serviceResourceAd.imageUrl}}" alt="">-->
            <ion-slides #slider [options]="slideOptions" (ionSlideTransitionEnd)="slideChange(slider)">
                <ion-slide #slide *ngFor="let asset of serviceResourceAd.imagesAndVideos; let aindex = index">
                    <div class="clickable-div" (click)="showServiceResourceAdWithLocationCheck($event, serviceResourceAd)"></div>
                    <img #img [src]="getServiceResourceResourceUrl(serviceResourceAd, asset.name)" (error)="onImageError(img)" alt="" *ngIf="!asset.isVideo">
                    <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(serviceResourceAd.multipleDisplayVideoPosterUrls, aindex)" *ngIf="asset.isVideo">
                        <source [src]="getServiceResourceResourceUrl(serviceResourceAd, asset.name)" type="video/mp4" />
                    </video>
                    <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="free-simple-top" *ngIf="!wlaActive && serviceResourceAd.tagText && serviceResourceAd.tagTextIsOffer">{{serviceResourceAd.tagText}}</div>
                    <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                        <ion-icon name="md-share" (click)="showServiceResourceAdSocialSharing($event, serviceResourceAd)"></ion-icon>
                    </div>
                    <div class="offer-top" *ngIf="!wlaActive && serviceResourceAd.tagText && !serviceResourceAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{serviceResourceAd.tagText}}</div>
                    <div class="restaurant-card-bottom-bar warning" *ngIf="serviceResourceAd.status && serviceResourceAd.status == 'comming-soon'">Coming soon</div>
                    <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                        <ion-grid>
                            <ion-row>
                                <ion-col class="text-only">&nbsp;&nbsp;{{serviceResourceAd.status}}</ion-col>
                                <ion-col class="left"></ion-col>
                                <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                                <ion-col class="left">{{serviceResourceAd.clicksCount}}</ion-col>
                                <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                                <ion-col class="left">{{serviceResourceAd.likesCount}}</ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                    <div *ngIf="serviceResourceAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                         [style.background]="'url(' + resourcesBaseUrl + serviceResourceAd.logoUrl + ')'"
                         [style.background-size]="'100% 100%'">
                    </div>
                    <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive && !isMyServiceResourceAd(serviceResourceAd) && !showInsightsSummary"></taeam-favourites-icon>
                    <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
                </ion-slide>
            </ion-slides>
        </div>
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasImageOnly(serviceResourceAd.imagesAndVideos) || (hasFirstImage(serviceResourceAd.imagesAndVideos) && hideSliderView)">
            <img #img [src]="getServiceResourceAdResourceUrl(serviceResourceAd)" (error)="onImageError(img)" alt="">
            <div class="free-simple-top" *ngIf="!wlaActive && serviceResourceAd.tagText && serviceResourceAd.tagTextIsOffer">{{serviceResourceAd.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showServiceResourceAdSocialSharing($event, serviceResourceAd)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && serviceResourceAd.tagText && !serviceResourceAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{serviceResourceAd.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="serviceResourceAd.status && serviceResourceAd.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                <ion-grid>
                    <ion-row>
                        <ion-col class="text-only">&nbsp;&nbsp;{{serviceResourceAd.status}}</ion-col>
                        <ion-col class="left"></ion-col>
                        <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                        <ion-col class="left">{{serviceResourceAd.clicksCount}}</ion-col>
                        <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                        <ion-col class="left">{{serviceResourceAd.likesCount}}</ion-col>
                    </ion-row>
                </ion-grid>
            </div>
            <div *ngIf="serviceResourceAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + serviceResourceAd.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive && !isMyServiceResourceAd(serviceResourceAd) && !showInsightsSummary"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasVideoOnly(serviceResourceAd.imagesAndVideos) || (hasFirstVideo(serviceResourceAd.imagesAndVideos) && hideSliderView)">
            <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(serviceResourceAd.multipleDisplayVideoPosterUrls, 0)">
                <source [src]="getServiceResourceAdResourceUrl(serviceResourceAd)" type="video/mp4" />
            </video>
            <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="free-simple-top" *ngIf="!wlaActive && serviceResourceAd.tagText && serviceResourceAd.tagTextIsOffer">{{serviceResourceAd.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showServiceResourceAdSocialSharing($event, serviceResourceAd)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && serviceResourceAd.tagText && !serviceResourceAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{serviceResourceAd.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="serviceResourceAd.status && serviceResourceAd.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                <ion-grid>
                    <ion-row>
                        <ion-col class="text-only">&nbsp;&nbsp;{{serviceResourceAd.status}}</ion-col>
                        <ion-col class="left"></ion-col>
                        <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                        <ion-col class="left">{{serviceResourceAd.clicksCount}}</ion-col>
                        <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                        <ion-col class="left">{{serviceResourceAd.likesCount}}</ion-col>
                    </ion-row>
                </ion-grid>
            </div>
            <div *ngIf="serviceResourceAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + serviceResourceAd.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive && !isMyServiceResourceAd(serviceResourceAd) && !showInsightsSummary"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div class="product-detail">
            <ion-grid class="no-padding">
                <ion-row class="no-padding">
                    <ion-col class="no-padding"><h2 *ngIf="showName()">{{serviceResourceAd.title}}</h2></ion-col>
                    <ion-col style="text-align:right" *ngIf="this.wlaActive && this.wlaShowChangeLocation && this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.deliveryMethod && this.stateService.device$.userPreferences.userLocation" class="change-location"><a (click)="goChangeLocation()">{{this.getLocationText()}}</a></ion-col>
                </ion-row>
            </ion-grid>
            <ion-thumbnail style="width:1px; height:1px;" *ngIf="!isMyServiceResourceAd(serviceResourceAd)">
                <ion-img style="width:0px; height:0px;" [src]="this.getImpressionBlankImageUrl(serviceResourceAd)" (ionImgDidLoad)="logServiceResourceAdImpression(serviceResourceAd)"></ion-img>
            </ion-thumbnail>
            <p class="{{getSummaryViewCss(false, applyPadding)}}">
                <span *ngIf="serviceResourceAd.reviewStars"><ion-icon name="star"></ion-icon><span>&nbsp;{{serviceResourceAd.reviewStars}}&nbsp;</span> {{serviceResourceAd.reviewRating}} ({{serviceResourceAd.reviewsCount}}) -</span>
                {{this.getServiceResourceAdHeading(serviceResourceAd)}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="showPrice(serviceResourceAd) && this.isNullOrEmpty(serviceResourceAd.condition)">
                {{getPrice(serviceResourceAd) | currency: serviceResourceAd.priceSymbol}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="showPrice(serviceResourceAd) && !this.isNullOrEmpty(serviceResourceAd.condition)">
                {{getPrice(serviceResourceAd) | currency: serviceResourceAd.priceSymbol}} - {{serviceResourceAd.condition}}
            </p>
            <p class="{{getSummaryViewCss(false, applyPadding)}}" style="margin-top: 5px;" *ngIf="serviceResourceAd.adMessage">
                {{serviceResourceAd.adMessage}}
            </p>
        </div>
        <div *ngIf="showInsightsSummary && showServiceResourceAdActionButtons(serviceResourceAd)">
            <ion-grid>
                <ion-row class="ion-justify-content-center">
                    <ion-col *ngIf="serviceResourceAd.isAdPublished && !isBooked(serviceResourceAd)">
                        <ion-button size="default" fill="solid" (click)="unPublishServiceResourceAd($event, serviceResourceAd)">Un-Publish</ion-button>
                    </ion-col>
                    <ion-col *ngIf="!serviceResourceAd.isAdPublished && !isBooked(serviceResourceAd)">
                        <ion-button size="default" fill="solid" (click)="publishServiceResourceAd($event, serviceResourceAd)">Publish</ion-button>
                    </ion-col>
                    <ion-col *ngIf="serviceResourceAd.isAdPublished && !isBooked(serviceResourceAd)">
                        <ion-button size="default" fill="solid" (click)="bookedServiceResourceAd($event, serviceResourceAd)">Booked</ion-button>
                    </ion-col>
                    <ion-col *ngIf="canBeUnBooked(serviceResourceAd)">
                        <ion-button size="default" fill="solid" (click)="unBookServiceResourceAd($event, serviceResourceAd)">Un-Booked</ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>

    </div>
</div>
<div class="{{getCardViewCss(false, 'provider-view-root')}}" *ngIf="serviceResourceAd && leftImageViewStyle">
    <div (click)="showServiceResourceAdWithLocationCheck($event, serviceResourceAd)" [ngClass]="applyPadding ? 'product-card' : 'product-card'">
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasMultipleImagesOrVideos(serviceResourceAd.imagesAndVideos) && !hideSliderView">
            <!--<img src="{{resourcesBaseUrl}}{{serviceResourceAd.imageUrl}}" alt="">-->
            <ion-slides #slider [options]="slideOptions" (ionSlideTransitionEnd)="slideChange(slider)">
                <ion-slide #slide *ngFor="let asset of serviceResourceAd.imagesAndVideos; let aindex = index">
                    <div class="clickable-div" (click)="showServiceResourceAdWithLocationCheck($event, serviceResourceAd)"></div>
                    <img #img [src]="getServiceResourceResourceUrl(serviceResourceAd, asset.name)" (error)="onImageError(img)" alt="" *ngIf="!asset.isVideo">
                    <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(serviceResourceAd.multipleDisplayVideoPosterUrls, aindex)" *ngIf="asset.isVideo">
                        <source [src]="getServiceResourceResourceUrl(serviceResourceAd, asset.name)" type="video/mp4" />
                    </video>
                    <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right play" (click)="play($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.paused">
                        <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && !adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer.nativeElement)" *ngIf="asset.isVideo && adPlayer && adPlayer.nativeElement.muted">
                        <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
                    </div>
                    <div class="free-simple-top" *ngIf="!wlaActive && serviceResourceAd.tagText && serviceResourceAd.tagTextIsOffer">{{serviceResourceAd.tagText}}</div>
                    <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                        <ion-icon name="md-share" (click)="showServiceResourceAdSocialSharing($event, serviceResourceAd)"></ion-icon>
                    </div>
                    <div class="offer-top" *ngIf="!wlaActive && serviceResourceAd.tagText && !serviceResourceAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{serviceResourceAd.tagText}}</div>
                    <div class="restaurant-card-bottom-bar warning" *ngIf="serviceResourceAd.status && serviceResourceAd.status == 'comming-soon'">Coming soon</div>
                    <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                        <ion-grid>
                            <ion-row>
                                <ion-col class="text-only">&nbsp;&nbsp;{{serviceResourceAd.status}}</ion-col>
                                <ion-col class="left"></ion-col>
                                <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                                <ion-col class="left">{{serviceResourceAd.clicksCount}}</ion-col>
                                <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                                <ion-col class="left">{{serviceResourceAd.likesCount}}</ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                    <div *ngIf="serviceResourceAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                         [style.background]="'url(' + resourcesBaseUrl + serviceResourceAd.logoUrl + ')'"
                         [style.background-size]="'100% 100%'">
                    </div>
                    <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive && !isMyServiceResourceAd(serviceResourceAd) && !showInsightsSummary"></taeam-favourites-icon>
                    <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
                </ion-slide>
            </ion-slides>
        </div>
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasImageOnly(serviceResourceAd.imagesAndVideos) || (hasFirstImage(serviceResourceAd.imagesAndVideos) && hideSliderView)">

            <ion-item style="padding-left:0px !important;" lines="none">
                <ion-thumbnail style="width:110px; height:100px;">
                    <ion-img style="width: 110px; height: 100px; border-top-left-radius: 5px; border-bottom-left-radius: 5px; "  [src]="getServiceResourceAdResourceUrl(serviceResourceAd)" onerror="this.onerror=null; this.src='{{getComingSoonImageUrl()}}'" alt=""></ion-img>
                </ion-thumbnail>
                <ion-label class="ion-text-wrap">
                    <h1>{{this.getServiceResourceAdHeading(serviceResourceAd)}}</h1>
                    <h2 *ngIf="showPrice(serviceResourceAd) && this.isNullOrEmpty(serviceResourceAd.condition)">{{getPrice(serviceResourceAd) | currency: serviceResourceAd.priceSymbol}}</h2>
                    <h2 *ngIf="showPrice(serviceResourceAd) && !this.isNullOrEmpty(serviceResourceAd.condition)">{{getPrice(serviceResourceAd) | currency: serviceResourceAd.priceSymbol}} - {{serviceResourceAd.condition}}</h2>
                </ion-label>
            </ion-item>
            <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive && !isMyServiceResourceAd(serviceResourceAd) && !showInsightsSummary"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
        <div [ngClass]="applyPadding ? 'product-cover with-padding' : 'product-cover'" *ngIf="hasVideoOnly(serviceResourceAd.imagesAndVideos) || (hasFirstVideo(serviceResourceAd.imagesAndVideos) && hideSliderView)">
            <video #adPlayer playsinline preload="auto" (ended)="videoEnded()" [poster]="getAssetPosterUrlV2(serviceResourceAd.multipleDisplayVideoPosterUrls, 0)">
                <source [src]="getServiceResourceAdResourceUrl(serviceResourceAd)" type="video/mp4" />
            </video>
            <div class="video-icons-bottom-right playPause" (click)="pause($event, adPlayer)" *ngIf="!adPlayer.paused">
                <ion-img src="/assets/images/pause.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right play" (click)="play($event, adPlayer)" *ngIf="adPlayer.paused">
                <ion-img src="/assets/images/play.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="mute($event, adPlayer)" *ngIf="!adPlayer.muted">
                <ion-img src="/assets/images/sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="video-icons-bottom-right speaker" (click)="unmute($event, adPlayer)" *ngIf="adPlayer.muted">
                <ion-img src="/assets/images/no-sound.svg" style="width:20px; height:20px"></ion-img>
            </div>
            <div class="free-simple-top" *ngIf="!wlaActive && serviceResourceAd.tagText && serviceResourceAd.tagTextIsOffer">{{serviceResourceAd.tagText}}</div>
            <div class="social-sharing-restaurant-top" *ngIf="this.canSocialShare">
                <ion-icon name="md-share" (click)="showServiceResourceAdSocialSharing($event, serviceResourceAd)"></ion-icon>
            </div>
            <div class="offer-top" *ngIf="!wlaActive && serviceResourceAd.tagText && !serviceResourceAd.tagTextIsOffer"><span class="fa fa-tag"></span> {{serviceResourceAd.tagText}}</div>
            <div class="restaurant-card-bottom-bar warning" *ngIf="serviceResourceAd.status && serviceResourceAd.status == 'comming-soon'">Coming soon</div>
            <div class="restaurant-card-summary-bar" *ngIf="showInsightsSummary">
                <ion-grid>
                    <ion-row>
                        <ion-col class="text-only">&nbsp;&nbsp;{{serviceResourceAd.status}}</ion-col>
                        <ion-col class="left"></ion-col>
                        <ion-col class="right"><ion-icon name="eye"></ion-icon></ion-col>
                        <ion-col class="left">{{serviceResourceAd.clicksCount}}</ion-col>
                        <ion-col class="right"><ion-icon name="thumbs-up"></ion-icon></ion-col>
                        <ion-col class="left">{{serviceResourceAd.likesCount}}</ion-col>
                    </ion-row>
                </ion-grid>
            </div>
            <div *ngIf="serviceResourceAd.logoUrl" class="restaurant-card-bottom-bar-logo"
                 [style.background]="'url(' + resourcesBaseUrl + serviceResourceAd.logoUrl + ')'"
                 [style.background-size]="'100% 100%'">
            </div>
            <taeam-favourites-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="!wlaActive && !isMyServiceResourceAd(serviceResourceAd) && !showInsightsSummary"></taeam-favourites-icon>
            <taeam-request-credit-icon [data]="serviceResourceAd" dataType="serviceresourcead" *ngIf="isUserLoggedIn() && serviceResourceAd.allowCredit && showRequestCredit"></taeam-request-credit-icon>
        </div>
    </div>
</div>
