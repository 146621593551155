import { DeviceService } from 'src/app/services/device.service';
import { Directive } from '@angular/core';
import { Observable, from, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { v4 as uuid } from 'uuid';
import "rxjs/add/operator/timeoutWith";
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs-compat/add/operator/timeout';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/operator/retryWhen';
import 'rxjs-compat/add/operator/mergeMap';
import 'rxjs-compat/add/operator/take';
import 'rxjs-compat/add/operator/concat';
import { StateService } from './state.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastNotificationService } from './toast.notification.service';
import { Guid } from "guid-typescript";
import { Base64 } from 'js-base64';
import { HTTP } from '@ionic-native/http/ngx';
import { MonitoringService } from './monitoring.service';
import { DeliveryMethods } from '../shared/models/shared.model';
import * as moment from 'moment';
import 'moment-timezone';
//import * as moment from 'moment-timezone';

declare function gtag_report_conversion(url): any;

@Directive()
export class ServiceBase {
    protected stateService: StateService;
    protected deviceService: DeviceService;
    protected toastNotificationService: ToastNotificationService;
    protected monitoringService: MonitoringService;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP
        ) {
        this.stateService = StateService.Instance;
        this.deviceService = DeviceService.Instance;
        this.toastNotificationService = ToastNotificationService.Instance;
        this.monitoringService = MonitoringService.Instance;
    }

    protected version: string = environment.version;
    protected showDebug: boolean = environment.showDebug;
    protected token: string = null;
    protected postTimeout = 10000;
    protected timeout = 10000;
    protected nativeTimeout = 60;
    protected waitBeforeRetry = 1000;
    protected maxRetries = 3;
    protected currentRetryNumber = 0;
    protected recentlyViewedLimit: number = environment.recentlyViewedLimit;
    protected wlaRecentlyViewedLimit: number = environment.wlaRecentlyViewedLimit;
    protected wlaActive: boolean = environment.wlaActive;
    protected wlaRestaurantGroupId: string = environment.wlaRestaurantGroupId;
    protected wlaRestaurantId: string = environment.wlaRestaurantId;
    protected wlaServiceId: string = environment.wlaServiceId;

    public resourcesBaseUrl: string = environment.resourcesBaseUrl;
    public marketingResourcesBaseUrl: string = environment.marketingResourcesBaseUrl;
    public marketplaceResourcesBaseUrl: string = environment.marketplaceResourcesBaseUrl;
    public classifiedAdsResourcesBaseUrl: string = environment.classifiedAdsResourcesBaseUrl;

    //protected apiVersion: string = "";
    protected post(url: string, data: any, options: HttpHeaders = null) {
        this.resetWaitRetryCounter();

        if (this.deviceService.isWeb) {
            return this.postPwa(url, data, options);
        } else {
            return this.postNative(url, data, options);
        }
    }

    protected put(url: string, data: any, options: HttpHeaders = null) {
        this.resetWaitRetryCounter();

        if (this.deviceService.isWeb) {
            return this.putPwa(url, data, options);
        } else {
            return this.putNative(url, data, options);
        }
    }

    protected get(url: string, options: HttpHeaders = null) {
        this.resetWaitRetryCounter();

        if (this.deviceService.isWeb) {
            return this.getPwa(url, options);
        } else {
            return this.getNative(url, options);
        }
    }

    protected getSimple(url: string, options: HttpHeaders = null) {
        this.resetWaitRetryCounter();

        if (this.deviceService.isWeb) {
            return this.getPwaSimple(url, options);
        } else {
            return this.getNativeSimple(url, options);
        }
    }

    protected postNative(url: string, data: any, options: HttpHeaders = null, retryCount: number = 1): Observable<any> {
        this.Trace("PostNative is called");
        this.Trace(url);
        this.Trace(retryCount);

        var headers: any = {};
        headers = this.getRequestHeaders(options);

        this.Log(data);
        this.Log(options);
        this.httpNative.setDataSerializer('json');
        this.httpNative.setRequestTimeout(this.nativeTimeout);
        this.httpNative.setServerTrustMode("nocheck");

        var request = this.httpNative.post(url, data, headers);
        return from(request)
            .timeout(this.postTimeout)
            .map(res => this.extractAllNative(res))
            .retryWhen(
                error => {
                    this.Trace("Error occurred in retryWhen when requesting to server within observable");
                    this.Trace(error);
                    return error
                        .flatMap((error: any) => {
                            //if (error.status == 0) {
                            //  return throwError({ error: 'Your internet connection is offline. Please connect and retry' });
                            //}

                            if (error.status >= 400 && error.stauts < 500) {
                                this.Log('404 error - insidie retryWhen method');
                                return of(error.status);
                            }

                            if ((error.status >= 500 && error.status < 600) || error.status === 0) {
                                return of(error.status).delay(this.getWaitBeforeRetry())
                            }

                            if (error.name && error.name == "TimeoutError") {
                                this.Log("Time out error identified by retry function and now it will retry", this.currentRetryNumber);
                                return of(error.status).delay(this.getWaitBeforeRetry())
                            }

                            this.Trace("Just before throwError");
                            this.Trace(error);

                            if (error && error.status) {
                                return throwError({ error: error });
                            }
                        })
                        .take(this.maxRetries)
                        .concat(throwError({ error: error, reason: `Sorry, there was an error (after ${this.maxRetries} retries)` }));
                });
    }

    protected putNative(url: string, data: any, options: HttpHeaders = null, retryCount: number = 1): Observable<any> {
        this.Trace("PuttNative is called");
        this.Trace(url);
        this.Trace(retryCount);

        var headers: any = {};
        headers = this.getRequestHeaders(options);

        this.Log(data);
        this.Log(options);
        this.Trace("Now setting up http native properties");
        this.httpNative.setDataSerializer('json');
        this.httpNative.setRequestTimeout(this.nativeTimeout);
        this.httpNative.setServerTrustMode("nocheck");
        
        var request = this.httpNative.put(url, data, headers);

        return from(request)
            .timeout(this.postTimeout)
            .map(res => this.extractAllNative(res))
            .retryWhen(
                error => {
                    return error
                        .flatMap((error: any) => {
                            if (error.status >= 400 && error.stauts < 500) {
                                this.Log('404 error - insidie retryWhen method');
                                return of(error.status);
                            }

                            if ((error.status >= 500 && error.status < 600) || error.status === 0) {
                                return of(error.status).delay(this.getWaitBeforeRetry())
                            }

                            if (error.name && error.name == "TimeoutError") {
                                this.Log("Time out error identified by retry function and now it will retry", this.currentRetryNumber);
                                return of(error.status).delay(this.getWaitBeforeRetry())
                            }

                            this.Log(error);

                            return throwError({ error: error });
                        })
                        .take(this.maxRetries)
                        .concat(throwError({ error: error, reason: `Sorry, there was an error (after ${this.maxRetries} retries)` }));
                });
    }

    protected getNative(url: string, options: any = null, retryCount: number = 1): Observable<any> {
        this.Trace("GetNative is called");
        this.Trace(url);
        this.Trace(retryCount);

        var headers: any = {};
        headers = this.getRequestHeaders(options);

        this.Trace("Now setting up http native properties");
        this.httpNative.setDataSerializer('json');
        this.httpNative.setRequestTimeout(this.nativeTimeout);
        this.httpNative.setServerTrustMode("nocheck");

        this.Trace(headers);
        let request = this.httpNative.get(url, {}, headers);
        return from(request)
            .timeout(this.timeout)
            .map(res => this.extractAllNative(res))
            .retryWhen(error => {
                this.Trace("Error occurred in retryWhen when requesting to server within observable");
                this.Trace(error);
                return error
                    .flatMap((error: any) => {
                        //if (error.status == 0) {
                        //  return throwError({ error: 'Your internet connection is offline. Please connect and retry' });
                        //}

                        if ((error.status >= 500 && error.status < 600) || error.status === 0) {
                            return of(error.status).delay(this.getWaitBeforeRetry())
                        }

                        if (error.name && error.name == "TimeoutError") {
                            this.Log("Time out error identified by retry function and now it will retry", this.currentRetryNumber);
                            return of(error.status).delay(this.getWaitBeforeRetry())
                        }

                        return throwError({ error: error });
                    })
                    .take(this.maxRetries)
                    .concat(throwError({ error: `Sorry, there was an error (after ${this.maxRetries} retries)` }));
            });
    }

    protected getNativeSimple(url: string, options: any = null, retryCount: number = 1): Observable<any> {
        this.Trace("GetNative simple is called");
        this.Trace(url);
        this.Trace(retryCount);

        var headers: any = {};
        headers = this.getRequestHeadersSimple(options);

        this.Trace("Now setting up http native properties");
        this.httpNative.setDataSerializer('json');
        this.httpNative.setRequestTimeout(this.nativeTimeout);
        this.httpNative.setServerTrustMode("nocheck");

        this.Trace(headers);
        let request = this.httpNative.get(url, {}, headers);
        return from(request)
            .timeout(this.timeout)
            .map(res => this.extractAllNative(res))
            .retryWhen(error => {
                this.Trace("Error occurred in retryWhen when requesting to server within observable");
                this.Trace(error);
                return error
                    .flatMap((error: any) => {
                        //if (error.status == 0) {
                        //  return throwError({ error: 'Your internet connection is offline. Please connect and retry' });
                        //}

                        if ((error.status >= 500 && error.status < 600) || error.status === 0) {
                            return of(error.status).delay(this.getWaitBeforeRetry())
                        }

                        if (error.name && error.name == "TimeoutError") {
                            this.Log("Time out error identified by retry function and now it will retry", this.currentRetryNumber);
                            return of(error.status).delay(this.getWaitBeforeRetry())
                        }

                        return throwError({ error: error });
                    })
                    .take(this.maxRetries)
                    .concat(throwError({ error: `Sorry, there was an error (after ${this.maxRetries} retries)` }));
            });
    }

    protected postPwa(url: string, data: any, options: HttpHeaders = null): Observable<any> {
        this.Trace("PostPwa is called");
        this.Trace(url);

        //if (!options) {
        //    options = this.getRequestHeaders();
        //}

        var headers = this.getRequestHeaders(options);

        this.Log(data);
        this.Log(options);
        return this.http
            .post(url, data, { headers: headers })
            .timeout(this.postTimeout)
            .map(res => this.extractAll(res))
            .retryWhen(
                error => {
                    return error
                        .flatMap((error: any) => {
                            //if (error.status == 0) {
                            //  return throwError({ error: 'Your internet connection is offline. Please connect and retry' });
                            //}

                            if (error.status >= 400 && error.stauts < 500) {
                                this.Log('404 error - insidie retryWhen method');
                                return of(error.status);
                            }

                            if ((error.status >= 500 && error.status < 600) || error.status === 0) {
                                return of(error.status).delay(this.getWaitBeforeRetry())
                            }

                            if (error.name && error.name == "TimeoutError") {
                                this.Log("Time out error identified by retry function and now it will retry", this.currentRetryNumber);
                                return of(error.status).delay(this.getWaitBeforeRetry())
                            }

                            this.Log(error);

                            return throwError({ error: error });
                        })
                        .take(this.maxRetries)
                        .concat(throwError({ error: error, reason: `Sorry, there was an error (after ${this.maxRetries} retries)` }));
                });
    }

    protected putPwa(url: string, data: any, options: HttpHeaders = null) {
        this.Trace("PutPwa is called");
        this.Trace(url);

        //if (!options) {
        //    options = this.getRequestHeaders();
        //}

        var headers = this.getRequestHeaders(options);

        this.Log(data);
        return this.http
            .put(url, data, { headers: headers })
            .timeout(this.postTimeout)
            .map(res => this.extractAll(res))
            .retryWhen(
                error => {
                    return error
                        .flatMap((error: any) => {
                            //if (error.status == 0) {
                            //  return throwError({ error: 'Your internet connection is offline. Please connect and retry' });
                            //}

                            if (error.status >= 400 && error.stauts < 500) {
                                this.Log('404 error - insidie retryWhen method');
                                return of(error.status);
                            }

                            if ((error.status >= 500 && error.status < 600) || error.status === 0) {
                                return of(error.status).delay(this.getWaitBeforeRetry())
                            }

                            if (error.name && error.name == "TimeoutError") {
                                this.Log("Time out error identified by retry function and now it will retry", this.currentRetryNumber);
                                return of(error.status).delay(this.getWaitBeforeRetry())
                            }

                            this.Log(error);

                            return throwError({ error: error });
                        })
                        .take(this.maxRetries)
                        .concat(throwError({ error: error, reason: `Sorry, there was an error (after ${this.maxRetries} retries)` }));
                });
    }

    protected getPwa(url: string, options: HttpHeaders = null) {
        this.Trace("GetPwa is called");
        this.Trace(url);

        //if (!options) {
        //    options = this.getRequestHeaders();
        //}

        var headers = this.getRequestHeaders(options);

        this.Log(this.stateService.props$);
        return this.http
            .get(url, { headers: headers })
            .timeout(this.timeout)
            .map(res => this.extractAll(res))
            .retryWhen(error => {
                return error
                    .flatMap((error: any) => {
                        //if (error.status == 0) {
                        //  return throwError({ error: 'Your internet connection is offline. Please connect and retry' });
                        //}
                        this.Log(error);
                        if ((error.status >= 500 && error.status < 600) || error.status === 0) {
                            return of(error.status).delay(this.getWaitBeforeRetry())
                        }

                        if (error.name && error.name == "TimeoutError") {
                            this.Log("Time out error identified by retry function and now it will retry", this.currentRetryNumber);
                            return of(error.status).delay(this.getWaitBeforeRetry())
                        }

                        this.Log(error);
                        return throwError({ error: error });
                    })
                    .take(this.maxRetries)
                    .concat(throwError({ error: `Sorry, there was an error (after ${this.maxRetries} retries)` }));
            });
    }

    protected getPwaSimple(url: string, options: HttpHeaders = null) {
        this.Trace("GetPwa simple is called");
        this.Trace(url);

        //if (!options) {
        //    options = this.getRequestHeaders();
        //}

        var headers = this.getRequestHeadersSimple(options);
        return this.http
            .get(url, { headers: headers })
            .timeout(this.timeout)
            .map(res => this.extractAll(res))
            .retryWhen(error => {
                return error
                    .flatMap((error: any) => {
                        //if (error.status == 0) {
                        //  return throwError({ error: 'Your internet connection is offline. Please connect and retry' });
                        //}

                        this.Log(error);
                        if ((error.status >= 500 && error.status < 600) || error.status === 0) {
                            return of(error.status).delay(this.getWaitBeforeRetry())
                        }

                        if (error.name && error.name == "TimeoutError") {
                            this.Log("Time out error identified by retry function and now it will retry", this.currentRetryNumber);
                            return of(error.status).delay(this.getWaitBeforeRetry())
                        }

                        return throwError({ error: error });
                    })
                    .take(this.maxRetries)
                    .concat(throwError({ error: `Sorry, there was an error (after ${this.maxRetries} retries)` }));
            });
    }

    protected extractAll(res: any) {
        if (res && res.json) {
            let body = res.json();
            return body;
        } else {
            return res;
        }
    }

    protected extractAllNative(res: any) {
        this.Trace("Calling extractAllNative function");
        this.Trace(res);

        if (res) {
            if (!res.data || res.data === ""){
                return JSON.parse("{}");
            } else {
                return JSON.parse(res.data)
            }
        } else {
            return res;
        }
    }

    protected addHeader(key: string, value: string, headers: any = null): any {
        var resultHeaders: any = {};

        if (this.deviceService.isWeb)
        {
            if (headers) {
                resultHeaders = headers.append(key, value);
            } else {
                resultHeaders = new HttpHeaders();
                resultHeaders = resultHeaders.append(key, value);
            }
        } else {
            if (headers) {
                for (let item of Object.keys(headers)) {
                    resultHeaders[item] = headers[item];
                }
            }

            resultHeaders[key] = value;
        }

        return resultHeaders;
    }

    public getPDHeader(includeWeatherData: boolean = false, headers: any = null): any {
        this.Trace("Get PD Header is called");
        var resultHeaders = this.addHeader("X-PD", this.getPData(includeWeatherData), headers);
        this.Trace("PD Headers:");
        this.Trace(resultHeaders);
        return resultHeaders;
    }

    protected getUId(): string {
        if (!this.stateService.device$.puid) {
            this.stateService.setDevice({ puid: uuid() });
        }

        return this.stateService.device$.puid;
    }

    protected getRequestHeaders(headers: any = null): any {
        this.Trace("Now creating request headers");

        headers = this.addHeader('Content-Type', 'application/json', headers);
        headers = this.addHeader('X-Correlation-ID', uuid(), headers);
        headers = this.addHeader('Ocp-Apim-Subscription-Key', environment.taeamApimKey, headers);
        //headers = this.addHeader('api-version', environment.taeamApiVersion, headers);
        headers = this.addHeader('api-version', this.getServiceApiVersion(), headers);
        headers = this.addHeader('x-app-version', environment.version, headers);
        headers = this.addHeader('x-app-release', environment.release.toString(), headers);
        headers = this.addHeader('x-app-id', environment.appId, headers);
        headers = this.addHeader('x-app-variant', environment.appVariant, headers);

        if (!this.stateService.device$.taeam || this.stateService.device$.taeam == undefined) {
            this.stateService.setDevice({ taeam: this.getUId() });
        }
        headers = this.addHeader('x-taeam', this.stateService.device$.taeam, headers);
        headers = this.addHeader('x-local-time', this.getLocalTime(), headers);
        headers = this.addHeader('x-local-day', this.getLocalDay(), headers);
        headers = this.addHeader('x-local-date', this.getLocalDate(), headers);

        var localTimezone = this.getLocalTimezone();
        headers = this.addHeader('x-local-timezone', localTimezone, headers);
        headers = this.addHeader('x-local-country', this.getLocalCountry(localTimezone), headers);

        var localUserIp = this.getUserIp();
        if (this.isNullOrEmpty(localUserIp)) {
            headers = this.addHeader('x-local-userp', localUserIp, headers);
        }

        if (this.wlaActive) {
            headers = this.addHeader('x-wlactive', 'true', headers);
            if (!this.isNullOrEmpty(this.wlaRestaurantGroupId)) {
                headers = this.addHeader('x-wlargid', this.wlaRestaurantGroupId, headers);
            }

            if (!this.isNullOrEmpty(this.wlaRestaurantId)) {
                headers = this.addHeader('x-wlarid', this.wlaRestaurantId, headers);
            }
        }

        if (this.stateService.device$ 
            && this.stateService.device$.appTrackingTransparency 
            && this.stateService.device$.appTrackingTransparency.status) {
            headers = this.addHeader('x-att', this.stateService.device$.appTrackingTransparency.status, headers);
        }

        if (this.stateService.device$ && this.stateService.device$.runCounter) {
            headers = this.addHeader('x-rc', this.stateService.device$.runCounter.toString(), headers);
        }
        headers = this.addHeader('x-uli-key', this.getUliKey(), headers);
        if (this.stateService.props$.defaultCountry) {
            headers = this.addHeader('x-default-country', this.stateService.props$.defaultCountry, headers);
        }
        if (this.stateService.device$.sysLocation) {
            headers = this.addHeader('x-sys-loc', JSON.stringify(this.stateService.device$.sysLocation), headers);
        }

        if (this.stateService.props$ && this.stateService.props$.userCurrentLocation) {
            headers = this.addHeader('x-uc-loc', this.encode(JSON.stringify(this.stateService.props$.userCurrentLocation)), headers);
        }

        if (this.wlaActive && !this.isNullOrEmpty(this.wlaServiceId)) {
            headers = this.addHeader('x-taeam-service', this.wlaServiceId, headers);
        } else if (this.stateService.device$ && this.stateService.device$.selectedService) {
            if (this.stateService.device$.selectedService.serviceName) {
                headers = this.addHeader('x-taeam-service', this.stateService.device$.selectedService.serviceName, headers);
            }
        }

        if (this.stateService.device$ && this.stateService.device$.selectedService) {
            if (this.stateService.device$.selectedService.serviceLocalTimeZone && this.stateService.device$.selectedService.serviceLocalTimeZone.trim().length > 0) {
                headers = this.addHeader('x-service-local-time', this.getLocalTime(this.stateService.device$.selectedService.serviceLocalTimeZone), headers);
                headers = this.addHeader('x-service-local-day', this.getLocalDay(this.stateService.device$.selectedService.serviceLocalTimeZone), headers);
                headers = this.addHeader('x-service-local-date', this.getLocalDate(this.stateService.device$.selectedService.serviceLocalTimeZone), headers);
                if (!this.isNullOrEmpty(this.stateService.device$.selectedService.serviceLocalTimeZone)) {
                    headers = this.addHeader('x-service-local-timezone', this.stateService.device$.selectedService.serviceLocalTimeZone, headers);
                }
            }

            if (!this.isNullOrEmpty(this.stateService.device$.selectedService.serviceApiVersion)) {
                headers = this.addHeader('x-csav', this.stateService.device$.selectedService.serviceApiVersion, headers);
            }

            if (!this.isNullOrEmpty(this.stateService.device$.selectedService.serviceRelease)) {
                headers = this.addHeader('x-csr', this.stateService.device$.selectedService.serviceRelease, headers);
            }
        }

        if (this.stateService.device$ && this.stateService.device$.appmId) {
            headers = this.addHeader('x-appmid', this.stateService.device$.appmId, headers);
        }

        if (this.stateService.props$ && this.stateService.props$.order && !this.isNullOrEmpty(this.stateService.props$.order.paymentGateway)) {
            headers = this.addHeader('x-p-t', this.stateService.props$.order.paymentGateway, headers);
        } else if (this.stateService.props$ && this.stateService.props$.selectedPaymentGateway && !this.isNullOrEmpty(this.stateService.props$.selectedPaymentGateway)) {
            headers = this.addHeader('x-p-t', this.stateService.props$.selectedPaymentGateway, headers);
        }

        if (!this.isNullOrEmpty(environment.xEnvironment)) {
            headers = this.addHeader('x-env', environment.xEnvironment, headers);
        }

        if (this.stateService.props$ && !this.isNullOrEmpty(this.stateService.props$.qStartDate)) {
            headers = this.addHeader('x-s-date', this.stateService.props$.qStartDate, headers);
        };

        if (this.stateService.props$ && !this.isNullOrEmpty(this.stateService.props$.qEndDate)) {
            headers = this.addHeader('x-e-date', this.stateService.props$.qEndDate, headers);
        };

        if (this.stateService.props$ && this.stateService.props$.token) {
            headers = this.addHeader('Authorization', 'Bearer ' + this.stateService.props$.token, headers);
        };

        return headers;
    }

    protected getRequestHeadersSimple(headers: any = null): any {
        headers = this.addHeader('Content-Type', 'application/json', headers);
        return headers;
    }

    protected handleError(error: any) {
        // In a real world app, you might use a remote logging infrastructure
        this.Log("Inside handle error function");
        let result: any;
        let errMsg: string;
        if (error instanceof Response) {
            result = new Object();
            const body: any = error.json() || '';
            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;

            result.IsSuccessful = false;
            result.Message = errMsg;
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        //console.error(errMsg);

        this.Log(result);
        return throwError(result);
        //return result;
    }

    protected getId(): string {
        var id = Guid.create().toString();
        return id.replace("-", "");
    }

    //protected Log(message: any) {
    //     if (environment.production == false) {
    //         console.log(message);
    //     }
    //}

    protected Log(message: any, ...optionalParams: any[]) {
        if (environment.production == false) {
            if (optionalParams && optionalParams.length > 0) {
                console.log(message, optionalParams);
            } else {
                console.log(message);
            }
        }
    }

    protected LogClear() {
        if (environment.production == false) {
            console.clear();
        }
    }

    protected Trace(message: any) {
        if (environment.production == true) {
            this.monitoringService.logTrace(message);
        }

        if (environment.production == false) {
            console.log(message);
        }
    }

    protected delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    protected getErrorMessage(error: Response | any): string {
        let errMsg: string;
        if (error instanceof Response) {

            // Ignore any decode errors
            let body: any = {};
            try {
                body = error.json() || {};
            } catch (e) { }

            const err = body.error || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;

            // No internet, probably
            if (error.status == 0) {
                console.error(errMsg);
                errMsg = 'Your internet connection is offline. Please connect and hit retry';
            }
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        console.error(errMsg);
        return errMsg;
    }

    protected encode(data: string): string {
        return Base64.encode(data);
    }

    protected decode(data: string): string {
        return Base64.decode(data);
    }

    protected getTimeOfDay(timeZone: string = null): string {
        var currentHour = moment().hour();
        if (timeZone) {
            currentHour = moment.tz(timeZone).hour();
        }

        var humanisedTimeOfDay = null; //return g
        var split_afternoon = 12 //24hr time to split the afternoon
        var split_evening = 17 //24hr time to split the evening
        var split_night = 20;
        var split_morning = 6;

        //var currentHour = parseFloat(m.format("HH"));

        if (currentHour >= split_afternoon && currentHour <= split_evening) {
            humanisedTimeOfDay = "Afternoon";
        } else if (currentHour > split_evening && currentHour < split_night) {
            humanisedTimeOfDay = "Evening";
        } else if (currentHour >= split_night || currentHour <= split_morning) {
            humanisedTimeOfDay = "Night";
        } else {
            humanisedTimeOfDay = "Morning";
        }

        return humanisedTimeOfDay;

    }

    protected getDayOfWeek(timeZone: string = null): string {
        var dt = moment();

        if (timeZone) {
            dt = moment().tz(timeZone);
        }

        return dt.format('dddd');
    }

    protected getLocalTime(timeZone: string = null): string {
        var dt = moment();

        if (timeZone) {
            dt = moment().tz(timeZone);
        }

        return dt.format('hh:mm a');
    }

    protected getLocalDay(timeZone: string = null): string {
        var dt = moment();

        if (timeZone) {
            dt = moment().tz(timeZone);
        }

        return dt.day().toString();
    }

    protected getLocalDate(timeZone: string = null): string {
        var dt = moment();

        if (timeZone) {
            dt = moment().tz(timeZone);
        }

        return dt.format('DD/MM/YYYY hh:mm a').toString();
    }    

    protected getLocalTimezone(): string {
        var timezone = moment.tz.guess();
        if (this.isNullOrEmpty(timezone)) {
            return "";
        }

        return timezone;
    }

    protected getLocalCountry(localTimeZone: string = null): string {
        if (!localTimeZone) {
            localTimeZone = this.getLocalTimezone();
        }

        var localZone: any = moment.tz.zone(localTimeZone);
        var countries = localZone.countries();
        if (countries && countries.length > 0) {
            return countries[0];
        } else {
            return "";
        }
    }


    protected isExpired(date: moment.Moment, validForInMinutes: number) {
        return date.isAfter(moment().add(validForInMinutes, 'minutes'))
    }

    protected getPData(includeWeatherData: boolean = false): string {
        var request = {
            weatherData: null,
            timeOfDay: null,
            dayofWeek: null,
            deliveryMethod: null,
            localTime: null,
            serviceTimeOfDay: null
        };

        if (includeWeatherData) {
            request.weatherData = this.stateService.props$.weatherData;
        }

        request.timeOfDay = this.getTimeOfDay();
        request.dayofWeek = this.getDayOfWeek();
        request.localTime = this.getLocalTime();
        if (this.stateService.device$.selectedService && this.stateService.device$.selectedService.serviceLocalTimeZone) {
            request.serviceTimeOfDay = this.getTimeOfDay(this.stateService.device$.selectedService.serviceLocalTimeZone);
        }

        if (!this.stateService.device$.userPreferences || !this.stateService.device$.userPreferences.deliveryMethod) {
            var userPreferences: any = {};
            if (this.stateService.device$.userPreferences) {
                userPreferences = this.stateService.device$.userPreferences;
                userPreferences.deliveryMethod = DeliveryMethods.Delivery;
            } else {
                userPreferences = { userLocation: this.stateService.props$.userLocation, deliveryMethod: DeliveryMethods.Delivery };
            }

            this.stateService.setDevice({ userPreferences });
        }

        request.deliveryMethod = this.stateService.device$.userPreferences.deliveryMethod;

        var result = this.encode(JSON.stringify(request));
        return result;
    }

    protected getServiceApiVersion(): any {
        if (this.stateService.device$ && this.stateService.device$.selectedService && !this.isNullOrEmpty(this.stateService.device$.selectedService.serviceApiVersion)) {
            return this.stateService.device$.selectedService.serviceApiVersion;
        }

        return environment.taeamApiVersion;
    }

    protected debugCore() {
        this.Log("****************");
        this.Log("Debug core data");
        this.Log("****************");
        this.Log("Device:")
        this.Log(this.stateService.device$);
        this.Log("State:")
        this.Log(this.stateService.state$);
        this.Log("Props:")
        this.Log(this.stateService.props$);
        this.Log("****************");
    }

    public clone(obj: any): any {
        var cloneObj = {};

        if (obj) {
            //for (let key of Object.keys(obj)) {
            //    cloneObj[key] = obj[key];
            //}
            cloneObj = JSON.parse(JSON.stringify(obj));
            this.Log(cloneObj);
        }

        return cloneObj;
    }

    public getJson(data: any): string {
        if (data) {
            return JSON.stringify(data);
        }

        return null;
    }

    public reportConversion() {
        gtag_report_conversion(window.location.href);
    }

    public isNullOrEmpty(val: any): boolean {
        if (val == null || val == undefined || val.length == null || (val.trim && (val.trim() == "" || val.trim().length == 0))) {
            return true;
        } else {
            return false;
        }
    }

    public copyToClipboard(data: string) {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', data);
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
    };

    public contains(source: string, value: string): boolean {
        if (!this.isNullOrEmpty(source) && !this.isNullOrEmpty(value)) {
            return source.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        }

        return false;
    }

    public isOdd(n): boolean {
        return Math.abs(n % 2) == 1;
    }

    public getRecentlyViewedLimit(): number {
        if (this.wlaActive) {
            return this.wlaRecentlyViewedLimit;
        } else {
            return this.recentlyViewedLimit;
        }
    }

    private createSearchParams(params: any) {
        let searchParams = new URLSearchParams();
        for (let k in params) {
            if (params.hasOwnProperty(k)) {
                searchParams.set(k, params[k]);
            }
        }

        return searchParams;
    }

    private getUliKey() {
        var userLocation = { latitude: 0, longitude: 0, address: null, postcode: null, city: null, state: null, country: null };

        if (this.stateService.props$ && this.stateService.props$.userLocation) {
            userLocation.latitude = this.stateService.props$.userLocation.latitude;
            userLocation.longitude = this.stateService.props$.userLocation.longitude;
            userLocation.address = this.stateService.props$.userLocation.address;
            userLocation.postcode = this.stateService.props$.userLocation.postcode;
            userLocation.city = this.stateService.props$.userLocation.city;
            userLocation.state = this.stateService.props$.userLocation.state;
            userLocation.country = this.stateService.props$.userLocation.country;
        } else if (this.stateService.device$ && this.stateService.device$.userPreferences && this.stateService.device$.userPreferences.userLocation) {
            userLocation.latitude = this.stateService.device$.userPreferences.userLocation.latitude;
            userLocation.longitude = this.stateService.device$.userPreferences.userLocation.longitude;
            userLocation.address = this.stateService.device$.userPreferences.userLocation.address;
            userLocation.postcode = this.stateService.device$.userPreferences.userLocation.postcode;
            userLocation.city = this.stateService.device$.userPreferences.userLocation.city;
            userLocation.state = this.stateService.device$.userPreferences.userLocation.state;
            userLocation.country = this.stateService.device$.userPreferences.userLocation.country;
        } else if (this.stateService.props$ && this.stateService.props$.geo && this.stateService.props$.geo.coords) {
            userLocation.latitude = this.stateService.props$.geo.coords.latitude;
            userLocation.longitude = this.stateService.props$.geo.coords.longitude;
        } else if (this.stateService.device$ && this.stateService.device$.geo && this.stateService.device$.geo.coords) {
            userLocation.latitude = this.stateService.device$.geo.coords.latitude;
            userLocation.longitude = this.stateService.device$.geo.coords.longitude;
        } else {
            userLocation = null;
        }

        if (userLocation) {
            return JSON.stringify(userLocation);
        } else {
            return JSON.stringify({});
        }        
    }

    public getUserIp(): string {
        if (window) {
            var ip: string = window["userIp"];
            return this.toHex(ip);
        } else {
            return "";
        }
    }

    public toHex(str): string {
        if (this.isNullOrEmpty(str)) {
            return ""
        }
        var result = '';
        for (var i = 0; i < str.length; i++) {
            result += str.charCodeAt(i).toString(16);
        }
        return result;
    }

    public hexToUtf8(hex) {
        return decodeURIComponent('%' + hex.match(/.{1,2}/g).join('%'));
    }

    public isSame(value1: string, value2: string, allowMultiple: boolean = false): boolean {
        if (!allowMultiple) {
            if (!this.isNullOrEmpty(value1) && !this.isNullOrEmpty(value2)) {
                value1 = value1.toLowerCase();
                value2 = value2.toLowerCase();
            }

            return value1 === value2;
        } else {
            if (!this.isNullOrEmpty(value1) && !this.isNullOrEmpty(value2)) {
                return this.contains(value1, value2);
            }
        }

        return false;
    }

    public hasData(data: any): boolean {
        if (data && data.length > 0) {
            return true;
        }

        return false;
    }

    public getTodayDate(): any {
        return moment();
    }

    public getIsoTodayDate(): any {
        return moment().toISOString();
    }

    public getIsoDate(date: any): any {
        return moment(date).toISOString();
    }

    public getPreviousDayIsoDate(date: any): any {
        var result = moment(date).add(-1, 'days');
        return result.toISOString();
    }

    public getDays(date1, date2, inclusive: boolean = true): any {

        if (this.isNullOrEmpty(date1) || this.isNullOrEmpty(date2)) {
            return null;
        }

        var dt1 = moment(date1);
        var dt2 = moment(date2);
        var days = 0;

        if (dt1 > dt2) {
            days = dt1.diff(dt2, 'days');
        } else {
            days = dt2.diff(dt1, 'days');
        }

        if (inclusive) {
            days++; //Add a day in total to include start date as first day
        }

        return days;
    }

    public getMonths(date1, date2, inclusive: boolean = true): any {

        if (this.isNullOrEmpty(date1) || this.isNullOrEmpty(date2)) {
            return null;
        }

        var dt1 = moment(date1);
        var dt2 = moment(date2);
        var months = 0;

        if (dt1 > dt2) {
            months = dt1.diff(dt2, 'months');
        } else {
            months = dt2.diff(dt1, 'months');
        }

        if (inclusive) {
            months++; //Add a month in total to include start date as first month
        }

        return months;
    }

    public getAllWeekDays(): any {
        var data = [];
        data.push({ dayNumber: 1, shortName: 'Mon', fullName: 'Monday' });
        data.push({ dayNumber: 2, shortName: 'Tue', fullName: 'Tuesday' });
        data.push({ dayNumber: 3, shortName: 'Wed', fullName: 'Wednesday' });
        data.push({ dayNumber: 4, shortName: 'Thu', fullName: 'Thursday' });
        data.push({ dayNumber: 5, shortName: 'Fri', fullName: 'Friday' });
        data.push({ dayNumber: 6, shortName: 'Sat', fullName: 'Saturday' });
        data.push({ dayNumber: 7, shortName: 'Sun', fullName: 'Sunday' });
        return data;
    }

    public getDayObj(dayName: string): any {
        if (this.isNullOrEmpty(dayName)) {
            return null;
        }

        if (dayName == "Mon") {
            return { dayNumber: 1, shortName: 'Mon', fullName: 'Monday' };
        } else if (dayName == "Tue") {
            return { dayNumber: 2, shortName: 'Tue', fullName: 'Tuesday' };
        } else if (dayName == "Wed") {
            return { dayNumber: 3, shortName: 'Wed', fullName: 'Wednesday' };
        } else if (dayName == "Thu") {
            return { dayNumber: 4, shortName: 'Thu', fullName: 'Thursday' };
        } else if (dayName == "Fri") {
            return { dayNumber: 5, shortName: 'Fri', fullName: 'Friday' };
        } else if (dayName == "Sat") {
            return { dayNumber: 6, shortName: 'Sat', fullName: 'Saturday' };
        } else if (dayName == "Sun") {
            return { dayNumber: 7, shortName: 'Sun', fullName: 'Sunday' };
        }

        return null;
    }

    public listContains(dataList: any, fieldName: string, value: any): boolean {
        if (!this.hasData(dataList) || this.isNullOrEmpty(fieldName) || this.isNullOrEmpty(value)) {
            return false;
        }

        var temp = dataList.filter((x) => x[fieldName] == value);
        if (temp && temp.length > 0) {
            return true;
        }

        return false;
    }

    public getDateOnly(value: any): moment.Moment {
        var date = moment(value);
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        return date;
    }

    public getDateOnlyAsString(value: any, seperator: string = "-"): string {
        if (value) {
            var date = moment(value);
            date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            var month = date.format('M');
            var day = date.format('D');
            var year = date.format('YYYY');
            return year + seperator + month + seperator + day;
        }

        return null;
    }

    public setQueryDates(startDate: any, endDate: any) {
        var sdate = this.getDateOnlyAsString(startDate);
        var edate = this.getDateOnlyAsString(endDate);
        this.setStartDate(sdate);
        this.setEndDate(edate);
    }

    public isSameDate(date1: any, date2: any): boolean {
        var dt1 = this.getDateOnly(date1);
        var dt2 = this.getDateOnly(date2);
        return dt1.isSame(dt2);
    }

    public comparer(n1, n2) {
        if (n1 > n2) {
            return 1;
        }

        if (n1 < n2) {
            return -1;
        }

        return 0;
    }

    public serviceCategoryOrderComparer(n1, n2) {
        if (this.isNullOrEmpty(n1.serviceCategoryOrder)) {
            return 1;
        }

        if (n1.serviceCategoryOrder > n2.serviceCategoryOrder) {
            return 1;
        }

        if (n1.serviceCategoryOrder < n2.serviceCategoryOrder) {
            return -1;
        }

        return 0;
    }

    public dateComparer(n1, n2) {

        var dt1 = moment(n1);
        var dt2 = moment(n2);

        if (dt1 > dt2) {
            return 1;
        }

        if (dt1 < dt2) {
            return -1;
        }

        return 0;
    }

    public weekDayNumberComparer(n1, n2) {
        if (n1.dayNumber > n2.dayNumber) {
            return 1;
        }

        if (n1.dayNumber < n2.dayNumber) {
            return -1;
        }

        return 0;
    }

    public setStartDate(date: any) {
        this.stateService.setProps({ qStartDate: date });
    }

    public setEndDate(date: any) {
        this.stateService.setProps({ qEndDate: date });
    }

    public resetQueryData() {
        this.stateService.setProps({ qStartDate: null, qEndDate: null });
    }

    private getWaitBeforeRetry() {

        this.currentRetryNumber++;

        if (this.currentRetryNumber == 1) {
            return 2000; //first attempt a little longer just in case if required things are not in place for serve;
        }

        return this.waitBeforeRetry;
    }

    private resetWaitRetryCounter() {
        this.currentRetryNumber = 0;
    }
}

export declare enum ResponseContentType {
    Text = 0,
    Json = 1,
    ArrayBuffer = 2,
    Blob = 3
}