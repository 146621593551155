import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import 'moment-timezone';
import { Common } from '../shared/common';

declare var google: any;

@Injectable({ providedIn: 'root'})
export class RecentlyViewedService extends ServiceBase {

    private static _instance: RecentlyViewedService;

    private getRecentlyViewedApiUrl: string;
    private updateRecentlyViewedApiUrl: string;
    private removeRecentlyViewedApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        //this.getRecentlyViewedApiUrl = environment.removeRecentlyViewedApiUrl + 'recentlyviewed/get';
        //this.updateRecentlyViewedApiUrl = environment.removeRecentlyViewedApiUrl + 'recentlyviewed/update';
        //this.removeRecentlyViewedApiUrl = environment.removeRecentlyViewedApiUrl + 'recentlyviewed/remove';
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance || (this._instance = Common.InjectorInstance.get<RecentlyViewedService>(RecentlyViewedService));
    }

    public addOrUpdate(serviceName: string, restaurantId: string, item: any): Observable<any> {
        if (!this.stateService.device$.recentlyViewed) {
            this.stateService.setDevice({ recentlyViewed: new Array<any>() });
        }

        var recentlyViewed = this.stateService.device$.recentlyViewed;
        var data = {};

        if (item.menuItemName) {
            var items = recentlyViewed.filter((_item) => _item.data.menuItemName == item.menuItemName && _item.restaurantId == restaurantId);
            if (items && items.length > 0) {
                data = { data: item, restaurantId: restaurantId, serviceName: serviceName };
                //this.post(this.removeRecentlyViewedApiUrl, data).subscribe(result => {});
                recentlyViewed = recentlyViewed.filter((_item) => _item.data.name != item.menuItemName && _item.restaurantId == restaurantId);
                this.save(recentlyViewed);
            } else {
                this.add(serviceName, restaurantId, item);
            }
        } else if (item.id) {
            var items = recentlyViewed.filter((_item) => _item.data.id == item.id && _item.restaurantId == restaurantId);
            if (items && items.length > 0) {
                data = { data: item, restaurantId: restaurantId, serviceName: serviceName };
                //this.post(this.removeRecentlyViewedApiUrl, data).subscribe(result => {});
                recentlyViewed = recentlyViewed.filter((_item) => _item.data.id != item.id && _item.restaurantId == restaurantId);
                this.save(recentlyViewed);
            } else {
                this.add(serviceName, restaurantId, item);
            }
        } else {
            this.add(serviceName, restaurantId, item);
        }        

        return Observable.of(item);
    }

    public update(serviceName: string, restaurantId: string, item: any): Observable<any> {
        if (!this.stateService.device$.recentlyViewed) {
            this.stateService.setDevice({ recentlyViewed: new Array<any>() });
        }

        var recentlyViewed = this.stateService.device$.recentlyViewed;

        if (item.menuItemName) {
            var items = recentlyViewed.filter((_item) => _item.data.menuItemName == item.menuItemName && _item.restaurantId == restaurantId);
            if (items && items.length > 0) {
                items[0].data = { data: item, restaurantId: restaurantId, serviceName: serviceName };
                this.save(recentlyViewed);
            } 
        } else if (item.id) {
            var items = recentlyViewed.filter((_item) => _item.data.id == item.id && _item.restaurantId == restaurantId);
            if (items && items.length > 0) {
                items[0].data = { data: item, restaurantId: restaurantId, serviceName: serviceName };
                this.save(recentlyViewed);
            } 
        }

        return Observable.of(item);
    }

    public exists(restaurantId: string, item: any): boolean {
        var recentlyViewed = this.stateService.device$.recentlyViewed;

        if (recentlyViewed) {
            if (item.menuItemName) {
                var items = recentlyViewed.filter((_item) => _item.data.menuItemName == item.menuItemName && _item.restaurantId == restaurantId);
                if (items && items.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else if (item.id) {
                var items = recentlyViewed.filter((_item) => _item.data.id == item.id && _item.restaurantId == restaurantId);
                if (items && items.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } 
        }

        return false;
    }

    public add(serviceName: string, restaurantId: string, item: any): Observable<any> {

        if (!this.stateService.device$.recentlyViewed) {
            this.stateService.setDevice({ recentlyViewed: new Array<any>() });
        }

        var recentlyViewed = this.stateService.device$.recentlyViewed;
        //this.post(this.updateRecentlyViewedApiUrl, { data: item, restaurantId: restaurantId }).subscribe(result => { });

        this.getList(serviceName).subscribe( existingItems => {            
            if (existingItems && existingItems.length > this.getRecentlyViewedLimit()) {
                recentlyViewed.splice(0, 1);
            }
        });

        recentlyViewed.push({ data: item, restaurantId: restaurantId, serviceName: serviceName });
        this.save(recentlyViewed);

        return Observable.of({ data: item, restaurantId: restaurantId, serviceName: serviceName });
    }

    public remove(restaurantId: string, item: any): Observable<any> {
        if (!this.stateService.device$.recentlyViewed) {
            return Observable.of({ data: item, restaurantId: restaurantId });
        }

        var recentlyViewed = this.stateService.device$.recentlyViewed;
        recentlyViewed = recentlyViewed.filter(x => x.data.id == x.data.id && x.restaurantId == restaurantId);
        this.stateService.setDevice({ recentlyViewed: recentlyViewed });

        return Observable.of(item);
    }

    public getList(serviceName: string, id: any = null): Observable<Array<any>> {

        var recentlyViewed = this.stateService.device$.recentlyViewed;
        var items = [];
        if (!recentlyViewed || recentlyViewed.length == 0) {
            //this.get(this.getRecentlyViewedApiUrl).subscribe(result => {
            //    this.save(result.recentlyViewed);                
            //});
            return Observable.of(null);
        } else {
            //this.get(this.getRecentlyViewedApiUrl).subscribe(result => {
            //    this.save(result.recentlyViewed);                
            //});

            if (serviceName) {

                if (id) {
                    items = recentlyViewed.filter((_item) => _item.serviceName == serviceName && _item.restaurantId == id);
                    return Observable.of(items);
                } else {
                    items = recentlyViewed.filter((_item) => _item.serviceName == serviceName);
                    return Observable.of(items);
                }
            }

            return Observable.of(null);
        }
    }

    public search(serviceName: string, text: string): Observable<Array<any>> {
        var recentlyViewed = this.stateService.device$.recentlyViewed;

        if (!recentlyViewed) {
            return Observable.of(null);
        }

        var items = recentlyViewed.filter((_item) => _item.data.serviceName == serviceName && this.contains(_item.menuItemName, text));
        return Observable.of(items);
    }

    public save(recentlyViewed: any) {
        this.stateService.setDevice({ recentlyViewed: recentlyViewed });
    }

    async setupRecentlyViewedItems(data: Array<any>) {
        if (data && data.length > 0) {
        }
    }    
}