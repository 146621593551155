import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service.base';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import 'moment-timezone';
import { Common } from '../shared/common';

declare var google: any;

@Injectable({ providedIn: 'root'})
export class FavouritesService extends ServiceBase {

    private static _instance: FavouritesService;

    private getFavouritesApiUrl: string;
    private updateFavouritesApiUrl: string;
    private removeFavouritesApiUrl: string;

    constructor(
        protected http: HttpClient,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.getFavouritesApiUrl = environment.favouritesApiUrl + 'favourites/get';
        this.updateFavouritesApiUrl = environment.favouritesApiUrl + 'favourites/update';
        this.removeFavouritesApiUrl = environment.favouritesApiUrl + 'favourites/remove';
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance || (this._instance = Common.InjectorInstance.get<FavouritesService>(FavouritesService));
    }

    public addOrRemove(item: any, type: string = "marketplace"): Observable<any> {
        if (!this.stateService.device$.favourites) {
            this.stateService.setDevice({ favourites: new Array<any>() });
        }

        var favourites = this.stateService.device$.favourites;
        var data = {};

        if (item.id) {
            var items = favourites.filter((_item) => _item.data.id == item.id && _item.type == type);
            if (items && items.length > 0) {
                data = { data: this.convertMarketplaceToFavouriteObject(item, type), type: type };
                this.post(this.removeFavouritesApiUrl, data).subscribe(result => {});
                favourites = favourites.filter((_item) => _item.data.id != item.id);
                this.Log(favourites);
                this.save(favourites);
            } else {
                this.add(item, type);
            }
        } else if (item.name) {
            var items = favourites.filter((_item) => _item.data.name == item.name && _item.type == type);
            if (items && items.length > 0) {
                this.post(this.removeFavouritesApiUrl, data).subscribe(result => {});
                favourites = favourites.filter((_item) => _item.data.name != item.name && _item.type != type);
                this.Log(favourites);
                this.save(favourites);
            } else {
                this.add(item, type);
            }
        } else {
            this.add(item, type);
        }        

        return Observable.of(item);
    }

    public update(item: any, type: string = "marketplace"): Observable<any> {

        if (item != null) {
            if (!this.stateService.device$.favourites) {
                this.stateService.setDevice({ favourites: new Array<any>() });
            }

            var favourites = this.stateService.device$.favourites;

            if (item.id) {
                var items = favourites.filter((_item) => _item.data.id == item.id && _item.type == type);
                if (items && items.length > 0) {
                    items[0].data = this.convertMarketplaceToFavouriteObject(item, type);
                    this.save(favourites);
                }
            } else if (item.name) {
                var items = favourites.filter((_item) => _item.data.name == item.name && _item.type == type);
                if (items && items.length > 0) {
                    items[0].data = this.convertMarketplaceToFavouriteObject(item, type);
                    this.save(favourites);
                }
            }
        }

        return Observable.of(item);
    }

    public exists(item: any, type: string = "marketplace"): boolean {
        var favourites = this.stateService.device$.favourites;

        if (favourites) {
            if (item.id) {
                var items = favourites.filter((_item) => _item.data.id == item.id && _item.type == type);
                if (items && items.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else if (item.name) {
                var items = favourites.filter((_item) => _item.data.name == item.name && _item.type == type);
                if (items && items.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } 
        }

        return false;
    }

    public add(item: any, type: string = "marketplace"): Observable<any> {

        if (!this.stateService.device$.favourites) {
            this.stateService.setDevice({ favourites: new Array<any>() });
        }

        var favourites = this.stateService.device$.favourites;
        this.post(this.updateFavouritesApiUrl, { data: this.convertMarketplaceToFavouriteObject(item, type), type: type }).subscribe(result => { });

        favourites.push({ data: this.convertMarketplaceToFavouriteObject(item, type), type: type });
        this.save(favourites);

        return Observable.of({ data: this.convertMarketplaceToFavouriteObject(item, type), type: type });
    }

    public remove(item: any, type: string = "marketplace"): Observable<any> {
        if (!this.stateService.device$.favourites) {
            return Observable.of({ data: item, type: type });
        }

        var favourites = this.stateService.device$.favourites;
        favourites = favourites.filter(x => x.data.id == x.data.id && x.type == type);
        this.stateService.setDevice({ favourites: favourites });

        return Observable.of(item);
    }

    public getList(): Observable<Array<any>> {

        if (!this.stateService.device$.favourites || this.stateService.device$.favourites.length == 0) {
            this.get(this.getFavouritesApiUrl).subscribe(result => {
                this.save(result.favourites);                
            });
            return Observable.of(null);
        } else {
            this.get(this.getFavouritesApiUrl).subscribe(result => {
                this.save(result.favourites);                
            });

            return Observable.of(this.stateService.device$.favourites);
        }
    }

    public search(text: string): Observable<Array<any>> {
        if (!this.stateService.device$.favourites) {
            return Observable.of(null);
        }

        var favourites = this.stateService.device$.favourites;

        return Observable.of(favourites);
    }

    public save(favourites: any) {
        this.stateService.setDevice({ favourites: favourites });
    }

    async setupFavourites(data: Array<any>) {

        if (data && data.length > 0) {
        }
    } 

    private convertMarketplaceToFavouriteObject(data: any, type: string): any {
        var item = this.clone(data);

        var result: any = {};
        if (!this.isNullOrEmpty(item.adKey)) {
            result.adKey = item.adKey;
        } else {
            result.nameKey = item.nameKey;
        }

        if (!this.isNullOrEmpty(item.title)) {
            result.name = item.title;
        } else {
            result.name = item.name;
        }

        result.dataType = type;
        result.id = item.id;
        result.timeZone = item.timeZone;
        result.avgOrderReadyTimeInMinutes = item.avgOrderReadyTimeInMinutes;
        result.address = item.address;
        result.assetImageUrl = item.assetImageUrl;
        result.assetUrls = item.assetUrls;
        result.contactNo = item.contactNo;
        result.country = item.country;
        result.description = item.description;
        result.foodTypes = item.foodTypes;
        result.categories = item.categories;
        result.heading = item.heading;
        result.imageUrl = item.imageUrl;
        result.imagesAndVideos = item.imagesAndVideos;
        result.menuTemplateId = item.menuTemplateId;
        result.multipleDisplayAssetUrls = item.multipleDisplayAssetUrls;
        result.postcode = item.postcode;
        result.serviceName = item.serviceName;
        result.town = item.town;
        result.videoUrl = item.videoUrl;
        result.firstOrderTime = item.firstOrderTime;
        result.lastOrderTime = item.lastOrderTime; 
        result.latitude = item.latitude; 
        result.longitude = item.longitude;

        if (type == 'classifiedad') {
            result.title = item.title;
            result.adParentCategory = item.adParentCategory;
            result.adCategory = item.adCategory;
            result.condition = item.condition;
            result.price = item.price;
            result.tax = item.tax;
            result.priceSymbol = item.priceSymbol;
        } else if (type == 'serviceresourcead') {
            result.title = item.title;
            result.adParentCategory = item.adParentCategory;
            result.adCategory = item.adCategory;
            result.condition = item.condition;
            result.price = item.price;
            result.tax = item.tax;
            result.priceSymbol = item.priceSymbol;
            result.hourlyRate = item.hourlyRate;
            result.dailyRate = item.dailyRate;
        }            

        return result;
    }
}