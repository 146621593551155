import { Injectable } from '@angular/core';
import { ServiceBase } from './service.base';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { Common } from '../shared/common';
import { PersonlisationService } from './personalisation.service';
import { AppAvailability } from '@ionic-native/app-availability/ngx';

@Injectable({
    providedIn: 'root'
})
export class MarketingService extends ServiceBase {

    private static _instance: MarketingService;

    constructor(
        protected http: HttpClient,
        private personalisationService: PersonlisationService,
        private appAvailability: AppAvailability,
        protected httpNative: HTTP) {
        super(http, httpNative);
        this.personalisationService = PersonlisationService.Instance;
        //this.getRestaurantApiUrl = environment.restaurantApiUrl + 'Restaurant/';
    }

    public static get Instance() {
        // Do you need arguments? Make it a regular method instead.
        return this._instance || (this._instance = Common.InjectorInstance.get<MarketingService>(MarketingService));
    }

    public async getMarketingLink(id: string, app: string = "capp"): Promise<any> {
        var result: any = {};
        var url: string = "";
        //this.personalisationService.logInHeaders('marketing', 'qrcode-scan', JSON.stringify({ id: id }));

        this.Log("Get marketing is called");
        if (app == "capp") {
            url = await this.getAppUrl();
        } else if (app == "cmapp") {
            url = await this.getMarketplaceAppUrl();
        } else if (app == "cdapp") {
            url = await this.getDeliveryAppUrl();
        } else {
            url = await this.getAppUrl();
        } 

        result.url = url;
        //result.isWeb = deviceInfo.isWeb;

        this.appAvailability.check(app)
            .then(
                (yes: boolean) => {
                    result.canOpen = true;
                    result.url = null;
                },
                (no: boolean) => {

                });

        this.Log(result); 
        return result;
    }

    public async getAppUrl(): Promise<string> {
        var deviceInfo = await this.deviceService.getDeviceInfo();
        var url: string = "";

        if (deviceInfo && deviceInfo.isiOSDevice) {
            url = "https://apps.apple.com/gb/app/crazon/id1550205085";
        } else if (deviceInfo && deviceInfo.isAndroidDevice) {
            url = "https://play.google.com/store/apps/details?id=com.crazon.app";
        } else {
            url = "https://www.crazon.io";
        }

        return url;
    }

    public async getMarketplaceAppUrl(): Promise<string> {
        var deviceInfo = await this.deviceService.getDeviceInfo();
        var url: string = "";

        if (deviceInfo && deviceInfo.isiOSDevice) {
            url = "https://apps.apple.com/gb/app/crazon/id1558944925";
        } else if (deviceInfo && deviceInfo.isAndroidDevice) {
            url = "https://play.google.com/store/apps/details?id=com.crazon.store.mobile.app";
        } else {
            url = "https://www.crazon.io";
        }

        return url;
    }

    public async getDeliveryAppUrl(): Promise<string> {
        var deviceInfo = await this.deviceService.getDeviceInfo();
        var url: string = "";

        if (deviceInfo && deviceInfo.isiOSDevice) {
            url = "https://apps.apple.com/gb/app/crazon/id1558956764";
        } else if (deviceInfo && deviceInfo.isAndroidDevice) {
            url = "https://play.google.com/store/apps/details?id=com.crazon.delivery.app";
        } else {
            url = "https://www.crazon.io";
        }

        return url;
    }

}
